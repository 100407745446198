import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

class BuyerDocForm extends Component {
  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, meta, disabled, type, maxLength }) => {
    return (
      <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
        <label className="d-block">{label}</label>
        <div className="input-field">
          <input
            {...input}
            type={type}
            maxLength={maxLength}
            autoComplete="off"
            placeholder={label}
            className="form-control"
            disabled={disabled}
          />
        </div>
        <p
          className="linkBtn2 d-block overflow-hidden pt-2 w-100"
          data-bs-toggle="modal"
          data-bs-target="#viewDocumentsModel"
        >
          View Uploaded Documents
        </p>
        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
  };

  renderFileInput = ({ input, label, meta, disabled, type, maxLength }) => {
    // const { mime } = this.props;
    return (
      <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
        <label className="d-block">{label}</label>
        <div className="input-field">
          <input
            name={input.name}
            type={type}
            className="form-control"
            // accept={mimke}
            onChange={(event) => this.handleChange(event, input)}
          />
        </div>

        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
    // return (
    //   <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
    //     <label className="d-block">{label}</label>
    //     <div className="input-field">
    //       <input
    //         {...input}
    //         type={type}
    //         maxLength={maxLength}
    //         autoComplete="off"
    //         placeholder={label}
    //         className="form-control"
    //         disabled={disabled}
    //         onChange={(event) => this.handleChange(event, input)}
    //       />
    //     </div>
    //     <div className="error">{this.renderError(meta)}</div>
    //   </div>
    // );
  };

  handleChange = (event, input) => {
    event.preventDefault();
    let imageFile = event.target.files[0];
    input.onChange(imageFile);
    // if (imageFile) {
    //   const localImageUrl = URL.createObjectURL(imageFile);
    //   const imageObject = new window.Image();

    //   imageObject.onload = () => {
    //     imageFile.width = imageObject.naturalWidth;
    //     imageFile.height = imageObject.naturalHeight;
    //     input.onChange(imageFile);
    //     URL.revokeObjectURL(imageFile);
    //   };
    //   imageObject.src = localImageUrl;
    // }
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <div className="mainBodyCard pt-30">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          {this.props.LDAPUser[0] || this.props.EditUser ? (
            <div className="row m-0">
              <Field
                name="DocumentType"
                component={this.renderInput}
                label="Document Type"
                type="text"
              />
              <Field
                name="Document"
                component={this.renderFileInput}
                label="Document"
                type="file"
              />

              <div className="mainBodyCardFtBtn">
                <button className="btnMain">Save</button>
              </div>
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    LDAPUser: Object.values(state.LDAPUser),
  };
};

const validate = (formValues) => {
  const errors = {};
  if (!formValues.user_id) {
    errors.user_id = "This Field is Required";
  }
  return errors;
};

const buyerDocForm = reduxForm({
  form: "BuyerDocForm",
  validate,
  enableReinitialize: true,
})(BuyerDocForm);

export default connect(mapStateToProps, null)(buyerDocForm);
