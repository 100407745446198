import React, { Component } from "react";
import EventForm from "./EventForm";
import { connect } from "react-redux";
import { fetchEventMaster } from "../../actions/masters/EventMasterActions";
import { createEvent } from "../../actions/event/EventActions";
import { Link } from "react-router-dom";
import history from "../../history";
import jwt_decode from "jwt-decode";

class EventCreate extends Component {
  componentDidMount() {
    this.props.fetchEventMaster();
  }

  onSubmit = (formValues) => {
    if (!formValues.typeOfContract) {
      formValues.typeOfContract = "Goods";
    }
    if (!formValues.keywords) {
      formValues.keywords = "Auction";
    }
    formValues.auctionDetails = formValues.remarks;
    this.props.createEvent(formValues);
  };

  render() {
   
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Event Create</h3>
          </div>{" "}
          <div className="float-end mt-15 ">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        <EventForm
          initialValues={{
            ...this.props.eventMaster[0],
            department: "Auto ICLM",
            officer: this.props.tokendata.username,
          }}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var tokendata = localStorage.getItem("userToken")
    ? jwt_decode(localStorage.getItem("userToken"))
    : null;
  return {
    eventMaster: Object.values(state.eventMaster),
    eventLoading: state.event.isCreateEventSaving,
    tokendata,
  };
};

export default connect(mapStateToProps, { fetchEventMaster, createEvent })(
  EventCreate
);
