import {
  ADD_EVENT_DOC,
  FETCH_EVENT_DOC,
  // ADD_EVENT_DOCsStart,
  // ADD_EVENT_DOCsEnd,
  DELETE_EVENT_DOCUMENT,
  CLEAR_EVENT_DOCs,
} from "../../actions/types";
import _ from "lodash";

const eventDocumentReducers = (state = {}, action) => {
  switch (action.type) {
    case ADD_EVENT_DOC:
      return { ...state, [action.payload.id]: action.payload };
    case FETCH_EVENT_DOC:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case DELETE_EVENT_DOCUMENT:
      console.log(action);
    // return _.omit(state,action.payload);
    case CLEAR_EVENT_DOCs:
      return {};
    default:
      return state;
  }
};

export default eventDocumentReducers;
