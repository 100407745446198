import AuctionPortal from "../../apis/AuctionPortal";
import history from "../../history";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isSessionExpired, userLogout } from "../login/loginActions";

import {
  ADD_EMPANELMENT_BUYER_DOCs,
  ADD_EMPANELMENT_BUYER_DOC,
  FETCH_DIGILOCKER_LIST,
  FETCH_EMPANELMENT_BUYER_DOCs,
  USER_LOGOUT,
} from "../types";
import { encryptId } from "../../components/Encryption";

toast.configure({
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
});

export const addManualBuyerEmpanelmentDocument =
  (id, formvalues) => async (dispatch) => {
    if (isSessionExpired()) {
      history.push("/SessionExpired");
    } else {
      try {
        document.getElementById("spinner").classList.add("spinnerDisplay");
        var bodyFormData = new FormData();
        bodyFormData.append("DocumentType", formvalues.DocumentType);
        bodyFormData.append("Document", formvalues.Document);
        const response = await AuctionPortal.post(
          `/Buyer/${encryptId(id)}/Documents`,
          bodyFormData,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          }
        );
        dispatch({
          type: ADD_EMPANELMENT_BUYER_DOC,
          payload: response.data,
        });
        toast("Created Successfully");
      } catch (error) {
        switch (error.response.status) {
          case 408:
            history.push("/sessionExpired");
            dispatch({ type: USER_LOGOUT });
            break;
          case 401:
            history.push("/NotAuthorised");
            break;
          case 403:
            history.push("/acessforbidden");
            break;
          case 404:
            history.push("/PageNotFound");
            break;
          case 400:
            if (error.response.data.errors) {
              alert(JSON.stringify(error.response.data.errors));
            } else {
              toast.error(error.response.data.Description[0]);
            }
            break;
          default:
            toast.error("Error Occurred");
        }
      } finally {
        document.getElementById("spinner").classList.remove("spinnerDisplay");
      }
    }
  };

export const addBuyerEmpanelmentDocument =
  (id, formvalues) => async (dispatch) => {
    if (isSessionExpired()) {
      history.push("/SessionExpired");
    } else {
      try {
        document.getElementById("spinner").classList.add("spinnerDisplay");
        const response = await AuctionPortal.post(
          `/digilocker/upload/${encryptId(id)}`,
          formvalues,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          }
        );
        dispatch({
          type: ADD_EMPANELMENT_BUYER_DOCs,
          payload: response.data.value,
        });

        document.getElementById("spinner").classList.remove("spinnerDisplay");
        toast("Created Successfully");
      } catch (error) {
        document.getElementById("spinner").classList.remove("spinnerDisplay");
        switch (error.response.status) {
          case 408:
            history.push("/sessionExpired");
            dispatch({ type: USER_LOGOUT });
            break;
          case 401:
            history.push("/NotAuthorised");
            break;
          case 403:
            history.push("/acessforbidden");
            break;
          case 404:
            history.push("/PageNotFound");
            break;
          case 400:
            if (error.response.data.errors) {
              alert(JSON.stringify(error.response.data.errors));
            } else {
              toast.error(error.response.data.Description[0]);
            }
            break;
          default:
            toast.error("Error Occurred");
        }
      }
    }
  };

export const fetchBuyerEmpanelmentDocument = (id) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get(`/Buyer/${encryptId(id)}/Documents`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      dispatch({
        type: FETCH_EMPANELMENT_BUYER_DOCs,
        payload: response.data,
      });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          // history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const fetchDigilockerDocList = (id, code) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get(
        `/digilocker/getListofDocs/${id}/${code}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({
        type: FETCH_DIGILOCKER_LIST,
        payload: response.data,
      });
      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          // history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};
