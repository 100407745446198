import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { userLogout } from "../../actions/login/loginActions";
import LogoutPage from "../Login/LogoutPage";
import logo from "../logo.png";

class TopBar extends Component {
  adminLinks = (
    <li className="nav-item dropdown ">
      <div>
        <Link
          className="nav-link dropdown-toggle"
          to="#"
          id="navbarDropdownMenuLeftLink"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Menu
        </Link>
        <div
          className="dropdown-menu dropdown-menu-end"
          aria-labelledby="navbarDropdownMenuLeftLink"
        >
          <Link
            to="/buyerempanelment/"
            className={
              this.props.activeRoute === "buyerempanelment"
                ? "dropdown-item active"
                : "dropdown-item"
            }
          >
            Buyer Empanelment
          </Link>
          <Link to="/home/cancelled" className="dropdown-item">
            Cancelled Events
          </Link>
        
          <Link
            to="/buyerrmc/view"
            className={
              this.props.activeRoute === "buyerrmc"
                ? "dropdown-item active"
                : "dropdown-item"
            }
          >
            Buyer Modification
          </Link>
          <Link to="/getReports" className="dropdown-item">
            Reports
          </Link>
        </div>
      </div>
    </li>
  );
  salvageRmLinks = (
    <li className="nav-item dropdown ">
      <div>
        <Link
          className="nav-link dropdown-toggle"
          to="#"
          id="navbarDropdownMenuLeftLink"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Menu
        </Link>
        <div
          className="dropdown-menu dropdown-menu-end"
          aria-labelledby="navbarDropdownMenuLeftLink"
        >
          <Link
            to="/buyerempanelment/"
            className={
              this.props.activeRoute === "buyerempanelment"
                ? "dropdown-item active"
                : "dropdown-item"
            }
          >
            Buyer Empanelment
          </Link>
          <Link to="/home/cancelled" className="dropdown-item">
            Cancelled Events
          </Link>
          <Link to="/getReports" className="dropdown-item">
            Reports
          </Link>
        </div>
      </div>
    </li>
  );

  render() {
    return (
      <div className="navMainContainer">
        <div>
          <div className="navContainer">
            <Fragment>
              <nav
                className="navbar navbar-expand-md navbar-light headerTopNav"
                style={{
                  backgroundImage: "linear-gradient(#ef7d00, #ad2221)",
                }}
              >
                <button
                  className="navbar-toggler menuIcon"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarTogglerDemo03"
                  aria-controls="navbarTogglerDemo03"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                ></button>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="navbar-brand"
                  href="https://www.icicilombard.com"
                >
                  <img src={logo} alt="Icici Lombard" />
                </a>
                {this.props.history.location.pathname !== "/SessionExpired" ? (
                  <Fragment>
                    <div
                      className="collapse navbar-collapse"
                      id="navbarTogglerDemo03"
                      style={{ justifyContent: 'flex-end' }}
                    >
                      <Link
                        to="#!"
                        className="sideMenuIcon"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarTogglerDemo03"
                        aria-controls="navbarTogglerDemo03"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      ></Link>
                      <ul className="navbar-nav ml-auto mt-2 mt-md-0 headNavMain">
                        {this.props.user.loggedIn ? (
                          <>
                            <div className="leftLinksInTopBar">
                              <li className="nav-item">
                                <span>Last Login:</span>
                                <br />
                                <span>
                                  {this.props.lastLogin.lastLoginDate}
                                </span>
                                <br />
                                <span>
                                  {this.props.lastLogin.lastLoginTime}
                                </span>
                              </li>
                            </div>

                            <li className="nav-item">
                              <Link className="nav-link" to="/home">
                                Home <span className="sr-only">(current)</span>
                              </Link>
                            </li>
                          </>
                        ) : null}

                        {this.props.user.role === "Admin" ||
                        this.props.user.role === "SalvageRM" ? (
                          <li className="nav-item">
                            <Link className="nav-link" to="/event/create">
                              Create Event
                            </Link>
                          </li>
                        ) : null}
                        {this.props.user.loggedIn &&
                        this.props.user.role !== "Buyer" &&
                        this.props.user.role !== "Account" &&
                        this.props.user.role !== "Pending Buyer" ? (
                          <li className="nav-item dropdown">
                            <div>
                              <Link
                                className="nav-link dropdown-toggle"
                                to="#"
                                id="navbarDropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Masters
                              </Link>
                              <div
                                className="dropdown-menu dropdown-menu-end"
                                aria-labelledby="navbarDropdownMenuLink"
                              >
                                <Link
                                  className="dropdown-item"
                                  to="/masters/users"
                                >
                                  Users
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/masters/buyers"
                                >
                                  Buyers
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/masters/location"
                                >
                                  Location
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/masters/NatureOfLoss"
                                >
                                  NOL
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/masters/FraudType"
                                >
                                  Fraud
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/masters/EventMaster"
                                >
                                  Event Master
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/masters/GroupMapping/"
                                >
                                  Group Mapping
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/masters/defaultTime"
                                >
                                  Time Master
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/masters/Product"
                                >
                                  Product
                                </Link>
                              </div>
                            </div>
                          </li>
                        ) : null}

                        <div className="leftLinksInTopBar">
                          {this.props.user.loggedIn ? (
                            <>
                              {this.props.user.role === "Admin" &&
                                this.adminLinks}
                              {this.props.user.role === "SalvageRM" &&
                                this.salvageRmLinks}
                            </>
                          ) : null}
                        </div>

                        {this.props.user.loggedIn ? (
                          <li className="nav-item dropdown mr-5 userMenuLi">
                            <div>
                              <Link
                                className="nav-link dropdown-toggle"
                                to="#"
                                id="profile"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {this.props.user.username}
                              </Link>

                              <div
                                className="dropdown-menu dropdown-menu-end"
                                aria-labelledby="profile"
                              >
                                <Link
                                  className="dropdown-item"
                                  to="/profile/viewprofile"
                                >
                                  View Profile
                                </Link>
                                <div
                                  className="dropdown-item logOutBtnDiv"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#navbarTogglerDemo03"
                                  aria-controls="navbarTogglerDemo03"
                                  aria-expanded="false"
                                  aria-label="Toggle navigation"
                                >
                                  <LogoutPage logout={this.props.userLogout} />
                                </div>
                              </div>
                            </div>
                          </li>
                        ) : null}
                       
                      </ul>
                    </div>
                  </Fragment>
                ) : null}
              </nav>
            </Fragment>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.login, lastLogin: state.lastLogin };
};

export default connect(mapStateToProps, { userLogout })(TopBar);
