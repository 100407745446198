import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchUser, editUser } from "../../../actions/masters/userActions";
import BuyerForm from "./BuyerForm";
import jwt_decode from "jwt-decode";
import { fetchBuyer, editBuyer } from "../../../actions/masters/buyerActions";
import _ from "lodash";
import BuyerDocForm from "./BuyerDocForm";
import {
  addBuyerDocument,
  fetchBuyerDocument,
  clearBuyerDocState,
} from "../../../actions/masters/buyerDocActions";
import BuyerDocView from "./BuyerDocView";
import history from "../../../history";
import { decryptId } from "../../Decryption";
class BuyerEdit extends Component {
  componentDidMount = async () => {
    await this.props.fetchUser(decryptId(this.props.match.params.id));
 
    this.props.fetchBuyer(decryptId(this.props.match.params.id));
    this.props.fetchBuyerDocument(this.props.userMaster[0].user_id);
  };

  componentWillUnmount() {
    this.props.clearBuyerDocState();
  }

  onDocSubmit = (formValues) => {
    this.props.addBuyerDocument(this.props.buyer[0].id, formValues);
  };

  onSubmit = (formValues) => {
    if (this.props.tokendata.role === "Buyer") {
    } else {
      const formValuesForUserMaster = _.pick(
        formValues,
        "active_flag",
        "active_status",
        "contact_no",
        "email_id",
        "mobile_no",
        "role_id",
        "user_id",
        "user_name"
      );
      formValuesForUserMaster.role_id = parseInt(formValues.role_id);
      formValuesForUserMaster.id = formValues.userMasterId;
      formValuesForUserMaster.regionId = formValues.permanentRegionId;
      const formValuesForBuyerMaster = _.pick(
        formValues,
        "buyerEmpanelmentId",
        "buyerRequestId",
        "carBikeNumber",
        "currentAccountNumber",
        "drivingLicenseNumber",
        "fatherName",
        "faxNumber",
        "fraudTypeId",
        "mobileNumber",
        "officeAddress",
        "officeTelephone",
        "panNumber",
        "passportNumber",
        "permanentAddress",
        "permanentCityId",
        "permanentRegionId",
        "permanentNearestLandmark",
        "permanentOwnedOrRental",
        "permanentPincode",
        "permanentStateId",
        "permanentStayingSince",
        "permanentTelephoneNumber",
        "photographPath",
        "presentAddress",
        "presentRegionId",
        "presentCityId",
        "presentNearestLandmark",
        "presentOwnedOrRental",
        "presentPincode",
        "presentStateId",
        "presentStayingSince",
        "presentTelephoneNumber",
        "residenceTelephone",
        "savingBankAccountNumber",
        "temporaryBlacklistDate",
        "blackListingRemarks"
      );
      formValuesForBuyerMaster.id = formValues.buyerMasterId;
      formValuesForBuyerMaster.oldFraudTypeId =
        this.props.buyerMaster[0].fraudTypeId;
      formValuesForBuyerMaster.oldTemporaryBlackListDate =
        this.props.buyerMaster[0].temporaryBlacklistDate;
      formValuesForBuyerMaster.oldBlackListRemarks =
        this.props.buyerMaster[0].blackListingRemarks;
      this.props.editUser(formValuesForUserMaster, true);
      this.props.editBuyer(
        formValuesForBuyerMaster.id,
        formValuesForBuyerMaster
      );
    }
  };

  getInitialValue = () => {
    let initValue = {
      ...this.props.userMaster[0],
      ...this.props.buyerMaster[0],
    };
    if (initValue.presentStayingSince) {
      initValue.presentStayingSince =
        initValue.presentStayingSince.split("T")[0];
    }
    if (initValue.permanentStayingSince) {
      initValue.permanentStayingSince =
        initValue.permanentStayingSince.split("T")[0];
    }
    if (initValue.temporaryBlacklistDate) {
      initValue.temporaryBlacklistDateform =
        initValue.temporaryBlacklistDate.split("T")[0];
    }
    if (this.props.buyerMaster[0]) {
      initValue.buyerMasterId = this.props.buyerMaster[0].id;
    }
    if (this.props.userMaster[0]) {
      initValue.userMasterId = this.props.userMaster[0].id;
    }

    return initValue;
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Edit Buyer</h3>
          </div>
          <div className="float-end mt-15">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>

        <BuyerForm
          onSubmit={this.onSubmit}
          initialValues={this.getInitialValue()}
          EditUser={true}
        />
        <hr />

        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Documents</h3>
          </div>
        </div>
        <BuyerDocForm EditUser={true} onSubmit={this.onDocSubmit} />
        <BuyerDocView buyerDocument={this.props.buyerDocument} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    buyer: Object.values(state.buyer),
    userMaster: Object.values(state.userMaster).filter(
      (u) => u.user_id === decryptId(ownProps.match.params.id)
    ),
    tokendata: localStorage.getItem("userToken")
      ? jwt_decode(localStorage.getItem("userToken"))
      : null,
    buyerMaster: Object.values(state.buyer).filter(
      (u) => u.buyerRequestId === decryptId(ownProps.match.params.id)
    ),
    buyerDocument: Object.values(state.buyerDocument),
  };
};
export default connect(mapStateToProps, {
  fetchUser,
  editUser,
  fetchBuyer,
  editBuyer,
  addBuyerDocument,
  fetchBuyerDocument,
  clearBuyerDocState,
})(BuyerEdit);
