import React, { Component } from "react";
import { Link } from "react-router-dom";
import history from "../../history";

class ForbiddenRequest extends Component {
  render() {
    return (
      <div className="BodyInContainer errorPage">
        <div className="mainBodyCard pt-30 d-flex flex-column justify-content-center align-items-center">
          <h1 className="errorCode">403</h1>
          <h4 className="errorName">Access Forbidden.</h4>
          <h4 className="errorTextEx">
            Sorry, your access is refused due to security reasons of our server
            and also our sensitive data. Please go back to the previous page to
            continue browsing.
          </h4>
          <Link
            className="float-end returnHomeBtn mt-5 btnMain"
            onClick={() => history.goBack()}
          >
            Go Back
          </Link>
        </div>
      </div>
    );
  }
}

export default ForbiddenRequest;
