import React, { Component } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import Spinner from "../../Spinner";

class ProdList extends Component {
  state = { selectedProduct: "" };

  handleSelect = (val) => {
    this.setState({ selectedProduct: val });
  };

  customFilter = (option, searchText) => {
    if (option.data.name.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <div>
        {this.props.products.length > 0 ? (
          <div className="row m-0">
            <div className="col-md-12 col-sm-12 pb-5 pl-0 mb_p_0">
              <div className="Header-Content overflow-hidden position-relative pmpdrpdwn">
                <h5 className="form_label">Products</h5>
                {this.props.user.role === "Admin" ? (
                  <Link to="/masters/product/create" className="addRegionBtn">
                    Add Product
                  </Link>
                ) : (
                  ""
                )}
              </div>
              <div className="">
                <Select
                  onChange={this.handleSelect}
                  getOptionLabel={(option) => `${option.name} `}
                  getOptionValue={(option) => `${option.id}`}
                  isOptionSelected={(option) => {
                    return this.state.selectedProduct.id === option.id
                      ? true
                      : false;
                  }}
                  options={this.props.products}
                  isSearchable={true}
                  filterOption={this.customFilter}
                  onInputChange={this.handleInputChange}
                  noOptionsMessage={() => null}
                  placeholder={"Search Product"}
                  autoFocus={true}
                  menuIsOpen={this.state.menuOpen}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,

                      primary25: "#ffa552",
                      primary50: "#fabc82",
                      primary: "#fcb26d",
                    },
                  })}
                />
              </div>
            </div>

            <div className="col-md-12 col-sm-12 pl-0 locationTableDiv mb_p_0">
              {this.state.selectedProduct !== "" ? (
                <div className="">
                  <table className="table table-hover tableStyle2">
                    <thead>
                      <tr>
                        <td>Product</td>
                        <td>Is Active</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td data-th="Product">
                          {this.state.selectedProduct.name}
                        </td>
                        <td data-th="Is Active">
                          {this.state.selectedProduct.activeFlag === "Y"
                            ? "True"
                            : "False"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {this.props.user.role === "Admin" ? (
                    <div className="row m-0">
                      <div className="col p-0">
                        <Link
                          to={`/masters/product/${this.state.selectedProduct.id}/subproduct/create`}
                          className="linkBtn1"
                        >
                          Add SubProduct
                        </Link>

                        <Link
                          to={`/masters/product/Edit/${this.state.selectedProduct.id}`}
                          className="linkBtn1"
                        >
                          Modify
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

export default ProdList;
