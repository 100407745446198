import React, { Component } from "react";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import {
  fetchBuyerEmpanelmentsList,
  adminBuyerApprove,
  salvageBuyerApprove,
  adminBuyerRelease,
} from "../../actions/BuyerEmpanelment/BuyerEmpanelmentActions";
import BuyerEmpanelmentData from "./BuyerEmpanelmentData";
import { fetchRegions } from "../../actions/masters/regionActions";
import { fetchAllStates } from "../../actions/masters/stateActions";
import { fetchAllCities } from "../../actions/masters/cityActions";
import { Link, Redirect } from "react-router-dom";
import LeftMenu from "../layout/LeftMenu";
import SessionExpiredPage from "../common/SessionExpiredPage";

class BuyerEmpanelmentList extends Component {
  state = {
    searchParameters: {
      buyerName: null,
      requestId: null,
      emailId: null,
    },
    search: false,
  };

  componentDidMount = async () => {
    this.props.fetchRegions();
    this.props.fetchAllStates();
    this.props.fetchAllCities();
    this.props.fetchBuyerEmpanelmentsList();
  };

  toggleSearch = () => {
    if (this.state.search) {
      this.setState({ search: false });
      this.setState({
        searchParameters: { buyerName: null, requestId: null, emailId: null },
      });
      document.getElementById("EmpanelmentSearchBuyerName").value = "";
      document.getElementById("EmpanelmentSearchRequestId").value = "";
      document.getElementById("EmpanelmentSearchEmailID").value = "";
    } else {
      this.setState({ search: true });
    }
  };

  submitBuyerNameSearch = (searchParam) => {
    this.setState((prevState) => ({
      searchParameters: {
        ...prevState.searchParameters,
        buyerName: searchParam.buyerName,
      },
    }));
  };

  submitRequestIdSearch = (searchParam) => {
    this.setState((prevState) => ({
      searchParameters: {
        ...prevState.searchParameters,
        requestId: searchParam.requestId,
      },
    }));
  };

  submitEmailIdSearch = (searchParam) => {
    this.setState((prevState) => ({
      searchParameters: {
        ...prevState.searchParameters,
        emailId: searchParam.emailId,
      },
    }));
  };

  filterData = (empanelmentData) => {
    if (this.state.searchParameters.buyerName) {
      empanelmentData =
        empanelmentData &&
        empanelmentData.filter((data) => {
          return (data.firstName + " " + data.middleName + " " + data.lastName)
            .toLowerCase()
            .includes(this.state.searchParameters.buyerName.toLowerCase());
        });
    }
    if (this.state.searchParameters.requestId) {
      empanelmentData =
        empanelmentData &&
        empanelmentData.filter((data) => {
          return data.buyerRequestId
            .toLowerCase()
            .includes(this.state.searchParameters.requestId.toLowerCase());
        });
    }
    if (this.state.searchParameters.emailId) {
      empanelmentData =
        empanelmentData &&
        empanelmentData.filter((data) => {
          return data.email
            .toLowerCase()
            .includes(this.state.searchParameters.emailId.toLowerCase());
        });
    }
    return empanelmentData;
  };

  pageNo = this.props.location.search
    ? parseInt(this.props.location.search.split("=")[1])
    : 1;

  render() {
    if (!localStorage.getItem("userToken")) {
      <Redirect to="/login" />;
      return <SessionExpiredPage />;
    }
    return (
      <div className="BodyInContainer homepage">
        <div className="mainBodyCard pt-30 overflow-hidden position-relative d-flex">
          <LeftMenu
            activeRoute="buyerempanelment"
            toggleSearch={this.toggleSearch}
            submitBuyerNameSearch={this.submitBuyerNameSearch}
            submitRequestIdSearch={this.submitRequestIdSearch}
            submitEmailIdSearch={this.submitEmailIdSearch}
          />

          <div className="rightTabBody belRightTabBody">
            <div className="tabbable-responsive">
              <div className="homeTabContainer tabbable">
                <ul
                  className="nav nav-tabs buyerEmpanelmentCardtabs"
                  id="myTab"
                  role="tablist"
                >
                   <li className="nav-item" role="presentation">
                    <Link
                      className={
                        !this.props.match.params.buyerempanelmentstatus ||
                        this.props.match.params.buyerempanelmentstatus === "" ||
                        this.props.match.params.buyerempanelmentstatus ===
                          "pending"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="pending-tab"
                      data-toggle="tab"
                      href="#pending"
                      role="tab"
                      aria-controls="pending"
                      aria-selected="true"
                      to="/buyerempanelment"
                    >
                      Pending
                      <span className="badge bg-warning rounded-pill mx-2">
                        {
                          this.filterData(this.props.BuyerApprovalPending)
                            .length
                        }
                      </span>
                    </Link>
                  </li>
                   <li className="nav-item" role="presentation">
                    <Link
                      className={
                        this.props.match.params.buyerempanelmentstatus ===
                        "approved"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="approved-tab"
                      data-toggle="tab"
                      href="#approved"
                      role="tab"
                      aria-controls="approved"
                      to="/buyerempanelment/approved"
                      aria-selected="false"
                    >
                      {this.props.userData.role === "Admin"
                        ? "Pending for SRM"
                        : "Completed"}
                      <span className="badge bg-warning rounded-pill mx-2">
                        {this.filterData(this.props.BuyerApproved).length}
                      </span>
                    </Link>
                  </li>
                  {this.props.userData.role === "Admin" ? (
                    <>
                       <li className="nav-item" role="presentation">
                        <Link
                          className={
                            this.props.match.params.buyerempanelmentstatus ===
                            "approvedsrm"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="approvedsrm-tab"
                          data-toggle="tab"
                          href="#approvedsrm"
                          role="tab"
                          aria-controls="approvedsrm"
                          aria-selected="true"
                          to="/buyerempanelment/approvedsrm"
                        >
                          Approved By SRM
                          <span className="badge bg-warning rounded-pill mx-2">
                            {
                              this.filterData(this.props.BuyerApprovedSRM)
                                .length
                            }
                          </span>
                        </Link>
                      </li>
                       <li className="nav-item" role="presentation">
                        <Link
                          className={
                            this.props.match.params.buyerempanelmentstatus ===
                            "finalrelease"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="release-tab"
                          data-toggle="tab"
                          href="#pendingrelease"
                          role="tab"
                          aria-controls="release"
                          to="/buyerempanelment/finalrelease"
                          aria-selected="false"
                        >
                          Final Release
                          <span className="badge bg-warning rounded-pill mx-2">
                            {
                              this.filterData(this.props.BuyerReleasePending)
                                .length
                            }
                          </span>
                        </Link>
                      </li>
                    </>
                  ) : null}
                  {this.props.userData.role === "Admin" ? (
                     <li className="nav-item" role="presentation">
                      <Link
                        className={
                          this.props.match.params.buyerempanelmentstatus ===
                          "completed"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        id="released-tab"
                        data-toggle="tab"
                        href="#released"
                        role="tab"
                        aria-controls="released"
                        aria-selected="false"
                        to="/buyerempanelment/completed"
                      >
                        Completed
                        <span className="badge bg-warning rounded-pill mx-2">
                          {this.filterData(this.props.BuyerReleased).length}
                        </span>
                      </Link>
                    </li>
                  ) : null}
                   <li className="nav-item" role="presentation">
                    <Link
                      className={
                        this.props.match.params.buyerempanelmentstatus ===
                        "rejected"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="rejected-tab"
                      data-toggle="tab"
                      href="#rejected"
                      role="tab"
                      aria-controls="rejected"
                      aria-selected="false"
                      to="/buyerempanelment/rejected"
                    >
                      Rejected
                      <span className="badge bg-warning rounded-pill mx-2">
                        {this.filterData(this.props.BuyerRejected).length}
                      </span>
                    </Link>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className={
                      !this.props.match.params.buyerempanelmentstatus ||
                      this.props.match.params.buyerempanelmentstatus ===
                        "pending"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pending"
                    role="tabpanel"
                    aria-labelledby="pending-tab"
                  >
                    {this.props.userData.role === "Admin" ? (
                      <BuyerEmpanelmentData
                        pageNo={this.pageNo}
                        state={this.props.states}
                        city={this.props.cities}
                        region={this.props.regions}
                        userData={this.props.userData}
                        data={this.filterData(this.props.BuyerApprovalPending)}
                        status="Pending"
                        approve={this.props.adminBuyerApprove}
                      />
                    ) : (
                      <BuyerEmpanelmentData
                        pageNo={this.pageNo}
                        state={this.props.states}
                        city={this.props.cities}
                        region={this.props.regions}
                        userData={this.props.userData}
                        data={this.filterData(this.props.BuyerApprovalPending)}
                        status="Pending"
                        approve={this.props.salvageBuyerApprove}
                      />
                    )}
                  </div>
                  <div
                    className={
                      this.props.match.params.buyerempanelmentstatus ===
                      "approved"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="approved"
                    role="tabpanel"
                    aria-labelledby="approved-tab"
                  >
                    <BuyerEmpanelmentData
                      pageNo={this.pageNo}
                      state={this.props.states}
                      city={this.props.cities}
                      region={this.props.regions}
                      userData={this.props.userData}
                      data={this.filterData(this.props.BuyerApproved)}
                      status="Approved"
                    />
                  </div>
                  <div
                    className={
                      this.props.match.params.buyerempanelmentstatus ===
                      "finalrelease"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="released"
                    role="tabpanel"
                    aria-labelledby="released-tab"
                  >
                    <BuyerEmpanelmentData
                      pageNo={this.pageNo}
                      state={this.props.states}
                      city={this.props.cities}
                      region={this.props.regions}
                      userData={this.props.userData}
                      data={this.filterData(this.props.BuyerReleasePending)}
                      approve={this.props.adminBuyerRelease}
                      status="PendingRelease"
                    />
                  </div>
                  <div
                    className={
                      this.props.match.params.buyerempanelmentstatus ===
                      "completed"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pendingrelease"
                    role="tabpanel"
                    aria-labelledby="pending-tab"
                  >
                    <BuyerEmpanelmentData
                      pageNo={this.pageNo}
                      state={this.props.states}
                      city={this.props.cities}
                      region={this.props.regions}
                      userData={this.props.userData}
                      data={this.filterData(this.props.BuyerReleased)}
                      status="completed"
                    />
                  </div>
                  <div
                    className={
                      this.props.match.params.buyerempanelmentstatus ===
                      "rejected"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="rejected"
                    role="tabpanel"
                    aria-labelledby="rejected-tab"
                  >
                    <BuyerEmpanelmentData
                      pageNo={this.pageNo}
                      state={this.props.states}
                      city={this.props.cities}
                      region={this.props.regions}
                      userData={this.props.userData}
                      data={this.filterData(this.props.BuyerRejected)}
                      status="Rejected"
                    />
                  </div>
                  <div
                    className={
                      this.props.match.params.buyerempanelmentstatus ===
                      "approvedsrm"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="approvedsrm"
                    role="tabpanel"
                    aria-labelledby="approvedsrm-tab"
                  >
                    {this.props.userData.role === "Admin" && (
                      <BuyerEmpanelmentData
                        pageNo={this.pageNo}
                        state={this.props.states}
                        city={this.props.cities}
                        region={this.props.regions}
                        userData={this.props.userData}
                        data={this.filterData(this.props.BuyerApprovedSRM)}
                        status="approvedsrm"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const userData =
    localStorage.getItem("userToken") &&
    jwt_decode(localStorage.getItem("userToken"));
  if (userData) {
    if (userData.role === "Admin") {
      return {
        regions: Object.values(state.region),
        states: Object.values(state.state),
        cities: Object.values(state.city),
        userData: userData,
        BuyerApprovalPending: Object.values(state.buyerEmpanelment)
          .filter(
            (buy) =>
              buy.isMakerApproved === " " ||
              (buy.isMakerApproved === "Y" &&
                buy.isCheckerApproved === "N" &&
                Date.parse(buy.makerApprovalTime) <=
                  Date.parse(buy.checkerApprovalTime))
          )
          .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0)),
        BuyerApproved: Object.values(state.buyerEmpanelment)
          .filter(
            (buy) =>
              buy.isMakerApproved === "Y" &&
              buy.isCheckerApproved === " " &&
              (buy.isCheckerApproved !== "Y" ||
                //buy.isDepositPaid !== "Y" ||
                buy.isDocumentUploaded !== "Y")
          )
          .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0)),
        BuyerApprovedSRM: Object.values(state.buyerEmpanelment)
          .filter(
            (buy) =>
              buy.isMakerApproved === "Y" &&
              buy.isCheckerApproved === "Y" &&
              (//buy.isDepositPaid !== "Y" || 
              buy.isDocumentUploaded !== "Y")
          )
          .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0)),
        BuyerRejected: Object.values(state.buyerEmpanelment)
          .filter((buy) => buy.isMakerApproved === "N")
          .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0)),
        BuyerReleasePending: Object.values(state.buyerEmpanelment)
          .filter(
            (buy) =>
              buy.isMakerApproved === "Y" &&
              buy.isCheckerApproved === "Y" &&
              //buy.isDepositPaid === "Y" &&
              buy.isDocumentUploaded === "Y" &&
              buy.isReleased === " "
          )
          .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0)),
        BuyerReleased: Object.values(state.buyerEmpanelment)
          .filter(
            (buy) =>
              buy.isMakerApproved === "Y" &&
              buy.isCheckerApproved === "Y" &&
             // buy.isDepositPaid === "Y" &&
              buy.isDocumentUploaded === "Y" &&
              buy.isReleased === "Y"
          )
          .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0)),
      };
    }
    return {
      regions: Object.values(state.region),
      states: Object.values(state.state),
      cities: Object.values(state.city),
      userData: userData,
      BuyerApprovalPending: Object.values(state.buyerEmpanelment)
        .filter(
          (buy) =>
            buy.isMakerApproved === "Y" &&
            (buy.isCheckerApproved === " " ||
              (buy.isCheckerApproved === "N" &&
                Date.parse(buy.makerApprovalTime) >
                  Date.parse(buy.checkerApprovalTime))) &&
            buy.presentRegionId.toString() === userData.region
        )
        .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0)),
      BuyerApproved: Object.values(state.buyerEmpanelment)
        .filter(
          (buy) =>
            buy.isMakerApproved === "Y" &&
            buy.isCheckerApproved === "Y" &&
            buy.presentRegionId.toString() === userData.region
        )
        .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0)),
      BuyerRejected: Object.values(state.buyerEmpanelment)
        .filter(
          (buy) =>
            buy.isMakerApproved === "Y" &&
            buy.isCheckerApproved === "N" &&
            Date.parse(buy.makerApprovalTime) <
              Date.parse(buy.checkerApprovalTime) &&
            buy.presentRegionId.toString() === userData.region
        )
        .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0)),
    };
  }
};

export default connect(mapStateToProps, {
  fetchRegions,
  fetchAllCities,
  fetchAllStates,
  fetchBuyerEmpanelmentsList,
  adminBuyerApprove,
  salvageBuyerApprove,
  adminBuyerRelease,
})(BuyerEmpanelmentList);
