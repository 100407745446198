import React, { Component } from "react";
import AddBidders from "./AddBidders";
import RemoveBidders from "./RemoveBidders";
import { fetchGenuineBuyers } from "../../../actions/masters/userActions";
import { connect } from "react-redux";
import { fetchEventBidders } from "../../../actions/event/EventBidderActions";
import { Link } from "react-router-dom";
import history from "../../../history";
import { fetchRegions } from "../../../actions/masters/regionActions";
import { fetchEvent } from "../../../actions/event/EventActions";

class AuctionBiddersPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchGenuineBuyers();
    this.props.fetchEventBidders(this.props.match.params.eid);
    this.props.fetchRegions();
    this.props.fetchEvent(this.props.match.params.eid);
  }

  renderFieldName(fieldId, fieldName) {
    if (fieldName.length > 0) {
      return fieldName.filter((pr) => pr.id === fieldId)[0].name;
    }
    return "";
  }

  render() {
    return (
      <div className="BodyInContainer">
        {this.props.event[0] && this.props.event[0].status === "PENDING" ? (
          <>
            <div className="d-block clearfix">
              <div className="Header-Content float-start">
                <h3 className="pageMainTitle">Bidders Page</h3>
              </div>
              <div className="float-end mt-15 ">
                <Link
                  className="float-end cardBackBtn"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </Link>
              </div>
            </div>
            <div className="mainBodyCard pt-0">
              <AddBidders
                buyers={this.props.buyers}
                currentbidders={this.props.currentbidders}
                eid={this.props.match.params.eid}
                regions={this.props.regions}
                renderFieldName={this.renderFieldName}
              />
              <hr />
              <RemoveBidders
                currentbidders={this.props.currentbidders}
                buyers={this.props.buyers}
                eid={this.props.match.params.eid}
                regions={this.props.regions}
                renderFieldName={this.renderFieldName}
              />
            </div>
          </>
        ) : this.props.event[0] && this.props.event[0].status !== "PENDING" ? (
          <div className="mainBodyCard pt-30 d-flex flex-column justify-content-center align-items-center">
            <h4 className="errorName">{`Event is in ${
              this.props.event[0] ? this.props.event[0].status : ""
            } stage`}</h4>
            <h4 className="errorTextEx">
              You can only add / remove bidders of events which are in{" "}
              <b>PENDING</b> stage
            </h4>
            <Link
              className="float-end returnHomeBtn mt-5 btnMain"
              onClick={() => {
                history.goBack();
              }}
            >
              Go Back
            </Link>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const buyers = state.userMaster;
  const currentbidders = state.eventBidders;
  const regions = state.regions;

  return {
    buyers,
    currentbidders,
    regions,
    event: Object.values(state.event).filter(
      (e) => e.id === parseInt(ownProps.match.params.eid)
    ),
  };
};

export default connect(mapStateToProps, {
  fetchGenuineBuyers,
  fetchEventBidders,
  fetchRegions,
  fetchEvent,
})(AuctionBiddersPage);
