import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
  fetchgroupmapping,
  editgroupmapping,
  deletegroupmapping,
} from "../../../actions/masters/groupmappingActions";
import GroupMappingForm from "./GroupMappingForm";
import { Link } from "react-router-dom";
import Spinner from "../../Spinner";
import history from "../../../history";

class GroupMappingEdit extends Component {
  componentDidMount() {
    this.props.fetchgroupmapping(this.props.match.params.id);
  }

  onSubmit = (formValues) => {
    this.props.editgroupmapping(this.props.match.params.id, formValues);
  };

  onClickDelete = () => {
    this.props.deletegroupmapping(
      this.props.match.params.id,
      this.props.groupmapping
    );
  };

  render() {
    return (
      <div>
        {this.props.groupmapping !== undefined ? (
          <div className="BodyInContainer">
            <div className="d-block clearfix">
              <div className="Header-Content float-start">
                <h3 className="pageMainTitle">Edit Group Mapping</h3>
              </div>
              <div className="float-end mt-15">
                <Link
                  className="float-end cardBackBtn"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </Link>
              </div>
            </div>

            <GroupMappingForm
              initialValues={_.pick(
                this.props.groupmapping,
                "name",
                "activeFlag"
              )}
              onSubmit={this.onSubmit}
              onDelete={this.onClickDelete}
            />
          </div>
        ) : (
          <Spinner />
        )}{" "}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { groupmapping: state.groupmapping[ownProps.match.params.id] };
};

export default connect(mapStateToProps, {
  fetchgroupmapping,
  editgroupmapping,
  deletegroupmapping,
})(GroupMappingEdit);
