import React, { Component } from "react";
import { connect } from "react-redux";
import { createRegion } from "../../../actions/masters/regionActions";
import { Link } from "react-router-dom";
import RegionForm from "./RegionForm";
import history from "../../../history";

class RegionCreate extends Component {
  onSubmit = (formValues) => {
    this.props.createRegion(formValues);
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Add a Region</h3>
          </div>
          <div className="float-end mt-15">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        <RegionForm onSubmit={this.onSubmit} />
      </div>
    );
  }
}
export default connect(null, { createRegion })(RegionCreate);
