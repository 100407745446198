import React, { Component } from "react";
import { Link } from "react-router-dom";

class SessionExpiredPage extends Component {
  render() {
    return (
      <div className="BodyInContainer errorPage">
        <div className="mainBodyCard pt-30 d-flex flex-column justify-content-center align-items-center">
          <h1 className="errorCode">440</h1>
          <h4 className="errorName">Session Expired.</h4>
          <h4 className="errorTextEx">Your Current Session is Expired.</h4>
          <h4 className="errorTextEx">Please login again to access it.</h4>
          <Link className="float-end returnHomeBtn mt-5 btnMain" to="/login">
            Login
          </Link>
        </div>
      </div>
    );
  }
}

export default SessionExpiredPage;
