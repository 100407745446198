import { OFFICERNAMES, CATEGORIES, POLICYPRODUCTS } from "../../actions/types";
import _ from "lodash";

const initialState = {
  officers: [],
  categories: [],
  policyproducts: [],
};

const reportsReducers = (state = initialState, action) => {
  switch (action.type) {
    case OFFICERNAMES:
      return {
        ...state,
        officers: action.payload.map((curroff, index) => {
          return { index: index, officer: curroff };
        }),
      };
    case CATEGORIES:
      return {
        ...state,
        categories: action.payload.map((curroff, index) => {
          return { index: index, category: curroff };
        }),
      };
    case POLICYPRODUCTS:
      return {
        ...state,
        policyproducts: action.payload.map((curroff, index) => {
          return { index: index, policyproduct: curroff };
        }),
      };
    default:
      return state;
  }
};

export default reportsReducers;
