import {
  CREATE_BUYER_EMPANELMENT,
  FETCH_BUYER_EMPANELMENTs,
  FETCH_BUYER_EMPANELMENT,
  BUYER_ADMIN_APPROVE,
  BUYER_ADMIN_RELEASE,
  BUYER_SALVAGE_APPROVE,
  BUYER_PAYMENT,
} from "../../actions/types";
import _ from "lodash";

const buyerReducers = (state = { isBuyerEmpanelmentSaving: false }, action) => {
  switch (action.type) {
    case CREATE_BUYER_EMPANELMENT:
      return {
        [action.payload.id]: action.payload,
        isBuyerEmpanelmentSaving: true,
      };
    case FETCH_BUYER_EMPANELMENTs:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case FETCH_BUYER_EMPANELMENT:
      return { ...state, [action.payload.id]: action.payload };
    case BUYER_ADMIN_APPROVE:
      return { ...state, [action.payload.id]: action.payload };
    case BUYER_ADMIN_RELEASE:
      return { ...state, [action.payload.id]: action.payload };
    case BUYER_SALVAGE_APPROVE:
      return { ...state, [action.payload.id]: action.payload };
    case BUYER_PAYMENT:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};

export default buyerReducers;
