import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import Select from "react-select";
import { fetchRegionsForEmpanelment } from "../../actions/masters/regionActions";
import { fetchAllStatesForEmpanelment } from "../../actions/masters/stateActions";
import { fetchAllCitiesForEmpanelment } from "../../actions/masters/cityActions";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";

class BuyerEmpanelmentForm extends Component {
  componentDidMount() {
    this.props.fetchRegionsForEmpanelment();
    this.props.fetchAllStatesForEmpanelment();
    this.props.fetchAllCitiesForEmpanelment();
  }

  state = {
    selectedPresentRegion: null,
    selectedPermanentRegion: null,
    pincodePresent: null,
    pincodePermanent: null,
    telephoneNumberPermanent: null,
    savingsAccountNumer: null,
    currentAccountNo: null,
    resiDenceContactNo: null,
    officeTeleNo: null,
    officeMobNo: null,
    officeMobNo2: null,
    officeFaxNo: null,
    presentPermanentAddress: false,
    telephonePresent: null,
  };

  onIntegerChange = (event, opt) => {
    const value = event.target.value.replace(/\D/g, "");
    if (opt === "present") {
      this.setState({ pincodePresent: value });
    }
    if (opt === "telephonePresent") {
      this.setState({ telephonePresent: value });
    }
    if (opt === "permanent") {
      this.setState({ pincodePermanent: value });
    }
    if (opt === "telephoneNumberPermanent") {
      this.setState({ telephoneNumberPermanent: value });
    }
    if (opt === "savingsAccountNumer") {
      this.setState({ savingsAccountNumer: value });
    }
    if (opt === "currentAccountNo") {
      this.setState({ currentAccountNo: value });
    }
    if (opt === "resiDenceContactNo") {
      this.setState({ resiDenceContactNo: value });
    }
    if (opt === "officeTeleNo") {
      this.setState({ officeTeleNo: value });
    }
    if (opt === "officeMobNo") {
      this.setState({ officeMobNo: value });
    }
    if (opt === "officeMobNo2") {
      this.setState({ officeMobNo2: value });
    }
    if (opt === "officeFaxNo") {
      this.setState({ officeFaxNo: value });
    }
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, value, meta, disabled, type, maxLength }) => {
    return (
      <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
        <label className="d-block">{label}</label>
        <div className="input-field">
          <input
            {...input}
            type={type}
            value={value}
            maxLength={maxLength}
            autoComplete="off"
            placeholder={label}
            className="form-control"
            disabled={disabled}
          />
        </div>
        <div className="pl-1 error">{this.renderError(meta)}</div>
      </div>
    );
  };

  renderTextArea = ({ input, label, meta, disabled, type, maxLength }) => {
    return (
      <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
        <label className="d-block">{label}</label>
        <div className="input-field">
          <textarea
            {...input}
            type={type}
            maxLength={maxLength}
            autoComplete="off"
            placeholder={label}
            className="form-control"
            disabled={disabled}
          />
        </div>
        <div className="pl-1 error">{this.renderError(meta)}</div>
      </div>
    );
  };

  renderRadioInput = ({ input, label, meta, type }) => {
    return (
      <div className="radio">
        <input
          {...input}
          autoComplete="off"
          type={type}
          value={input.value}
          id={label}
        />

        <label className="radio-label" htmlFor={label}>
          {input.value === "Y"
            ? "Active"
            : input.value === "N"
            ? "In Active"
            : input.value === "A"
            ? "Unlocked"
            : input.value === "I"
            ? "Locked"
            : input.value}
        </label>

        <div className="pl-1 error">{this.renderError(meta)}</div>
      </div>
    );
  };

  renderSelectInput = ({ input, label, meta, options, placeholder }) => {
    return (
      <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
        <label className="d-block">{label}</label>
        <div className="">
          <Select
            {...input}
            value={
              input.value === ""
                ? null
                : options.find((obj) => obj.id === input.value)
            }
            onChange={input.onChange}
            onBlur={(value) => input.onBlur(value)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onBlurResetsInput={false}
            onCloseResetsInput={false}
            options={options}
            onFocus={input.onFocus}
            isSearchable={true}
            noOptionsMessage={() => null}
            placeholder={placeholder}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,

                primary25: "#ffa552",
                primary50: "#fabc82",
                primary: "#fcb26d",
              },
            })}
          />
          <div className="pl-1 error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  renderDate = ({ input, label, meta, maxDate }) => {
    return (
      <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
        <div className="row m-0">
          <label className="d-block w-100">{label}</label>
          <div className="input-field">
            <input
              {...input}
              type="date"
              autoComplete="off"
              placeholder={label}
              className="form-control"
              max={maxDate}
            />
          </div>
          <div className="pl-1 error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  onSubmit = (formValues) => {
    //Added encryption for sensitive data
    var key = CryptoJS.enc.Utf8.parse("8080808080808080");
    var iv = CryptoJS.enc.Utf8.parse("8080808080808080");

    formValues.presentRegionId = this.state.selectedPresentRegion;
    formValues.permanentRegionId = this.state.selectedPermanentRegion;
    formValues.emdAmount = "0";
     // this.props.categoryEmdPrice[this.props.vehicleTypeValue];
    if (this.state.presentPermanentAddress) {
      formValues.permanentAddress = formValues.presentAddress;
      formValues.permanentNearestLandmark = formValues.presentNearestLandmark;
      formValues.permanentStateId = formValues.presentStateId;
      formValues.permanentCityId = formValues.presentCityId;
      formValues.permanentRegionId = formValues.presentRegionId;
      formValues.permanentPincode = formValues.presentPincode;
      formValues.permanentTelephoneNumber = formValues.presentTelephoneNumber;
      formValues.permanentOwnedOrRental = formValues.presentOwnedOrRental;
      formValues.permanentStayingSince = formValues.presentStayingSince;
    }
    // formValues.firstName = CryptoJS.AES.encrypt(
    //   CryptoJS.enc.Utf8.parse(formValues.firstName),
    //   key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    //   }
    // ).toString();
    // formValues.lastName = CryptoJS.AES.encrypt(
    //   CryptoJS.enc.Utf8.parse(formValues.lastName),
    //   key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    //   }
    // ).toString();
    // formValues.email = CryptoJS.AES.encrypt(
    //   CryptoJS.enc.Utf8.parse(formValues.email),
    //   key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    //   }
    // ).toString();
    // formValues.presentAddress = CryptoJS.AES.encrypt(
    //   CryptoJS.enc.Utf8.parse(formValues.presentAddress),
    //   key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    //   }
    // ).toString();
    // formValues.presentNearestLandmark = CryptoJS.AES.encrypt(
    //   CryptoJS.enc.Utf8.parse(formValues.presentNearestLandmark),
    //   key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    //   }
    // ).toString();
    // formValues.presentPincode = CryptoJS.AES.encrypt(
    //   CryptoJS.enc.Utf8.parse(formValues.presentPincode),
    //   key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    //   }
    // ).toString();
    // formValues.presentTelephoneNumber = CryptoJS.AES.encrypt(
    //   CryptoJS.enc.Utf8.parse(formValues.presentTelephoneNumber),
    //   key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    //   }
    // ).toString();
    // formValues.panNumber = CryptoJS.AES.encrypt(
    //   CryptoJS.enc.Utf8.parse(formValues.panNumber),
    //   key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    //   }
    // ).toString();
    // formValues.officeAddress = CryptoJS.AES.encrypt(
    //   CryptoJS.enc.Utf8.parse(formValues.officeAddress),
    //   key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    //   }
    // ).toString();
    // formValues.mobileNumber = CryptoJS.AES.encrypt(
    //   CryptoJS.enc.Utf8.parse(formValues.mobileNumber),
    //   key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    //   }
    // ).toString();
    
    // formValues.permanentAddress = CryptoJS.AES.encrypt(
    //   CryptoJS.enc.Utf8.parse(formValues.permanentAddress),
    //   key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    //   }
    // ).toString();
    // formValues.permanentNearestLandmark = CryptoJS.AES.encrypt(
    //   CryptoJS.enc.Utf8.parse(formValues.permanentNearestLandmark),
    //   key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    //   }
    // ).toString();
    // formValues.permanentPincode = CryptoJS.AES.encrypt(
    //   CryptoJS.enc.Utf8.parse(formValues.permanentPincode),
    //   key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    //   }
    // ).toString();
    // formValues.permanentTelephoneNumber = CryptoJS.AES.encrypt(
    //   CryptoJS.enc.Utf8.parse(formValues.permanentTelephoneNumber),
    //   key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    //   }
    // ).toString();

   
    // if(formValues.drivingLicenseNumber != undefined){
    //   formValues.drivingLicenseNumber = CryptoJS.AES.encrypt(
    //     CryptoJS.enc.Utf8.parse(formValues.drivingLicenseNumber),
    //     key,
    //     {
    //       keySize: 128 / 8,
    //       iv: iv,
    //       mode: CryptoJS.mode.CBC,
    //       padding: CryptoJS.pad.Pkcs7,
    //     }
    //     ).toString();
    //   }
    // if(formValues.firmPAN != undefined){
    //   formValues.firmPAN = CryptoJS.AES.encrypt(
    //     CryptoJS.enc.Utf8.parse(formValues.firmPAN),
    //     key,
    //     {
    //       keySize: 128 / 8,
    //       iv: iv,
    //       mode: CryptoJS.mode.CBC,
    //       padding: CryptoJS.pad.Pkcs7,
    //     }
    //   ).toString();
    // }
    // if(formValues.mobileNumber2 != undefined){
    //   formValues.mobileNumber2 = CryptoJS.AES.encrypt(
    //     CryptoJS.enc.Utf8.parse(formValues.mobileNumber2),
    //     key,
    //     {
    //       keySize: 128 / 8,
    //       iv: iv,
    //       mode: CryptoJS.mode.CBC,
    //       padding: CryptoJS.pad.Pkcs7,
    //     }
    //   ).toString();
    // }
    // if(formValues.gstNo != undefined){
    //   formValues.gstNo = CryptoJS.AES.encrypt(
    //     CryptoJS.enc.Utf8.parse(formValues.gstNo),
    //     key,
    //     {
    //       keySize: 128 / 8,
    //       iv: iv,
    //       mode: CryptoJS.mode.CBC,
    //       padding: CryptoJS.pad.Pkcs7,
    //     }
    //   ).toString();
    // }

    this.props.onSubmit(formValues);
  };

  onPresentStateChange = (value, opt) => {
    if (opt === "present") {
      this.setState({ selectedPresentRegion: value });
    }
    if (opt === "permanent") {
      this.setState({ selectedPermanentRegion: value });
    }
  };

  onPresentPermanentAddressCheckboxClick = (val) => {
    this.setState({ presentPermanentAddress: val });
    if (val === false) {
    }
  };

  render() {
    return (
      <div className="mainBodyCard pt-30">
        <p style={{ color: "grey" }}>* - Mandatory Required Fields</p>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div className="row m-0">
            <Field
              name="firstName"
              component={this.renderInput}
              label="* First Name"
              type="text"
            />
            <Field
              name="middleName"
              component={this.renderInput}
              label="Middle Name"
              type="text"
            />
            <Field
              name="lastName"
              component={this.renderInput}
              label="* Last Name"
              type="text"
            />
            <Field
              name="email"
              component={this.renderInput}
              label="* Email"
              type="text"
            />
            <div className="row m-0">
              <h5 className="col-sm-12 cardTitle border-0">Present Address</h5>

              <Field
                name="presentAddress"
                component={this.renderInput}
                label="* Address"
                type="text"
              />
              <Field
                name="presentNearestLandmark"
                component={this.renderInput}
                label="* Nearest Landmark"
                type="text"
              />

              <Field
                name="presentStateId"
                component={this.renderSelectInput}
                placeholder="Select Present State"
                label="* State"
                props={{
                  options: this.props.states,
                }}
                onChange={(e) =>
                  this.onPresentStateChange(e.regionID, "present")
                }
                normalize={(value) => value.id}
              />
              <Field
                name="presentCityId"
                component={this.renderSelectInput}
                placeholder="Select Present City"
                label="* City"
                props={{
                  options: this.props.cities.filter((c) => {
                    return (
                      c.stateId === parseInt(this.props.PresentStateIdValue)
                    );
                  }),
                }}
                normalize={(value) => value.id}
              />
              <Field
                name="presentRegionId"
                component={this.renderInput}
                placeholder="Select Region"
                label="Region"
                props={{
                  value: this.state.selectedPresentRegion
                    ? this.props.regions[this.state.selectedPresentRegion].name
                    : "",
                }}
                readOnly
              />
              <Field
                name="presentPincode"
                component={this.renderInput}
                label="* Pincode"
                type="text"
                props={{
                  value: this.state.pincodePresent,
                }}
                onChange={(event) => this.onIntegerChange(event, "present")}
              />
              <Field
                name="presentTelephoneNumber"
                component={this.renderInput}
                label="* Telephone Number"
                type="text"
                props={{
                  value: this.state.telephonePresent,
                }}
                onChange={(event) =>
                  this.onIntegerChange(event, "telephonePresent")
                }
              />
              <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0 row m-0">
                <label className="col-sm-12 p-0">*House Owned/Rental</label>
                <div className="col-sm-12 p-0">
                  <Field
                    name="presentOwnedOrRental"
                    component={this.renderRadioInput}
                    type="radio"
                    label="PresentOwned"
                    value="Owned"
                  />
                  <Field
                    name="presentOwnedOrRental"
                    component={this.renderRadioInput}
                    type="radio"
                    label="PresentRental"
                    value="Rental"
                  />
                </div>
              </div>
              <Field
                name="presentStayingSince"
                component={this.renderDate}
                label="* Staying Since"
                maxDate={new Date().toISOString().split("T")[0]}
              />
            </div>
            <hr />
            <input
              type="checkbox"
              id="presemt_permanent"
              name="presemt_permanent"
              value={true}
              onClick={(e) =>
                this.onPresentPermanentAddressCheckboxClick(e.target.checked)
              }
            />
            <label for="presemt_permanent" className="ml-2 font-weight-bold">
              IS PERMANENT ADDRESS SAME AS PRESENT ADDRESS ?
            </label>
            <br></br>
            {!this.state.presentPermanentAddress ? (
              <div className="col-md-12 col-sm-12 eventCreateInputCol pl-0 row m-0 pb-0 pr-0">
                <h5 className="col-sm-12 cardTitle border-0">
                  Permanent Address
                </h5>
                <Field
                  name="permanentAddress"
                  component={this.renderInput}
                  label="* Address"
                  type="text"
                />
                <Field
                  name="permanentNearestLandmark"
                  component={this.renderInput}
                  label="* Nearest Landmark"
                  type="text"
                />

                <Field
                  name="permanentStateId"
                  component={this.renderSelectInput}
                  placeholder="Select Permanent State"
                  label="* State"
                  onChange={(e) =>
                    this.onPresentStateChange(e.regionID, "permanent")
                  }
                  props={{
                    options: this.props.states,
                  }}
                  normalize={(value) => value.id}
                />
                <Field
                  name="permanentCityId"
                  component={this.renderSelectInput}
                  placeholder="Select Permanent City"
                  label="* City"
                  props={{
                    options: this.props.cities.filter((c) => {
                      return (
                        c.stateId === parseInt(this.props.PermanentStateIdValue)
                      );
                    }),
                  }}
                  normalize={(value) => value.id}
                />
                <Field
                  name="permanentRegionId"
                  component={this.renderInput}
                  placeholder="Select Permanent Region"
                  label="Region"
                  props={{
                    value: this.state.selectedPermanentRegion
                      ? this.props.regions[this.state.selectedPermanentRegion]
                          .name
                      : "",
                  }}
                  normalize={(value) => value.id}
                />
                <Field
                  name="permanentPincode"
                  component={this.renderInput}
                  label="* Pincode"
                  type="text"
                  props={{
                    value: this.state.pincodePermanent,
                  }}
                  onChange={(event) => this.onIntegerChange(event, "permanent")}
                />
                <Field
                  name="permanentTelephoneNumber"
                  component={this.renderInput}
                  label="* Telephone Number"
                  type="text"
                  props={{
                    value: this.state.telephoneNumberPermanent,
                  }}
                  onChange={(event) =>
                    this.onIntegerChange(event, "telephoneNumberPermanent")
                  }
                />
                <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
                  <label>*House Owned/Rental</label>
                  <div>
                    <Field
                      name="permanentOwnedOrRental"
                      component={this.renderRadioInput}
                      type="radio"
                      label="PermanentOwned"
                      value="Owned"
                    />
                  </div>
                  <div>
                    <Field
                      name="permanentOwnedOrRental"
                      component={this.renderRadioInput}
                      type="radio"
                      label="PermanentRental"
                      value="Rental"
                    />
                  </div>
                </div>
                <Field
                  name="permanentStayingSince"
                  component={this.renderDate}
                  label="* Staying Since"
                  maxDate={new Date().toISOString().split("T")[0]}
                />
              </div>
            ) : null}
            <hr />

            <div className="col-md-12 col-sm-12 eventCreateInputCol pl-0 row m-0 pb-0 pr-0">
              <h5 className="col-sm-12 cardTitle border-0">Personal Details</h5>

              <Field
                name="drivingLicenseNumber"
                component={this.renderInput}
                label="Driving License Number"
                type="text"
              />
              <Field
                name="panNumber"
                component={this.renderInput}
                label="* PAN Number"
                type="text"
              />
              <Field
                name="vehicleType"
                component={this.renderSelectInput}
                label="* Vehicle Type"
                props={{
                  options: [
                    { name: "Normal Vehicle", id: "Normal Vehicle" },
                    { name: "Luxury Vehicle", id: "Luxury Vehicle" },
                    { name: "Corporate", id: "Corporate" },
                  ],
                }}
                normalize={(value) => value.id}
              />
              <div style={{ display: 'none' }}>
              <Field  
                name="emdAmount"
                component={this.renderInput}
                label="* EMD Amount"
                type="text"
                props={{
                 
                  value: "0",
                }}
                readOnly
              />
               {/*this.props.vehicleTypeValue
                    ? this.props.categoryEmdPrice[this.props.vehicleTypeValue]
                :*/}
              </div>
             
            </div>
            <hr />
            <div className="col-md-12 col-sm-12 eventCreateInputCol pl-0 row m-0 pb-0 pr-0">
              <h5 className="col-sm-12 cardTitle border-0">Firm Details</h5>

              <Field
                name="officeAddress"
                component={this.renderInput}
                label="* Office Address"
                type="text"
              />
              <Field
                name="gstNo"
                component={this.renderInput}
                label="GST No."
                type="text"
              />
              <Field
                name="firmPAN"
                component={this.renderInput}
                label="Firm PAN No."
                type="text"
              />
              <Field
                name="mobileNumber"
                component={this.renderInput}
                label="*Primary Mobile Number"
                type="text"
                props={{
                  value: this.state.officeMobNo,
                }}
                onChange={(event) => this.onIntegerChange(event, "officeMobNo")}
              />
              <Field
                name="mobileNumber2"
                component={this.renderInput}
                label="Secondary Mobile Number"
                type="text"
                props={{
                  value: this.state.officeMobNo2,
                }}
                onChange={(event) =>
                  this.onIntegerChange(event, "officeMobNo2")
                }
              />
            </div>
            <div className="mainBodyCardFtBtn mt-50">
              <Link className="btnMain mr-5" to="/login">
                Cancel
              </Link>
              <button className="btnMain">Submit</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const selector = formValueSelector("buyerEmpanelmentForm");
  const PresentStateIdValue = selector(state, "presentStateId");
  const PermanentStateIdValue = selector(state, "permanentStateId");
  const presentRegionIdValue = selector(state, "presentRegionId");
  const permanentRegionIdValue = selector(state, "permanentRegionId");
  const vehicleTypeValue = selector(state, "vehicleType");
 // const categoryEmdPrice = {
  //  "Normal Vehicle": "30000",
  //  "Luxury Vehicle": "50000",
  //  Corporate: "50000",
  //};
  return {
    PresentStateIdValue,
    PermanentStateIdValue,
    presentRegionIdValue,
    permanentRegionIdValue,
    regions: state.region,
    vehicleTypeValue,
    //categoryEmdPrice,
    states: Object.values(state.state),
    cities: Object.values(state.city),
  };
};

const validate = (formValues) => {
  const errors = {};
  if (!formValues.firstName) {
    errors.firstName = "This Field is Required";
  }
  else if(!/^[a-zA-Z]+$/.test(formValues.firstName)){
    errors.firstName = "No Special Characters Allowed";
  }
  if(!/^[a-zA-Z]+$/.test(formValues.middleName)){
    errors.middleName = "No Special Characters Allowed";
  }
  if (!formValues.lastName) {
    errors.lastName = "This Field is Required";
  }
  else if(!/^[a-zA-Z]+$/.test(formValues.lastName)){
    errors.lastName = "No Special Characters Allowed";
  }
  if (!formValues.email) {
    errors.email = "This Field is Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)
  ) {
    errors.email = "Invalid email address";
  }
  if (
    formValues.drivingLicenseNumber &&
    !/^[A-Z]{2}[0-9]{2}[ C]{1}[0-9]{11}$/i.test(formValues.drivingLicenseNumber)
  ) {
    errors.drivingLicenseNumber = "Enter DL in Format HR06 19850034761";
  }
  if (!formValues.panNumber) {
    errors.panNumber = "This Field is Required";
  } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i.test(formValues.panNumber)) {
    errors.panNumber = "Invalid PAN Number";
  }
  if (
    formValues.passportNumber &&
    !/^[A-PR-WY][0-9]{8}$/i.test(formValues.passportNumber)
  ) {
    errors.passportNumber = "Invalid Passport Number";
  }
  if (
    formValues.presentStateId === null ||
    formValues.presentStateId === 0 ||
    formValues.presentStateId === undefined
  ) {
    errors.presentStateId = "This Field is Required";
  }
  if (
    formValues.permanentStateId === null ||
    formValues.permanentStateId === 0 ||
    formValues.permanentStateId === undefined
  ) {
    errors.permanentStateId = "This Field is Required";
  }

  if (
    formValues.presentCityId === null ||
    formValues.presentCityId === 0 ||
    formValues.presentCityId === undefined
  ) {
    errors.presentCityId = "This Field is Required";
  }
  if (
    formValues.permanentCityId === null ||
    formValues.permanentCityId === 0 ||
    formValues.permanentCityId === undefined
  ) {
    errors.permanentCityId = "This Field is Required";
  }

  if (!formValues.presentAddress) {
    errors.presentAddress = "This Field is Required";
  }

  else if(!/^[A-Za-z0-9 ,-]*$/.test(formValues.presentAddress)){
    errors.presentAddress = "Special Character Not Allowed";
  }
//End
  if (!formValues.presentNearestLandmark) {
    errors.presentNearestLandmark = "This Field is Required";
  }
    //Added By Mayur

  else if(!/^[A-Za-z0-9 ,-]*$/.test(formValues.presentNearestLandmark)){
    errors.presentNearestLandmark = "Special Character Not Allowed";
  }
  //End
  if (!formValues.permanentAddress) {
    errors.permanentAddress = "This Field is Required";
  }
    //Added By Mayur

  else if(!/^[A-Za-z0-9 ,-]*$/.test(formValues.permanentAddress)){
    errors.permanentAddress = "Special Character Not Allowed";
  }
  //End
  if (!formValues.permanentNearestLandmark) {
    errors.permanentNearestLandmark = "This Field is Required";
  }
  //Added By Mayur
  else if(!/^[A-Za-z0-9 ,-]*$/.test(formValues.permanentNearestLandmark)){
    errors.permanentNearestLandmark = "Special Character Not Allowed";
  }
//End
  if (!formValues.mobileNumber) {
    errors.mobileNumber = "This Field is Required";
  }

  if (!formValues.presentPincode) {
    errors.presentPincode = "This Field is Required";
  } else if (
    formValues.presentPincode < 100000 ||
    formValues.presentPincode > 999999
  ) {
    errors.presentPincode = "Pincode must be 6 digits";
  }

  if (!formValues.presentTelephoneNumber) {
    errors.presentTelephoneNumber = "This Field is Required";
  } else if (
    formValues.presentTelephoneNumber < 1000000000 ||
    formValues.presentTelephoneNumber > 9999999999
  ) {
    errors.presentTelephoneNumber = "Telephone must be 10 digits";
  }

  if (!formValues.presentOwnedOrRental) {
    errors.presentOwnedOrRental = "This Field is Required";
  }

  if (formValues.presentStayingSince === undefined) {
    errors.presentStayingSince = "This Field is Required";
  }
  if (!formValues.permanentPincode) {
    errors.permanentPincode = "This Field is Required";
  } else if (
    formValues.permanentPincode < 100000 ||
    formValues.permanentPincode > 999999
  ) {
    errors.permanentPincode = "Pincode must be 6 digits";
  }

  if (!formValues.permanentTelephoneNumber) {
    errors.permanentTelephoneNumber = "This Field is Required";
  } else if (
    formValues.permanentTelephoneNumber < 1000000000 ||
    formValues.permanentTelephoneNumber > 9999999999
  ) {
    errors.permanentTelephoneNumber = "Telephone must be 10 digits";
  }

  if (!formValues.permanentOwnedOrRental) {
    errors.permanentOwnedOrRental = "This Field is Required";
  }

  if (formValues.permanentStayingSince === undefined) {
    errors.permanentStayingSince = "This Field is Required";
  }
  if (formValues.officeAddress === undefined) {
    errors.officeAddress = "This Field is Required";
  }
  //Added By Mayur
  else if(!/^[A-Za-z0-9 ,-]*$/.test(formValues.officeAddress)){
    errors.officeAddress = "Special Character Not Allowed";
  }
  if(formValues.firmPAN){
  if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i.test(formValues.firmPAN)) {
    errors.firmPAN = "Invalid PAN Number";  
  }}
  if(formValues.gstNo){
  if (!/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/i.test(formValues.gstNo)) {
    errors.gstNo = "Invalid GST Number";
  }}
  //End
  

  if (formValues.mobileNumber === undefined) {
    errors.mobileNumber = "This Field is Required";
  }
  if (formValues.vehicleType === undefined) {
    errors.vehicleType = "This Field is Required";
  }

  return errors;
};

const buyerEmpanelmentForm = reduxForm({
  form: "buyerEmpanelmentForm",
  validate,
  enableReinitialize: true,
})(BuyerEmpanelmentForm);

export default connect(mapStateToProps, {
  fetchRegionsForEmpanelment,
  fetchAllCitiesForEmpanelment,
  fetchAllStatesForEmpanelment,
})(buyerEmpanelmentForm);
