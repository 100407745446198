import {
  CREATE_EVENT_PARAMETER,
  EDIT_EVENT_PARAMETER,
  FETCH_EVENT_PARAMETER,
} from "../../actions/types";

const eventParameterReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_EVENT_PARAMETER:
      return { ...state, ...action.payload };
    case EDIT_EVENT_PARAMETER:
      return { ...state, ...action.payload };
    case FETCH_EVENT_PARAMETER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default eventParameterReducers;
