import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchSubProducts } from "../../../actions/masters/subProductActions";
import history from "../../../history";

class SubProductList extends Component {
  componentDidMount() {
    this.props.fetchSubProducts(this.props.match.params.id);
  }

  renderList() {
    const { id } = this.props.match.params;
    return this.props.subProduct.map((subprod) => {
      if (subprod.productId === parseInt(id)) {
        return (
          <tr key={subprod.id}>
            <td>{subprod.name}</td>
            <td>
              <Link to={`/masters/product/${id}/subproduct/${subprod.id}/edit`}>
                <span>
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </span>
              </Link>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Sub Product Master</h3>
          </div>
          <div className="float-end mt-15">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        <div className="mainBodyCard pt-0">
          <div className="overflow-hidden d-block position-relative">
            <h5 className="cardTitle">Sub Product Name</h5>
            <Link
              className="btnMain float-end position-absolute spmAddBtn"
              to={`/masters/product/${this.props.match.params.id}/subproduct/create`}
            >
              Add
            </Link>
          </div>
          <table className="table table-hover tableStyle1 spnTable">
            <tbody>{this.renderList()}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    subProduct: Object.values(state.subProduct),
  };
};

export default connect(mapStateToProps, { fetchSubProducts })(SubProductList);
