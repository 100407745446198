import _ from "lodash";

import {
  FETCH_REGION,
  FETCH_REGIONs,
  DELETE_REGION,
  CREATE_REGION,
  EDIT_REGION,
} from "../../actions/types";

const regionReducers = (state = {}, action) => {
  switch (action.type) {
    case FETCH_REGIONs:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case FETCH_REGION:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_REGION:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_REGION:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_REGION:
      return _.omit(state, action.payload);

    default:
      return state;
  }
};

export default regionReducers;
