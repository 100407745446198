import CryptoJS from "crypto-js";
const encryptId = (id) => {
  try 
  {
    id = id.toString();
    let key = '';
    let iv = '';
    for (let index = 0; index < 4; index++) {
      key += '8080';
      iv += '8080';
    }
    key = CryptoJS.enc.Utf8.parse(key);
    iv = CryptoJS.enc.Utf8.parse(iv);
  
    let idx = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(id + '~' + new Date().toISOString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
  
    while (idx.includes('/')) {
      idx = idx.replace('/', 'zxcvb');
    }
    while (idx.includes('+')) {
      idx = idx.replace('+', 'plus');
    }
  
    return idx;
  } catch (error) {
    console.error('Encryption error:', error);
    return null;
  }
  };
  const decryptIndex = (Id) => {

    try
    {
    var k = '';
    var d = new Date().toLocaleString();
    for (let index = 0; index <4; index++) {
    k += "8080";
    var value = "";
  }
    
    for (const key in Id) {
      value = Id[key];
      if (Id.hasOwnProperty(key) && value && typeof value === 'string' &&  value.length>5)
      {
        if (value.includes('zxcvb') || value.includes('plus')) {
        while(value.includes('zxcvb'))
        value = value.replace('zxcvb','/')
        while(value.includes('plus'))
        value = value.replace('plus','+')
      }
      if(!(value.includes('T') && value.includes(':'))){
        value =  CryptoJS.AES.decrypt(
          value,
          CryptoJS.enc.Utf8.parse(k),
          {
            keySize: 128 / 8,
            iv:CryptoJS.enc.Utf8.parse(k),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
          }
        ).toString(CryptoJS.enc.Utf8);
        value = value.substring(0,value.indexOf('~'));
        Id[key]=value.toString();
      }
    }
  }
    return Id;
  } catch (error) {
    console.error('Decryption error:', error);
    return null;
  }
  }
  export {encryptId,decryptIndex};
