import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchgroupmappings } from "../../../actions/masters/groupmappingActions";
import Spinner from "../../Spinner";

class GroupMappingList extends Component {
  componentDidMount() {
    this.props.fetchgroupmappings();
  }

  renderList() {
    return this.props.groupmapping.map((gm) => {
      return (
        <tr key={gm.id}>
          <td>{gm.name}</td>
          <td>{gm.activeFlag === "Y" ? "Active" : "InActive"}</td>
          {this.props.user.role === "Admin" ? (
            <td>
              <Link to={`/masters/groupmapping/Edit/${gm.id}`}>
                <span>
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </span>
              </Link>
            </td>
          ) : (
            ""
          )}
        </tr>
      );
    });
  }

  render() {
    return (
      <div>
        {this.props.groupmapping.length > 0 ? (
          <div className="BodyInContainer">
            <div className="d-block clearfix">
              <div className="Header-Content float-start">
                <h3 className="pageMainTitle">Group Mapping Master</h3>
              </div>
              {this.props.user.role === "Admin" ? (
                <div className="float-end mt-15">
                  <Link className="btnMain" to="/masters/groupmapping/create">
                    Add
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="mainBodyCard pt-0">
              <h5 className="cardTitle">Group Type</h5>

              <table className="table table-hover">
                <tbody>{this.renderList()}</tbody>
              </table>
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groupmapping: Object.values(state.groupmapping),
    user: state.login,
  };
};

export default connect(mapStateToProps, { fetchgroupmappings })(
  GroupMappingList
);
