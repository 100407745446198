import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import history from "../../history";
import { fetchEventBidHistory } from "../../actions/event/EventAuditTrailActions";
import { connect } from "react-redux";
import { decryptId } from "../Decryption";
const ViewEventBidDetails = ({
  match,
  fetchEventBidHistory,
  eventBidHistory,
}) => {
  useEffect(() => {
    fetchEventBidHistory(decryptId(match.params.eventId));
  }, [fetchEventBidHistory]);

  const renderList = (data) => {
    return data.map((bid) => {
      return (
        <tr key={bid.id}>
          <td>{bid.createdBy}</td>
          <td>{bid.description}</td>
        </tr>
      );
    });
  };

  return (
    <div className="BodyInContainer  bidDetailsAudit">
      <div className="d-block clearfix">
        <div className="Header-Content float-start">
          <h3 className="cardTitle">Event ID - {decryptId(match.params.eventId)}</h3>
        </div>
        <div className="float-end mt-15">
          <Link
            className="float-end cardBackBtn"
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </Link>
        </div>
      </div>

      <div className="mainBodyCard pt-0">
        {eventBidHistory.length > 0 ? (
          <table className="table table-hover">
            <thead>
              <tr>
                <td>UserId</td>
                <td>Description</td>
              </tr>
            </thead>
            <tbody>{renderList(eventBidHistory)}</tbody>
          </table>
        ) : (
          <div
            className="mainBodyCard pt-30 d-flex flex-row justify-content-center"
            style={{ top: "100px", right: "25px" }}
          >
            <h2 className="notFound">No Bids Placed For the Event</h2>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  eventBidHistory: state.auditTrailHistory.eventBidHistory,
});

const mapDispatchToProps = { fetchEventBidHistory };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewEventBidDetails);
