import React, { Component } from "react";
import { Link } from "react-router-dom";
import history from "../../history";

class PageNotFoundPage extends Component {
  render() {
    return (
      <div className="BodyInContainer errorPage">
        <div className="mainBodyCard pt-30 d-flex flex-column justify-content-center align-items-center">
          <h1 className="errorCode">404</h1>
          <h4 className="errorName">Page Not Found.</h4>
          <h4 className="errorTextEx">
            We're sorry, we couldn't find the page you requested.
          </h4>
          <h4 className="errorTextEx">
            If you feel something is missing that should be here, please contact
            admin.
          </h4>
          <Link
            className="float-end returnHomeBtn mt-5 btnMain"
            onClick={() => history.goBack()}
          >
            Go Back
          </Link>
        </div>
      </div>
    );
  }
}

export default PageNotFoundPage;
