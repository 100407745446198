import CryptoJS from 'crypto-js';

const decryptId = (encryptedId) => {
  try {
    let key = '';
    for (let index = 0; index < 4; index++) {
      key += '8080';
    }
    key = CryptoJS.enc.Utf8.parse(key);
    const iv = key;

    let modifiedId = decodeURIComponent(encryptedId);
    while (modifiedId.includes('zxcvb')) {
      modifiedId = modifiedId.replace('zxcvb', '/');
    }
    while (modifiedId.includes('plus')) {
      modifiedId = modifiedId.replace('plus', '+');
    }

    const decrypted = CryptoJS.AES.decrypt(
      modifiedId,
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString(CryptoJS.enc.Utf8);

    const id = decrypted.substring(0, decrypted.indexOf('~'));

    return id;
  } catch (error) {
    console.error('Decryption error:', error);
    return null;
  }
};

export { decryptId };
