import {
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAIL,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_FAIL,
  CLEAR_OTP_STATE,
} from "../../actions/types";

const initialState = {
  isOtpSent: false,
  isloading: true,
  message: null,
  error: null,
  isOtpValidated: false,
  username: null,
  validotp: null,
};

const otpReducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GENERATE_OTP_SUCCESS:
      return {
        ...state,
        isOtpSent: true,
        isloading: false,
        isOtpValidated: false,
        message: payload.resMessage,
        username: payload.username,
      };
    case GENERATE_OTP_FAIL:
      return {
        ...state,
        isOtpSent: false,
        isloading: false,
        isOtpValidated: false,
      };
    case VALIDATE_OTP_FAIL:
      return {
        ...state,
        isOtpSent: true,
        isloading: false,
        isOtpValidated: false,
      };
    case VALIDATE_OTP_SUCCESS:
      return {
        ...state,
        isOtpSent: true,
        isloading: false,
        isOtpValidated: true,
        validotp: payload.otp,
      };
    case CLEAR_OTP_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

export default otpReducers;
