import React, { Component } from "react";
import { connect } from "react-redux";
import { removeEventBidders } from "../../../actions/event/EventBidderActions";
import { Field, formValueSelector, reduxForm } from "redux-form";
import Select from "react-select";
import _ from "lodash";

class RemoveBidders extends Component {
  state = {
    selectedBidders: [],
    searchOn: "",
    regionOn: 0,
    buyRender: [],
    checkbox_selected: false,
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = (e) => {
    e.preventDefault();
    const { selectedBidders } = this.state;
    this.props.removeEventBidders(this.props.eid, selectedBidders);
    document.removebidders.reset();
    this.state.selectedBidders = [];
  };
 
  selectAllBidders = () => {
    const { buyers } = this.props;
    if (document.querySelector("#selectAllChkboxRemove:checked") !== null) {
      const selectedBidders = Object.values(buyers).map((buyer) => buyer.id);
      this.setState({ selectedBidders, checkbox_selected: true });
    } else {
      this.setState({ selectedBidders: [], checkbox_selected: false });
    }
  };
 
  isBidderSelected = (id) => {
    this.setState((prevState) => {
      const selectedBidders = [...prevState.selectedBidders];
      const index = selectedBidders.indexOf(id);
      if (index !== -1) {
        selectedBidders.splice(index, 1);
      } else {
        selectedBidders.push(id);
      }
      return { selectedBidders };
    });
  };

  clearSelectedBuyersState = () => {
    this.setState({ selectedBidders: [] });
  };

  renderInput = ({ input, label, disabled, type, onChange }) => {
    return (
      <div>
        <div className="eventCreateInputCol">
          <label className="d-none">{label}</label>
          <div className="input-field">
            <input
              {...input}
              type={type}
              autoComplete="off"
              placeholder={label}
              className="form-control"
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    );
  };

  renderList(bidders, buyers) {
    var filtdbidders = Object.values(buyers).filter((b) => {
      if (this.state.searchOn === "Region") {
        return b.regionId === this.state.regionOn;
      } else if (this.props.buyerSearchValue) {
        if (this.state.searchOn === "Name") {
          return b.user_name
            .toLowerCase()
            .includes(this.props.buyerSearchValue.toLowerCase());
        } else if (this.state.searchOn === "Email") {
          return b.email_id
            .toLowerCase()
            .includes(this.props.buyerSearchValue.toLowerCase());
        }
      } else {
        return b;
      }
    });

    var currentbid = _.intersection(
      Object.keys(bidders),
      Object.keys(_.keyBy(filtdbidders, "id"))
    );
 
    return currentbid.map((buy) => {
      return (
        <tr key={buy}>
          <td data-th="">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                onClick={() => this.isBidderSelected(buyers[buy].id)}
                className="custom-control-input"
                id={buyers[buy].id}
                checked={this.state.selectedBidders.includes(buyers[buy].id)}
              />
              <label
                className="custom-control-label"
                htmlFor={buyers[buy].id}
              ></label>
            </div>
          </td>
          <td data-th="User Name">{buyers[buy].user_name}</td>
          <td data-th="Email Id">{buyers[buy].email_id}</td>
          <td data-th="Mobile No.">{buyers[buy].mobile_no}</td>
          <td data-th="Region">
            {this.props.renderFieldName(
              buyers[buy].regionId,
              this.props.regions
            )}
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div className="mappedBiddersContainer">
        <div className="row">
          <div className="col-md-4">
            <h4 className="cardTitle">Mapped Bidders</h4>
          </div>

          <div className="col-md-4">
            <div className="eventCreateInputCol buyerSearch">
              <form onSubmit={(e) => e.preventDefault()}>
                <Select
                  name="dropSelector"
                  id="dropDownSelector"
                  onChange={(ev) => {
                    this.setState({
                      searchOn: ev.value,
                    });
                    this.clearSelectedBuyersState();
                  }}
                  options={[
                    { value: "Name", label: "Name" },
                    { value: "Email", label: "Email" },
                    { value: "Region", label: "Region" },
                  ]}
                  isSearchable={true}
                  placeholder={"Search Criteria"}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#ffa552",
                      primary50: "#fabc82",
                      primary: "#fcb26d",
                    },
                  })}
                />
              </form>
            </div>
          </div>

          <div className="col-md-4">
            {this.state.searchOn !== "Region" ? (
              this.state.searchOn === "Email" ||
              this.state.searchOn === "Name" ? (
                <div className="eventCreateInputCol buyerSearch">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Field
                      name="bidderSearch"
                      component={this.renderInput}
                      onChange={() => this.clearSelectedBuyersState()}
                      label="Buyer Search"
                      type="text"
                    />
                  </form>
                </div>
              ) : (
                <></>
              )
            ) : this.state.searchOn === "Region" ? (
              <div className="eventCreateInputCol buyerSearch">
                <form onSubmit={(e) => e.preventDefault()}>
                  <Select
                    name="regionSelect"
                    isSearchable={true}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={this.props.regions}
                    onChange={(ev) => {
                      this.setState({
                        regionOn: ev.id,
                      });
                      this.clearSelectedBuyersState();
                    }}
                    placeholder={"Search Region"}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#ffa552",
                        primary50: "#fabc82",
                        primary: "#fcb26d",
                      },
                    })}
                  />
                </form>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {Object.keys(this.props.currentbidders).length > 0 &&
        Object.keys(this.props.buyers).length > 0 ? (
          <form onSubmit={this.onSubmit} name="removebidders">
            <div className="mappedBiddersTableContainer">
              <table className="table table-hover tableStyle1">
                <thead>
                  <tr>
                    <td>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="selectAllChkboxRemove"
                          onClick={() => this.selectAllBidders()}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="selectAllChkboxRemove"
                        >
                          Select All
                        </label>
                      </div>
                    </td>
                    <td>User Name</td>
                    <td>Email Id</td>
                    <td>Mobile No.</td>
                    <td>Region</td>
                  </tr>
                </thead>
                <tbody>
                  {this.renderList(
                    this.props.currentbidders,
                    this.props.buyers
                  )}
                </tbody>
              </table>
            </div>
            <div className="mainBodyCardFtBtn mt-50">
              <button className="btnMain">Remove Bidders</button>
            </div>
          </form>
        ) : (
          "Currently There are No Bidders Mapped"
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var selector = formValueSelector("RemoveBiddersForm");
  const buyerSearchValue = selector(state, "bidderSearch");
  return {
    buyerSearchValue,
    regions: Object.values(state.region),
  };
};

const RemoveBiddersForm = reduxForm({
  form: "RemoveBiddersForm",
  enableReinitialize: true,
})(RemoveBidders);

export default connect(mapStateToProps, { removeEventBidders })(
  RemoveBiddersForm
);
