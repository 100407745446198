import React, { Component } from "react";

import OTPInput, { ResendOTP } from "otp-input-react";
import { connect } from "react-redux";
import {
  generateOTP,
  validateOtp,
  setnewpassword,
  resetOtpState,
} from "../../actions/login/otpActions";

class ForgotPasswordPage extends Component {
  state = { OTP: "", username: "", newPassword: "", newPasswordConfirm: "" };

  componentWillUnmount() {
    this.props.resetOtpState();
  }

  onOtpChange = (e) => {
    this.setState({ OTP: e });
  };

  onChange = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  renderButton = (buttonProps) =>
    !buttonProps.disabled && (
      <button
        {...buttonProps}
        className={
          buttonProps.disabled ? "btnMainDisabled" : "btnMain loginBtn"
        }
      >
        Resend
      </button>
    );

  renderTime = (remainingTime) => {
    return remainingTime > 0 ? (
      <h5>Kindly Wait {remainingTime} seconds to Resend OTP</h5>
    ) : null;
  };

  validateOtp = async () => {
    if (this.state.OTP === "") {
      alert("Please enter the OTP");
    } else if (this.state.OTP.length < 4) {
      alert("The OTP is 4digits Long");
    } else {
      this.props.validateOtp(this.props.otp.username, this.state.OTP);
    }
  };

  getOTP = async (e) => {
    e.preventDefault();
    await this.props.generateOTP(this.state.username);
  };

  resetPassword = async (e) => {
    e.preventDefault();
    if (this.state.newPassword !== this.state.newPasswordConfirm) {
      alert("Password Mismatch");
    } else {
      await this.props.setnewpassword(
        this.props.otp.validotp,
        this.state.newPassword,
        this.state.newPasswordConfirm,
        this.props.otp.username
      );
    }
  };

  render() {
    return (
      <div>
        <img
          className="loginImage"
          src="/assets/images/LoginPage1.png"
          alt="loginPagePic"
        />
        <div className="loginContainer snpPage">
          <div>
            <h3 className="loginTitle">Set New Password</h3>
            {this.props.otp.isOtpValidated ? (
              <div>
                <form method="post" onSubmit={(e) => this.resetPassword(e)}>
                  <div className="clearfix">
                    <div className="form-group">
                      <h5>New Password</h5>
                      <div className="input-field">
                        <input
                          type="password"
                          name="newPassword"
                          value={this.state.newPassword}
                          placeholder="Enter Username"
                          className="form-control"
                          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                          title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                          onChange={(e) => {
                            this.onChange(e);
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="form-group">
                      <h5>Confirm Password</h5>
                      <div className="input-field">
                        <input
                          type="password"
                          name="newPasswordConfirm"
                          value={this.state.newPasswordConfirm}
                          placeholder="Enter Username"
                          className="form-control"
                          onChange={(e) => {
                            this.onChange(e);
                          }}
                          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                          title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btnMain loginBtn">
                    Submit
                  </button>
                </form>
              </div>
            ) : this.props.otp.isOtpSent ? (
              <div className="clearfix">
                <div className="form-group">
                  <h5>Enter Otp</h5>
                  <OTPInput
                    value={this.state.OTP}
                    onChange={this.onOtpChange}
                    OTPLength={4}
                    inputClassName="otpInput"
                    otpType="number"
                    disabled={false}
                  />
                  <div className="d-block">
                    <ResendOTP
                      maxTime={30}
                      renderButton={this.renderButton}
                      renderTime={this.renderTime}
                      onResendClick={() =>
                        this.props.generateOTP(this.props.otp.username)
                      }
                    />
                    {this.props.otp.message ? (
                      <p className="pt-5">{this.props.otp.message}</p>
                    ) : (
                      <></>
                    )}
                    <button
                      className="btnMain loginBtn"
                      onClick={() => this.validateOtp()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <form method="post" onSubmit={(e) => this.getOTP(e)}>
                  <div className="clearfix">
                    <div className="form-group">
                      <h5>Enter Username</h5>
                      <div className="input-field mb-4">
                        <input
                          type="text"
                          value={this.state.username}
                          placeholder="Enter Username"
                          className="form-control"
                          onChange={(e) => {
                            this.setState({ username: e.target.value });
                          }}
                          required
                        />
                      </div>
                      <button type="submit" className="btnMain loginBtn">
                        Get Otp
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  otp: state.otp,
});

export default connect(mapStateToProps, {
  generateOTP,
  validateOtp,
  setnewpassword,
  resetOtpState,
})(ForgotPasswordPage);
