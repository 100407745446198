import React from "react";
import { Link } from "react-router-dom";

const MultipleAuthentication = () => {
  return (
    <div className="BodyInContainer errorPage">
      <div className="mainBodyCard pt-30 d-flex flex-column justify-content-center align-items-center">
        <h4 className="errorName">Another Device Login</h4>
        <h4 className="errorTextEx">
          Your Current Session is Expired as Same id was used in Another Device
          .
        </h4>
        <h4 className="errorTextEx">Please login again to access it.</h4>
        <Link className="float-end returnHomeBtn mt-5 btnMain" to="/login">
          Login
        </Link>
      </div>
    </div>
  );
};

export default MultipleAuthentication;
