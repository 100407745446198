import React, { Component } from "react";

class PaymentRefundViewDetails extends Component {
  render() {
    return this.props.Popup > 0 && this.props.id === this.props.Popup ? (
      <div
        className="modal fade"
        id="paymentRefundViewDetailsModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="paymentRefundViewDetailsLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Refund Request Details [{this.props.pay.requestId}]
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body bidModalBody">
              <div>
                <label> Buyer Name: </label>{" "}
                <label> {this.props.pay.buyerName}</label>
              </div>
              <div>
                <label> Account Holder Name: </label>{" "}
                <label> {this.props.pay.bankAccountName}</label>
              </div>
              <div>
                <label> Account Number: </label>{" "}
                <label> {this.props.pay.bankAccountNumber}</label>
              </div>
              <div>
                <label> Bank: </label> <label> {this.props.pay.bankName}</label>
              </div>
              <div>
                <label> IFSC: </label> <label> {this.props.pay.ifsc}</label>
              </div>

              {this.props.pay.status === "Closed" ? (
                <div>
                  <div>
                    <label> Payment Reference Number </label>{" "}
                    <label> {this.props.pay.paymentRefNo}</label>
                  </div>
                  <div>
                    <label> Remarks </label>{" "}
                    <label> {this.props.pay.remarks}</label>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default PaymentRefundViewDetails;
