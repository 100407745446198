import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchNols } from "../../../actions/masters/natureOfLossActions";
import Spinner from "../../Spinner";

class NatureOfLossList extends Component {
  componentDidMount() {
    this.props.fetchNols();
  }

  renderList() {
    return this.props.natureofloss.map((nol) => {
      return (
        <tr key={nol.id}>
          <td>{nol.name}</td>
          <td>{nol.activeFlag === "Y" ? "Active" : "InActive"}</td>
          {this.props.user.role === "Admin" ? (
            <td>
              <Link to={`/masters/NatureOfLoss/Edit/${nol.id}`}>
                <span>
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </span>
              </Link>
            </td>
          ) : (
            ""
          )}
        </tr>
      );
    });
  }

  render() {
    return (
      <div>
        {this.props.natureofloss.length > 0 ? (
          <div className="BodyInContainer">
            <div className="d-block clearfix">
              <div className="Header-Content float-start">
                <h3 className="pageMainTitle">Nature of Loss Master</h3>
              </div>
              {this.props.user.role === "Admin" ? (
                <div className="float-end mt-15">
                  <Link className="btnMain" to="/masters/NatureOfLoss/create">
                    Add
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="mainBodyCard pt-0">
              <h5 className="cardTitle">Nature of Loss</h5>

              <table className="table table-hover tableStyle1 natureOfLossTable">
                <thead>
                  <tr>
                    <td>NOL Name</td>
                    <td>Active/Inactive</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>{this.renderList()}</tbody>
              </table>
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    natureofloss: Object.values(state.natureofloss),
    user: state.login,
  };
};

export default connect(mapStateToProps, { fetchNols })(NatureOfLossList);
