import React from "react";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";

function renderCity(cityId, city) {
  if (city.length > 0) {
    return city.filter((pr) => pr.id === cityId)[0].name;
  }
  return "";
}

function renderState(stateId, state) {
  if (state.length > 0) {
    return state.filter((pr) => pr.id === stateId)[0].name;
  }
  return "";
}

function renderRegion(regionId, region) {
  if (region.length > 0) {
    return region.filter((pr) => pr.id === regionId)[0].name;
  }
  return "";
}
const encryptId = (id) => {
  id = id.toString();
  let key = '';
  let iv = '';
  for (let index = 0; index < 4; index++) {
    key += '8080';
    iv += '8080';
  }
  key = CryptoJS.enc.Utf8.parse(key);
  iv = CryptoJS.enc.Utf8.parse(iv);

  let idx = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(id + '~' + new Date().toISOString()),
    key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();

  while (idx.includes('/')) {
    idx = idx.replace('/', 'zxcvb');
  }
  while (idx.includes('+')) {
    idx = idx.replace('+', 'plus');
  }

  return idx;
};
const BuyerRmcData = ({
  userData,
  status,
  data,
  approve,
  state,
  city,
  region,
}) => {
  if (data === undefined) {
    return <div></div>;
  }
  return (
    <div>
      {data.map((buy) => {
        return (
          <div
            className="card tabCard mb-1 cardFadeIn"
            key={buy.id}
            id={`buyerModificationCard_${buy.id}`}
          >
            <div className="card-body">
              <h6 className="card-title clearfix">
                <span className="float-start">
                  Buyer Request Id :
                  <span className="card-subtitle mb-2 text-orange">
                    {buy.buyerRequestrmcId}
                  </span>
                </span>
                <span className="float-end">
                  Email Id :
                  <span className="card-subtitle mb-2 text-orange">
                    {buy.email}
                  </span>
                </span>
              </h6>
              <div className="card-text">
                <h6 className="d-block cardLabelText">
                  Name :
                  <span className="card-subtitle mb-2 text-muted">
                    {buy.firstName +
                      " " +
                      (buy.middleName ? buy.middleName : "") +
                      " " +
                      buy.lastName}
                  </span>
                </h6>
                <div className="d-block m-0 d-flex flex-row overflow-hidden cardFloatItems">
                  <span className="float-left">
                    <span className="cardLabelText">Region</span>
                    <span className="cardDetailsText card-subtitle mb-2 text-muted">
                      {region
                        ? renderRegion(buy.presentRegionId, region)
                        : null}
                    </span>
                  </span>
                  <span className="float-left">
                    <span className="cardLabelText">State</span>
                    <span className="cardDetailsText card-subtitle mb-2 text-muted">
                      {state ? renderState(buy.presentStateId, state) : null}
                    </span>
                  </span>
                  <span className="float-left">
                    <span className="cardLabelText">City</span>
                    <span className="cardDetailsText  card-subtitle mb-2 text-muted">
                      {city ? renderCity(buy.presentCityId, city) : null}
                    </span>
                  </span>
                  <span className="float-left">
                    <span className="cardLabelText">Mobile Number</span>
                    <span className="cardDetailsText  card-subtitle mb-2 text-muted">
                      {buy.presentTelephoneNumber}
                    </span>
                  </span>
                </div>
              </div>

              <div className="card-footer overflow-hidden">
                <div className="float-start byCfLeftBtns">
                  <Link
                    to={`/buyerrmc/view/${encryptId(buy.id)}`}
                    className="btn btn-link byCfBtn"
                  >
                    View Details
                  </Link>
                </div>
                {status === "Pending" ? (
                  <div className="float-end w-50 border-left byCfRightBtnsMain">
                    {userData.role === "Admin" ? (
                      <div className="byCfRightBtns">
                        <button
                          className="btn btn-link byCfBtn approveBtn"
                          onClick={() => approve(buy.id, "A")}
                        >
                          Approve
                        </button>
                        <button
                          className="btn btn-link byCfBtn rejectBtn"
                          onClick={() => approve(buy.id, "R")}
                        >
                          Reject
                        </button>
                      </div>
                    ) : userData.role === "SalvageRM" ? (
                      <div className="byCfRightBtns">
                        <button
                          className="btn btn-link byCfBtn approveBtn"
                          onClick={() => approve(buy.id, "A")}
                        >
                          Approve
                        </button>
                        <button
                          className="btn btn-link byCfBtn rejectBtn"
                          onClick={() => approve(buy.id, "R")}
                        >
                          Reject
                        </button>
                      </div>
                    ) : null}
                  </div>
                ) : status === "PendingRelease" && userData.role === "Admin" ? (
                  <button
                    className="btn btn-link byCfBtn"
                    onClick={() => approve(buy.id, "A")}
                  >
                    Approve
                  </button>
                ) : (
                  status === "Rejected" &&
                  buy.releaseRemarks && (
                    <div className="float-end mr-2 byCfLeftBtns">
                      <p className="m-2">
                        <b>Rejection Remarks : </b>
                        {buy.releaseRemarks}
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BuyerRmcData;
