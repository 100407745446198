import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
  fetchFraud,
  editFraud,
  deleteFraud,
} from "../../../actions/masters/fraudActions";
import FraudTypeForm from "./FraudTypeForm";
import { Link } from "react-router-dom";
import Spinner from "../../Spinner";
import history from "../../../history";

class FraudTypeEdit extends Component {
  componentDidMount() {
    this.props.fetchFraud(this.props.match.params.id);
  }

  onSubmit = (formValues) => {
    this.props.editFraud(this.props.match.params.id, formValues);
  };

  onClickDelete = () => {
    this.props.deleteFraud(this.props.match.params.id, this.props.fraudType);
  };

  render() {
    return (
      <div>
        {Object.values(this.props.fraudType).length > 0 ? (
          <div className="BodyInContainer">
            <div className="d-block clearfix">
              <div className="Header-Content float-start">
                <h3 className="pageMainTitle">Edit Fraud Type</h3>
              </div>
              <div className="float-end mt-15">
                <Link
                  className="float-end cardBackBtn"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </Link>
              </div>
            </div>
            <FraudTypeForm
              initialValues={_.pick(this.props.fraudType, "name", "activeFlag")}
              onSubmit={this.onSubmit}
              onDelete={this.onClickDelete}
            />
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { fraudType: state.fraudType[ownProps.match.params.id] };
};

export default connect(mapStateToProps, { editFraud, fetchFraud, deleteFraud })(
  FraudTypeEdit
);
