import AuctionPortal from "../../apis/AuctionPortal";
import history from "../../history";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isSessionExpired, userLogout } from "../login/loginActions";
import CryptoJS from "crypto-js";
import { encryptId,decryptIndex } from "../../components/Encryption";
import {
  EDIT_BUYER,
  FETCH_BUYER,
  EDIT_BUYERRMC,
  FETCH_BUYERRMC_LISTS,
  FETCH_BUYER_RMC,
  BUYERRMC_ADMIN_APPROVE,
  USER_LOGOUT,
} from "../types";

toast.configure({
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
});

export const fetchBuyer = (buyerId) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get(`/Buyer/${encryptId(buyerId)}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      var k = '';
      var d = new Date().toLocaleString();
      for (let index = 0; index <4; index++) {
      k += "8080";
      var value = "";
    }
      const decryptedData = {};
      debugger;
      for (const key in response.data) {
        value = response.data[key];
        if (response.data.hasOwnProperty(key) && value != null && value.length>5)
        {
          while(value.includes('zxcvb'))
          value = value.replace('zxcvb','/')
          while(value.includes('plus'))
          value = value.replace('plus','+')
          if(!(value.includes('T') && value.includes(':'))){
          value = await CryptoJS.AES.decrypt(
            value,
            CryptoJS.enc.Utf8.parse(k),
            {
              keySize: 128 / 8,
              iv:CryptoJS.enc.Utf8.parse(k),
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          ).toString(CryptoJS.enc.Utf8);
          value = value.substring(0,value.indexOf('~'));
          response.data[key]=value.toString();
        }
      }
      }
      dispatch({ type: FETCH_BUYER, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const editBuyer = (id, formValues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    if (formValues.fraudTypeId !== formValues.oldFraudTypeId) {
      if (formValues.fraudTypeId === 1) {
        formValues.BlackListingRemarks = "";
        formValues.temporaryBlacklistDate = "0001-01-01T00:00:00";
      } else if (formValues.fraudTypeId === 3) {
        formValues.BlackListingRemarks = prompt(
          `Reason For Blacklisting the Buyer : ${formValues.buyerRequestId}`
        );
        formValues.temporaryBlacklistDate = "0001-01-01T00:00:00";
      } else {
        if (
          formValues.oldTemporaryBlackListDate !==
          formValues.temporaryBlacklistDate
        ) {
          formValues.BlackListingRemarks = prompt(
            `Reason For Blacklisting the Buyer : ${formValues.buyerRequestId}`
          );
        }
      }
    } else {
      if (formValues.fraudTypeId === 1) {
        formValues.BlackListingRemarks = "";
        formValues.temporaryBlacklistDate = "0001-01-01T00:00:00";
      } else {
        if (
          formValues.oldTemporaryBlackListDate !==
          formValues.temporaryBlacklistDate
        ) {
          formValues.BlackListingRemarks = prompt(
            `Reason For Blacklisting the Buyer : ${formValues.buyerRequestId}`
          );
        } else {
          formValues.BlackListingRemarks = formValues.oldBlackListRemarks;
        }
      }
    }

    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.put(
        `/Buyer/${encryptId(id)}`,
        {
          ...formValues,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      var k = '';
      var d = new Date().toLocaleString();
      for (let index = 0; index <4; index++) {
      k += "8080";
      var value = "";
    }
      const decryptedData = {};
      for (const key in response.data) {
        value = response.data[key];
        if (response.data.hasOwnProperty(key) && value != null &&  value.length>5)
        {
          while(value.includes('zxcvb'))
          value = value.replace('zxcvb','/')
          while(value.includes('plus'))
          value = value.replace('plus','+')
          if(!(value.includes('T') && value.includes(':'))){
          value = await CryptoJS.AES.decrypt(
            value,
            CryptoJS.enc.Utf8.parse(k),
            {
              keySize: 128 / 8,
              iv:CryptoJS.enc.Utf8.parse(k),
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          ).toString(CryptoJS.enc.Utf8);
          value = value.substring(0,value.indexOf('~'));
          response.data[key]=value.toString();
        }
      }
      }
      dispatch({ type: EDIT_BUYER, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.push("/masters/buyers");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const editBuyerRMC = (formValues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.post(
        "/buyerrmc",
        {
          ...formValues,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      var k = '';
      var d = new Date().toLocaleString();
      for (let index = 0; index <4; index++) {
      k += "8080";
      var value = "";
    }
      const decryptedData = {};
      for (const key in response.data) {
        value = response.data[key];
        if (response.data.hasOwnProperty(key) && value != null &&  value.length>5)
        {
          while(value.includes('zxcvb'))
          value = value.replace('zxcvb','/')
          while(value.includes('plus'))
          value = value.replace('plus','+')
          if(!(value.includes('T') && value.includes(':'))){
          value = await CryptoJS.AES.decrypt(
            value,
            CryptoJS.enc.Utf8.parse(k),
            {
              keySize: 128 / 8,
              iv:CryptoJS.enc.Utf8.parse(k),
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          ).toString(CryptoJS.enc.Utf8);
          value = value.substring(0,value.indexOf('~'));
          response.data[key]=value.toString();
        }
      }
      }
      dispatch({ type: EDIT_BUYERRMC, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.push("/profile/viewprofile");
      toast("Request generated Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const fetchBuyerRMCLists = () => async (dispatch) => {
  try {
    document.getElementById("spinner").classList.add("spinnerDisplay");
    const response = await AuctionPortal.get("/buyerrmc", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    let k = '';
    for (let index = 0; index < 4; index++) {
      k += "8080";
    }
    
   
    
    for(const major in response.data){
      for(const key in response.data[major]){
        var value = response.data[major][key];
        try{
        if (response.data[major].hasOwnProperty(key) && value !==null && value.length>5)
        {
          while(value.includes('zxcvb'))
          value = value.replace('zxcvb','/')
          while(value.includes('plus'))
          value = value.replace('plus','+')
          if(!(value.includes('T') && value.includes(':')))
          {
          value = await CryptoJS.AES.decrypt(
            value,
            CryptoJS.enc.Utf8.parse(k),
            {
              keySize: 128 / 8,
              iv:CryptoJS.enc.Utf8.parse(k),
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          ).toString(CryptoJS.enc.Utf8);
          value = value.substring(0,value.indexOf('~'));
          response.data[major][key]=value.toString();
        }
        }}
        catch(error){
         
          console.log(error);
         
         
        }
      }
     
      
    }
    dispatch({ type: FETCH_BUYERRMC_LISTS, payload: response.data });

    document.getElementById("spinner").classList.remove("spinnerDisplay");
  } catch (error) {
    document.getElementById("spinner").classList.remove("spinnerDisplay");
    switch (error.response.status) {
      case 408:
        history.push("/sessionExpired");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        history.push("/BadRequest");
        break;
      default:
        toast.error("Error Occurred");
    }
  }
};

export const fetchBuyerRmc = (id) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      //added by siddharth for points
      var k='';
      //var d = new Date().toLocaleString();
      for (let index = 0; index <4; index++) {
      k += "8080";
      }

      var key = CryptoJS.enc.Utf8.parse(k);
      var iv = CryptoJS.enc.Utf8.parse(k);
    
      var idSEND = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(id),
        key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
      
      while(idSEND.includes('/'))
      idSEND = idSEND.replace('/','zxcvb')
    while(idSEND.includes('+'))
    idSEND = idSEND.replace('+','plus')
    
      const response = await AuctionPortal.get(`/buyerrmc/${idSEND}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      var k = '';
      var d = new Date().toLocaleString();
      for (let index = 0; index <4; index++) {
      k += "8080";
      var value = "";
    }
      const decryptedData = {};
      for (const key in response.data) {
        value = response.data[key];
        if (response.data.hasOwnProperty(key) && value != null && value.length>5)
        {
          while(value.includes('zxcvb'))
          value = value.replace('zxcvb','/')
          while(value.includes('plus'))
          value = value.replace('plus','+')
          if(!(value.includes('T') && value.includes(':'))){
          value = await CryptoJS.AES.decrypt(
            value,
            CryptoJS.enc.Utf8.parse(k),
            {
              keySize: 128 / 8,
              iv:CryptoJS.enc.Utf8.parse(k),
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          ).toString(CryptoJS.enc.Utf8);
          value = value.substring(0,value.indexOf('~'));
          response.data[key]=value.toString();
        }
      }
      }
      dispatch({ type: FETCH_BUYER_RMC, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const adminBuyerRmcApprove = (id, value) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      let ApprovalRemarks = "";
      if (value == "R") {
        ApprovalRemarks = prompt("Rejection Remarks ");
      } else {
        ApprovalRemarks = "Done";
      }
      document.getElementById("spinner").classList.add("spinnerDisplay");
      var k='';
      //var d = new Date().toLocaleString();
      for (let index = 0; index <4; index++) {
      k += "8080";
      }

      var key = CryptoJS.enc.Utf8.parse(k);
      var iv = CryptoJS.enc.Utf8.parse(k);
    
      var idSEND = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(id),
        key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
      
      while(idSEND.includes('/'))
      idSEND = idSEND.replace('/','zxcvb')
    while(idSEND.includes('+'))
    idSEND = idSEND.replace('+','plus')
      const response = await AuctionPortal.put(
        `buyerrmc/${idSEND}/AdminApproval`,
        {
          isApproved: value,
          remarks: ApprovalRemarks,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      document
        .getElementById(`buyerModificationCard_${id}`)
        .classList.remove("cardFadeIn");
      document
        .getElementById(`buyerModificationCard_${id}`)
        .classList.add("cardFadeOut");

      setTimeout(function () {
        dispatch({ type: BUYERRMC_ADMIN_APPROVE, payload: response.data });
      }, 500);

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      if (value === "A") {
        toast("Buyer Modification Request Approved By Admin");
      } else {
        toast("Buyer Modification Request Rejected By Admin");
      }
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};
