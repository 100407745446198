import React, { useState } from "react";

const FAQ = () => {
  const [openFaq, setOpenFaq] = useState(null);

  return (
    <div className="BodyInContainer defaultTimeMasterPage">
      <div className="d-block clearfix">
        <div className="Header-Content float-start">
          <h3 className="pageMainTitle">FAQs / Contact</h3>
        </div>
      </div>
      <div className="mainBodyCard pt-0">
        <div className="accordion">
          <div className="accordion-item">
            <a>What can JavaScript Do?</a>
            <div className="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Elementum sagittis vitae et leo duis ut. Ut tortor pretium
                viverra suspendisse potenti.
              </p>
            </div>
          </div>
          <div className="accordion-item">
            <a>How JavaScript Can Modify HTML and CSS Values?</a>
            <div className="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Elementum sagittis vitae et leo duis ut. Ut tortor pretium
                viverra suspendisse potenti.
              </p>
            </div>
          </div>
          <div className="accordion-item">
            <a>What Is SVG?</a>
            <div className="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Elementum sagittis vitae et leo duis ut. Ut tortor pretium
                viverra suspendisse potenti.
              </p>
            </div>
          </div>
          <div className="accordion-item">
            <a>Does FAQ Section Matters In Website?</a>
            <div className="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Elementum sagittis vitae et leo duis ut. Ut tortor pretium
                viverra suspendisse potenti.
              </p>
            </div>
          </div>
          <div className="accordion-item">
            <a>How To Create a Light FAQ Element?</a>
            <div className="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Elementum sagittis vitae et leo duis ut. Ut tortor pretium
                viverra suspendisse potenti.
              </p>
            </div>
          </div>
        </div>
        <h2 />
        <h2 id="#contact">Contact us</h2>
        <div className="footnode">
          ICICI Lombard General Insurance Company Limited, &nbsp;
          <br />
          ICICI Lombard House, 414, Veer Savarkar Marg, Near Siddhi Vinayak
          Temple, Prabhadevi, Mumbai - 400025.
          <br />
          Fax no - 022 61961323
          <br />
          Reg. No.115
        </div>
      </div>
    </div>
  );
};

export default FAQ;
