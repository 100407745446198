import React, { Component } from "react";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import {
  fetchBuyerRMCLists,
  adminBuyerRmcApprove,
} from "../../../actions/masters/buyerActions";
import BuyerRmcData from "./BuyerRmcData";
import { fetchRegions } from "../../../actions/masters/regionActions";
import { fetchAllStates } from "../../../actions/masters/stateActions";
import { fetchAllCities } from "../../../actions/masters/cityActions";
import LeftMenu from "../../layout/LeftMenu";
import { Redirect } from "react-router";
import SessionExpiredPage from "../../common/SessionExpiredPage";

class BuyerRmcList extends Component {
  state = {
    searchParameters: {
      buyerName: null,
      requestId: null,
      emailId: null,
    },
    search: false,
  };

  componentDidMount = async () => {
    this.props.fetchRegions();
    this.props.fetchAllStates();
    this.props.fetchAllCities();
    this.props.fetchBuyerRMCLists();
  };

  toggleSearch = () => {
    if (this.state.search) {
      this.setState({ search: false });
      this.setState({
        searchParameters: { buyerName: null, requestId: null, emailId: null },
      });
      document.getElementById("BuyerRmcSearchBuyerName").value = "";
      document.getElementById("BuyerRmcSearchRequestId").value = "";
      document.getElementById("BuyerRmcSearchEmailID").value = "";
    } else {
      this.setState({ search: true });
    }
  };

  submitBuyerNameSearch = (searchParam) => {
    this.setState((prevState) => ({
      searchParameters: {
        ...prevState.searchParameters,
        buyerName: searchParam.buyerName,
      },
    }));
  };

  submitRequestIdSearch = (searchParam) => {
    this.setState((prevState) => ({
      searchParameters: {
        ...prevState.searchParameters,
        requestId: searchParam.requestId,
      },
    }));
  };

  submitEmailIdSearch = (searchParam) => {
    this.setState((prevState) => ({
      searchParameters: {
        ...prevState.searchParameters,
        emailId: searchParam.emailId,
      },
    }));
  };

  filterData = (BuyerRmcData) => {
    if (this.state.searchParameters.buyerName) {
      BuyerRmcData = BuyerRmcData.filter((data) => {
        return (data.firstName + " " + data.middleName + " " + data.lastName)
          .toLowerCase()
          .includes(this.state.searchParameters.buyerName.toLowerCase());
      });
    }
    if (this.state.searchParameters.requestId) {
      BuyerRmcData = BuyerRmcData.filter((data) => {
        return data.buyerRequestrmcId
          .toLowerCase()
          .includes(this.state.searchParameters.requestId.toLowerCase());
      });
    }
    if (this.state.searchParameters.emailId) {
      BuyerRmcData = BuyerRmcData.filter((data) => {
        return data.email
          .toLowerCase()
          .includes(this.state.searchParameters.emailId.toLowerCase());
      });
    }
    return BuyerRmcData;
  };

  render() {
    if (!localStorage.getItem("userToken")) {
      <Redirect to="/login" />;
      return <SessionExpiredPage />;
    }
    return (
      <div className="BodyInContainer homepage">
        <div className="mainBodyCard pt-30 overflow-hidden position-relative d-flex">
          <LeftMenu
            activeRoute="buyerrmc"
            toggleSearch={this.toggleSearch}
            submitRequestIdSearch={this.submitRequestIdSearch}
            submitBuyerNameSearch={this.submitBuyerNameSearch}
            submitEmailIdSearch={this.submitEmailIdSearch}
          />
          <div className="rightTabBody belRightTabBody">
            <div className="tabbable-responsive">
              <div className="homeTabContainer tabbable">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="pending-tab"
                      data-bs-toggle="tab"
                      href="#pending"
                      role="tab"
                      aria-controls="pending"
                      aria-selected="true"
                    >
                      Pending
                      <span className="badge bg-warning rounded-pill mx-2">
                        {
                          this.filterData(this.props.BuyerRmcApprovalPending)
                            .length
                        }
                      </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="approved-tab"
                      data-bs-toggle="tab"
                      href="#approved"
                      role="tab"
                      aria-controls="approved"
                      aria-selected="false"
                    >
                      Approved
                      <span className="badge bg-warning rounded-pill mx-2">
                        {this.filterData(this.props.BuyerRmcApproved).length}
                      </span>
                    </a>
                  </li>

                  {
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="rejected-tab"
                        data-bs-toggle="tab"
                        href="#rejected"
                        role="tab"
                        aria-controls="rejected"
                        aria-selected="false"
                      >
                        Rejected
                        <span className="badge bg-warning rounded-pill mx-2">
                          {this.filterData(this.props.BuyerRmcRejected).length}
                        </span>
                      </a>
                    </li>
                  }
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane show active"
                    id="pending"
                    role="tabpanel"
                    aria-labelledby="pending-tab"
                  >
                    {/* {this.props.userData.role === "Admin" ? ( */}
                    <BuyerRmcData
                      state={this.props.states}
                      city={this.props.cities}
                      region={this.props.regions}
                      userData={this.props.userData}
                      data={this.filterData(this.props.BuyerRmcApprovalPending)}
                      status="Pending"
                      approve={this.props.adminBuyerRmcApprove}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="approved"
                    role="tabpanel"
                    aria-labelledby="approved-tab"
                  >
                    <BuyerRmcData
                      state={this.props.states}
                      city={this.props.cities}
                      region={this.props.regions}
                      userData={this.props.userData}
                      data={this.filterData(this.props.BuyerRmcApproved)}
                      status="Approved"
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="released"
                    role="tabpanel"
                    aria-labelledby="released-tab"
                  >
                    <BuyerRmcData
                      state={this.props.states}
                      city={this.props.cities}
                      region={this.props.regions}
                      userData={this.props.userData}
                      data={this.props.BuyerReleased}
                      status="Released"
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pendingrelease"
                    role="tabpanel"
                    aria-labelledby="pending-tab"
                  >
                    <BuyerRmcData
                      state={this.props.states}
                      city={this.props.cities}
                      region={this.props.regions}
                      userData={this.props.userData}
                      data={this.props.BuyerReleasePending}
                      status="PendingRelease"
                      approve={this.props.adminBuyerRmcApprove}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="rejected"
                    role="tabpanel"
                    aria-labelledby="rejected-tab"
                  >
                    <BuyerRmcData
                      state={this.props.states}
                      city={this.props.cities}
                      region={this.props.regions}
                      userData={this.props.userData}
                      data={this.filterData(this.props.BuyerRmcRejected)}
                      status="Rejected"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const userData =
    localStorage.getItem("userToken") &&
    jwt_decode(localStorage.getItem("userToken"));
  if (userData) {
    if (userData && userData.role === "Admin") {
      return {
        regions: Object.values(state.region),
        states: Object.values(state.state),
        cities: Object.values(state.city),
        userData: userData,
        BuyerRmcApprovalPending: Object.values(state.buyer).filter(
          (buy) => buy.approverFlag === "N"
        ),
        BuyerRmcApproved: Object.values(state.buyer).filter(
          (buy) => buy.approverFlag === "A"
        ),
        BuyerRmcRejected: Object.values(state.buyer).filter(
          (buy) => buy.approverFlag === "R"
        ),
      };
    }
  }
};

export default connect(mapStateToProps, {
  fetchRegions,
  fetchAllCities,
  fetchAllStates,
  fetchBuyerRMCLists,
  adminBuyerRmcApprove,
})(BuyerRmcList);
