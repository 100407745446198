import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BiddingPopUp from "../event/eventBid/BiddingPopUp";
import Ticker from "./TimeTicker";
import ReactPaginate from "react-paginate";
import history from "../../history";
import EventParticipationAgreement from "../event/eventBid/EventParticipationAgreement";
import CryptoJS from "crypto-js";

function renderCategory(categoryId, product) {
  if (product.length > 0) {
    return product.filter((pr) => pr.id === categoryId)[0].name;
  }
  return "";
}

function renderState(stateId, states) {
  if (states.length > 0) {
    return states.filter((pr) => pr.id === stateId)[0].name;
  }
  return "";
}
const encryptId = (id) => {
  id = id.toString();
  let key = '';
  let iv = '';
  for (let index = 0; index < 4; index++) {
    key += '8080';
    iv += '8080';
  }
  key = CryptoJS.enc.Utf8.parse(key);
  iv = CryptoJS.enc.Utf8.parse(iv);

  let idx = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(id + '~' + new Date().toISOString()),
    key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();

  while (idx.includes('/')) {
    idx = idx.replace('/', 'zxcvb');
  }
  while (idx.includes('+')) {
    idx = idx.replace('+', 'plus');
  }

  return idx;
};
const AuctionData = ({
  currentTime,
  data,
  product,
  state,
  approveEvent,
  tokendata,
  buyerEventMapping,
  pageNo,
  eventStatus,
 
}) => {
  const [buttonPopup, setButtonPopup] = React.useState(0);
  const [dropbutton, setDropbutton] = React.useState(0);
  const [eventTerms, setEventTerms] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(pageNo - 1);
  var Number_PAGE = 10;
  
  if(eventStatus === "SEARCHED"){
  
   Number_PAGE = 100;}
  const PER_PAGE=Number_PAGE;
  const offset = currentPage * PER_PAGE;
  const currentPageData = data.slice(offset, offset + PER_PAGE);
  const pageCount = Math.ceil(data.length / PER_PAGE);

  function handlePageClick({ selected: selectedPage }) {
    history.push(`/home/${eventStatus}?pageNo=${selectedPage + 1}`);
    setCurrentPage(selectedPage);
  }
  const toggleClass = (evntid, currid) => {
    if (dropbutton === 0) {
      setDropbutton(evntid);
    } else {
      setDropbutton(0);
    }
  };

  return (
    <div className="">
      {currentPageData.length > 0 ? (
        currentPageData.map((event) => {
          return (
            <div
              className="card tabCard mb-1"
              key={event.id}
              id={`EventCard_${event.id}`}
            >
              <div className="card-body">
                <h6
                  className="card-title clearfix"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className="float-left">
                    Event Id :
                    <span className="card-subtitle mb-2 text-orange">
                      {event.eventId ? event.eventId : "NA"}
                    </span>
                  </span>
                  <span>
                    Claim No :
                    <span className="card-subtitle mb-2 text-orange">
                      {event.claimNo ? event.claimNo : "NA"}
                    </span>
                  </span>
                  <div className="float-end timeDisplay">
                    {event.status === "LIVE" ? (
                      <span className="text-danger">
                        <Ticker
                          currentTime={currentTime}
                          DateTime={event.parameter.endDateTime}
                        />
                      </span>
                    ) : event.status === "FUTURE" ? (
                      <span className="text-danger">
                        {event.parameter ? (
                          <Ticker
                            currentTime={currentTime}
                            DateTime={event.parameter.startDateTime}
                          />
                        ) : null}
                      </span>
                    ) : (
                      event.status === "ARCHIVE" &&
                      event.liveCancelRemarks && (
                        <span className="text-danger animate_blink">
                          {event.liveCancelRemarks}
                        </span>
                      )
                    )}
                  </div>
                 

                 </h6>
                <div className="card-text">
                  <h6 className="d-block cardLabelText">
                    Details :
                    <span className="card-subtitle mb-2 text-muted">
                      {event.auctionDetails ? event.auctionDetails : "NA"}
                    </span>
                  </h6>
                  <div className="d-block m-0 d-flex flex-row overflow-hidden cardFloatItems">
                    <span className="row" style={{ margin: "0px" }}>
                      <span className="float-left">
                        <span className="d-block cardLabelText">
                          State{": "}
                          <span className="card-subtitle mb-2 text-muted">
                            {renderState(event.garageStateId, state)}
                          </span>
                        </span>
                      </span>
                      {event.category === 2 ? (
                        <>
                          <span className="float-left">
                            <span className="d-block cardLabelText">
                              Make{": "}
                              <span className=" card-subtitle mb-2 text-muted">
                                {event.vehicleMake ? event.vehicleMake : "NA"}
                              </span>
                            </span>
                          </span>
                          <span className="float-left">
                            <span className="d-block cardLabelText">
                              Varient{": "}
                              <span className="  card-subtitle mb-2 text-muted">
                                {event.modelVariant ? event.modelVariant : "NA"}
                              </span>
                            </span>
                          </span>
                          <span className="float-left">
                            <span className="d-block cardLabelText">
                              Manufacturing Year{": "}
                              <span className="card-subtitle mb-2 text-muted">
                                {event.manufacturingYear
                                  ? event.manufacturingYear
                                  : "NA"}
                              </span>
                            </span>
                          </span>
                          {event.parameter && (
                            <span className="float-left">
                              <span className="d-block cardLabelText">
                                Reg. No {": "}
                                <span className="card-subtitle mb-2 text-muted">
                                  {event.registrationNo
                                    ? event.registrationNo
                                    : "NA"}
                                </span>
                              </span>
                            </span>
                          )}
                          {event.parameter && (
                            <span className="float-left">
                              <span className="d-block cardLabelText">
                                Fuel Type{": "}
                                <span className="card-subtitle mb-2 text-muted">
                                  {event.fuelType ? event.fuelType : "NA"}
                                </span>
                              </span>
                            </span>
                          )}{" "}
                          <span className="float-left">
                            <span className="d-block cardLabelText">
                              Ownership No {": "}
                              <span className="card-subtitle mb-2 text-muted">
                                {event.ownerShipSerialNo
                                  ? event.ownerShipSerialNo
                                  : "NA"}
                              </span>
                            </span>
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="float-left">
                            <span className="d-block cardLabelText">
                              Name{": "}
                              <span className=" card-subtitle mb-2 text-muted">
                                {event.garageName ? event.garageName : "NA"}
                              </span>
                            </span>
                          </span>
                          <span className="float-left">
                            <span className="d-block cardLabelText">
                              Address{": "}
                              <span className=" card-subtitle mb-2 text-muted">
                                {event.garageAddress
                                  ? event.garageAddress
                                  : "NA"}
                              </span>
                            </span>
                          </span>
                          <span className="float-left">
                            <span className="d-block cardLabelText">
                              Material{": "}
                              <span className=" card-subtitle mb-2 text-muted">
                                {event.damagedMaterial
                                  ? event.damagedMaterial
                                  : "NA"}
                              </span>
                            </span>
                          </span>
                          <span className="float-left">
                            <span className="d-block cardLabelText">
                              Quantity{" "}
                              <span className="card-subtitle mb-2 text-muted">
                                {event.damagedQuantity
                                  ? event.damagedQuantity
                                  : "NA"}
                              </span>
                            </span>
                          </span>
                        </>
                      )}{" "}
                      {event.status !== "PENDING" &&
                      event.status !== "CANCELLED" ? (
                        <>
                          {event.parameter ? (
                            <>
                              <span className="float-left">
                                <span className="d-block cardLabelText">
                                  Category{": "}
                                  <span className="card-subtitle mb-2 text-muted">
                                    {renderCategory(event.category, product)}
                                  </span>
                                </span>
                              </span>

                              {/* {event.category == 2 && (
                              <span className="float-left">
                                <span className="cardLabelText">&nbsp;</span>
                                <span className="cardDetailsText card-subtitle mb-2 text-muted">
                                  &nbsp;
                                </span>
                              </span>
                            )} */}

                              <span className="float-left">
                                <span className="d-block cardLabelText">
                                  Start Date{": "}
                                  <span className=" card-subtitle mb-2 text-muted">
                                    {
                                      event.parameter.startDateTime.split(
                                        "T"
                                      )[0]
                                    }{" "}
                                    {
                                      event.parameter.startDateTime.split(
                                        "T"
                                      )[1]
                                    }
                                  </span>
                                </span>
                              </span>
                              {/* <span className="float-left">
                              <span className="d-block cardLabelText">
                                Start Time{" "}
                                <span className=" card-subtitle mb-2 text-muted">
                                  {event.parameter.startDateTime.split("T")[1]}
                                </span>
                              </span>
                            </span> */}
                              <span className="float-left">
                                <span className="d-block cardLabelText">
                                  End Date{": "}
                                  <span className=" card-subtitle mb-2 text-muted">
                                    {event.parameter.endDateTime.split("T")[0]}{" "}
                                    {event.parameter.endDateTime.split("T")[1]}
                                  </span>
                                </span>
                              </span>
                              {/* <span className="float-left">
                              <span className="cardLabelText">End Time </span>
                              <span className="cardDetailsText card-subtitle mb-2 text-muted">
                                {event.parameter.endDateTime.split("T")[1]}
                              </span>
                            </span> */}

                              <span className="float-left">
                                <span className="d-block cardLabelText">
                                  Start Price{": "}
                                  <span className=" card-subtitle mb-2 text-muted">
                                    {event.parameter.startPrice}
                                  </span>
                                </span>
                              </span>
                              <span className="float-left">
                                <span className="d-block cardLabelText">
                                  Increment{": "}
                                  <span className=" card-subtitle mb-2 text-muted">
                                    {event.parameter.increment}
                                  </span>
                                </span>
                              </span>
                              <div className="leftLinksInTopBar">
                                {event.status === "LIVE" ? (
                                  <span className="cfct">
                                    <span className="cardLabelText">
                                      Bidding Countdown
                                    </span>
                                    <span className="cardDetailsText card-subtitle text-danger animate_blink">
                                      <Ticker
                                        currentTime={currentTime}
                                        DateTime={event.parameter.endDateTime}
                                      />
                                    </span>
                                  </span>
                                ) : event.status === "FUTURE" ? (
                                  <span className="cfct">
                                    <span className="cardLabelText">
                                      Time To Live
                                    </span>
                                    <span className="cardDetailsText card-subtitle text-danger animate_blink">
                                      <Ticker
                                        currentTime={currentTime}
                                        DateTime={event.parameter.startDateTime}
                                      />
                                    </span>
                                  </span>
                                ) : null}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
                {event.status === "PENDING" ? (
                  <div className="card-footer overflow-hidden">
                    <div className="overflow-hidden">
                      <div
                        className={
                          tokendata && tokendata.role === "Admin"
                            ? "float-start byCfLeftBtns justify-content-around w-50"
                            : "float-start byCfLeftBtns justify-content-around w-100"
                        }
                      >
                        <Link
                          to={`/event/view/${event.id}`}
                          className="btn btn-link byCfBtn"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Details
                        </Link>
                        <Link
                          to={`/event/${event.id}/eventdocuments`}
                          className="btn btn-link byCfBtn"
                        >
                          Documents
                        </Link>
                      </div>
                      {tokendata && tokendata.role === "Admin" ? (
                        <div className="float-end w-50 border-left byCfRightBtnsMain">
                          <div className="byCfRightBtns">
                            <button
                              className="btn btn-link byCfBtn approveBtn"
                              onClick={() => approveEvent(event.id, "A")}
                            >
                              Approve
                            </button>
                            <button
                              className="btn btn-link byCfBtn rejectBtn"
                              onClick={() => approveEvent(event.id, "R")}
                            >
                              Reject
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className={
                        dropbutton === event.id
                          ? "mt-3 cardFooterBtns2 toggleFooter"
                          : "mt-3 cardFooterBtns2"
                      }
                    >
                      <p
                        className="cardFooterBtnsCollpseIcon text-center"
                        onClick={() => toggleClass(event.id, dropbutton)}
                      >
                        <i
                          className="fa fa-angle-double-down"
                          aria-hidden="true"
                        ></i>
                      </p>

                      <div className="cardFooter2 d-flex justify-content-around">
                        {event.parameter === null ? (
                          <Link
                            to={`/event/${event.id}/createparameter`}
                            className="btn btn-link byCfBtn"
                          >
                            Add Parameters
                          </Link>
                        ) : (
                          <>
                            <Link
                              to={`/event/${event.id}/editparameter`}
                              className="btn btn-link byCfBtn"
                            >
                              Edit Parameters
                            </Link>
                            <Link
                              to={`/event/${event.id}/eventbidders`}
                              className="btn btn-link byCfBtn"
                            >
                              View/Edit Bidders
                            </Link>
                          </>
                        )}
                        <Link
                          to={`/event/edit/${event.id}`}
                          className="btn btn-link byCfBtn"
                        >
                          Edit Event
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {(event.status === "LIVE" || event.status === "ARCHIVE") &&
                tokendata.role === "Buyer" ? (
                  <div className="card-footer overflow-hidden">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="float-start byCfLeftBtns">
                          <Link
                            to={`/event/view/${event.id}`}
                            className="btn btn-link byCfBtn"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                      {buyerEventMapping
                        .filter((ob) => ob.auctionId === event.id)
                        .map((Mapping) =>
                          Mapping.auctionParticipation === "Y" ? (
                            <>
                              <div className="col-md-4">
                                {event.status === "LIVE" ? (
                                  <div className="float-end w-50 border-left byCfRightBtnsMain">
                                    {buyerEventMapping
                                      .filter((ob) => ob.auctionId === event.id)
                                      .map((Mapping) =>
                                        Mapping.bidAmount <
                                        event.parameter.reservePrice ? (
                                          <div className="byCfRightBtns">
                                            <button
                                              className="btn btn-link byCfBtn approveBtn"
                                              data-bs-toggle="modal"
                                              data-bs-target="#bidModal"
                                              onClick={() =>
                                                setButtonPopup(event.id)
                                              }
                                            >
                                              Bid
                                            </button>
                                            <BiddingPopUp
                                              amount={Mapping.bidAmount}
                                              events={event}
                                              buyer={tokendata.sub}
                                              buttonPopup={buttonPopup}
                                              setButtonPopup={setButtonPopup}
                                            />
                                          </div>
                                        ) : null
                                      )}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          ) : event.status === "LIVE" ? (
                            <div className="col-md-4">
                              <div className="float-start byCfLeftBtns">
                                <button
                                  className="btn btn-primary"
                                  data-bs-toggle="modal"
                                  data-bs-target="#eventTermsAndConditions"
                                  onClick={() => setEventTerms(event.id)}
                                >
                                  View Terms and Conditions
                                </button>
                                <EventParticipationAgreement
                                  setEventTerms={setEventTerms}
                                  eventTerms={eventTerms}
                                  auctionId={Mapping.auctionId}
                                />
                              </div>
                            </div>
                          ) : null
                        )}

                      <div className="col-md-4 pb-0">
                        {event.status === "LIVE" ||
                        event.status === "ARCHIVE" ? (
                          <div className="">
                            {buyerEventMapping
                              .filter((ob) => ob.auctionId === event.id)
                              .map((Mapping) =>
                                Mapping.bidAmount <=
                                  event.parameter.reservePrice &&
                                Mapping.auctionParticipation === "Y" ? (
                                  <div>
                                    {Mapping.bidAmount !== 0 ? (
                                      <p className="fRight m-0">
                                        <span>
                                          Bid Amount :{" "}
                                          <span className="card-subtitle mb-2 text-orange">
                                            &nbsp;{" "}
                                            {Number(Mapping.bidAmount).toFixed(
                                              2
                                            )}
                                          </span>
                                        </span>
                                        <br></br>

                                        <span>
                                          Rank :{" "}
                                          <span className="card-subtitle mb-2 text-orange">
                                            &nbsp; {Mapping.rank}
                                          </span>
                                        </span>
                                      </p>
                                    ) : null}
                                  </div>
                                ) : null
                              )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : event.status === "LIVE" &&
                  tokendata &&
                  (tokendata.role === "Admin" ||
                    tokendata.role === "SalvageRM") ? (
                  <div className="card-footer overflow-hidden">
                    <div className="overflow-hidden">
                      <div className="float-start byCfLeftBtns justify-content-around w-50">
                        <Link
                          to={`/event/view/${event.id}`}
                          className="btn btn-link byCfBtn"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Details
                        </Link>
                        {tokendata.role === "Admin" && (
                          <Link
                            to={`/event/${event.id}/editparameter`}
                            className="btn btn-link byCfBtn"
                          >
                            Edit Event
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                ) : tokendata &&
                  (tokendata.role === "Admin" ||
                    tokendata.role === "SalvageRM") &&
                  event.status === "ARCHIVE" ? (
                  event.eventId && (
                    <div className="card-footer overflow-hidden">
                      <div className="overflow-hidden d-flex justify-content-around">
                        <Link
                          to={`/event/view/${event.id}`}
                          className="btn btn-link byCfBtn"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Details
                        </Link>
                        <Link
                          to={`/event/viewbiddetails/${encryptId(event.eventId)}`}
                          className="btn btn-link byCfBtn"
                        >
                          View Bid Details
                        </Link>
                        <Link
                          to={`/event/viewEventRanks/${event.id}`}
                          className="btn btn-link byCfBtn"
                        >
                          View Rank List
                        </Link>
                        <span className="d-block cardLabelText">
                                  Highest Bid Amount{": "}
                                  <span className=" card-subtitle mb-2 text-muted">
                                
                                  {event.highestBidAmount
                                  ? event.highestBidAmount
                                  : " "}
                                  </span>
                                </span>

                                <span className="d-block cardLabelText">
                                  Highest Bidder{": "}
                                  <span className=" card-subtitle mb-2 text-muted">
                                  {event.highestBidder
                                  ? event.highestBidder
                                  : " "}
                                  </span>
                                </span>
                      </div>
                    </div>
                  )
                ) : (
                  event.status === "CANCELLED" &&
                  event.remarks && (
                    <div className="card-footer overflow-hidden">
                      <div className="float-start byCfLeftBtns">
                        <div className="container-fluid">
                          <h6>
                            <span className="font-weight-bold">Remarks : </span>
                            {event.remarks}
                          </h6>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          );
        })
      ) : (
        <div
          className="mainBodyCard pt-30 d-flex flex-row justify-content-center"
          style={{ top: "100px", right: "25px" }}
        >
          <h2 className="notFound">No Events Found</h2>
        </div>
      )}
      {currentPageData.length > 0 ? (
        // <div className="row justify-content-center paginationCss">

        // </div>
        <div className="row paginationCss">
          <ReactPaginate
            previousLabel="&laquo;"
            nextLabel="&raquo;"
            pageCount={pageCount}
            forcePage={pageNo - 1}
            onPageChange={handlePageClick}
            breakClassName="page-item"
            breakLabel={<Link className="page-link h4">...</Link>}
            activeLinkClassName="paginationPageLink"
            pageClassName="page-item"
            previousClassName="page-item"
            nextClassName="page-item"
            pageLinkClassName="page-link h4"
            previousLinkClassName="page-link h4"
            nextLinkClassName="page-link h4"
            containerClassName="pagination"
          />
        </div>
      ) : null}
    </div>
  );
};

export default AuctionData;
