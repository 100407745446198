import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import Select from "react-select";
import { clearLDAPUser } from "../../../actions/masters/LDAPActions";
import { fetchRegions } from "../../../actions/masters/regionActions";
import { fetchRoles } from "../../../actions/masters/roleActions";

class UserForm extends Component {
  componentDidMount() {
    this.props.fetchRegions();
    this.props.fetchRoles();
  }

  componentWillUnmount() {
    this.props.clearLDAPUser();
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, meta, disabled, type, maxLength }) => {
    return (
      <div className="col-md-12 col-sm-12">
        <div className="form-group">
          <label className="form_label">{label}</label>
          <div className="input-field">
            <input
              {...input}
              type={type}
              maxLength={maxLength}
              autoComplete="off"
              placeholder={label}
              className="form-control"
              disabled={disabled}
            />
          </div>
          <div className="error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  renderRadioInput = ({ input, meta, type, label }) => {
    return (
      <div className="radio mr-5 egmRadio">
        <input
          className=""
          {...input}
          autoComplete="off"
          type={type}
          value={input.value}
          id={label}
        />

        <label className="radio-label" htmlFor={label}>
          {input.value === "Y" ? "Active" : "In Active"}
        </label>
        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
  };

  renderSelectInput = ({
    input,
    label,
    meta,
    component,
    options,
    placeholder,
  }) => {
    return (
      <div className="col-md-12 col-sm-12 aiuRoleDrpdwn">
        <div className="form-group">
          <label className="form_label">{label}</label>
          <div className="input-field">
            <Select
              {...input}
              value={
                component.toUpperCase() === "ROLE"
                  ? input.value === ""
                    ? null
                    : options.find(
                        (obj) => obj.role_Id === parseInt(input.value)
                      )
                  : input.value === ""
                  ? null
                  : options.find((obj) => obj.id === parseInt(input.value))
              }
              onChange={input.onChange}
              onBlur={(value) => input.onBlur(value)}
              getOptionLabel={
                component.toUpperCase() === "ROLE"
                  ? (option) => option.role_name
                  : (option) => option.name
              }
              getOptionValue={
                component.toUpperCase() === "ROLE"
                  ? (option) => option.role_Id
                  : (option) => option.id
              }
              onBlurResetsInput={false}
              onCloseResetsInput={false}
              options={options}
              onFocus={input.onFocus}
              isSearchable={true}
              noOptionsMessage={() => null}
              placeholder={placeholder}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,

                  primary25: "#ffa552",
                  primary50: "#fabc82",
                  primary: "#fcb26d",
                },
              })}
            />
            <div className="error">{this.renderError(meta)}</div>
          </div>
        </div>
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <div>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          {this.props.LDAPUser[0] || this.props.EditUser ? (
            <div className="row">
              <Field
                name="user_id"
                component={this.renderInput}
                label="Employee ID"
                type="text"
                disabled={true}
              />
              <Field
                name="user_name"
                component={this.renderInput}
                label="Employee Name"
                type="text"
                disabled={true}
              />
              <Field
                name="email_id"
                component={this.renderInput}
                label="Email ID"
                type="text"
                disabled={true}
              />
              <Field
                name="mobile_no"
                component={this.renderInput}
                label="Mobile No"
                type="text"
                maxLength="10"
              />
              <Field
                name="contact_no"
                component={this.renderInput}
                label="Contact No"
                maxLength="10"
                type="text"
              />
              <Field
                name="role_id"
                component={this.renderSelectInput}
                placeholder="Select Role"
                label="Role"
                props={{
                  options: this.props.roles,
                  component: "role",
                }}
                normalize={(value) => value.role_Id}
              />
              {parseInt(this.props.roleIdValue) === 2 ? (
                <Field
                  name="regionId"
                  component={this.renderSelectInput}
                  placeholder="Select Region"
                  label="Region"
                  props={{
                    options: this.props.regions,
                    component: "region",
                  }}
                  normalize={(value) => value.id}
                />
              ) : (
                ""
              )}

              {this.props.EditUser ? (
                <div className="col-md-12 col-sm-12">
                  <label className="form_label">Active/Inactive</label>
                  <div className="input-field">
                    <Field
                      name="active_flag"
                      component={this.renderRadioInput}
                      type="radio"
                      label="UserMasterActiveTrue"
                      value="Y"
                    />
                    <Field
                      name="active_flag"
                      component={this.renderRadioInput}
                      type="radio"
                      label="UserMasterActiveFalse"
                      value="N"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="mainBodyCardFtBtn mt-50">
                <button className="btnMain">Save</button>
              </div>
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const selector = formValueSelector("UserForm");
  const roleIdValue = selector(state, "role_id");
  return {
    roleIdValue,
    LDAPUser: Object.values(state.LDAPUser),
    regions: Object.values(state.region),
  };
};

const validate = (formValues) => {
  const errors = {};
  if (!formValues.user_id) {
    errors.user_id = "This Field is Required";
  }
  if (!formValues.user_name) {
    errors.user_name = "This Field is Required";
  }
  if (!formValues.email_id) {
    errors.email_id = "This Field is Required";
  }
  if (formValues.mobile_no && formValues.mobile_no.length !== 10) {
    errors.mobile_no = "Mobile no must be less than 10 digits";
  }
  if (formValues.contact_no && formValues.contact_no.length !== 10) {
    errors.contact_no = "This Field is Required";
  }
  if (!formValues.role_id) {
    errors.role_id = "This Field is Required";
  }
  return errors;
};

const userForm = reduxForm({
  form: "UserForm",
  validate,
  enableReinitialize: true,
})(UserForm);

export default connect(mapStateToProps, {
  clearLDAPUser,
  fetchRegions,
  fetchRoles,
})(userForm);
