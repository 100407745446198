import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";

class EventParameterForm extends Component {
  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderNumber = ({ input, label, meta, minValue, step }) => {
    return (
      <div className="col-md-6 col-sm-12 eventCreateInputCol">
        <label className="d-block">{label}</label>
        <div className="input-field">
          <input
            {...input}
            type="number"
            parse={(val) => parseFloat(val)}
            minValue={minValue ? minValue : "1"}
            autoComplete="off"
            placeholder={label}
            className="form-control"
            step={step}
          />
        </div>
        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
  };

  renderDate = ({ input, label, meta, minDate }) => {
    return (
      <div>
        <label className="d-block">{label}</label>
        <div className="input-field">
          <input
            {...input}
            type="date"
            autoComplete="off"
            placeholder={label}
            className="form-control"
            minValue={minDate}
          />
        </div>
        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
  };

  renderTimeInput = ({ input, label, meta, type }) => {
    return (
      <>
        <div className="col-md-3 col-sm-12 eventCreateInputCol">
          <label className="d-block">{label}</label>
          <div className="text-center">
            <div className="input-group clockpicker">
              <input
                {...input}
                autoComplete="off"
                type={type}
                value={input.value}
                className="form-control"
              />

              <span className="input-group-addon">
                <span className="glyphicon glyphicon-time"></span>
              </span>
            </div>

            <div>{this.renderError(meta)}</div>
          </div>
        </div>
        <div className="col-md-3 col-sm-12 eventCreateInputCol"></div>
      </>
    );
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <div className="mainBodyCard pt-30 addParametersPage">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div className="row">
            <Field
              name="startPrice"
              type="number"
              parse={(val) => parseFloat(val)}
              step="0.01"
              minValue="0"
              component={this.renderNumber}
              label="Auction Start Price"
            />
            <Field
              name="reservePrice"
              type="number"
              parse={(val) => parseFloat(val)}
              step="0.01"
              minValue="0"
              component={this.renderNumber}
              label="Auction Reserve Price"
            />
            <div className="col-md-6 col-sm-12 eventCreateInputCol">
              <Field
                name="startDate"
                type="date"
                component={this.renderDate}
                label="Auction Start Date"
                minDate={new Date().toISOString().split("T")[0]}
              />
              {/* {this.props.edit && this.props.initialValues.startTime ? (
                <p>
                  <b>Start Time : </b>
                  {this.props.initialValues.startTime}
                </p>
              ) : (
                <p>
                  <b>Default Start Time : </b>
                  {this.props.defaultStartTime}
                </p>
              )} */}
            </div>

            <div className="col-md-6 col-sm-12 eventCreateInputCol">
              <Field
                name="endDate"
                type="date"
                component={this.renderDate}
                label="Auction End Date"
                minDate={
                  this.props.selectedStartDate
                    ? this.props.selectedStartDate
                    : new Date().toISOString().split("T")[0]
                }
              />

              {/* {this.props.edit && this.props.initialValues.endTime ? (
                <p>
                  <b>End Time : </b>
                  {this.props.initialValues.endTime}
                </p>
              ) : (
                <p>
                  <b>Default End Time : </b>
                  {this.props.defaultEndTime}
                </p>
              )} */}
            </div>
            <Field
              name="startTime"
              component={this.renderTimeInput}
              label="Auction Start Time"
              type="time"
            />

            <Field
              name="endTime"
              component={this.renderTimeInput}
              label="Auction End Time"
              type="time"
            />

            <Field
              name="extendTimeWhenValidBidReceivedInLast"
              type="number"
              normalize={(val) => parseInt(val)}
              component={this.renderNumber}
              label="Extend Time When A Valid Bid Is Received In Last"
            />
            <Field
              name="extendTimeInMinutes"
              type="number"
              parse={(val) => parseInt(val)}
              component={this.renderNumber}
              label="Extend Time In Minutes"
            />
            <Field
              name="increment"
              type="number"
              parse={(val) => parseFloat(val)}
              component={this.renderNumber}
              label="Increment"
              step="0.01"
              minValue="0"
            />
            <Field
              name="incrementDuringExtension"
              type="number"
              parse={(val) => parseFloat(val)}
              step="0.01"
              minValue="0"
              component={this.renderNumber}
              label="Increment During Extension"
            />
            <div className="mainBodyCardFtBtn mt-50">
              <button className="btnMain">Submit</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.startPrice) {
    errors.startPrice = "This Field is Required";
  }
  if (!formValues.reservePrice) {
    errors.reservePrice = "This Field is Required";
  }
  if (formValues.startDate === undefined) {
    errors.startDate = "This Field is Required";
  }
  if (formValues.endDate === undefined) {
    errors.endDate = "This Field is Required";
  }
  if (formValues.startDate > formValues.endDate) {
    errors.endDate = "Auction End Date Should be Greater than Start Date";
  }
  if (!formValues.extendTimeWhenValidBidReceivedInLast) {
    errors.extendTimeWhenValidBidReceivedInLast = "This Field is Required";
  }
  if (!formValues.extendTimeInMinutes) {
    errors.extendTimeInMinutes = "This Field is Required";
  }
  if (!formValues.increment) {
    errors.increment = "This Field is Required";
  }
  if (!formValues.incrementDuringExtension) {
    errors.incrementDuringExtension = "This Field is Required";
  }
  return errors;
};

const mapStatetoProps = (state) => {
  const selector = formValueSelector("EventParametersForm");
  const selectedStartDate = selector(state, "startDate");
  return {
    selectedStartDate,
  };
};

const eventParametersForm = reduxForm({
  form: "EventParametersForm",
  validate,
  enableReinitialize: true,
})(EventParameterForm);

export default connect(mapStatetoProps)(eventParametersForm);
