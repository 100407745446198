import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import Modal from "../../Modal";

class GroupMappingForm extends Component {
  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, meta }) => {
    return (
      <div className="groupMappingEditCard">
        <div className="form-group">
          <div className="input-field">
            <input
              {...input}
              autoComplete="off"
              placeholder={label}
              className="form-control"
            />
          </div>
          <div className="error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  renderRadioInput = ({ input, meta, type, label }) => {
    return (
      <div className="radio ml-0 egmRadio">
        <input
          {...input}
          autoComplete="off"
          type={type}
          value={input.value}
          id={label}
        />
        <label
          className="radio-label"
          id={input.value.toString()}
          htmlFor={label}
        >
          {input.value === "Y" ? "Active" : "In Active"}
        </label>
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <div className="mainBodyCard centerAlignCard">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field
            name="name"
            component={this.renderInput}
            label="Enter Group Mapping"
          />
          {this.props.onDelete ? (
            <div className="row m-0">
              <div className="d-inline-block mr-5">
                <Field
                  name="activeFlag"
                  component={this.renderRadioInput}
                  label="GroupMappingYes"
                  type="radio"
                  value="Y"
                />
              </div>
              <div className="d-inline-block">
                <Field
                  name="activeFlag"
                  component={this.renderRadioInput}
                  label="GroupMappingNo"
                  type="radio"
                  value="N"
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="mainBodyCardFtBtn mt-5">
            <button className="btnMain">Save</button>
          </div>
        </form>
        <Modal onDeleteClick={this.props.onDelete} />
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.name) {
    errors.name = "This Field is Required";
  }

  return errors;
};

export default reduxForm({
  form: "groupmappingform",
  validate,
  enableReinitialize: true,
})(GroupMappingForm);
