import _ from "lodash";

import {
  FETCH_PRODUCTs,
  FETCH_PRODUCT,
  EDIT_PRODUCT,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
} from "../../actions/types";

const productReducers = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PRODUCTs:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case FETCH_PRODUCT:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_PRODUCT:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_PRODUCT:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_PRODUCT:
      return _.omit(state, action.payload);

    default:
      return state;
  }
};

export default productReducers;
