import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { fetchPaymentRefunds } from "../../../actions/BuyerEmpanelment/PaymentRefund/PaymentRefundActions";
import history from "../../../history";
import AdminPaymentRefundForm from "../BuyerPaymentRefund/AdminPaymentRefundForm";
import PaymentRefundViewDetails from "./PaymentRefundViewDetails";

class BuyerPaymentRefundList extends Component {
  state = {
    popup: 0,
  };

  componentDidMount() {
    this.props.fetchPaymentRefunds();
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, meta, disabled, type, maxLength }) => {
    return (
      <div>
        <div className="buyerMasterSearch">
          <div className="input-field">
            <input
              style={{ marginTop: "0px" }}
              {...input}
              type={type}
              maxLength={maxLength}
              autoComplete="off"
              placeholder={label}
              className="form-control"
              disabled={disabled}
            />
          </div>
          <div className="error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  renderList = () => {
    return this.props.Payment.filter((p) => {
      if (this.props.buyerPaymentSearchValue) {
        return (
          p.requestId
            .toLowerCase()
            .includes(this.props.buyerPaymentSearchValue.toLowerCase()) ||
          p.buyerName
            .toLowerCase()
            .includes(this.props.buyerPaymentSearchValue.toLowerCase())
        );
      } else {
        return p;
      }
    }).map((payment) => {
      return (
        <tr key={payment.id}>
          <td data-th="Request Id">{payment.requestId}</td>
          <td data-th="Buyer Name">{payment.buyerName}</td>
          <td data-th="Date">{payment.createdDate.split("T")[0]}</td>
          <td data-th="Status">{payment.status}</td>
          {/* <td data-th="Role">{buyer.role_id}</td> */}
          <td data-th="Active/Inactive">
            {payment.activeFlag === "Y" ? "Active" : "InActive"}
          </td>
          <td data-th="Edit">
            <Link
              className="btn btn-link"
              data-bs-toggle="modal"
              data-bs-target="#paymentRefundViewDetailsModal"
              onClick={() =>
                this.setState({
                  popup: payment.id,
                })
              }
            >
              View Details
            </Link>
          </td>
          <td data-th="Edit">
            {payment.status === "Open" ? (
              <Link>
                <span style={{ color: "#00447c" }}>
                  <i
                    className="fa fa-pencil"
                    data-bs-toggle="modal"
                    data-bs-target="#paymentModal"
                    onClick={() =>
                      this.setState({
                        popup: payment.id,
                      })
                    }
                  ></i>
                </span>
              </Link>
            ) : (
              ""
            )}
            <PaymentRefundViewDetails
              pay={payment}
              id={payment.id}
              Popup={this.state.popup}
            />
            <AdminPaymentRefundForm id={payment.id} Popup={this.state.popup} />
          </td>
        </tr>
      );
    });
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Payment Refund Request</h3>
          </div>
          <div className="float-end mt-15 ">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        <div className="mainBodyCard pt-30">
          <form onSubmit={(e) => e.preventDefault()}>
            <Field
              name="paymentSearch"
              component={this.renderInput}
              label="Search Request Id/ Name"
              type="text"
            />
          </form>
          <table className="table table-hover tableStyle1">
            <thead>
              <tr>
                <td>Request Id</td>
                <td>Buyer Name</td>
                <td>Date</td>
                <td>Status</td>
                <td>Active/Inactive</td>
                <td></td>
              </tr>
            </thead>
            <tbody>{this.renderList()}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var selector = formValueSelector("BuyerRefundSearchForm");
  const buyerPaymentSearchValue = selector(state, "paymentSearch");
  return {
    buyerPaymentSearchValue,
    Payment: Object.values(state.buyerPaymentRefund).sort((a, b) =>
      a.id < b.id ? 1 : b.id < a.id ? -1 : 0
    ),
  };
};

const buyerSearchForm = reduxForm({
  form: "BuyerRefundSearchForm",
  enableReinitialize: true,
})(BuyerPaymentRefundList);

export default connect(mapStateToProps, { fetchPaymentRefunds })(
  buyerSearchForm
);
