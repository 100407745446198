import React, { Component } from "react";
import EventForm from "./EventForm";
import { connect } from "react-redux";
import { fetchEvent, editEvent } from "../../actions/event/EventActions";
import { Link } from "react-router-dom";
import history from "../../history";

class EventCreate extends Component {
  componentDidMount = async () => {
    window.scrollTo(0, 0);
    await this.props.fetchEvent(this.props.match.params.id);
  };

  onSubmit = (formValues) => {
    if (!formValues.keywords) {
      formValues.keywords = "Auction";
    }
    formValues.auctionDetails = formValues.remarks;
    this.props.editEvent(this.props.match.params.id, formValues);
  };

  initialParameter = () => {
    const para = this.props.event[0];

    if (para.dateOfLoss) {
      para.dateOfLoss = para.dateOfLoss.split("T")[0];
    }
   
    return para;
  };

  render() {
    return (
      <div>
        <div className="BodyInContainer">
          <div className="d-block clearfix">
            <div className="Header-Content float-start">
              <h3 className="pageMainTitle">
                Edit Event :{" "}
                {this.props.event[0] ? this.props.event[0].eventId : ""}
              </h3>
            </div>
            <div className="float-end mt-15">
              <Link
                className="float-end cardBackBtn"
                onClick={() => {
                  history.goBack();
                }}
              >
                Back
              </Link>
            </div>
          </div>
          {this.props.event[0] && this.props.event[0].status === "PENDING" ? (
            <div className="mainBodyCard">
              <EventForm
                initialValues={this.initialParameter()}
                onSubmit={this.onSubmit}
              />
            </div>
          ) : this.props.event[0] &&
            this.props.event[0].status !== "PENDING" ? (
            <div className="mainBodyCard pt-30 d-flex flex-column justify-content-center align-items-center">
              <h4 className="errorName">{`Event is in ${
                this.props.event[0] ? this.props.event[0].status : ""
              } stage`}</h4>
              <h4 className="errorTextEx">
                You can only modify/change event details of events which are in{" "}
                <b>PENDING</b> stage
              </h4>
              <Link
                className="float-end returnHomeBtn mt-5 btnMain"
                onClick={() => {
                  history.goBack();
                }}
              >
                Go Back
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    event: Object.values(state.event).filter(
      (ev) => ev.id === parseInt(ownProps.match.params.id)
    ),
  };
};

export default connect(mapStateToProps, { fetchEvent, editEvent })(EventCreate);
