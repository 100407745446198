import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchStates } from "../../../actions/masters/stateActions";
import history from "../../../history";

class StateList extends Component {
  componentDidMount() {
    this.props.fetchStates(this.props.match.params.id);
  }

  renderList() {
    const { id } = this.props.match.params;
    return this.props.states.map((state) => {
      if (state.regionID === parseInt(id)) {
        return (
          <tr key={state.id}>
            <td className="pl-3 py-2" style={{ width: "500px" }}>
              <Link
                to={`/masters/region/${id}/state/${state.id}/city`}
                style={{ padding: "2px" }}
              >
                <span> {state.name}</span>
              </Link>
            </td>
            <td className="pl-3 py-2" style={{ width: "500px" }}>
              {state.shortName}
            </td>
            <td className="py-2">
              <Link to={`/masters/region/${id}/state/${state.id}/edit`}>
                <span>
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </span>
              </Link>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  render() {
    return (
      <div
        className="container"
        style={{ padding: "80px 30px", maxWidth: "800px" }}
      >
        <div className="float-end mt-15">
          <Link
            className="float-end cardBackBtn"
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </Link>
        </div>
        <div className="container">
          <div className="row" style={{ paddingBottom: "5px" }}>
            <div
              className="Header-Content col-8"
              style={{ paddingTop: "5px", paddingBottom: "5px" }}
            >
              <h3 style={{ marginBottom: "0px" }}>State Master</h3>
            </div>
            <div className="col-4" style={{ padding: "5px 20px" }}>
              <Link
                className="btnMain"
                style={{
                  padding: "7px",
                  textAlign: "center",
                  minWidth: "70px",
                }}
                to={`/masters/region/${this.props.match.params.id}/state/create`}
              >
                Add
              </Link>
            </div>
          </div>
          <div>
            <h5
              style={{
                borderTop: "1px solid #dee2e6",
                borderBottom: "2px solid #dee2e6",
                marginBottom: "0px",
                padding: "5px 0px 5px 15px",
              }}
            >
              Name
              <span style={{ paddingLeft: "33%" }}>Short Form</span>{" "}
            </h5>
          </div>
          <table className="table table-hover">
            <tbody>{this.renderList()}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    states: Object.values(state.state),
  };
};

export default connect(mapStateToProps, { fetchStates })(StateList);
