
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

class LoginForm extends Component {
  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, meta, type, maxLength }) => {
    return (
      <div className="clearfix">
        <div className="form-group">
          <div className="input-field">
            <input
              {...input}
              type={type}
              autoComplete="off"
              placeholder={label}
              className="form-control"
              maxLength={maxLength} // Set the maxLength attribute
            />
          </div>
          <div className="error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="">
        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
          <Field
            name="UserId"
            type="text"
            component={this.renderInput}
            label="Enter Login ID/ Email"
            maxLength={500} // Set your desired maxLength here
          />
          <Field
            name="password"
            type="password"
            component={this.renderInput}
            label="Enter Password"
          />
          <div className="d-block">
            <button className="btnMain loginBtn">Login</button>
          </div>
          <div className="forgLog">
            <Link className="forgtPass" to="/forgotpassword">
              Forgot password?
            </Link>
            {/* <a id="btnLoginOTP" className="pull-right logOtp" href="javascript:void(0);">Login with OTP</a> */}
          </div>
          <div className="regNow">
            <p className="text-center">
              Don't have an account?
              <Link className="regNowbtn ml-2" to="/buyerempanelment/create">
                Register Now
              </Link>
            </p>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  const validInput = /^[0-9a-zA-Z.@_]+$/;
  if (!formValues.UserId) {
    errors.UserId = "This Field is Required";
  }
  if (!formValues.password) {
    errors.password = "This Field is Required";
  }
  if(!validInput.test(formValues.UserId)){
    errors.UserId = "Only Alphanumeric Characters are Allowed";
  }
  if (formValues.UserId?.length > 500) {
    errors.UserId = "Username must be less than 20 characters";
  }

  return errors;
};

export default reduxForm({
  form: "loginform",
  validate,
})(LoginForm);
