import React, { Component, Fragment } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Spinner from "../../Spinner";

class EventDocumentsView extends Component {
  openBase64InNewTab = (data, mimeType) => {
    var byteCharacters = atob(data);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: mimeType + ";base64" });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  Slideshow = (imagedocs) => {
    if (imagedocs) {
      if (imagedocs.length !== 0) {
        return (
          <div className="slide-container">
            <Slide>
              {imagedocs.map((doccontent) => {
                return (
                  <div
                    className="each-slide"
                    onClick={() =>
                      this.openBase64InNewTab(
                        `${doccontent.dmsDocContent}`,
                        "image/jpeg"
                      )
                    }
                  >
                    <div
                      className="sliderImage"
                      style={{
                        backgroundImage: `url(data:image/jpeg;base64,${doccontent.dmsDocContent})`,
                      }}
                    ></div>
                    <h5
                      className="slideshowtitle"
                      style={{ textAlign: "center" }}
                    >
                      {doccontent.description}
                    </h5>
                  </div>
                );
              })}
            </Slide>
          </div>
        );
      }
    }
  };

  componentWillUnmount() {
    this.props.clearDocState();
  }

  renderList(documents) {
    var docsitems = documents;
    // var docsitems = documents.filter(
    //   (doc) =>
    //     doc.fileType.toLowerCase() !== ".png" &&
    //     doc.fileType.toLowerCase() !== ".jpg"
    // );
    if (docsitems.length === 0) {
      return null;
    } else {
      return (
        <div className="modal-body">
          <hr></hr>
          <p className="docClass">Documents Submitted :</p>
          <table className="table1 table-hover tableStyle1">
            <thead>
              <tr>
                <td>
                  <div className="or">Description</div>
                </td>
                <td>
                  <div className="or">FileName</div>
                </td>
              </tr>
            </thead>
            <tbody>
              {docsitems.map((document) => {
                return (
                  <tr key={document.id}>
                    <td data-th="Description">{document.description}</td>
                    <td data-th="FileName">
                      {document.fileType === ".pdf" ? (
                        <a
                          onClick={() =>
                            this.openBase64InNewTab(
                              `${document.dmsDocContent}`,
                              "application/pdf"
                            )
                          }
                        >
                          {document.fileName}
                        </a>
                      ) : document.fileType.toLowerCase() === ".jpg" ||
                        document.fileType.toLowerCase() === ".png" ? (
                        <a
                          href={`data:application/octet-stream;base64,${document.dmsDocContent}`}
                          download={document.fileName}
                        >
                          {document.fileName}
                        </a>
                      ) : (
                        <a
                          href={`data:application/octet-stream;base64,${document.dmsDocContent}`}
                          download={document.fileName}
                        >
                          {document.fileName}
                        </a>
                      )}
                    </td>
                    <td>
                      {this.props.event &&
                        this.props.event[0].status === "PENDING" && (
                          <p
                            className="btn btn-danger"
                            onClick={() =>
                              this.props.deleteDocument(document.id)
                            }
                          >
                            Delete Document
                          </p>
                        )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <div
          className="modal fade bd-example-modal-lg edv_viewDocumentsModel"
          id="viewDocumentsModel"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="viewDocumentsModelTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            {this.props.event &&
            this.props.event[0] &&
            this.props.event[0].noOfDocuments === 0 ? (
              <h3 className=" d-block overflow-hidden pt-4 w-100 pl-4">
                No Document Uploaded
              </h3>
            ) : this.props.eventDocuments.length > 0 ? (
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="viewDocumentsModelTitle">
                    View Documents
                  </h5>
                  <button
                    type="button"
                    className="close docButton"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <Fragment>{this.Slideshow(this.props.eventImages)}</Fragment>
                {this.renderList(this.props.eventDocuments)}
              </div>
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default EventDocumentsView;
