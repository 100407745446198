import {
  FETCH_BUYER_EVENTMAPPING,
  ACCEPT_TERMS_AND_CONDITIONS,
  BID_EVENT,
} from "../../actions/types";
import _ from "lodash";

const buyerEventMappingReducers = (state = {}, action) => {
  switch (action.type) {
    case ACCEPT_TERMS_AND_CONDITIONS:
      return { ...state, [action.payload.auctionId]: action.payload };
    case FETCH_BUYER_EVENTMAPPING:
      return { ...state, ..._.mapKeys(action.payload, "auctionId") };
    case BID_EVENT:
      return { ...state, [action.payload.auctionId]: action.payload };
      
    default:
      return state;
  }
};

export default buyerEventMappingReducers;
