import { CLEAR_LDAPUSER, FETCH_LDAPUSERs } from "../../actions/types";

const userReducers = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LDAPUSERs:
      return { ...state, user: action.payload };
    case CLEAR_LDAPUSER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default userReducers;
