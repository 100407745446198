import React, { Component } from "react";
import LocationCity from "./LocationCity";
import LocationRegion from "./LocationRegion";
import LocationState from "./LocationState";
import { fetchRegions } from "../../../actions/masters/regionActions";
import { fetchAllStates } from "../../../actions/masters/stateActions";
import { fetchAllCities } from "../../../actions/masters/cityActions";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";

class LocationList extends Component {
  state = { regionId: "", stateId: "", cityId: "" };

  componentDidMount() {
    this.props.fetchRegions();
    this.props.fetchAllStates();
    this.props.fetchAllCities();
  }

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Location Master</h3>
          </div>
        </div>
        <div className="mainBodyCard">
          <div className="row m-0">
            <div className="col-md-4 col-sm-12 p-0">
              <LocationRegion
                regions={this.props.regions}
                user={this.props.userData}
              />
            </div>

            <div className="col-md-4 col-sm-12 p-0">
              <LocationState
                states={this.props.states}
                regions={this.props.regions}
                user={this.props.userData}
              />
            </div>

            <div className="col-md-4 col-sm-12 p-0">
              <LocationCity
                cities={this.props.cities}
                states={this.props.states}
                user={this.props.userData}
                regions={this.props.regions}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    regions: Object.values(state.region),
    states: Object.values(state.state),
    cities: Object.values(state.city),
    userData: localStorage.getItem("userToken")
      ? jwt_decode(localStorage.getItem("userToken"))
      : null,
  };
};

export default connect(mapStateToProps, {
  fetchRegions,
  fetchAllStates,
  fetchAllCities,
})(LocationList);
