import React, { useState, useEffect } from "react";

const Ticker = ({ DateTime, currentTime }) => {
  const [currentAPITime, setCurrentAPITime] = useState(currentTime);

  const calculateTimeLeft = (date) => {
    let datetime = new Date(Date.parse(date));
    var res = Math.abs(datetime - currentAPITime) / 1000;
    var timeLeft = {
      days: Math.floor(res / 86400),
      hours: Math.floor(res / 3600) % 24,
      minutes: Math.floor(res / 60) % 60,
      seconds: Math.floor(res % 60),
      res: datetime < currentAPITime,
    };
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(DateTime));

  useEffect(() => {
    setCurrentAPITime(currentTime);
  }, [currentTime]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(DateTime));
      setCurrentAPITime(currentAPITime + 1000);
    }, 1000);
    return () => clearTimeout(timer);
  }, [currentAPITime]);

  return (
    <>
      {timeLeft.res ? (
        <div style={{ textAlign: "end" }}>"Fetching results - refresh for latest updates"</div>
      ) : (
        <div style={{ textAlign: "end" }}>
          {timeLeft.days > 0 ? (
            timeLeft.days === 1 ? (
              <span>{timeLeft.days} Day</span>
            ) : (
              <span>{timeLeft.days} Days</span>
            )
          ) : null}
          <div>
            <span>
              {timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}
            </span>
            <span>
              :
              {timeLeft.minutes < 10
                ? `0${timeLeft.minutes}`
                : timeLeft.minutes}
            </span>
            <span>
              :
              {timeLeft.seconds < 10
                ? `0${timeLeft.seconds}`
                : timeLeft.seconds}
            </span>{" "}
            <span>{timeLeft.hours < 0 ? "Hour" : "Hours"}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Ticker;
