import React, { Component } from "react";
import { connect } from "react-redux";
//import AuctionPortal from "../../apis/AuctionPortal";
import jwt_decode from "jwt-decode";
import { fetchUser } from "../../actions/masters/userActions";
import { handleAdminButtonClick } from "../../actions/masters/userActions";

import { Link } from "react-router-dom";


class ViewProfile extends Component {
  state = { role: "" };
  componentDidMount() {
    const { sub, role } = jwt_decode(localStorage.getItem("userToken"));
    this.setState({ role: role });
    this.props.fetchUser(sub);
    
  }

  renderProfileData = (user) => {
    return (
      <div className="row m-0">
        <div className="col-md-12 col-sm-12 p-0">
          <div className="form-group">
            <div className="form_label">User Role</div>
            <div className="label_details float-start">{this.state.role}</div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 p-0">
          <div className="form-group">
            <div className="form_label">User Id</div>
            <div className="label_details float-start">{user.user_id}</div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 p-0">
          <div className="form-group">
            <div className="form_label">User Name</div>
            <div className="label_details float-start">{user.user_name}</div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 p-0">
          <div className="form-group">
            <div className="form_label">Email Id</div>
            <div className="label_details float-start">{user.email_id}</div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 p-0">
          <div className="form-group">
            <div className="form_label">Contact No </div>
            <div className="label_details float-start">{user.mobile_no}</div>
          </div>
        </div>
        {user.contact_no ? (
          <div className="col-md-12 col-sm-12 p-0">
            <div className="form-group">
              <div className="form_label">Alternative Contact No : </div>
              <div className="label_details float-start">{user.contact_no}</div>
            </div>
          </div>
        ) : (
          ""
        )}
        {["ILOM31039".toUpperCase(),"ILOM29558".toUpperCase(),"1036201".toUpperCase(),"96896".toUpperCase(),"1004758".toUpperCase()].includes(user.user_id.toUpperCase()) ? (
      <div className="float-end" style={{ paddingTop: '2vw', paddingBottom: '2vw' }}>
      <button
        className="btn btn-primary btn-lg"
        onClick={handleAdminButtonClick}
      >
        Scheduler
      </button>
    </div>
    ) : (
      ""
    )}
        
      </div>
    );
  };

  render() {
    try{
    if(this.props.tokendata.role==null)
    {
      window.location="/";
    }
  }
  catch{
    window.location="/";
  }
   

    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">View Profile</h3>
          </div>
          {(this.props.tokendata.role === "Buyer") ? (
            <div className="float-end vPePrP">
              <Link
                to={`/masters/buyers/editrmc/${this.props.tokendata.sub}`}
                className="vPePrPBtns display-inline-block"
              >
                Edit Profile
              </Link>
              <Link
                to={`/resetpassword`}
                className="vPePrPBtns display-inline-block"
              >
                Reset Password
              </Link>
              {/* {this.props.refund.isRequested &&
              !this.props.refund.isRequested.requestId ? (
                <Link
                  data-toggle="modal"
                  data-target="#buyerPaymentModal"
                  className="vPePrPBtns display-inline-block"
                >
                  Request Refund
                </Link>
              ) : null} */}
            </div>
          ) : null
          }
        </div>
        {this.props.userprof[0] ? (
          <div className="mainBodyCard pt-30">
            {this.renderProfileData(this.props.userprof[0])}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
   
    userprof: Object.values(state.userMaster),
    tokendata: localStorage.getItem("userToken")
      ? jwt_decode(localStorage.getItem("userToken"))
      : null,
  };
};

export default connect(mapStateToProps, { fetchUser })(
  ViewProfile
);
