import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import Select from "react-select";
import { DigilockerUrl } from "../../../apis/AuctionPortal";

class BuyerEmpanelmentDocUpload extends Component {
  state = { selectedDocsURI: [], uploadType: 0 };

  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, meta, disabled, type, maxLength, id }) => {
    return (
      <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
        <label className="d-block">{label}</label>
        <div className="input-field">
          <input
            {...input}
            type={type}
            maxLength={maxLength}
            autoComplete="off"
            id={id}
            placeholder={label}
            className="form-control"
            disabled={disabled}
          />
        </div>
        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
  };

  renderFileInput = ({ input, label, meta, id, disabled, type, maxLength }) => {
    return (
      <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
        <label className="d-block">{label}</label>
        <div className="input-field">
          <input
            name={input.name}
            type={type}
            id={id}
            className="form-control"
            onChange={(event) => this.handleChange(event, input)}
          />
        </div>
        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
  };

  handleChange = (event, input) => {
    event.preventDefault();
    let imageFile = event.target.files[0];
    input.onChange(imageFile);
  };

  handleDocsURI = (event) => {
    if (event.target.checked) {
      if (!this.state.selectedDocsURI.includes(event.target.value)) {
        this.setState((prevState) => ({
          selectedDocsURI: [...prevState.selectedDocsURI, event.target.value],
        }));
      }
    } else {
      this.setState((prevState) => ({
        selectedDocsURI: prevState.selectedDocsURI.filter(
          (day) => day !== event.target.value
        ),
      }));
    }
  };

  renderList(documents) {
    return documents.map((document) => {
      return (
        <tr key={document.id}>
          <td>
            <input
              type="checkbox"
              value={document.uri}
              onChange={this.handleDocsURI}
            />
          </td>
          <td>{document.name}</td>
          <td data-th="Download">{document.issuer}</td>
        </tr>
      );
    });
  }

  onSubmit() {
    if (this.state.selectedDocsURI.length < 2) {
      alert("Select minimum 2 documents");
    } else {
      this.props.onSubmit(this.state.selectedDocsURI);
    }
  }

  render() {
    return (
      <>
        <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
          <label className="d-block">Upload type</label>
          <div className="">
            <Select
              name="Upload Type"
              id="uploadType"
              placeholder="Upload Type"
              className="w-100"
              onChange={(e) => {
                this.setState({ uploadType: e.id });
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onBlurResetsInput={false}
              onCloseResetsInput={false}
              options={[
                { name: "Digilocker Upload", id: 1 },
                { name: "Manual Upload", id: 2 },
              ]}
              isSearchable={true}
              noOptionsMessage={() => null}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,

                  primary25: "#ffa552",
                  primary50: "#fabc82",
                  primary: "#fcb26d",
                },
              })}
            />
          </div>
        </div>
        {this.state.uploadType === 1 ? (
          <>
            <h5 className="mt-4">
              Kindly Sign in to your DigiLocker Account to upload documents:
            </h5>
            <a
              className="linkBtn2 d-block overflow-hidden"
              href={DigilockerUrl}
            >
              <img
                className="digilockerImage"
                src="/assets/images/digiLocker-Small.PNG"
                alt="loginPagePic"
              />
            </a>

            {this.props.buyerEmpDocuments.length > 0 ? (
              <>
                <table className="table table-hover tableStyle1">
                  <thead>
                    <tr>
                      <td></td>
                      <td>FileName</td>
                      <td>Issuer</td>
                    </tr>
                  </thead>
                  <tbody>{this.renderList(this.props.buyerEmpDocuments)}</tbody>
                </table>
                <button
                  id="digiupload"
                  className="btnMain"
                  onClick={() => this.onSubmit()}
                >
                  Upload
                </button>
              </>
            ) : null}
          </>
        ) : this.state.uploadType === 2 ? (
          <>
            <form
              onSubmit={this.props.handleSubmit(this.props.onManualSubmit)}
              className="row m-0 col-sm-12 p-0"
            >
              <Field
                name="DocumentType"
                component={this.renderInput}
                id="buyerDocDescription"
                label="Document Type"
                type="text"
              />
              <Field
                name="Document"
                component={this.renderFileInput}
                id="buyerDocFile"
                label="Document"
                type="file"
              />
              <div className="mainBodyCardFtBtn mt-5">
                <button id="manualUpload" className="btnMain">
                  Upload
                </button>
              </div>
            </form>

            <div className="mainBodyCardFtBtn mt-5">
              <p
                className="linkBtn2 d-block overflow-hidden pt-2 w-100"
                data-bs-toggle="modal"
                data-bs-target="#viewDocumentsModel"
              >
                View Uploaded Documents
              </p>
            </div>
          </>
        ) : null}
      </>
    );
  }
}
const validate = (formValues) => {
  const errors = {};
  if (!formValues.Document) {
    errors.Document = "This Field is Required";
  }
  if (!formValues.DocumentType) {
    errors.DocumentType = "This Field is Required";
  }
  return errors;
};

export default reduxForm({
  form: "BuyerEmpanelmentDocumentUpload",
  validate,
  enableReinitialize: true,
})(BuyerEmpanelmentDocUpload);
