import React, { Component } from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import {
  isSessionExpired,
  userLogin,
  userLogout,
} from "../../actions/login/loginActions";
import history from "../../history";
import LoginForm from "./LoginForm";

class LoginPage extends Component {
  onSubmit = (formValues, dispatch) => {
    this.props.userLogin(formValues);
    dispatch(reset("loginform"));
  };

  componentDidMount() {
    if (!isSessionExpired()) {
      history.push("/home");
    }
  }

  render() {
    return (
      <div>
        <img
          className="loginImage"
          src="/assets/images/LoginPage1.png"
          alt="loginPagePic"
        />
        <div className="loginContainer">
          <div>
            <h3 className="loginTitle">Login</h3>
            <LoginForm onSubmit={this.onSubmit} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.login };
};

export default connect(mapStateToProps, { userLogin, userLogout })(LoginPage);
