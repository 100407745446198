import React, { useState, useEffect, useRef } from "react";
import "./popup.css";
import { acceptTermsAndConditions } from "../../../actions/event/EventActions";
import { connect } from "react-redux";

const EventParticipationAgreement = ({
  acceptTermsAndConditions,
  setEventTerms,
  eventTerms,
  auctionId,
}) => {
  const [agreeTerms, setAgreeTerms] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = new window.bootstrap.Modal(modalElement, {
        backdrop: "static",
        keyboard: false,
      });

      if (eventTerms > 0 && auctionId === eventTerms) {
        modal.show();
      }

      return () => {
        modal.hide();
      };
    }
  }, [eventTerms, auctionId]);

  return (
    <div>
      {eventTerms > 0 && auctionId === eventTerms && (
        <div
          className="modal fade"
          id="eventTermsAndConditions"
          tabIndex="-1"
          aria-labelledby="eventTermsAndConditionsLabel"
          aria-hidden="true"
          ref={modalRef}
          onDoubleClick={() => {
            const modalElement = modalRef.current;
            if (modalElement) {
              const modal = new window.bootstrap.Modal(modalElement, {
                backdrop: "static",
                keyboard: false,
              });
              modal.show();
            }
          }}
        >
          <div className="modal-dialog  modal-md agreementModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="eventTermsAndConditionsLabel">Terms And Conditions</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body ">
                <div className="container-fluid">
                  <div className="row">
                    <h5>We, hereby declare that,</h5>
                  </div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col=md-1 h6">1.</div>
                      <div className="col-md-11 agreementModalContent">
                        We have read, examined and understood the online
                        valuation documents pertaining to this online valuation
                        notice and have no reservations to the same.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col=md-1 h6">2.</div>
                      <div className="col-md-11 agreementModalContent">
                        We understand that, ICICI Lombard GIC Limited is a mere
                        facilitator in the deal and has no role of a seller in
                        whatsoever means.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col=md-1 h6">3.</div>
                      <div className="col-md-11 agreementModalContent">
                        We understand that, ICICI Lombard GIC Limited is only
                        providing an online platform for enabling and
                        facilitating a transparent and good realization of
                        insured s salvage. The salvage valuation is being held
                        on behalf of the insured.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col=md-1 h6">4.</div>
                      <div className="col-md-11 agreementModalContent">
                        We offer to execute the works in conformity with the
                        Online Valuation Documents.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col=md-1 h6">5.</div>
                      <div className="col-md-11 agreementModalContent">
                        Our bid shall be valid for a period as mentioned in the
                        Online Valuation document and it shall remain binding
                        upon us.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col=md-1 h6">6.</div>
                      <div className="col-md-11 agreementModalContent">
                        We understand that insured of ICICI Lombard GIC Limited
                        is not bound to accept the lowest bid in case of reverse
                        valuation and highest bid in case of forward valuation
                        or any other bid that you may receive.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col=md-1 h6">7.</div>
                      <div className="col-md-11 agreementModalContent">
                        The Buyer shall be allotted the salvage deals on the
                        basis of his bids, at the sole discretion of insured of
                        ICICI Lombard GIC Limited. However, being the highest
                        bidder does not guarantee allotment of the deal to the
                        Buyer.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col=md-1 h6">8.</div>
                      <div className="col-md-11 agreementModalContent">
                        Insured can cancel any deal allotted to the buyer,
                        without any prior notice, in case of any irregularities
                        in the case.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col=md-1 h6">9.</div>
                      <div className="col-md-11 agreementModalContent">
                        The buyer shall be statutorily responsible for the
                        malfunctioning of the salvaged materials/condemned goods
                        subsequently sold at his end and informing the
                        regulatory authorities, if any, and following the
                        processes of the regulatory authorities accordingly.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col=md-1 h6">10.</div>
                      <div className="col-md-11 agreementModalContent">
                        ICICI Lombard GIC Limited does not hold any liability
                        for any occurrence of any mishap arising from your usage
                        of our Services resulting in any financial, material or
                        human damage. We understand and agree that ICICI Lombard
                        GIC Limited shall not be liable to us for any direct,
                        indirect, incidental, special, consequential or
                        exemplary damages, including without limitation damages
                        for loss of profits, goodwill, use, data or other
                        intangible losses resulting from or relating to the
                        usage of the services or platform provided by ICICI
                        Lombard GIC Limited.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col=md-1 h6">11.</div>
                      <div className="col-md-11 agreementModalContent">
                        ICICI Lombard GIC Limited may terminate your access to
                        or use of the Services, or any portion thereof,
                        immediately and at any point, at its sole discretion, if
                        the user violates or breaches any of its obligations,
                        responsibilities, or covenants under these Terms.
                        Notwithstanding anything to the contrary contained in
                        the Terms, upon termination of your access to or use of
                        the Services, all amounts or outstanding monies due by
                        us in relation to our use of or access to the Services
                        shall become immediately payable.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col=md-1 h6">12.</div>
                      <div className="col-md-11 agreementModalContent">
                        When we apply to become a registered Buyer and during
                        our use of the Website and the Services, we will provide
                        us with data. We will respect the privacy of such data.
                        We will only collect, use, disclose or process your data
                        in accordance with data protection laws for more
                        information as to how we will do this please refer to
                        our Privacy Policy, which we accept by our continued use
                        of the Website or the Services. We must keep our data
                        accurate and up-to-date and promptly send ICICI Lombard
                        GIC Limited any new or amended data. ICICI Lombard GIC
                        Limited will only share data with other parties as set
                        out in our Privacy Policy.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col=md-1 h6">13.</div>
                      <div className="col-md-11 agreementModalContent">
                        We agree to act lawfully, diligently and honestly at all
                        times when we access and use the Website or Services and
                        will comply with all laws and regulations applicable to
                        our use of the Website or Services. We guarantee that
                        all the details we supply on registration or at any time
                        thereafter are and will remain true and accurate.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col=md-1 h6">14.</div>
                      <div className="col-md-11 agreementModalContent">
                        We agree that we will not interfere with, jeopardise,
                        disrupt or harm the Website or Services and that we will
                        not intercept, expropriate, re-use, steal or re-utilise
                        any system, data, photographs or information comprised
                        in or provided to ICICI Lombard GIC Limited via the
                        Website or Services.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col=md-1 h6">15.</div>
                      <div className="col-md-11 agreementModalContent">
                        We agree that we shall be solely liable for actions
                        taken using our username and password to access the
                        Website or Services, whether taken by us or third
                        parties and/or with or without our consent or knowledge.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="agreementModalFooter">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="agreementCheckbox"
                    onClick={() => setAgreeTerms(!agreeTerms)}
                  ></input>
                  <label
                    className="custom-control-label"
                    htmlFor="agreementCheckbox"
                  >
                    I acknowledge that I have read, and do hereby accept the
                    terms and conditions
                  </label>
                </div>

                {agreeTerms ? (
                  <div className="row">
                    <button
                      className="agreementModalBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        acceptTermsAndConditions(auctionId);
                        setEventTerms(0);
                      }}
                    >
                      Accept
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(null, { acceptTermsAndConditions })(
  EventParticipationAgreement
);
