import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import BuyerEmpanelmentSearch from "../BuyerEmpanelment/BuyerEmpanelmentSearch";
import BuyerRmcSearch from "../masters/buyer/BuyerRmcSearch";
import SearchAuction from "../search/searchAuction";
import {
  fetchEventsByStatus,
  
  fetchBuyerEventsByStatus,
} from "../../actions/event/EventActions";

class LeftMenu extends Component {
  adminLinks = (
    <>
      <li>
        <Link
          to="/buyerempanelment/"
          className={
            this.props.activeRoute === "buyerempanelment" ? "active" : ""
          }
        >
          Buyer Empanelment
        </Link>
      </li>
      <li>
        <Link to="/home/cancelled"onClick={()=>{
          
          this.props.fetchEventsByStatus("CANCELLED")}
         
          
          }>Cancelled Events</Link>
      </li>

      <li>
        <Link
          to="/buyerrmc/view"
          className={this.props.activeRoute === "buyerrmc" ? "active" : ""}
        >
          Buyer Modification
        </Link>
      </li>
      <li>
        <Link
          to="/getReports"
          className={this.props.activeRoute === "getReports" ? "active" : ""}
        >
          Reports
        </Link>
      </li>
      <li>
        <Link
          to="/searched"
        
        >
         Get Details With ClaimNo
        </Link>
      </li>
    </>
  );

  salvageRMLinks = (
    <>
      <li>
        <Link
          to="/buyerempanelment/"
          className={
            this.props.activeRoute === "buyerempanelment" ? "active" : ""
          }
        >
          Buyer Empanelment
        </Link>
      </li>
      <li>
        <Link to="/home/cancelled"onClick={()=>{
          
          this.props.fetchEventsByStatus("CANCELLED")}
         
          
          }>Cancelled Events</Link>
      </li>
      <li>
        <Link
          to="/getReports"
          className={this.props.activeRoute === "getReports" ? "active" : ""}
        >
          Reports
        </Link>
      </li>
      <li>
        <Link
          to="/searched"
        
        >
         Get Details With ClaimNo
        </Link>
      </li>
    </>
  );

  render() {
    return (
      <div className="leftLinks border-right">
        <ul className="mb-0 leftlinkMobile">
          <li>
            <div className="lastLoginCss">
              <span>Last Login:</span>
              <br />
              <span>{this.props.lastLogin.lastLoginDate}</span>
              <br />
              <span>{this.props.lastLogin.lastLoginTime}</span>
            </div>
          </li>
          {this.props.tokendata.role === "Admin" && this.adminLinks}
          {this.props.tokendata.role === "SalvageRM" && this.salvageRMLinks}
          {this.props.tokendata.role === "Buyer" && (
            <li>
              <a
                href="/assets/Contents/AuctionPortalWorkflow.pdf#toolbar=0"
                without
                rel="noopener noreferrer"
                target="_blank"
              >
                <button
                  trailingIcon="picture_as_pdf"
                  label="Resume"
                  className="pdfLeftLink"
                >
                  User Manual
                </button>
              </a>
            </li>
          )}
           <li>
        <Link to="/home/archive" onClick={()=>{
           if (
            this.props.tokendata.role === "Admin" ||
            this.props.tokendata.role === "SalvageRM"
          ) {
          this.props.fetchEventsByStatus("ARCHIVE")}
          if (this.props.tokendata.role === "Buyer"){
            this.props.fetchBuyerEventsByStatus("ARCHIVE",this.props.tokendata.sub)
          }
          
          }}>
          {/* <span onClick={console.log("heloooo")}>Archived Events</span></Link> */}
          Archive</Link>
      </li>
        </ul>

        {this.props.activeRoute === "homepage" ? (
          <div className="cardFilter" >
            <SearchAuction
              submitEventIdSearch={this.props.submitEventIdSearch}
              submitRegionSearch={this.props.submitRegionSearch}
              submitStateSearch={this.props.submitStateSearch}
              submitCitySearch={this.props.submitCitySearch}
              submitCategorySearch={this.props.submitCategorySearch}
              submitAuctionStartDateSearch={
                this.props.submitAuctionStartDateSearch
              }
              submitAuctionEndDateSearch={this.props.submitAuctionEndDateSearch}
              toggleAdvanceSearch={this.props.toggleAdvanceSearch}
              advancesearch={this.props.advancesearch}
              searchState={this.props.searchState}
            />
          </div>
        ) : null}
        {this.props.activeRoute === "buyerempanelment" ? (
          <div className="cardFilter" >
            <BuyerEmpanelmentSearch
              toggleSearch={this.props.toggleSearch}
              submitBuyerNameSearch={this.props.submitBuyerNameSearch}
              submitRequestIdSearch={this.props.submitRequestIdSearch}
              submitEmailIdSearch={this.props.submitEmailIdSearch}
            />
          </div>
        ) : null}
        {this.props.activeRoute === "buyerrmc" ? (
          <div className="cardFilter" >
            <BuyerRmcSearch
              toggleSearch={this.props.toggleSearch}
              submitRequestIdSearch={this.props.submitRequestIdSearch}
              submitBuyerNameSearch={this.props.submitBuyerNameSearch}
              submitEmailIdSearch={this.props.submitEmailIdSearch}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tokendata: localStorage.getItem("userToken")
      ? jwt_decode(localStorage.getItem("userToken"))
      : null,
    lastLogin: state.lastLogin,

  };
};

export default connect(mapStateToProps,{
  fetchEventsByStatus,
 
  fetchBuyerEventsByStatus,
  
})(LeftMenu);
