import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
  fetchRegion,
  editRegion,
  deleteRegion,
} from "../../../actions/masters/regionActions";
import RegionForm from "./RegionForm";
import { Link } from "react-router-dom";
import history from "../../../history";

class RegionEdit extends Component {
  componentDidMount() {
    this.props.fetchRegion(this.props.match.params.id);
  }

  onSubmit = (formValues) => {
    this.props.editRegion(this.props.match.params.id, formValues);
  };

  onClickDelete = () => {
    this.props.deleteRegion(this.props.match.params.id, this.props.regions);
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Edit Region</h3>
          </div>
          <div className="float-end mt-15">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        <RegionForm
          initialValues={_.pick(
            this.props.regions,
            "name",
            "shortName",
            "activeFlag"
          )}
          onSubmit={this.onSubmit}
          onDelete={this.onClickDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { regions: state.region[ownProps.match.params.id] };
};

export default connect(mapStateToProps, {
  editRegion,
  fetchRegion,
  deleteRegion,
})(RegionEdit);
