import React, { Component } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import Spinner from "../../Spinner";

class LocationCity extends Component {
  state = { selectedCity: "", cityState: "", cityRegion: "" };

  handleSelect = (val) => {
    const state = this.props.states.filter((st) => st.id === val.stateId)[0];
    const region = this.props.regions.filter(
      (reg) => reg.id === state.regionID
    )[0];
    this.setState({ selectedCity: val, cityState: state, cityRegion: region });
  };

  customFilter = (option, searchText) => {
    if (option.data.name.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <div>
        {this.props.cities.length > 0 ? (
          <div className="row m-0">
            <div className="col-md-12 col-sm-12 pb-5 pl-0 mb_p_0">
              <div className="Header-Content overflow-hidden">
                <h5 className="form_label">Cities</h5>
              </div>
              <div className="">
                <Select
                  onChange={this.handleSelect}
                  getOptionLabel={(option) => `${option.name} `}
                  getOptionValue={(option) => `${option.id}`}
                  isOptionSelected={(option) => {
                    return this.state.selectedCity.id === option.id
                      ? true
                      : false;
                  }}
                  options={this.props.cities}
                  isSearchable={true}
                  filterOption={this.customFilter}
                  onInputChange={this.handleInputChange}
                  noOptionsMessage={() => null}
                  placeholder={"Search City"}
                  autoFocus={false}
                  menuIsOpen={this.state.menuOpen}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,

                      primary25: "#ffa552",
                      primary50: "#fabc82",
                      primary: "#fcb26d",
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 pl-0 locationTableDiv mb_p_0">
              {this.state.selectedCity !== "" ? (
                <div className="">
                  <table className="table table-hover tableStyle2">
                    <thead>
                      <tr>
                        <td>City</td>
                        <td>State</td>
                        <td>Region</td>
                        <td>Is Active</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td data-th="City">{this.state.selectedCity.name}</td>
                        <td data-th="State">{this.state.cityState.name}</td>
                        <td data-th="Region">{this.state.cityRegion.name}</td>
                        <td data-th="Is Active">
                          {this.state.selectedCity.activeFlag === "Y"
                            ? "True"
                            : "False"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {this.props.user.role === "Admin" ? (
                    <div className="row m-0">
                      <Link
                        to={`/masters/state/${this.state.cityState.id}/city/${this.state.selectedCity.id}/edit`}
                        className="linkBtn1"
                      >
                        Modify
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

export default LocationCity;
