import _ from "lodash";

import {
  FETCH_STATEs,
  FETCH_STATE,
  EDIT_STATE,
  CREATE_STATE,
  DELETE_STATE,
  FETCH_ALL_STATES,
} from "../../actions/types";

const stateReducers = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STATEs:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case FETCH_STATE:
      return { ...state, [action.payload.id]: action.payload };
    case FETCH_ALL_STATES:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case EDIT_STATE:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_STATE:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_STATE:
      return _.omit(state, action.payload);

    default:
      return state;
  }
};

export default stateReducers;
