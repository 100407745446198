import React, { Component } from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { fetchRegions } from "../../actions/masters/regionActions";
import { fetchAllStates } from "../../actions/masters/stateActions";
import { fetchAllCities } from "../../actions/masters/cityActions";
import { fetchProducts } from "../../actions/masters/productActions";
import { connect } from "react-redux";
import Select from "react-select";
import jwt_decode from "jwt-decode";

class EventForm extends Component {
  componentDidMount() {
    this.props.fetchRegions();
    this.props.fetchAllStates();
    this.props.fetchAllCities();
    this.props.fetchProducts();
  }

  renderError = ({ error, touched }) => {
    if (touched && error) {
      return <div>{error}</div>;
    }
  };

  renderTextInput = ({ input, label, meta, maxLength, val }) => {
    return (
      <div>
        <div className="input-field">
          <input
            style={{ maxWidth: "600px", marginTop: "0px" }}
            {...input}
            autoComplete="off"
            placeholder={label}
            className="form-control"
            maxLength={maxLength}
            // value={val}
          />
        </div>
        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
  };

  renderRadioInput = ({ input, meta, type, label }) => {
    return (
      <div className="radio">
        <input
          className=""
          {...input}
          autoComplete="off"
          type={type}
          value={input.value}
          id={label}
        />
        <label className="radio-label" htmlFor={label}>
          {input.value === true
            ? "True"
            : input.value === false
            ? "False"
            : input.value}
        </label>
        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
  };

  renderSelectInput = ({ input, meta, options, placeholder }) => {
    return (
      <div>
        <div style={{ maxWidth: "600px" }}>
          <Select
            {...input}
            value={
              input.value === ""
                ? null
                : options.find((obj) => obj.id === input.value)
            }
            onChange={input.onChange}
            onBlur={(value) => input.onBlur(value)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onBlurResetsInput={false}
            onCloseResetsInput={false}
            options={options}
            onFocus={input.onFocus}
            isSearchable={true}
            noOptionsMessage={() => null}
            placeholder={placeholder}
            // autoFocus={true}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,

                primary25: "#ffa552",
                primary50: "#fabc82",
                primary: "#fcb26d",
              },
            })}
          />
          <div className="error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  renderTextArea = ({ input, label, meta, disabled, type, maxLength }) => {
    return (
      <div>
        <div className="input-field">
          <textarea
            {...input}
            type={type}
            maxLength={maxLength}
            autoComplete="off"
            placeholder={label}
            className="form-control"
            disabled={disabled}
          />
        </div>
        <div className="pl-1 error">{this.renderError(meta)}</div>
      </div>
    );
  };

  renderDate = ({ input, label, meta, minDate }) => {
    return (
      <div>
        <label className="d-block">{label}</label>
        <div className="input-field">
          <input
            {...input}
            type="date"
            autoComplete="off"
            placeholder={label}
            className="form-control"
            minValue={minDate}
          />
        </div>
        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <div className="mainBodyCard pt-30">
        <div>
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <div className="row">
              <div className="col-md-6 col-sm-12 eventCreateInputCol">
                <label>Department</label>
                <Field
                  name="department"
                  component={this.renderTextInput}
                  label="Enter Department"
                  maxLength={15}
                />
              </div>
              <div className="col-md-6 col-sm-12 eventCreateInputCol">
                <label>Officer</label>
                <Field
                  name="officer"
                  component={this.renderTextInput}
                  label="Enter Officer"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12 eventCreateInputCol">
                <label>Category</label>
                <Field
                  name="category"
                  component={this.renderSelectInput}
                  placeholder="Select Category"
                  props={{
                    options: this.props.products.filter((s) => {
                      return s.activeFlag === "Y";
                    }),
                  }}
                  normalize={(value) => value.id}
                />
              </div>
            </div>
            {this.props.categoryValue && (
              <>
                <div className="row">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label>Claim No</label>
                    <Field
                      name="claimNo"
                      component={this.renderTextInput}
                      label="Enter Claim Number"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label>
                      {this.props.categoryValue === 1
                        ? "Cause Of Loss"
                        : "Brief Scope Of Work"}
                    </label>
                    <Field
                      name="briefScopeOfWork"
                      component={this.renderTextInput}
                      label={
                        this.props.categoryValue === 1
                          ? "Enter Cause of Loss"
                          : "Enter Brief Scope Of Work"
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label>Auction Details</label>
                    <Field
                      name="auctionDetails"
                      component={this.renderTextInput}
                      label="Enter Auction Details"
                    />
                  </div> */}
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    {this.props.categoryValue === 1 ? (
                      <Field
                        name="dateOfLoss"
                        type="date"
                        component={this.renderDate}
                        label="Date Of Loss"
                        maxDate={new Date().toISOString().split("T")[0]}
                      />
                    ) : (
                      <>
                        <label className="d-block">Type Of Contract</label>
                        <Field
                          name="typeOfContract"
                          component={this.renderRadioInput}
                          type="radio"
                          value="Goods"
                          label="EventGoodsContract"
                        />
                        <Field
                          name="typeOfContract"
                          component={this.renderRadioInput}
                          type="radio"
                          value="Service"
                          label="EventServiceContract"
                        />
                        <Field
                          name="typeOfContract"
                          component={this.renderRadioInput}
                          type="radio"
                          value="Works"
                          label="EventWorksContract"
                        />
                        <Field
                          name="typeOfContract"
                          component={this.renderRadioInput}
                          type="radio"
                          value="Turnkey"
                          label="EventTurnKeyContract"
                        />
                        <Field
                          name="typeOfContract"
                          component={this.renderRadioInput}
                          type="radio"
                          value="Project"
                          label="EventProjectContract"
                        />
                        <Field
                          name="typeOfContract"
                          component={this.renderRadioInput}
                          type="radio"
                          value="Other"
                          label="EventOtherContract"
                        />
                      </>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label>Remarks</label>
                    <Field
                      name="remarks"
                      component={this.renderTextInput}
                      label="Remarks"
                    />
                  </div>
                </div>
                {this.props.categoryValue === 1 ? (
                  <div className="row">
                    <div className="col-md-6 col-sm-12 eventCreateInputCol">
                      <label>Damaged Quantity</label>
                      <Field
                        name="damagedQuantity"
                        component={this.renderTextInput}
                        label="Enter Quantity"
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 eventCreateInputCol">
                      <label>Damaged Material</label>
                      <Field
                        name="damagedMaterial"
                        component={this.renderTextInput}
                        label="Enter Material"
                      />
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label>
                      {this.props.categoryValue === 1
                        ? "Insured Name"
                        : "Garage Name"}
                    </label>
                    <Field
                      name="garageName"
                      component={this.renderTextInput}
                      label={
                        this.props.categoryValue === 1
                          ? "Enter Insured Name"
                          : "Enter Garage Name"
                      }
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label>
                      {this.props.categoryValue === 1
                        ? "Location Of Material"
                        : "Garage Address"}
                    </label>
                    <Field
                      name="garageAddress"
                      component={this.renderTextArea}
                      label={
                        this.props.categoryValue === 1
                          ? "Enter Location Of Material"
                          : "Enter Address"
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label>
                      {this.props.categoryValue === 1
                        ? "Insured Region"
                        : "Garage Region"}
                    </label>
                    <Field
                      name="garageRegionId"
                      component={this.renderSelectInput}
                      placeholder={
                        this.props.categoryValue === 1
                          ? "Select Insured Region"
                          : "Select Garage Region"
                      }
                      props={{
                        options: this.props.regions.filter((s) => {
                          return s.activeFlag === "Y";
                        }),
                      }}
                      normalize={(value) => value.id}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label>
                      {this.props.categoryValue === 1
                        ? "Insured State"
                        : "Garage State"}
                    </label>
                    <Field
                      name="garageStateId"
                      component={this.renderSelectInput}
                      placeholder={
                        this.props.categoryValue === 1
                          ? "Select Insured State"
                          : "Select Garage State"
                      }
                      props={{
                        options: this.props.states.filter((s) => {
                          return (
                            s.regionID ===
                              parseInt(this.props.garageRegionIdValue) &&
                            s.activeFlag === "Y"
                          );
                        }),
                      }}
                      normalize={(value) => value.id}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label>
                      {this.props.categoryValue === 1
                        ? "Insured City"
                        : "Garage City"}
                    </label>
                    <Field
                      name="garageCityId"
                      component={this.renderSelectInput}
                      placeholder={
                        this.props.categoryValue === 1
                          ? "Select Insured City"
                          : "Select Garage City"
                      }
                      props={{
                        options: this.props.cities.filter((c) => {
                          return (
                            c.stateId ===
                            parseInt(this.props.garageStateIdValue)
                          );
                        }),
                      }}
                      normalize={(value) => value.id}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label>
                      {this.props.categoryValue === 1
                        ? "Commodity"
                        : "Fuel Type"}
                    </label>
                    <Field
                      name="fuelType"
                      component={this.renderTextInput}
                      label={
                        this.props.categoryValue === 1
                          ? "Enter Commodity"
                          : "Enter Fuel Type"
                      }
                    />
                  </div>
                </div>

                <div className="row">
                  {this.props.categoryValue === 1 ? null : (
                    <>
                      <div className="col-md-6 col-sm-12 eventCreateInputCol">
                        <label>Manufacturing Year</label>
                        <Field
                          name="manufacturingYear"
                          component={this.renderTextInput}
                          label="Enter Manufacturing Year"
                        />
                      </div>
                      <div className="col-md-6 col-sm-12 eventCreateInputCol">
                        <label>Model Variant</label>
                        <Field
                          name="modelVariant"
                          component={this.renderTextInput}
                          label="Enter Model Variant"
                        />
                      </div>
                    </>
                  )}
                </div>
                {this.props.categoryValue === 1 ? null : (
                  <>
                    <div className="row">
                      <div className="col-md-6 col-sm-12 eventCreateInputCol">
                        <label>Ownership Serial Number</label>
                        <Field
                          name="ownerShipSerialNo"
                          component={this.renderTextInput}
                          label="Enter Ownership Serial Number"
                        />
                      </div>
                      <div className="col-md-6 col-sm-12 eventCreateInputCol">
                        <label>policy Number</label>
                        <Field
                          name="policyNumber"
                          component={this.renderTextInput}
                          label="Enter Policy Number"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-12 eventCreateInputCol">
                        <label>Policy Product</label>
                        <Field
                          name="policyProduct"
                          component={this.renderTextInput}
                          label="Enter Policy product"
                        />
                      </div>
                      <div className="col-md-6 col-sm-12 eventCreateInputCol">
                        <label>Registration number</label>
                        <Field
                          name="registrationNo"
                          component={this.renderTextInput}
                          label="Enter Registration Number"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-12 eventCreateInputCol">
                        <label>Vehicle Make</label>
                        <Field
                          name="vehicleMake"
                          component={this.renderTextInput}
                          label="Enter Vehicle Make"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="row hideEventFields">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol ">
                    <label>Auction Base Currency</label>
                    <Field
                      name="auctionBaseCurrency"
                      component={this.renderTextInput}
                      label="Enter Auction Base Currency"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label>Auction Variant</label>
                    <Field
                      name="auctionVariant"
                      component={this.renderTextInput}
                      label="Enter Auction Variant"
                    />
                  </div>
                </div>
                <div className="row hideEventFields">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol hideEventFields">
                    <label className="d-block">Auction Type</label>
                    <Field
                      name="auctionType"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Standard"
                      label="EventStandardType"
                    />
                    <Field
                      name="auctionType"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Rank"
                      label="EventRankType"
                    />
                  </div>
                </div>
                <div className="row hideEventFields">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">
                      Bidding Price Increment In
                    </label>
                    <Field
                      name="biddingPriceIncrementIn"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Figure"
                      label="EventFigureBidding"
                    />
                    <Field
                      name="biddingPriceIncrementIn"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Percentage"
                      label="EventPercentageBidding"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">
                      Increment Decrement In Multiples
                    </label>
                    <div>
                      <Field
                        name="incrementDecrementInMultiples"
                        component={this.renderRadioInput}
                        type="radio"
                        value={true}
                        label="EventIncrementMultipleTrue"
                        normalize={(value) => value === "true"}
                      />
                      <Field
                        name="incrementDecrementInMultiples"
                        component={this.renderRadioInput}
                        type="radio"
                        value={false}
                        label="EventIncrementMultipleFalse"
                        normalize={(value) => value === "true"}
                      />
                    </div>
                  </div>
                </div>
                <div className="row hideEventFields">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">
                      Reserve Price Check Required
                    </label>
                    <Field
                      name="reservePriceCheckRequired"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      label="EventReservePriceRequiredTrue"
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="reservePriceCheckRequired"
                      component={this.renderRadioInput}
                      label="EventReservePriceRequiredFalse"
                      type="radio"
                      value={false}
                      normalize={(value) => value === "true"}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">Item Selection Required</label>
                    <Field
                      name="itemSelectionRequired"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      label="EventItemSelectionTrue"
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="itemSelectionRequired"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      label="EventItemSelectionFalse"
                      normalize={(value) => value === "true"}
                    />
                  </div>
                </div>
                <div className="row hideEventFields">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">
                      Line Item Wise Bid Submission Time Allowed
                    </label>
                    <Field
                      name="lineItemWiseBidSubmissionTimeAllowed"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      label="EventItemWiseBidSubmissionTrue"
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="lineItemWiseBidSubmissionTimeAllowed"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      label="EventItemWiseBidSubmissionFalse"
                      normalize={(value) => value === "true"}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">Bid Submission Mode</label>
                    <Field
                      name="bidSubmissionMode"
                      component={this.renderRadioInput}
                      type="radio"
                      label="EventItemWiseBidSubmissionModeSerial"
                      value="Serial"
                    />
                    <Field
                      name="bidSubmissionMode"
                      component={this.renderRadioInput}
                      label="EventItemWiseBidSubmissionModeParallel"
                      type="radio"
                      value="Parallel"
                    />
                  </div>
                </div>
                <div className="row hideEventFields">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">Auto Bid Allowed</label>
                    <Field
                      name="autoBidAllowed"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      label="EvenAutoBidAllowedTrue"
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="autoBidAllowed"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      label="EvenAutoBidAllowedFalse"
                      normalize={(value) => value === "true"}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">Allow Mobile Bidding</label>
                    <Field
                      name="allowMobileBidding"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      label="EventAllowMobileBiddingTrue"
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="allowMobileBidding"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      label="EventAllowMobileBiddingFalse"
                      normalize={(value) => value === "true"}
                    />
                  </div>
                </div>
                <div className="row hideEventFields">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">Bidding Form</label>
                    <Field
                      name="biddingForm"
                      component={this.renderRadioInput}
                      type="radio"
                      label="EventBiddingFormStandard"
                      value="Use standard form"
                    />
                    <Field
                      name="biddingForm"
                      component={this.renderRadioInput}
                      type="radio"
                      label="EventBiddingFormNewForm"
                      value="Create new form"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">Allowed Auto Extension</label>
                    <Field
                      name="allowedAutoExtension"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      label="EventAllowedAutoExtensionTrue"
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="allowedAutoExtension"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      label="EventAllowedAutoExtensionFalse"
                      normalize={(value) => value === "true"}
                    />
                  </div>
                </div>
                <div className="row hideEventFields">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">Auto Extension Mode</label>
                    <Field
                      name="autoExtensionMode"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Limited extensions"
                      label="EventAutoExtentionLimited"
                    />
                    <Field
                      name="autoExtensionMode"
                      component={this.renderRadioInput}
                      type="radio"
                      label="EventAutoExtentionUnLimited"
                      value="Unlimited extensions"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">
                      First Bid Acceptance Condition
                    </label>
                    <Field
                      name="firstBidAcceptanceCondition"
                      component={this.renderRadioInput}
                      type="radio"
                      label="EventFirstBidAcceptanceConditionStart"
                      value="Accept start price"
                    />
                    <Field
                      name="firstBidAcceptanceCondition"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Accept start price + increment value"
                      label="EventFirstBidAcceptanceConditionStartIncrement"
                    />
                  </div>
                </div>
                <div className="row hideEventFields">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">Display L1 H1 Amount</label>
                    <Field
                      name="displayL1_H1Amount"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      label="EventDisplayL1_H1AmountTrue"
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="displayL1_H1Amount"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      label="EventDisplayL1_H1AmountFalse"
                      normalize={(value) => value === "true"}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">Demo Auction</label>
                    <Field
                      name="demoAuction"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      label="EventDemoAuctionTrue"
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="demoAuction"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      label="EventDemoAuctionFalse"
                      normalize={(value) => value === "true"}
                    />
                  </div>
                </div>
                <div className="row hideEventFields">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">Display IP Address</label>
                    <Field
                      name="displayIPAddress"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      label="EventDisplayIPAddressTrue"
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="displayIPAddress"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      label="EventDisplayIPAddressFalse"
                      normalize={(value) => value === "true"}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">
                      Display Price Bid Break Up Link
                    </label>
                    <Field
                      name="displayPriceBidBreakUpLink"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      label="EventDisplayPriceBidBreakUpLinkTrue"
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="displayPriceBidBreakUpLink"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      label="EventDisplayPriceBidBreakUpLinkFalse"
                      normalize={(value) => value === "true"}
                    />
                  </div>
                </div>
                <div className="row hideEventFields">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">
                      Hide Live Bidding To Department User
                    </label>
                    <Field
                      name="hideLiveBiddingToDepartmentUser"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      label="EventHideLiveBiddingToDepartmentUserTrue"
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="hideLiveBiddingToDepartmentUser"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      label="EventHideLiveBiddingToDepartmentUserFalse"
                      normalize={(value) => value === "true"}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol">
                    <label className="d-block">Bid Submission For</label>
                    <Field
                      name="bidSubmissionFor"
                      component={this.renderRadioInput}
                      type="radio"
                      label="EventBidSubmissionForGrandTotal"
                      value="Grand total"
                    />
                    <Field
                      name="bidSubmissionFor"
                      component={this.renderRadioInput}
                      type="radio"
                      label="EventBidSubmissionForEachLine"
                      value="Each line item"
                    />
                    <Field
                      name="bidSubmissionFor"
                      component={this.renderRadioInput}
                      type="radio"
                      label="EventBidSubmissionForItemandGrandTotal"
                      value="Each line item and grand total"
                    />
                  </div>
                </div>

                <div className="mainBodyCardFtBtn mt-50">
                  <button className="btnMain">Submit</button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const selector = formValueSelector("eventform");
  const garageRegionIdValue = selector(state, "garageRegionId");
  const garageStateIdValue = selector(state, "garageStateId");
  const categoryValue = selector(state, "category");
  var tokendata = localStorage.getItem("userToken")
    ? jwt_decode(localStorage.getItem("userToken"))
    : null;
  return {
    tokendata,
    garageRegionIdValue,
    garageStateIdValue,
    categoryValue,
    regions: Object.values(state.region),
    states: Object.values(state.state),
    cities: Object.values(state.city),
    products: Object.values(state.product),
  };
};

const validate = (formValues) => {
  const errors = {};

  if (!formValues.department) {
    errors.department = "This Field is Required";
  }

  // if (!formValues.officer) {
  //   errors.officer = "This Field is Required";
  // }

  if (!formValues.claimNo) {
    errors.claimNo = "This Field is Required";
  }
  if (
    formValues.ownerShipSerialNo &&
    !/^[0-9]*$/i.test(formValues.ownerShipSerialNo)
  ) {
    errors.ownerShipSerialNo = "Only Numbers are allowed";
  }
  // if (
  //   formValues.damagedQuantity &&
  //   !/^[0-9]*$/i.test(formValues.damagedQuantity)
  // ) {
  //   errors.damagedQuantity = "Only Numbers are allowed";
  // }

  if (!formValues.briefScopeOfWork) {
    errors.briefScopeOfWork = "This Field is Required";
  }

  if (!formValues.auctionDetails) {
    errors.auctionDetails = "This Field is Required";
  }

  if (!formValues.typeOfContract) {
    errors.typeOfContract = "This Field is Required";
  }

  if (!formValues.keywords) {
    errors.keywords = "This Field is Required";
  }

  if (!formValues.auctionBaseCurrency) {
    errors.auctionBaseCurrency = "This Field is Required";
  }

  if (!formValues.auctionVariant) {
    errors.auctionVariant = "This Field is Required";
  }

  if (
    formValues.garageRegionId === 0 ||
    formValues.garageRegionId === undefined
  ) {
    errors.garageRegionId = "This Field is Required";
  }

  if (
    formValues.garageStateId === 0 ||
    formValues.garageStateId === undefined
  ) {
    errors.garageStateId = "This Field is Required";
  }

  if (formValues.category === 0 || formValues.category === undefined) {
    errors.category = "This Field is Required";
  }

  return errors;
};

const eventForm = reduxForm({
  form: "eventform",
  validate,
  enableReinitialize: true,
})(EventForm);

export default connect(mapStateToProps, {
  fetchRegions,
  fetchAllStates,
  fetchAllCities,
  fetchProducts,
})(eventForm);
