import AuctionPortal from "../../apis/AuctionPortal";
import history from "../../history";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  ADD_EVENT_DOC,
  FETCH_EVENT_DOC,
  ADD_EVENT_DOCsStart,
  ADD_EVENT_DOCsEnd,
  CLEAR_EVENT_DOCs,
  DELETE_EVENT_DOCUMENT,
  USER_LOGOUT,
} from "../types";
import { fetchEvent } from "./EventActions";
import { isSessionExpired, userLogout } from "../login/loginActions";
import { encryptId,decryptIndex } from "../../components/Encryption";
toast.configure({
  position: "top-center",
  autoClose: 10000,
  hideProgressBar: true,
});

export const addEventDocument = (id, formvalues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      var bodyFormData = new FormData();
      bodyFormData.append("Description", formvalues.Description);
      bodyFormData.append("Document", formvalues.Document);
      const response = await AuctionPortal.post(
        `event/${encryptId(id)}/eventdocument`,
        bodyFormData,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: ADD_EVENT_DOC, payload: response.data });
      dispatch(fetchEvent(id));

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.push(`/event/${id}/eventdocuments`);
      toast("Uploaded Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const fetchEventDocument = (id) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
     document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get(`/event/${id}/eventdocument`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      dispatch({ type: FETCH_EVENT_DOC, payload: response.data });

       document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      // document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const downloadDocument = (id) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    const response = await AuctionPortal.post(
      "http://localhost:52246/api/DMS/DMSView",
      {
        docIndex: id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    return response.data.docContent;
  }
};

export const clearDocState = (id) => async (dispatch) => {
  dispatch({ type: CLEAR_EVENT_DOCs });
};

export const deleteDocument = (docId) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.delete(
        `event/eventdocument/${docId}`,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: DELETE_EVENT_DOCUMENT, payload: response.data });
      // dispatch(fetchEvent());

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      // history.push(`/event/${id}/eventdocuments`);
      toast("Deleted Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};
