import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";

class BuyerEmpanelmentPaymentForm extends Component {
  state = {
    paymentreference: "",
    bnkname: "",
    buyername: "",
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <div style={{ textAlignLast: "center" }}>{error}</div>;
    }
  }

  renderInput = ({ input, label, meta, disabled, type, maxLength, value }) => {
    return (
      <div className="col-md-12 col-sm-12">
        <div className="form-group">
          <label className="form_label">{label}</label>
          <div className="input-field">
            <input
              {...input}
              type={type}
              maxLength={maxLength}
              autoComplete="off"
              placeholder={label}
              className="form-control"
              disabled={disabled}
              value={value}
            />
          </div>
          <div className="error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  renderDate = ({ input, label, meta }) => {
    return (
      <div className="col-md-12 col-sm-12">
        <div className="form-group">
          <label className="form_label">{label}</label>
          <div className="input-field">
            <input
              {...input}
              type="date"
              autoComplete="off"
              placeholder={label}
              className="form-control"
            />
          </div>
          <div className="error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <div className="row mt-5">
          <Field
            name="paymentRefNo"
            component={this.renderInput}
            label="Payment Reference Number"
            type="text"
            props={{
              value: this.state.paymentreference,
            }}
            onChange={(event) =>
              this.setState({
                paymentreference: event.target.value,
              })
            }
          />
          <Field
            name="dateOfPayment"
            component={this.renderDate}
            label="Date Of Payment"
            type="text"
          />
          <Field
            name="bankName"
            component={this.renderInput}
            label="Bank Name"
            type="text"
            props={{
              value: this.state.bnkname,
            }}
            onChange={(event) =>
              this.setState({
                bnkname: event.target.value.replace(/[^a-z A-Z]+/gi, ""),
              })
            }
          />
          <Field
            name="accountHolderName"
            component={this.renderInput}
            label="Account Holder Name"
            type="text"
            props={{
              value: this.state.buyername,
            }}
            onChange={(event) =>
              this.setState({
                buyername: event.target.value.replace(/[^a-z A-Z]+/gi, ""),
              })
            }
          />
          <Field
            name="amount"
            component={this.renderInput}
            label="Amount"
            type="number"
            normalize={(value) => parseInt(value)}
          />

          <div className="mainBodyCardFtBtn mt-50">
            <button className="btnMain">Submit</button>
          </div>
        </div>
      </form>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     regions: Object.values(state.region),
//   };
// };

const validate = (formValues) => {
  const errors = {};
  if (!formValues.user_id) {
    errors.user_id = "This Field is Required";
  }
  if (!formValues.paymentRefNo) {
    errors.paymentRefNo = "This Field is Required";
  }
  if (!formValues.dateOfPayment) {
    errors.dateOfPayment = "This Field is Required";
  }
  if (!formValues.amount) {
    errors.amount = "This Field is Required";
  }
  if (!formValues.accountHolderName) {
    errors.accountHolderName = "This Field is Required";
  }
  if (!formValues.bankName) {
    errors.bankName = "This Field is Required";
  }
  return errors;
};

export default reduxForm({
  form: "BuyerEmpanelmentPaymentForm",
  validate,
  enableReinitialize: true,
})(BuyerEmpanelmentPaymentForm);
