import React, { Component } from "react";

class SimpleSearch extends Component {
  render() {
    return (
      <div className="card border-0 bg-transparent">
        <div className="input-group mb-3 input-field col-md-12 col-sm-12 w-100 p-0">
          <input
            placeholder="Claim no."
            className="form-control searcher"
            type="text"
            name="claimNo"
            id="simpleSearchClaimNo"
            autoComplete="off"
            // style="width: 167px"
            // className="form-control w-100 float-end"
            maxLength="20"
            value={this.props.searchState.claimNo}
            onChange={(e) => {
              this.props.submitClaimNoSearch({
                [e.target.name]: e.target.value.replace(
                  /[!@#$%^&*><":?/.,]/,
                  ""
                ),
              });
            }}
          />

          <div className="input-group-append">
            <span
              className="input-group-text fa fa-search splitter1"
              id="basic-addon1"
            ></span>
          </div>
        </div>
      </div>
    );
  }
}

export default SimpleSearch;
