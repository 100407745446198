import {
  REMOVE_EVENT_BIDDERs,
  ADD_EVENT_BIDDERs,
  FETCH_EVENT_BIDDERs,
} from "../../actions/types";
import _ from "lodash";

const eventBidderReducers = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_EVENT_BIDDERs:
      return { ..._.mapKeys(action.payload, "buyerId") };
    case ADD_EVENT_BIDDERs:
      return { ...state, ..._.mapKeys(action.payload, "buyerId") };
    case FETCH_EVENT_BIDDERs:
      return { ...state, ..._.mapKeys(action.payload, "buyerId") };
    default:
      return state;
  }
};

export default eventBidderReducers;
