import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
  fetchSubProduct,
  editSubProduct,
  deleteSubProduct,
} from "../../../actions/masters/subProductActions";
import SubProductForm from "./SubProductForm";
import { Link } from "react-router-dom";
import { fetchProducts } from "../../../actions/masters/productActions";
import history from "../../../history";

class SubProductEdit extends Component {
  componentDidMount() {
    this.props.fetchSubProduct(
      this.props.match.params.id,
      this.props.match.params.sid
    );
    this.props.fetchProducts();
  }

  onSubmit = (formValues) => {
    this.props.editSubProduct(
      this.props.match.params.id,
      this.props.match.params.sid,
      formValues
    );
  };

  onClickDelete = () => {
    this.props.deleteSubProduct(
      this.props.match.params.id,
      this.props.match.params.sid,
      this.props.subProduct
    );
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Edit Sub Product</h3>
          </div>
          <div className="float-end mt-15">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        <SubProductForm
          initialValues={_.pick(
            this.props.subProduct,
            "name",
            "activeFlag",
            "productId"
          )}
          onSubmit={this.onSubmit}
          onDelete={this.onClickDelete}
          products={this.props.products}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    subProduct: state.subProduct[ownProps.match.params.sid],
    products: Object.values(state.product),
  };
};

export default connect(mapStateToProps, {
  editSubProduct,
  fetchSubProduct,
  deleteSubProduct,
  fetchProducts,
})(SubProductEdit);
