import {
  FETCH_DEFAULTTIMEs,
  FETCH_DEFAULTTIME,
  EDIT_DEFAULTTIME,
  FETCH_CURRENT_TIME,
} from "../../actions/types";
import _ from "lodash";

const currentTimeReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CURRENT_TIME:
      return { ...state, currentTime: action.payload.currentTime };
    default:
      return state;
  }
};

export default currentTimeReducer;
