import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchRegions } from "../../../actions/masters/regionActions";

class RegionList extends Component {
  componentDidMount() {
    this.props.fetchRegions();
  }

  renderList() {
    return this.props.regions.map((regn) => {
      return (
        <tr key={regn.id}>
          <td className="pl-3 py-2" style={{ width: "500px" }}>
            <Link to={`/masters/region/${regn.id}/state`}>
              <span> {regn.name}</span>
            </Link>
          </td>
          <td className="pl-3 py-2" style={{ width: "500px" }}>
            {regn.shortName}
          </td>
          <td className="py-2">
            <Link to={`/masters/region/Edit/${regn.id}`}>
              <span>
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </span>
            </Link>
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div
        className="container"
        style={{ padding: "80px 30px", maxWidth: "800px" }}
      >
        <div className="container">
          <div className="row" style={{ paddingBottom: "5px" }}>
            <div
              className="Header-Content col-8"
              style={{ paddingTop: "5px", paddingBottom: "5px" }}
            >
              <h3 style={{ marginBottom: "0px" }}>Region Master</h3>
            </div>
            <div className="col-4" style={{ padding: "5px 15px" }}>
              <Link
                className="btnMain"
                style={{
                  padding: "7px",
                  textAlign: "center",
                  minWidth: "70px",
                }}
                to="/masters/region/create"
              >
                Add
              </Link>
            </div>
          </div>
          <div>
            <h5
              style={{
                borderTop: "1px solid #dee2e6",
                borderBottom: "2px solid #dee2e6",
                marginBottom: "0px",
                padding: "5px 0px 5px 15px",
              }}
            >
              Name
              <span style={{ paddingLeft: "33%" }}>Short Form</span>
            </h5>
          </div>
          <table className="table table-hover">
            <tbody>{this.renderList()}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    regions: Object.values(state.region),
  };
};

export default connect(mapStateToProps, { fetchRegions })(RegionList);
