import React, { Component } from "react";
import { fetchDefaultTimes } from "../../../actions/masters/DefaultTimeAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class defaultTimeList extends Component {
  componentDidMount() {
    this.props.fetchDefaultTimes();
  }

  renderList() {
    return this.props.defaultTime.map((time) => {
      return (
        <tr key={time.id}>
          <td>{time.name}</td>
          <td>{time.defaultTime}</td>
          {this.props.user.role === "Admin" ? (
            <td>
              <Link to={`/masters/defaultTime/edit/${time.id}`}>
                <span>
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </span>
              </Link>
            </td>
          ) : (
            <td></td>
          )}
        </tr>
      );
    });
  }

  render() {
    return (
      <div className="BodyInContainer defaultTimeMasterPage">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Default Time Master</h3>
          </div>
        </div>
        <div className="mainBodyCard pt-0">
          <table className="table table-hover tableStyle1">
            <thead>
              <tr>
                <td>Time Type</td>
                <td>Time</td>
                <td></td>
              </tr>
            </thead>
          </table>
          <table className="table table-hover tableStyle1">
            <tbody>{this.renderList()}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultTime: Object.values(state.defaultTime),
    user: state.login,
  };
};

export default connect(mapStateToProps, { fetchDefaultTimes })(defaultTimeList);
