import React, { Component } from "react";
import { connect } from "react-redux";
import { createProduct } from "../../../actions/masters/productActions";
import { Link } from "react-router-dom";
import ProductForm from "./ProductForm";
import history from "../../../history";

class ProductCreate extends Component {
  onSubmit = (formValues) => {
    this.props.createProduct(formValues);
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Add a Product</h3>
          </div>
          <div className="float-end mt-15">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        <ProductForm onSubmit={this.onSubmit} />
      </div>
    );
  }
}
export default connect(null, { createProduct })(ProductCreate);
