import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BuyerEmpanelmentForm from "./BuyerEmpanelmentForm";
import { createBuyerEmpanelment } from "../../actions/BuyerEmpanelment/BuyerEmpanelmentActions";

class UserEdit extends Component {
  onSubmit = (formValues) => {
    this.props.createBuyerEmpanelment(formValues);
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Buyer Empanelment Registration</h3>
          </div>
          <div className="float-end mt-15">
            <Link className="float-end homeBtn" to="/login">
              <i className="fa fa-home" aria-hidden="true"></i> Home
            </Link>
          </div>
        </div>
        {!this.props.buyerEmpanelment[0] ? (
          <BuyerEmpanelmentForm onSubmit={this.onSubmit} />
        ) : (
          <div>
            <div className="card tabCard mb-1">
              <div className="card-body">
                <h6 className="card-title clearfix">
                  <span className="float-left">
                    Buyer Empanelment Process Initiated
                    <span className="card-subtitle mb-2 text-orange">
                      {/* {this.props.buyerEmpanelment[0].eventId} */}
                    </span>
                  </span>
                </h6>
                <div className="card-text">
                  <h6 className="d-block cardLabelText">
                    Details :
                    <span className="card-subtitle mb-2 text-muted">
                      {/* {this.props.buyerEmpanelment[0].auctionDetails} */}
                    </span>
                  </h6>
                  <div className="d-block m-0 d-flex flex-row overflow-hidden cardFloatItems">
                    <span className="float-left">
                      <span className="cardLabelText">Request ID</span>
                      <span className="cardDetailsText card-subtitle mb-2 text-muted">
                        {this.props.buyerEmpanelment[0].buyerRequestId}
                      </span>
                    </span>
                    <span className="float-left">
                      <span className="cardLabelText">Name</span>
                      <span className="cardDetailsText card-subtitle mb-2 text-muted">
                        {this.props.buyerEmpanelment[0].firstName +
                          " " +
                          (this.props.buyerEmpanelment[0].middleName
                            ? this.props.buyerEmpanelment[0].middleName
                            : "") +
                          " " +
                          this.props.buyerEmpanelment[0].lastName}
                      </span>
                    </span>
                    <span className="float-left">
                      <span className="cardLabelText">Email</span>
                      <span className="cardDetailsText  card-subtitle mb-2 text-muted">
                        {this.props.buyerEmpanelment[0].email}
                      </span>
                    </span>

                    <span className="float-left">
                      <span className="cardLabelText">Mobile Number</span>
                      <span className="cardDetailsText card-subtitle mb-2 text-muted">
                        {this.props.buyerEmpanelment[0].mobileNumber}
                      </span>
                    </span>
                    <span className="float-left">
                      <span className="cardLabelText">Case Status</span>
                      <span className="cardDetailsText card-subtitle mb-2 text-muted">
                        {this.props.buyerEmpanelment[0].caseStatus}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    buyerEmpanelment: Object.values(state.buyerEmpanelment),
    empanelmentCreateSaving: state.buyerEmpanelment.isBuyerEmpanelmentSaving,
  };
};

export default connect(mapStateToProps, {
  createBuyerEmpanelment,
})(UserEdit);
