import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { resetPassword } from "../../actions/login/loginActions";
import jwt_decode from "jwt-decode";
import { Redirect } from "react-router";

class PasswordReset extends Component {
  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, meta, type }) => {
    return (
      <div className="clearfix">
        <div className="form-group">
          <h5>{label}</h5>
          <div className="input-field">
            <input
              {...input}
              type={type}
              autoComplete="off"
              placeholder={label}
              className="form-control"
            />
          </div>
          <div className="error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.resetPassword(this.props.userData.sub, formValues);
  };

  render() {
    if (this.props.userData === null) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="loginContainer rpPage forgotCenter">
        <div>
          <h3 className="loginTitle">Reset Password</h3>
          <div className="">
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
              {/* <form> */}
              <Field
                name="ext_pwd_old"
                type="password"
                component={this.renderInput}
                label="Enter Old Password"
              />
              <Field
                name="ext_pwd"
                type="password"
                component={this.renderInput}
                label="New Password"
              />
              <Field
                name="confirmPassword"
                type="password"
                component={this.renderInput}
                label="Confirm Password"
              />
              <div className="d-block">
                <button className="btnMain loginBtn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.ext_pwd_old) {
    errors.ext_pwd_old = "This Field is Required";
  }
  if (!formValues.ext_pwd) {
    errors.ext_pwd = "This Field is Required";
  }
  const re = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})"
  );
  if (formValues.ext_pwd && !re.test(formValues.ext_pwd)) {
    errors.ext_pwd =
      "Password must has at least 6 characters that include at least 1 lowercase character, 1 uppercase characters, 1 number, and 1 special character in (!@#$%^&*)')";
  }
  if (!formValues.confirmPassword) {
    errors.confirmPassword = "This Field is Required";
  }
  if (
    formValues.confirmPassword &&
    formValues.ext_pwd !== formValues.confirmPassword
  ) {
    errors.confirmPassword = "Passwords mismatch";
  }
  return errors;
};

const mapStateToProps = (state) => ({
  userData: localStorage.getItem("userToken")
    ? jwt_decode(localStorage.getItem("userToken"))
    : null,
});

const PasswordResetPage = reduxForm({
  form: "passwordResetForm",
  validate,
})(PasswordReset);

export default connect(mapStateToProps, { resetPassword })(PasswordResetPage);
