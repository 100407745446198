import AuctionPortal from "../../apis/AuctionPortal";
import history from "../../history";
import {
  CREATE_USER,
  FETCH_USERs,
  FETCH_USER,
  EDIT_USER,
  FETCH_BUYERs,
  USER_LOGOUT,
} from "../types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.minimal.css";
import { isSessionExpired, userLogout } from "../login/loginActions";
import CryptoJS from "crypto-js";
import { encryptId,decryptIndex } from "../../components/Encryption";

toast.configure({
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
});

export const fetchUsers = () => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get("/masters/GetUser", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      let k = '';
      for (let index = 0; index < 4; index++) {
        k += "8080";
      }
      
     
      
      for(const major in response.data){
        for(const key in response.data[major]){
          var value = response.data[major][key];
          try{
          if (response.data[major].hasOwnProperty(key) && value !==null && value.length>5)
          {
            while(value.includes('zxcvb'))
            value = value.replace('zxcvb','/')
            while(value.includes('plus'))
            value = value.replace('plus','+')
            if(!(value.includes('T') && value.includes(':')))
            {
            value = await CryptoJS.AES.decrypt(
              value,
              CryptoJS.enc.Utf8.parse(k),
              {
                keySize: 128 / 8,
                iv:CryptoJS.enc.Utf8.parse(k),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
              }
            ).toString(CryptoJS.enc.Utf8);
            value = value.substring(0,value.indexOf('~'));
            response.data[major][key]=value.toString();
          }
          }}
          catch(error){
           
            console.log(error);
           
           
          }
        }
       
        
      }
      debugger;
      dispatch({ type: FETCH_USERs, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const fetchGenuineBuyers = () => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get(
        "/masters/GetGenuineBuyerUsers",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      let k = '';
      for (let index = 0; index < 4; index++) {
        k += "8080";
      }
       for(const major in response.data){
        for(const key in response.data[major]){
          var value = response.data[major][key];
          try{
          if (response.data[major].hasOwnProperty(key) && value !==null && value.length>5)
          {
            while(value.includes('zxcvb'))
            value = value.replace('zxcvb','/')
            while(value.includes('plus'))
            value = value.replace('plus','+')
            if(!(value.includes('T') && value.includes(':')))
            {
            value = await CryptoJS.AES.decrypt(
              value,
              CryptoJS.enc.Utf8.parse(k),
              {
                keySize: 128 / 8,
                iv:CryptoJS.enc.Utf8.parse(k),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
              }
            ).toString(CryptoJS.enc.Utf8);
            value = value.substring(0,value.indexOf('~'));
            response.data[major][key]=value.toString();
          }
          }}
          catch(error){
           
            console.log(error);
            }
        }
        }
      dispatch({ type: FETCH_BUYERs, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const fetchBuyers = () => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get("/masters/GetBuyerUsers", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      let k = '';
      for (let index = 0; index < 4; index++) {
        k += "8080";
      }
      
     
      
      for(const major in response.data){
        for(const key in response.data[major]){
          var value = response.data[major][key];
          try{
          if (response.data[major].hasOwnProperty(key) && value !==null && value.length>5)
          {
            while(value.includes('zxcvb'))
            value = value.replace('zxcvb','/')
            while(value.includes('plus'))
            value = value.replace('plus','+')
            if(!(value.includes('T') && value.includes(':')))
            {
            value = await CryptoJS.AES.decrypt(
              value,
              CryptoJS.enc.Utf8.parse(k),
              {
                keySize: 128 / 8,
                iv:CryptoJS.enc.Utf8.parse(k),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
              }
            ).toString(CryptoJS.enc.Utf8);
            value = value.substring(0,value.indexOf('~'));
            response.data[major][key]=value.toString();
          }
          }}
          catch(error){
           
            console.log(error);
           
           
          }
        }
       
        
      }
      dispatch({ type: FETCH_BUYERs, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const fetchUser = (id) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      //Added By Mayur
      var k='';
    var d = new Date().toLocaleString();
    for (let index = 0; index <4; index++) {
      k += "8080";
    }

    var key = CryptoJS.enc.Utf8.parse(k);
    var iv = CryptoJS.enc.Utf8.parse(k);
    id = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(id+"~"+d),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    while(id.includes('/'))
    id = id.replace('/','zxcvb')
  while(id.includes('+'))
  id = id.replace('+','plus')
      //End
  document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get(`/masters/GetUser/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      var k = '';
      var d = new Date().toLocaleString();
      for (let index = 0; index <4; index++) {
      k += "8080";
      var value = "";
    }
      const decryptedData = {};
      for (const key in response.data) {
        value = response.data[key];
        if (response.data.hasOwnProperty(key)&& value != null && value.length>5)
        {
          while(value.includes('zxcvb'))
          value = value.replace('zxcvb','/')
          while(value.includes('plus'))
          value = value.replace('plus','+')
          if(!(value.includes('T') && value.includes(':'))){
          value = await CryptoJS.AES.decrypt(
            value,
            CryptoJS.enc.Utf8.parse(k),
            {
              keySize: 128 / 8,
              iv:CryptoJS.enc.Utf8.parse(k),
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          ).toString(CryptoJS.enc.Utf8);
          value = value.substring(0,value.indexOf('~'));
          response.data[key]=value.toString();
        }
      }
      }
      dispatch({ type: FETCH_USER, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const createUser = (formvalues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.post(
        "/masters/Adduser",
        {
          ...formvalues,
          User_type: "I",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: CREATE_USER, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.push("/masters/users");
      toast("Created Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const editUser = (formvalues, editBuyer) => async (dispatch) => {
  //debugger;
  if(formvalues.active_flag=="Y"){
    formvalues.active_status="A";
  }
  else{
    formvalues.active_status="I";
  }
  
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.put(
        `/masters/updateuser/${encryptId(formvalues.id)}`,
        {
          ...formvalues,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      var k = '';
      var d = new Date().toLocaleString();
      for (let index = 0; index <4; index++) {
      k += "8080";
      var value = "";
    }
      const decryptedData = {};
      debugger;
      for (const key in response.data) {
        value = response.data[key];
        if (response.data.hasOwnProperty(key)&& value != null && value.length>5)
        {
          while(value.includes('zxcvb'))
          value = value.replace('zxcvb','/')
          while(value.includes('plus'))
          value = value.replace('plus','+')
          if(!(value.includes('T') && value.includes(':'))){
          value = await CryptoJS.AES.decrypt(
            value,
            CryptoJS.enc.Utf8.parse(k),
            {
              keySize: 128 / 8,
              iv:CryptoJS.enc.Utf8.parse(k),
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          ).toString(CryptoJS.enc.Utf8);
          value = value.substring(0,value.indexOf('~'));
          response.data[key]=value.toString();
        }
      }
      }
      dispatch({ type: EDIT_USER, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      if (editBuyer === true) {
        history.push("/masters/buyers");
      } else {
        history.push("/masters/users");
      }
      toast("Edited Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};



// Thunk action creator
//Created by Ashok 1036112



export const handleAdminButtonClick =  async () => {
  try {
    const pendingEndpoint = '/eventScheduler/events/pending';
    const futureEndpoint = '/eventScheduler/events/future';
    const LiveEndpoint = '/eventScheduler/events/live';
 
    const [pendingResponse, futureResponse] = await Promise.all([
      AuctionPortal.put(LiveEndpoint, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }),
      AuctionPortal.put(futureEndpoint, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }),
      AuctionPortal.put(pendingEndpoint, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }),
    ]);
    toast("HIT Success");
  } catch (error) {
    toast.error("Error Occurred");
   
  }
};

