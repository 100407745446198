import React, { Component } from "react";
import { Link } from "react-router-dom";

class NoAuthorizationPage extends Component {
  render() {
    return (
      <div className="BodyInContainer errorPage">
        <div className="mainBodyCard pt-30 d-flex flex-column justify-content-center align-items-center">
          <h1 className="errorCode">401</h1>
          <h4 className="errorName">No authorization found.</h4>
          <h4 className="errorTextEx">
            This page is not publically available.
          </h4>
          <h4 className="errorTextEx">To access it please login first.</h4>
          <Link className="float-end returnHomeBtn mt-5 btnMain" to="/">
            Return Home
          </Link>
        </div>
      </div>
    );
  }
}

export default NoAuthorizationPage;
