import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchReportWitOutParameter,
  fetchReportWithParameter,
  fetchOfficers,
  fetchCategories,
  fetchPolicyProducts,
} from "../../actions/Reports/ievaluateReports";
import LeftMenu from "../layout/LeftMenu";
import { isSessionExpired } from "../../actions/login/loginActions";
import history from "../../history";
import Select from "react-select";
import { fetchRegions } from "../../actions/masters/regionActions";
import { fetchAllStates } from "../../actions/masters/stateActions";
import { fetchProducts } from "../../actions/masters/productActions";

class GenerateReports extends Component {
  state = {
    route: null,
    reportType: null,
    StartDate: null,
    EndDate: null,
    CategoryID: null,
    PolicyProduct: null,
    RegionId: null,
    StateId: null,
    Officer: null,
  };

  componentDidMount() {
    this.props.fetchRegions();
    this.props.fetchAllStates();
    this.props.fetchProducts();
    this.props.fetchOfficers();
    this.props.fetchCategories();
    this.props.fetchPolicyProducts();
  }

  onSubmit = () => {
    if (this.state.reportType < 5) {
      if (this.state.EndDate) {
        this.props.fetchReportWithParameter(this.state);
      } else {
        this.props.fetchReportWithParameter({
          ...this.state,
          EndDate: new Date().toJSON(),
        });
      }
    } else {
      this.props.fetchReportWitOutParameter(this.state.route);
    }
  };

  render() {
    if (isSessionExpired()) {
      history.push("/login");
      return null;
    } else {
      return (
        <div className="BodyInContainer homepage">
          <div className="mainBodyCard pt-30 overflow-hidden position-relative d-flex">
            {this.props.user.role !== "Pending Buyer" ? (
              <LeftMenu activeRoute="getReports" />
            ) : null}

            <div className="rightTabBody position-relative">
              <div className="mainBodyCard pt-30 addParametersPage">
                <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
                  <label className="d-block">Report type</label>
                  <div className="">
                    <Select
                      name="Report Type"
                      id="reportType"
                      placeholder="Report Type"
                      className="w-100"
                      onChange={(e) => {
                        this.setState({
                          reportType: e.id,
                          route: e.route,
                          StartDate: null,
                          EndDate: null,
                          CategoryID: null,
                          PolicyProduct: null,
                          RegionId: null,
                          StateId: null,
                          Officer: null,
                        });
                        if (document.getElementById("reportStartDate"))
                          document.getElementById("reportStartDate").value = "";
                        if (document.getElementById("reportEndDate"))
                          document.getElementById("reportEndDate").value = "";
                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onBlurResetsInput={false}
                      onCloseResetsInput={false}
                      options={[
                        {
                          name: "Daily Online Valuation",
                          id: 1,
                          route: "DOVR",
                        },
                        {
                          name: "StateWise / ManagerWise - Bid / NoBid",
                          id: 2,
                          route: "ManagerReport",
                        },
                        {
                          name: "Buyer Activity Report",
                          id: 3,
                          route: "BuyerActivityReport",
                        },
                        {
                          name: "Buyer Performance Report",
                          id: 4,
                          route: "BuyerPerformanceReport",
                        },
                        {
                          name: "Buyer Status Report",
                          id: 5,
                          route: "BuyerStatusReport",
                        },
                        {
                          name: "Buyer Participation / Non-Participation",
                          id: 6,
                          route: "BuyerParticipationReport",
                        },
                        {
                          name: "Buyer EMD Report",
                          id: 7,
                          route: "BuyerEMDReport",
                        },
                      ]}
                      isSearchable={true}
                      noOptionsMessage={() => null}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,

                          primary25: "#ffa552",
                          primary50: "#fabc82",
                          primary: "#fcb26d",
                        },
                      })}
                    />
                  </div>
                </div>

                {this.state.reportType && this.state.reportType < 5 && (
                  <>
                    {this.state.reportType < 4 && (
                      <>
                        <h5 className="mb-3">Date Range</h5>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 eventCreateInputCol">
                            <label className="d-block">Start Date</label>
                            <div className="input-field">
                              <input
                                type="date"
                                autoComplete="off"
                                onChange={(e) =>
                                  this.setState({ StartDate: e.target.value })
                                }
                                placeholder="Start Date"
                                id="reportStartDate"
                                className="form-control"
                                max={new Date().toISOString().split("T")[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 eventCreateInputCol">
                            <label className="d-block">End Date</label>
                            <div className="input-field">
                              <input
                                type="date"
                                autoComplete="off"
                                id="reportEndDate"
                                placeholder="End Date"
                                onChange={(e) =>
                                  this.setState({ EndDate: e.target.value })
                                }
                                className="form-control"
                                min={this.state.StartDate}
                                max={new Date().toISOString().split("T")[0]}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="row">
                      {(this.state.reportType === 2 ||
                        this.state.reportType === 3) && (
                        <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
                          <label className="d-block">Category</label>
                          <div className="">
                            <Select
                              name="Category"
                              id="reportCategoryId"
                              placeholder="Category"
                              className="w-100"
                              onChange={(e) => {
                                this.setState({ CategoryID: e.category });
                              }}
                              getOptionLabel={(option) =>
                                this.props.product.filter(
                                  (pr) => pr.id === option.category
                                )[0].name
                              }
                              value={
                                this.state.CategoryID
                                  ? this.props.categories.find(
                                      (obj) =>
                                        obj.category === this.state.CategoryID
                                    )
                                  : null
                              }
                              getOptionValue={(option) =>
                                this.props.product.filter(
                                  (pr) => pr.id === option.category
                                )[0].name
                              }
                              onBlurResetsInput={false}
                              onCloseResetsInput={false}
                              options={this.props.categories}
                              isSearchable={true}
                              noOptionsMessage={() => null}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,

                                  primary25: "#ffa552",
                                  primary50: "#fabc82",
                                  primary: "#fcb26d",
                                },
                              })}
                            />
                          </div>
                        </div>
                      )}
                      {this.state.reportType === 3 && this.state.CategoryID && (
                        <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
                          <label className="d-block">Policy Product</label>
                          <div className="">
                            <Select
                              name="Policy Product"
                              id="reportPolicyProduct"
                              placeholder="Policy Product"
                              className="w-100"
                              onChange={(e) => {
                                this.setState({
                                  PolicyProduct: e.policyproduct,
                                });
                              }}
                              value={
                                this.state.PolicyProduct
                                  ? this.props.policyproducts.find(
                                      (obj) =>
                                        obj.policyproduct ===
                                        this.state.PolicyProduct
                                    )
                                  : null
                              }
                              getOptionLabel={(option) => option.policyproduct}
                              getOptionValue={(option) => option.policyproduct}
                              onBlurResetsInput={false}
                              onCloseResetsInput={false}
                              options={this.props.policyproducts}
                              isSearchable={true}
                              noOptionsMessage={() => null}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,

                                  primary25: "#ffa552",
                                  primary50: "#fabc82",
                                  primary: "#fcb26d",
                                },
                              })}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {this.state.reportType === 4 && (
                      <>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 eventCreateInputCol">
                            <label className="d-block">Select Region</label>
                            <Select
                              name="Region"
                              id="reportRegionId"
                              placeholder="Region"
                              className="w-100"
                              onChange={(e) => {
                                this.setState({ RegionId: e.id });
                              }}
                              value={
                                this.state.RegionId
                                  ? this.props.region.find(
                                      (obj) => obj.id === this.state.RegionId
                                    )
                                  : null
                              }
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              onBlurResetsInput={false}
                              onCloseResetsInput={false}
                              options={this.props.region}
                              isSearchable={true}
                              noOptionsMessage={() => null}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,

                                  primary25: "#ffa552",
                                  primary50: "#fabc82",
                                  primary: "#fcb26d",
                                },
                              })}
                            />
                          </div>
                          {this.state.RegionId && (
                            <div className="col-md-6 col-sm-12 eventCreateInputCol">
                              <label className="d-block">Select State</label>
                              <Select
                                name="State"
                                id="reportStateId"
                                placeholder="State"
                                className="w-100"
                                onChange={(e) => {
                                  this.setState({ StateId: e.id });
                                }}
                                value={
                                  this.state.StateId
                                    ? this.props.state.find(
                                        (obj) => obj.id === this.state.StateId
                                      )
                                    : null
                                }
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onBlurResetsInput={false}
                                onCloseResetsInput={false}
                                options={this.props.state.filter(
                                  (st) => st.regionID === this.state.RegionId
                                )}
                                isSearchable={true}
                                noOptionsMessage={() => null}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,

                                    primary25: "#ffa552",
                                    primary50: "#fabc82",
                                    primary: "#fcb26d",
                                  },
                                })}
                              />
                            </div>
                          )}
                          <div className="col-md-6 col-sm-12 eventCreateInputCol">
                            <label className="d-block">Officer</label>
                            <div className="">
                              <Select
                                name="uniqueOfficers"
                                id="reportUniqueOfficers"
                                placeholder="Officers"
                                className="w-100"
                                onChange={(e) => {
                                  this.setState({
                                    Officer: e.officer,
                                  });
                                }}
                                value={
                                  this.state.Officer
                                    ? this.props.officers.find(
                                        (obj) =>
                                          obj.officer === this.state.Officer
                                      )
                                    : null
                                }
                                getOptionLabel={(option) => option.officer}
                                getOptionValue={(option) => option.officer}
                                onBlurResetsInput={false}
                                onCloseResetsInput={false}
                                options={this.props.officers}
                                isSearchable={true}
                                noOptionsMessage={() => null}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,

                                    primary25: "#ffa552",
                                    primary50: "#fabc82",
                                    primary: "#fcb26d",
                                  },
                                })}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {this.state.reportType && (
                  <button className="btnMain" onClick={this.onSubmit}>
                    Download Report
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.login,
    product: Object.values(state.product),
    region: Object.values(state.region),
    state: Object.values(state.state),
    officers: state.reports.officers,
    categories: state.reports.categories,
    policyproducts: state.reports.policyproducts,
  };
};

export default connect(mapStateToProps, {
  fetchReportWitOutParameter,
  fetchReportWithParameter,
  fetchRegions,
  fetchAllStates,
  fetchProducts,
  fetchOfficers,
  fetchCategories,
  fetchPolicyProducts,
})(GenerateReports);
