import AuctionPortal from "../../apis/AuctionPortal";
import history from "../../history";
import {
  CREATE_EVENT,
  EDIT_EVENT,
  FETCH_EVENT,
  FETCH_EVENTs,
  FETCH_BUYER_EVENTS,
  BID_EVENT,
  APPROVE_EVENT,
  FETCH_BUYER_EVENTMAPPING,
  USER_LOGOUT,
  ACCEPT_TERMS_AND_CONDITIONS,
 
} from "../types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isSessionExpired, userLogout } from "../login/loginActions";
import { encryptId,decryptIndex } from "../../components/Encryption";

export const createEvent = (formValues) => async (dispatch) => {
  try {
    document.getElementById("spinner").classList.add("spinnerDisplay");
    const response = await AuctionPortal.post(
      "/event",
      {
        ...formValues,
        originatedFrom: "AP",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    dispatch({ type: CREATE_EVENT, payload: response.data });

    document.getElementById("spinner").classList.remove("spinnerDisplay");
    history.push("/");
    toast(`Event Created Successfully with event ID: ${response.data.eventId}`);
  } catch (error) {
    document.getElementById("spinner").classList.remove("spinnerDisplay");
    switch (error.response.status) {
      case 408:
        history.push("/sessionExpired");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        if (error.response.data.errors) {
          alert(JSON.stringify(error.response.data.errors));
        } else {
          toast.error(error.response.data.Description[0]);
        }
        break;
      default:
        alert(error);
    }
  }
};

export const fetchEvent = (id) => async (dispatch) => {
  try {
    // document.getElementById("spinner").classList.add("spinnerDisplay");
    //debugger;
    const response = await AuctionPortal.get(`/event/${encryptId(id)}/AuctionDetails`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    //debugger;
    dispatch({ type: FETCH_EVENT, payload: response.data });
    // document.getElementById("spinner").classList.remove("spinnerDisplay");
  } catch (error) {
    // document.getElementById("spinner").classList.remove("spinnerDisplay");
    switch (error.response.status) {
      case 408:
        history.push("/sessionExpired");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        history.push("/BadRequest");
        break;
      default:
        alert(error);
    }
  }
};

export const fetchEvents = () => async (dispatch) => {
  //debugger;
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      // document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get("/event", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      dispatch({ type: FETCH_EVENTs, payload: response.data });
      // document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      // document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          alert(error);
      }
    }
  }
};

export const fetchEventsByStatus = (status) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      // document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get(`/event/status/${status}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      dispatch({ type: FETCH_EVENTs, payload: response.data });
      // document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      // document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          alert(error);
      }
    }
  }
};

export const fetchBuyerEvents = (id) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      // document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get(`/event/buyerId/${encryptId(id)}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      dispatch({ type: FETCH_BUYER_EVENTS, payload: response.data });
      // document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      // document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          alert(error);
      }
    }
  }
};

export const fetchBuyerEventsByStatus = (status, id) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      // document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get(
        `/event/buyerId/${encryptId(id)}/${status}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: FETCH_BUYER_EVENTS, payload: response.data });
      // document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      // document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response?.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          alert(error);
      }
    }
  }
};

export const fetchBuyerEventMapping = (id) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    // document.getElementById("spinner").classList.add("spinnerDisplay");
    try {
      const response = await AuctionPortal.get(
        `/event/eventsmapping/UserId/${encryptId(id)}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: FETCH_BUYER_EVENTMAPPING, payload: response.data });
      // document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      // document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          alert(error);
      }
    }
  }
};

export const bidEvent = (id, buyerId, amount) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      // document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.post(
        `/event/${encryptId(id)}/placebid/${encryptId(buyerId)}`,
        {
          BidAmount: amount,
          Agreement1: "Agreed",
          Agreement2: "Agreed",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: BID_EVENT, payload: response.data });
      // document.getElementById("spinner").classList.remove("spinnerDisplay");
      toast("Bid Placed Successfully");
    } catch (error) {
      // document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          alert(error);
      }
    }
  }
};

export const fetchWordsFromMumbers = (number) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    const response = await AuctionPortal.get(`/convertNumberToWord/${number}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data;
  }
};

export const editEvent = (id, formValues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.put(
        `/event/${encryptId(id)}`,
        {
          ...formValues,
          // originatedFrom: "AP",
          activeFlag: "Y",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: EDIT_EVENT, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.goBack();
      toast("Edited Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          alert(error);
      }
    }
  }
};

export const approveEvent = (id, value) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      var Remarks;
      if (value === "R") {
        Remarks = prompt("Add Rejection Remarks ");
      } else {
        Remarks = "Approved";
      }
      const response = await AuctionPortal.put(
        `/event/${encryptId(id)}/AdminApproval`,
        {
          AdminApproval: value,
          Remarks: Remarks,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      document.getElementById(`EventCard_${id}`).classList.remove("cardFadeIn");
      document.getElementById(`EventCard_${id}`).classList.add("cardFadeOut");

      setTimeout(function () {
        dispatch({ type: APPROVE_EVENT, payload: response.data });
      }, 500);

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      if (value === "A") {
        toast("Event Approved");
      } else {
        toast("Event Rejected");
      }
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          alert(error);
      }
    }
  }
};

export const acceptTermsAndConditions = (auctionId) => async (dispatch) => {
  try {
    document.getElementById("spinner").classList.add("spinnerDisplay");
    const response = await AuctionPortal.post(
      `/event/buyerAgreement/${encryptId(auctionId)}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    dispatch({ type: ACCEPT_TERMS_AND_CONDITIONS, payload: response.data });
    document.getElementById("spinner").classList.remove("spinnerDisplay");
  } catch (error) {
    document.getElementById("spinner").classList.remove("spinnerDisplay");
    switch (error.response.status) {
      case 408:
        history.push("/sessionExpired");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        alert(JSON.stringify(error.response.data.errors));
        break;
      default:
        alert(error);
    }
  }
};

export const cancelLiveEvent = (id) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      var Remarks = prompt("Add Cancellation Remarks ");

      const response = await AuctionPortal.put(
        `/event/${encryptId(id)}/cancellive`,
        {
          Remarks: Remarks,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: EDIT_EVENT, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.goBack();
      toast("Live Event Cancelled Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          alert(error);
      }
    }
  }
};
