import { FETCH_BUYER_RMC } from "../../actions/types";
import _ from "lodash";

const buyerReducers = (state = {}, action) => {
  switch (action.type) {
    case FETCH_BUYER_RMC:
      return { ...state, [action.payload.id]: action.payload };

    default:
      return state;
  }
};

export default buyerReducers;
