import React, { Component } from "react";
import { connect } from "react-redux";
import {
  editEventParameter,
  fetchEventParameter,
  editEventLiveParameter,
} from "../../../actions/event/EventParameterActions";
import EventParameterForm from "./EventParameterForm";
import { Link } from "react-router-dom";
import { fetchDefaultTimes } from "../../../actions/masters/DefaultTimeAction";
import history from "../../../history";
import { fetchEvent } from "../../../actions/event/EventActions";
import EventParameterLiveForm from "./EventParameterLiveForm";

class EventParameterCreate extends Component {
  componentDidMount() {
    this.props.fetchDefaultTimes();
    this.props.fetchEventParameter(this.props.match.params.eid);
    this.props.fetchEvent(this.props.match.params.eid);
  }
  onSubmit = (formValues) => {
    const startTime = this.props.defaultStartTime;
    const endTime = this.props.defaultEndTime;
    formValues.startDateTime = `${formValues.startDate}T${formValues.startTime}`;
    formValues.endDateTime = `${formValues.endDate}T${formValues.endTime}`;
    const { eid } = this.props.match.params;
    this.props.editEventParameter(eid, formValues);
  };

  onLiveSubmit = (formValues) => {
    // const startTime = this.props.defaultStartTime;
    // const endTime = this.props.defaultEndTime;
    // formValues.startDateTime = `${formValues.startDate}T${startTime}`;
    formValues.endDateTime = `${formValues.endDate}T${formValues.endTime}`;
    const { eid } = this.props.match.params;
    this.props.editEventLiveParameter(eid, formValues);
  };

  initialParameter = () => {
    const para = this.props.eventParameters;

    if (para.startDateTime) {
      para.startDate = para.startDateTime.split("T")[0];
      para.startTime = para.startDateTime.split("T")[1];
    }
    if (para.endDateTime) {
      para.endDate = para.endDateTime.split("T")[0];
      para.endTime = para.endDateTime.split("T")[1];
    }
    return para;
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Edit Parameters</h3>
          </div>
          <div className="float-end mt-15 ">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        {this.props.event[0] && this.props.event[0].status === "PENDING" ? (
          <EventParameterForm
            onSubmit={this.onSubmit}
            defaultStartTime={this.props.defaultStartTime}
            defaultEndTime={this.props.defaultEndTime}
            initialValues={this.initialParameter()}
            edit
          />
        ) : this.props.event[0] && this.props.event[0].status === "LIVE" ? (
          <EventParameterLiveForm
            onSubmit={this.onLiveSubmit}
            initialValues={this.initialParameter()}
            eventid={this.props.match.params.eid}
          />
        ) : (
          this.props.event[0] && (
            <div className="mainBodyCard pt-30 d-flex flex-column justify-content-center align-items-center">
              <h4 className="errorName">{`Event is in ${
                this.props.event[0] ? this.props.event[0].status : ""
              } stage`}</h4>
              <h4 className="errorTextEx">
                You can only modify/change event parametes of events which are
                in <b>PENDING</b> stage
              </h4>
              <Link
                className="float-end returnHomeBtn mt-5 btnMain"
                onClick={() => {
                  history.goBack();
                }}
              >
                Go Back
              </Link>
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    defaultStartTime:
      state.defaultTime[2] && state.defaultTime[2].defaultTime
        ? state.defaultTime[2].defaultTime
        : null,
    defaultEndTime:
      state.defaultTime[3] && state.defaultTime[3].defaultTime
        ? state.defaultTime[3].defaultTime
        : null,
    eventParameters: state.eventParameter,
    event: Object.values(state.event).filter(
      (e) => e.id === parseInt(ownProps.match.params.eid)
    ),
  };
};

export default connect(mapStateToProps, {
  editEventParameter,
  editEventLiveParameter,
  fetchEventParameter,
  fetchDefaultTimes,
  fetchEvent,
})(EventParameterCreate);
