import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOGOUT,
  USER_LOAD,
  USER_TOKEN_REFRESH,
} from "../../actions/types";
import jwt_decode from "jwt-decode";

const loginReducers = (state = {}, action) => {
  switch (action.type) {
    case USER_TOKEN_REFRESH:
      localStorage.clear();
    case LOGIN_SUCCESS:
      localStorage.setItem("userToken", action.payload);
      const tokendata = jwt_decode(action.payload);
      if (tokendata.role)
        return {
          ...state,
          userToken: action.payload,
          loggedIn: true,
          username: tokendata.username,
          user_id: tokendata.sub,
          role: tokendata.role,
          region: tokendata.region,
        };
      break;
    case USER_LOAD:
      const stokendata = jwt_decode(action.payload);
      return {
        ...state,
        userToken: action.payload,
        loggedIn: true,
        username: stokendata.username,
        user_id: stokendata.sub,
        role: stokendata.role,
        region: stokendata.region,
      };

    case LOGIN_FAIL:
      return { ...state };

    case USER_LOGOUT:
      localStorage.removeItem("userToken");
      return {
        ...state,
        userToken: null,
        loggedIn: null,
        username: null,
        user_id: null,
        role: null,
        region: null,
      };

    default:
      return state;
  }
};

export default loginReducers;
