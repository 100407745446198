import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchDefaultTime,
  editDefaultTime,
} from "../../../actions/masters/DefaultTimeAction";
import { Field, reduxForm } from "redux-form";
import history from "../../../history";

class defaultTimeEdit extends Component {
  componentDidMount() {
    this.props.fetchDefaultTime(this.props.match.params.id);
  }

  onSubmit = (formValues) => {
    if (formValues.time === this.props.defaultTime.defaultTime) {
      formValues.time =
        formValues.time.split(":")[0] + ":" + formValues.time.split(":")[1];
    }

    const consolidatedValues = {
      defaultTime: `${formValues.time}:00`,
      activeFlag: "Y",
    };
    this.props.editDefaultTime(this.props.match.params.id, consolidatedValues);
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderTimeInput = ({ input, meta, type }) => {
    return (
      <div>
        <div className="text-center">
          <div className="input-group clockpicker">
            <input
              {...input}
              autoComplete="off"
              type={type}
              value={input.value}
              className="form-control"
            />

            <span className="input-group-addon">
              <span className="glyphicon glyphicon-time"></span>
            </span>
          </div>

          <div>{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">
              Edit{" "}
              {this.props.defaultTime === undefined
                ? ""
                : this.props.defaultTime.name}
            </h3>
          </div>
          <div className="float-end mt-15">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        <div className="mainBodyCard pt-0 centerAlignCard">
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <Field name="time" component={this.renderTimeInput} type="time" />
            <div className="mainBodyCardFtBtn mt-5">
              <button className="btnMain">Submit</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const initialValues = {};
  if (state.defaultTime[ownProps.match.params.id] !== undefined) {
    initialValues.time =
      state.defaultTime[ownProps.match.params.id].defaultTime;
  }
  return {
    defaultTime: state.defaultTime[ownProps.match.params.id],
    initialValues,
  };
};

const validate = (formValues) => {
  const error = {};
  if (parseInt(formValues.HH) >= 24 || parseInt(formValues.HH) < 0) {
    error.HH = "Hours cannot be greater than 24 or less than 0";
  }
  if (parseInt(formValues.MM) >= 60 || parseInt(formValues.MM) < 0) {
    error.MM = "Minutes cannot be greater than 24 or less than 0";
  }
  if (parseInt(formValues.SS) >= 60 || parseInt(formValues.SS) < 0) {
    error.SS = "Seconds cannot be greater than 24 or less than 0";
  }

  return error;
};

const defaultTime = reduxForm({
  form: "defaultTime",
  validate,
  enableReinitialize: true,
})(defaultTimeEdit);

export default connect(mapStateToProps, { fetchDefaultTime, editDefaultTime })(
  defaultTime
);
