import {
  FETCH_DEFAULTTIMEs,
  FETCH_DEFAULTTIME,
  EDIT_DEFAULTTIME,
} from "../../actions/types";
import _ from "lodash";

const defaultTimeReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DEFAULTTIMEs:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case FETCH_DEFAULTTIME:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_DEFAULTTIME:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};

export default defaultTimeReducer;
