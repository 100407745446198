import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchUser, editUser } from "../../../actions/masters/userActions";
import BuyerRMCForm from "./BuyerRMCForm";
import jwt_decode from "jwt-decode";
import {
  fetchBuyer,
  editBuyerRMC,
} from "../../../actions/masters/buyerActions";
import _ from "lodash";

class BuyerRMCEdit extends Component {
  componentDidMount() {
    this.props.fetchUser(this.props.match.params.id);
    this.props.fetchBuyer(this.props.match.params.id);
  }

  onSubmit = (formValues) => {
    if (
      this.props.tokendata.role === "Admin" ||
      this.props.tokendata.role === "Buyer"
    ) {
      const formValuesForBuyerMaster = _.pick(
        formValues,
        "active_flag",
        "active_status",
        "contact_no",
        "email_id",
        "mobile_no",
        "role_id",
        "user_id",
        "user_name",
        "buyerEmpanelmentId",
        "buyerRequestId",
        "carBikeNumber",
        "currentAccountNumber",
        "drivingLicenseNumber",
        "fatherName",
        "faxNumber",
        "fraudTypeId",
        "mobileNumber",
        "officeAddress",
        "officeTelephone",
        "panNumber",
        "passportNumber",
        "permanentAddress",
        "permanentCityId",
        "permanentRegionId",
        "permanentNearestLandmark",
        "permanentOwnedOrRental",
        "permanentPincode",
        "permanentStateId",
        "permanentStayingSince",
        "permanentTelephoneNumber",
        "photographPath",
        "presentAddress",
        "presentRegionId",
        "presentCityId",
        "presentNearestLandmark",
        "presentOwnedOrRental",
        "presentPincode",
        "presentStateId",
        "presentStayingSince",
        "presentTelephoneNumber",
        "residenceTelephone",
        "savingBankAccountNumber"
      );
      formValuesForBuyerMaster.FirstName = formValues.user_name;
      formValuesForBuyerMaster.LastName = formValues.lastName;
      formValuesForBuyerMaster.email = formValues.email;

      //this.props.editUser(formValuesForUserMaster, true);
      this.props.editBuyerRMC(
        // formValuesForBuyerMaster.id,
        formValuesForBuyerMaster
      );
    }
  };

  getInitialValue = () => {
    let initValue = {
      ...this.props.userMaster[0],
      ...this.props.buyerMaster[0],
    };
    if (initValue.presentStayingSince) {
      initValue.presentStayingSince =
        initValue.presentStayingSince.split("T")[0];
    }
    if (initValue.permanentStayingSince) {
      initValue.permanentStayingSince =
        initValue.permanentStayingSince.split("T")[0];
    }
    if (this.props.buyerMaster[0]) {
      initValue.buyerMasterId = this.props.buyerMaster[0].id;
    }
    if (this.props.userMaster[0]) {
      initValue.userMasterId = this.props.userMaster[0].id;
    }
    return initValue;
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Modify Buyer Request</h3>
          </div>
          <div className="float-end mt-15">
            <Link className="float-end mt-15 cardBackBtn" to="/Home">
              Home
            </Link>
          </div>
        </div>

        <BuyerRMCForm
          onSubmit={this.onSubmit}
          initialValues={this.getInitialValue()}
          EditUser={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userMaster: Object.values(state.userMaster).filter(
      (u) => u.user_id === ownProps.match.params.id
    ),
    tokendata: localStorage.getItem("userToken")
      ? jwt_decode(localStorage.getItem("userToken"))
      : null,
    buyerMaster: Object.values(state.buyer).filter(
      (u) => u.buyerRequestId === ownProps.match.params.id
    ),
  };
};
export default connect(mapStateToProps, {
  fetchUser,
  editUser,
  fetchBuyer,
  editBuyerRMC,
})(BuyerRMCEdit);
