import React, { Component } from "react";
import { Link } from "react-router-dom";

class BuyerRmcSearch extends Component {
  render() {
    return (
      <div id="accordion" className="accordion leftLinksAccordion">
        <div className="card border-0 bg-transparent">
          <div
            className="card-header collapsed bg-transparent border-0 p-0 w-100"
            data-toggle="collapse"
            href="#aSearch"
            onClick={this.props.toggleSearch}
          >
            <Link className="card-title">Search Buyer</Link>
          </div>
          <div
            id="aSearch"
            className="card-body pl-0 pr-0 collapse filterCardsContainer"
            data-parent="#accordion"
          >
            <div className="col-md-12 col-sm-12 eventCreateInputCol mb-3 pl-0 pr-0">
              <div className="input-field w-100">
                <input
                  type="text"
                  name="buyerName"
                  id="BuyerRmcSearchBuyerName"
                  placeholder="Buyer Name"
                  className="form-control w-100"
                  onChange={(e) => {
                    this.props.submitBuyerNameSearch({
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 eventCreateInputCol mb-3 pl-0 pr-0">
              <div className="input-field w-100">
                <input
                  type="text"
                  name="requestId"
                  id="BuyerRmcSearchRequestId"
                  placeholder="Request ID"
                  className="form-control w-100"
                  onChange={(e) => {
                    this.props.submitRequestIdSearch({
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 eventCreateInputCol mb-3 pl-0 pr-0">
              <div className="input-field w-100">
                <input
                  type="text"
                  name="emailId"
                  id="BuyerRmcSearchEmailID"
                  placeholder="Email ID"
                  className="form-control w-100"
                  onChange={(e) => {
                    this.props.submitEmailIdSearch({
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BuyerRmcSearch;
