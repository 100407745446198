import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchEventMaster } from "../../../actions/masters/EventMasterActions";
import { Link } from "react-router-dom";
import Spinner from "../../Spinner";

class EventMasterView extends Component {
  componentDidMount() {
    this.props.fetchEventMaster();
    window.scrollTo(0, 0);
  }

  renderValues = () => {
    return this.props.eventMaster.map((em) => {
      return (
        <tbody key={em.id}>
          <tr>
            <th>Auction Base Currency</th>
            <td>{em.auctionBaseCurrency}</td>
          </tr>
          <tr>
            <th>Auction Variant</th>
            <td>{em.auctionVariant}</td>
          </tr>
          <tr>
            <th>Auction Type</th>
            <td>{em.auctionType}</td>
          </tr>
          <tr>
            <th>Bidding Price Increment In</th>
            <td>{em.biddingPriceIncrementIn}</td>
          </tr>
          <tr>
            <th>Increment Decrement In Multiples</th>
            <td>{em.incrementDecrementInMultiples ? "True" : "False"}</td>
          </tr>
          <tr>
            <th>Bid Submission For</th>
            <td>{em.bidSubmissionFor}</td>
          </tr>
          <tr>
            <th>Reserve Price Check Required</th>
            <td>{em.reservePriceCheckRequired ? "True" : "False"}</td>
          </tr>
          <tr>
            <th>Item Selection Required</th>
            <td>{em.itemSelectionRequired ? "True" : "False"}</td>
          </tr>
          <tr>
            <th>Line Item Wise Bid Submission Time Allowed</th>
            <td>
              {em.lineItemWiseBidSubmissionTimeAllowed ? "True" : "False"}
            </td>
          </tr>
          <tr>
            <th>Bid Submission Mode</th>
            <td>{em.bidSubmissionMode}</td>
          </tr>
          <tr>
            <th>Auto Bid Allowed</th>
            <td>{em.autoBidAllowed ? "True" : "False"}</td>
          </tr>
          <tr>
            <th>Allow Mobile Bidding</th>
            <td>{em.allowMobileBidding ? "True" : "False"}</td>
          </tr>
          <tr>
            <th>Bidding Form</th>
            <td>{em.biddingForm}</td>
          </tr>
          <tr>
            <th>Allowed Auto Extension</th>
            <td>{em.allowedAutoExtension ? "True" : "False"}</td>
          </tr>
          <tr>
            <th>Auto Extension Mode</th>
            <td>{em.autoExtensionMode}</td>
          </tr>
          <tr>
            <th>First Bid Acceptance Condition</th>
            <td>{em.firstBidAcceptanceCondition}</td>
          </tr>
          <tr>
            <th>Display L1_H1 Amount</th>
            <td>{em.displayL1_H1Amount ? "True" : "False"}</td>
          </tr>
          <tr>
            <th>Demo Auction</th>
            <td>{em.demoAuction ? "True" : "False"}</td>
          </tr>
          <tr>
            <th>Display IP Address</th>
            <td>{em.displayIPAddress ? "True" : "False"}</td>
          </tr>
          <tr>
            <th>Display Price Bid Break Up Link</th>
            <td>{em.displayPriceBidBreakUpLink ? "True" : "False"}</td>
          </tr>
          <tr>
            <th>Hide Live Bidding To Department User</th>
            <td>{em.hideLiveBiddingToDepartmentUser ? "True" : "False"}</td>
          </tr>
        </tbody>
      );
    });
  };

  render() {
    return (
      <div>
        {this.props.eventMaster.length > 0 ? (
          <div className="BodyInContainer">
            <div className="d-block clearfix">
              <div className="Header-Content float-start">
                <h3 className="pageMainTitle">Event Master</h3>
              </div>
              {this.props.user.role === "Admin" ? (
                <div className="float-end mt-15">
                  <Link className="btnMain" to="/masters/EventMaster/edit">
                    Edit
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="mainBodyCard pt-0">
              <table className="table table-hover tableStyle1 eventMasterTableHeader">
                <thead>
                  <tr>
                    <td>Field</td>
                    <td className="">Value</td>
                  </tr>
                </thead>
              </table>
              <table className="table table-hover tableStyle1 eventMasterTable">
                {this.renderValues()}
              </table>
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    eventMaster: Object.values(state.eventMaster),
    user: state.login,
  };
};
export default connect(mapStateToProps, { fetchEventMaster })(EventMasterView);
