import _ from "lodash";

import {
  FETCH_FRAUD,
  FETCH_FRAUDs,
  DELETE_FRAUD,
  CREATE_FRAUD,
  EDIT_FRAUD,
} from "../../actions/types";

const fraudReducers = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FRAUDs:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case FETCH_FRAUD:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_FRAUD:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_FRAUD:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_FRAUD:
      return _.omit(state, action.payload);

    default:
      return state;
  }
};

export default fraudReducers;
