import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchUser, editUser } from "../../../actions/masters/userActions";
import Spinner from "../../Spinner";
import UserForm from "./UserForm";
import { fetchRoles } from "../../../actions/masters/roleActions";
import history from "../../../history";
import jwt_decode from "jwt-decode";
import { decryptId } from "../../Decryption";
class UserEdit extends Component {
  componentDidMount() {
    this.props.fetchUser(decryptId(this.props.match.params.id));
    this.props.fetchRoles();
  }
  onSubmit = (formValues) => {
    this.props.editUser(formValues);
  };

  getInitialValue = () => {
    let initValue = {
      ...this.props.userMaster[0],
    };
    if (this.props.userMaster[0]) {
      initValue.role_id = this.props.userMaster[0].role_id;
    }
    return initValue;
  };

  render() {
    //Added By Mayur
    try{
      const { sub, role } = jwt_decode(localStorage.getItem("userToken"));    
      if(role !== "Admin"){
        history.push("/acessforbidden");
      }}
      catch{
        history.push("/acessforbidden");
      }
  
    //End
    return (
      <div>
        {this.props.userMaster.length > 0 ? (
          <div className="BodyInContainer">
            <div className="d-block clearfix">
              <div className="Header-Content float-start">
                <h3 className="pageMainTitle">Edit User</h3>
              </div>
              <div className="float-end mt-15">
                <Link
                  className="float-end cardBackBtn"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </Link>
              </div>
            </div>

            <div className="mainBodyCard pt-30">
              <UserForm
                onSubmit={this.onSubmit}
                initialValues={this.getInitialValue()}
                roles={this.props.roles.filter(
                  (role) =>
                    parseInt(role.role_Id) !== 5 && parseInt(role.role_Id) !== 7
                )}
                EditUser={true}
              />
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userMaster: Object.values(state.userMaster).filter(
      (u) => u.user_id === decryptId(ownProps.match.params.id)
    ),

    roles: Object.values(state.role),
  };
};
export default connect(mapStateToProps, { fetchUser, editUser, fetchRoles })(
  UserEdit
);
