import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
  fetchCity,
  editCity,
  deleteCity,
} from "../../../actions/masters/cityActions";
import CityForm from "./CityForm";
import { Link } from "react-router-dom";
import { fetchAllStates } from "../../../actions/masters/stateActions";
import history from "../../../history";

class CityEdit extends Component {
  componentDidMount() {
    this.props.fetchCity(
      this.props.match.params.sid,
      this.props.match.params.cid
    );
    this.props.fetchAllStates();
  }

  onSubmit = (formValues) => {
    this.props.editCity(
      this.props.match.params.id,
      this.props.match.params.sid,
      this.props.match.params.cid,
      formValues
    );
  };

  onClickDelete = () => {
    this.props.deleteCity(
      this.props.match.params.id,
      this.props.match.params.sid,
      this.props.match.params.cid,
      this.props.cities
    );
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Edit City</h3>
          </div>
          <div className="float-end mt-15">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        <CityForm
          initialValues={_.pick(
            this.props.cities,
            "name",
            "activeFlag",
            "stateId"
          )}
          onSubmit={this.onSubmit}
          states={this.props.states}
          onDelete={this.onClickDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    cities: state.city[ownProps.match.params.cid],
    states: Object.values(state.state),
  };
};

export default connect(mapStateToProps, {
  editCity,
  fetchCity,
  deleteCity,
  fetchAllStates,
})(CityEdit);
