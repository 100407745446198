import React, { Component } from "react";
import { connect } from "react-redux";
import { userLogout } from "../../actions/login/loginActions";

class LogoutPage extends Component {
  render() {
    return (
      <div onClick={this.props.logout}>
        <button className="btnMain">
          Logout
          <i className="fa fa-power-off" aria-hidden="true"></i>
        </button>
      </div>
    );
  }
}

export default connect(null, { userLogout })(LogoutPage);
