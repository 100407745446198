import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import history from "../../history";
import CryptoJS from "crypto-js";

function renderCity(cityId, city) {
  if (city.length > 0) {
    return city.filter((pr) => pr.id === cityId)[0].name;
  }
  return "";
}

function renderState(stateId, state) {
  if (state.length > 0) {
    return state.filter((pr) => pr.id === stateId)[0].name;
  }
  return "";
}

function renderRegion(regionId, region) {
  if (region.length > 0) {
    return region.filter((pr) => pr.id === regionId)[0].name;
  }
  return "";
}

const encryptId = (id) => {
  id = id.toString();
  let key = '';
  let iv = '';
  for (let index = 0; index < 4; index++) {
    key += '8080';
    iv += '8080';
  }
  key = CryptoJS.enc.Utf8.parse(key);
  iv = CryptoJS.enc.Utf8.parse(iv);

  let idx = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(id + '~' + new Date().toISOString()),
    key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();

  while (idx.includes('/')) {
    idx = idx.replace('/', 'zxcvb');
  }
  while (idx.includes('+')) {
    idx = idx.replace('+', 'plus');
  }

  return idx;
};
const BuyerEmpanelmentData = ({
  userData,
  status,
  data,
  approve,
  state,
  city,
  pageNo,
  region,
}) => {
  const [currentPage, setCurrentPage] = useState(pageNo - 1);
  const PER_PAGE = 5;
  const offset = currentPage * PER_PAGE;
  const currentPageData = data && data.slice(offset, offset + PER_PAGE);
  const pageCount = data && Math.ceil(data.length / PER_PAGE);

  function handlePageClick({ selected: selectedPage }) {
    history.push(
      `/buyerempanelment/${status.toLowerCase()}?pageNo=${selectedPage + 1}`
    );
    setCurrentPage(selectedPage);
  }

  if (data === undefined) {
    return <div></div>;
  }

  return (
    <div>
      {currentPageData && currentPageData.length > 0 ? (
        currentPageData.map((buy) => {
          return (
            <div
              className="card tabCard mb-1 cardFadeIn"
              key={buy.id}
              id={`empanelmentCard_${buy.id}`}
            >
              <div className="card-body">
                <h6 className="card-title clearfix">
                  <span className="float-start">
                    Buyer Request Id :
                    <span className="card-subtitle mb-2 text-orange">
                      {buy.buyerRequestId}
                    </span>
                  </span>
                  {/* Changes made by Ashok jyani */}
                  {status != "Rejected" ? (
                    <span className="float-end">
                      Email Id:
                      <span className="card-subtitle mb-2 text-orange">
                        {buy.email}
                      </span>
                    </span>
                  ) : null}
                  {/* <span className="float-end">
                    Email Id :
                    <span className="card-subtitle mb-2 text-orange">
                      {buy.email}
                    </span>
                  </span> */}
                </h6>
                <div className="card-text">
                  <h6 className="d-block cardLabelText">
                    Name :
                    <span className="card-subtitle mb-2 text-muted">
                      {buy.firstName +
                        " " +
                        (buy.middleName ? buy.middleName : "") +
                        " " +
                        buy.lastName}
                    </span>
                  </h6>
                  <div className="d-block m-0 d-flex flex-row overflow-hidden cardFloatItems">
                    <span className="float-left">
                      <span className="cardLabelText">Region</span>
                      <span className="cardDetailsText card-subtitle mb-2 text-muted">
                        {region
                          ? renderRegion(buy.presentRegionId, region)
                          : null}
                      </span>
                    </span>
                    <span className="float-left">
                      <span className="cardLabelText">State</span>
                      <span className="cardDetailsText card-subtitle mb-2 text-muted">
                        {state ? renderState(buy.presentStateId, state) : null}
                      </span>
                    </span>
                    <span className="float-left">
                      <span className="cardLabelText">City</span>
                      <span className="cardDetailsText  card-subtitle mb-2 text-muted">
                        {city ? renderCity(buy.presentCityId, city) : null}
                      </span>
                    </span>
                    <span className="float-left">
                      <span className="cardLabelText">Mobile Number</span>
                      <span className="cardDetailsText  card-subtitle mb-2 text-muted">
                        {buy.presentTelephoneNumber}
                      </span>
                    </span>
                  </div>
                </div>

                <div className="card-footer overflow-hidden">
                  <div className="float-start byCfLeftBtns">
                    <Link
                      to={`/buyerempanelment/view/${encryptId(buy.id)}`}
                      className="btn btn-link byCfBtn"
                    >
                      View Details
                    </Link>
                  </div>
                  {status === "Pending" ? (
                    <div className="float-end w-50 border-left byCfRightBtnsMain">
                      {userData.role === "Admin" ? (
                        <>
                          <div className="byCfRightBtns">
                            <button
                              className="btn btn-link byCfBtn approveBtn"
                              onClick={() => approve(buy.id, "Y")}
                            >
                              Approve
                            </button>
                            <button
                              className="btn btn-link byCfBtn rejectBtn"
                              onClick={() => approve(buy.id, "N")}
                            >
                              Reject
                            </button>
                          </div>
                          {buy.isCheckerApproved === "N" && (
                            <div className="float-end mr-2 byCfLeftBtns">
                              <p className="m-2">
                                <b>SRM Rejection Remarks : </b>
                                {buy.checkerApprovalRemarks}
                              </p>
                            </div>
                          )}
                        </>
                      ) : userData.role === "SalvageRM" ? (
                        <div className="byCfRightBtns">
                          <button
                            className="btn btn-link byCfBtn approveBtn"
                            onClick={() => approve(buy.id, "Y")}
                          >
                            Approve
                          </button>
                          <button
                            className="btn btn-link byCfBtn rejectBtn"
                            onClick={() => approve(buy.id, "N")}
                          >
                            Reject
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ) : status === "PendingRelease" &&
                    userData.role === "Admin" ? (
                    <button
                      className="btn btn-link byCfBtn"
                      onClick={() => approve(buy.id, "Y")}
                    >
                      Approve
                    </button>
                  ) : status === "Rejected" ? (
                    userData.role === "Admin" ? (
                      <div className="float-end mr-2 byCfLeftBtns">
                        <p className="m-2">
                          <b>Rejection Remarks : </b>
                          {buy.makerApprovalRemarks}
                        </p>
                      </div>
                    ) : (
                      <div className="float-end mr-2 byCfLeftBtns">
                        <p className="m-2">
                          <b>Rejection Remarks : </b>
                          {buy.checkerApprovalRemarks}
                        </p>
                      </div>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div
          className="mainBodyCard pt-30 d-flex flex-row justify-content-center"
          style={{ top: "100px", right: "25px" }}
        >
          <h2 className="notFound">No Buyers Found</h2>
        </div>
      )}
      {currentPageData.length > 0 ? (
        <div className="row paginationCss">
          <ReactPaginate
            previousLabel="&laquo;"
            nextLabel="&raquo;"
            pageCount={pageCount}
            forcePage={pageNo - 1}
            onPageChange={handlePageClick}
            breakClassName="page-item"
            breakLabel={<Link className="page-link h4">...</Link>}
            activeLinkClassName="paginationPageLink"
            pageClassName="page-item"
            previousClassName="page-item"
            nextClassName="page-item"
            pageLinkClassName="page-link h4"
            previousLinkClassName="page-link h4"
            nextLinkClassName="page-link h4"
            containerClassName="pagination"
          />
        </div>
      ) : null}
    </div>
  );
};

export default BuyerEmpanelmentData;
