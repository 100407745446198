import React, { Component } from "react";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import BuyerEmpanelmentDocView from "./BuyerEmpanelmentDocView";
import BuyerEmpanelmentDocUpload from "./BuyerEmpanelmentDocUpload";
import {
  addBuyerEmpanelmentDocument,
  addManualBuyerEmpanelmentDocument,
  fetchBuyerEmpanelmentDocument,
  fetchDigilockerDocList,
} from "../../../actions/BuyerEmpanelment/BuyerEmpanelmentDocActions";
import { fetchBuyer } from "../../../actions/masters/buyerActions";
import { reset } from "redux-form";

class BuyerEmpanelmentDocPage extends Component {
  componentDidMount = async () => {
    await this.props.fetchBuyer(this.props.userData.sub);
    this.props.fetchBuyerEmpanelmentDocument(
      this.props.buyer[0].buyerRequestId
    );
    if (this.props.digiLockerCode) {
      this.props.fetchDigilockerDocList(
        this.props.buyer[0].buyerRequestId,
        this.props.digiLockerCode
      );
    }
  };

  onManualSubmit = (formValues, dispatch) => {
    this.props.addManualBuyerEmpanelmentDocument(
      this.props.buyer[0].id,
      formValues
    );
    dispatch(reset("BuyerEmpanelmentDocumentUpload"));
    document.getElementById("buyerDocDescription").value = "";
    document.getElementById("buyerDocFile").value = "";
  };

  onSubmit = (formValues) => {
    this.props.addBuyerEmpanelmentDocument(
      this.props.buyer[0].buyerRequestId,
      formValues
    );
  };

  render() {
    return (
      <div className="row mt-5 m-0">
        <BuyerEmpanelmentDocUpload
          onSubmit={this.onSubmit}
          onManualSubmit={this.onManualSubmit}
          buyerEmpDocuments={this.props.buyerDigiLockerDocs}
        />

        <BuyerEmpanelmentDocView
          buyerEmpDocuments={this.props.buyerEmpanelmentDocs}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: localStorage.getItem("userToken")
      ? jwt_decode(localStorage.getItem("userToken"))
      : null,
    buyer: Object.values(state.buyer),
    buyerEmpanelmentDocs: Object.values(state.buyerEmpanelmentDocuments),
    buyerDigiLockerDocs: Object.values(state.buyerDigiLockerDocuments),
  };
};

export default connect(mapStateToProps, {
  addBuyerEmpanelmentDocument,
  addManualBuyerEmpanelmentDocument,
  fetchBuyerEmpanelmentDocument,
  fetchBuyer,
  fetchDigilockerDocList,
})(BuyerEmpanelmentDocPage);
