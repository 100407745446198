import {
  EDIT_BUYER,
  FETCH_BUYER,
  EDIT_BUYERRMC,
  FETCH_BUYERRMC_LISTS,
  BUYERRMC_ADMIN_APPROVE,
} from "../../actions/types";
import _ from "lodash";

const buyerReducers = (state = {}, action) => {
  switch (action.type) {
    case FETCH_BUYER:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_BUYER:
      return { ...state, [action.payload.id]: action.payload };

    case EDIT_BUYERRMC:
      return { [action.payload.id]: action.payload };

    case FETCH_BUYERRMC_LISTS:
      return { ...state, ..._.mapKeys(action.payload, "id") };

    case BUYERRMC_ADMIN_APPROVE:
      return { ...state, [action.payload.id]: action.payload };

    default:
      return state;
  }
};

export default buyerReducers;
