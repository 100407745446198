import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import Modal from "../../Modal";

class RegionForm extends Component {
  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, meta }) => {
    return (
      <div className="addNatureOfLossCard">
        <div className="form-group">
          <div className="input-field">
            <input
              {...input}
              type="text"
              autoComplete="off"
              placeholder={label}
              className="form-control"
            />
          </div>
          <div className="error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  renderRadioInput = ({ input, meta, type, label }) => {
    return (
      <div className="d-inline-block mr-5">
        <div className="radio ml-0 enofRadio">
          <input
            {...input}
            autoComplete="off"
            type={type}
            value={input.value}
            id={label}
          />
          <label className="radio-label" htmlFor={label}>
            {input.value === "Y" ? "Active" : "In Active"}
          </label>
          <div>{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <div className="mainBodyCard centerAlignCard">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field name="name" component={this.renderInput} label="Enter Name" />
          <Field
            name="shortName"
            component={this.renderInput}
            label="Enter Short Name"
          />
          {this.props.onDelete ? (
            <div className="row m-0">
              <Field
                name="activeFlag"
                component={this.renderRadioInput}
                type="radio"
                label="RegionFormActiveTrue"
                value="Y"
              />
              <Field
                name="activeFlag"
                component={this.renderRadioInput}
                type="radio"
                label="RegionFormActiveFalse"
                value="N"
              />
            </div>
          ) : (
            ""
          )}
          <div className="mainBodyCardFtBtn mt-5">
            <button className="btnMain">Save</button>
          </div>
        </form>
        <Modal onDeleteClick={this.props.onDelete} />
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.name) {
    errors.name = "This Field is Required";
  }
  if (!formValues.shortName) {
    errors.shortName = "This Field is Required";
  }
  return errors;
};

export default reduxForm({
  form: "RegionForm",
  validate,
  enableReinitialize: true,
})(RegionForm);
