import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchEvent } from "../../actions/event/EventActions";
import history from "../../history";
import {
  fetchEventDocument,
  deleteDocument,
  clearDocState,
} from "../../actions/event/EventDocumentActions";
import EventDocumentsView from "../event/eventDocuments/EventDocumentsView";
import _ from "lodash";
import { fetchRegions } from "../../actions/masters/regionActions";
import { fetchAllStates } from "../../actions/masters/stateActions";
import { fetchAllCities } from "../../actions/masters/cityActions";
import { fetchProducts } from "../../actions/masters/productActions";
import jwt_decode from "jwt-decode";

class AuctionIndividualData extends Component {
  componentDidMount = async () => {
    window.scrollTo(0, 0);
    await this.props.fetchEventDocument(this.props.match.params.id);
    this.props.fetchEvent(this.props.match.params.id);
    this.props.fetchProducts();
    this.props.fetchRegions();
    this.props.fetchAllStates();
    this.props.fetchAllCities();
  };

  componentWillUnmount() {
    this.props.clearDocState();
  }

  renderFieldName(fieldId, fieldName) {
    if (fieldName.length > 0) {
      return fieldName.filter((pr) => pr.id === fieldId)[0].name;
    }
    return "";
  }

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            {this.props.individualEvent ? (
              <h3 className="pageMainTitle">
                Event ID -{this.props.individualEvent.eventId}
              </h3>
            ) : null}
          </div>
          <div className="float-end mt-15">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        <div className="mainBodyCard pt-30">
          {this.props.individualEvent ? (
            <>
              <div className="row m-0">
                <h5 className="col-sm-12 cardTitle border-0">
                  {this.props.individualEvent.category === 1
                    ? "Product Details"
                    : "Vehicle Details"}
                </h5>
                {this.props.individualEvent.category === 1 ? null : (
                  <>
                    <div className="col-md-4 col-sm-12 mb-3">
                      <div className="w-100 d-block form_label">Make</div>
                      <div className="label_details">
                        {this.props.individualEvent.vehicleMake
                          ? this.props.individualEvent.vehicleMake
                          : "NA"}
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3">
                      <div className="w-100 d-block form_label">Variant</div>
                      <div className="label_details">
                        {this.props.individualEvent.modelVariant
                          ? this.props.individualEvent.modelVariant
                          : "NA"}
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3">
                      <div className="w-100 d-block form_label">
                        Manufacturing Year
                      </div>
                      <div className="label_details">
                        {this.props.individualEvent.manufacturingYear
                          ? this.props.individualEvent.manufacturingYear
                          : "NA"}
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3">
                      <div className="w-100 d-block form_label">
                        Ownership Serial Number
                      </div>
                      <div className="label_details">
                        {this.props.individualEvent.ownerShipSerialNo
                          ? this.props.individualEvent.ownerShipSerialNo
                          : "NA"}
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3">
                      <div className="w-100 d-block form_label">
                        Registration Number
                      </div>
                      <div className="label_details">
                        {this.props.individualEvent.registrationNo
                          ? this.props.individualEvent.registrationNo
                          : "NA"}
                      </div>
                    </div>
                  </>
                )}
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    {this.props.individualEvent.category === 1
                      ? "Commodity"
                      : "Fuel Type"}
                  </div>
                  <div className="label_details">
                    {this.props.individualEvent.fuelType
                      ? this.props.individualEvent.fuelType
                      : "NA"}
                  </div>
                </div>{" "}
                {this.props.individualEvent.category === 1 ? (
                  <div className="col-md-4 col-sm-12 mb-3">
                    <div className="w-100 d-block form_label">Date of Loss</div>
                    <div className="label_details">
                      {this.props.individualEvent.dateOfLoss
                        ? this.props.individualEvent.dateOfLoss.split("T")[0]
                        : "NA"}
                    </div>
                  </div>
                ) : null}
                {this.props.individualEvent.category === 1 ? (
                  <div className="col-md-4 col-sm-12 mb-3">
                    <div className="w-100 d-block form_label">
                      Location Of Material
                    </div>
                    <div className="label_details">
                      {this.props.individualEvent.garageAddress
                        ? this.props.individualEvent.garageAddress
                        : "NA"}
                    </div>
                  </div>
                ) : null}
                {this.props.individualEvent.category === 1 ? (
                  <div className="col-md-4 col-sm-12 mb-3">
                    <div className="w-100 d-block form_label">
                      Damaged Material
                    </div>
                    <div className="label_details">
                      {this.props.individualEvent.damagedMaterial
                        ? this.props.individualEvent.damagedMaterial
                        : "NA"}
                    </div>
                  </div>
                ) : null}
                {this.props.individualEvent.category === 1 ? (
                  <div className="col-md-4 col-sm-12 mb-3">
                    <div className="w-100 d-block form_label">
                      Damaged Quantity
                    </div>
                    <div className="label_details">
                      {this.props.individualEvent.damagedQuantity
                        ? this.props.individualEvent.damagedQuantity
                        : "NA"}
                    </div>
                  </div>
                ) : null}
              </div>
              <hr className="mb-0" />
              <div className="row m-0">
                <h5 className="col-sm-12 cardTitle border-0">
                  {this.props.individualEvent.category === 1
                    ? "Insured Details"
                    : "Garage Details"}
                </h5>

                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    {this.props.individualEvent.category === 1
                      ? "Insured Name"
                      : "Garage Name"}
                  </div>
                  <div className="label_details">
                    {this.props.individualEvent.garageName
                      ? this.props.individualEvent.garageName
                      : "NA"}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    {this.props.individualEvent.category === 1
                      ? "Insured Address"
                      : "Garage Address"}
                  </div>
                  <div className="label_details">
                    {this.props.individualEvent.garageAddress}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    {this.props.individualEvent.category === 1
                      ? "Insured City"
                      : "Garage City"}
                  </div>
                  <div className="label_details">
                    {this.renderFieldName(
                      this.props.individualEvent.garageCityId,
                      this.props.cities
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    {this.props.individualEvent.category === 1
                      ? "Insured Region"
                      : "Garage Region"}
                  </div>
                  <div className="label_details">
                    {this.renderFieldName(
                      this.props.individualEvent.garageRegionId,
                      this.props.regions
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    {this.props.individualEvent.category === 1
                      ? "Insured State"
                      : "Garage State"}
                  </div>
                  <div className="label_details">
                    {this.renderFieldName(
                      this.props.individualEvent.garageStateId,
                      this.props.states
                    )}
                  </div>
                </div>

                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Category</div>
                  <div className="label_details">
                    {this.renderFieldName(
                      this.props.individualEvent.category,
                      this.props.product
                    )}
                  </div>
                </div>
              </div>

              <hr className="mb-0" />

              <div className="row m-0">
                <h5 className="col-sm-12 cardTitle border-0">
                  Department Details
                </h5>

                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Department</div>
                  <div className="label_details">
                    {this.props.individualEvent.department}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Officer</div>
                  <div className="label_details">
                    {this.props.individualEvent.officer}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Claim Number</div>
                  <div className="label_details">
                    {this.props.individualEvent.claimNo}
                  </div>
                </div>

                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    {this.props.individualEvent.category === 1
                      ? "Cause Of Loss"
                      : "Brief Scope Of Work"}
                  </div>
                  <div className="label_details">
                    {this.props.individualEvent.briefScopeOfWork}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    {this.props.individualEvent.category === 1
                      ? "Material Detail"
                      : "Auction Detail"}
                  </div>
                  <div className="label_details">
                    {this.props.individualEvent.category === 1
                      ? this.props.individualEvent.damagedMaterial
                      : this.props.individualEvent.auctionDetails}
                  </div>
                </div>
                {this.props.individualEvent.category === 1 ? null : (
                  <div className="col-md-4 col-sm-12 mb-3">
                    <div className="w-100 d-block form_label">
                      Type of Contract
                    </div>
                    <div className="label_details">
                      {this.props.individualEvent.typeOfContract}
                    </div>
                  </div>
                )}

                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Remarks</div>
                  <div className="label_details">
                    {this.props.individualEvent.remarks}
                  </div>
                </div>
              </div>

              <hr className="mb-0" />

              {this.props.tokendata.role !== "Buyer" ? (
                this.props.individualEvent.category === 1 ? null : (
                  <>
                    <div className="row m-0">
                      <h5 className="col-sm-12 cardTitle border-0">
                        Policy Details
                      </h5>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Policy Number
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.policyNumber}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Policy Product
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.policyProduct}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">Remarks</div>
                        <div className="label_details">
                          {this.props.individualEvent.remarks
                            ? this.props.individualEvent.remarks
                            : "NA"}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Originated From
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.originatedFrom}
                        </div>
                      </div>
                    </div>

                    <hr className="mb-0" />

                    <div className="row m-0">
                      <h5 className="col-sm-12 cardTitle border-0">
                        Auction Details
                      </h5>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Auction base currency
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.auctionBaseCurrency}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Auction Type
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.auctionType}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Auction Variant
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.auctionVariant}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Bid submission for
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.bidSubmissionFor}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Line Item Wise Bid Submission Time Allowed ?
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent
                            .lineItemWiseBidSubmissionTimeAllowed
                            ? "Yes"
                            : "No"}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Bid Submission mode
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.bidSubmissionMode}
                        </div>
                      </div>
                    </div>

                    <div className="row m-0">
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Allowed auto extension?
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.allowedAutoExtension
                            ? "Yes"
                            : "No"}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Auto entension mode
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.autoExtensionMode}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Reserve price check require
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.reservePriceCheckRequired
                            ? "Yes"
                            : "No"}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Bidding Price Increment In
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.biddingPriceIncrementIn}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Increment / Decrement in multiples
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent
                            .incrementDecrementInMultiples
                            ? "Yes"
                            : "No"}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Auto Bid Allowed ?
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.autoBidAllowed
                            ? "Yes"
                            : "No"}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Allow Mobile Bidding
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.allowMobileBidding
                            ? "Yes"
                            : "No"}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          First Bid Acceptance
                        </div>
                        <div className="label_details">
                          {
                            this.props.individualEvent
                              .firstBidAcceptanceCondition
                          }
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Bidding form
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.biddingForm}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Display l1/h1 amount
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.displayL1_H1Amount
                            ? "Yes"
                            : "No"}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Demo Auction?
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.demoAuction
                            ? "Yes"
                            : "No"}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Item Selection Required?
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.itemSelectionRequired
                            ? "Yes"
                            : "No"}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Display Price Bid Break Up Link?
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent.displayPriceBidBreakupLink
                            ? "Yes"
                            : "No"}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Hide Live Bidding To Department User?
                        </div>
                        <div className="label_details">
                          {this.props.individualEvent
                            .hideLiveBiddingToDepartmentUser
                            ? "Yes"
                            : "No"}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">Status</div>
                        <div className="label_details">
                          {this.props.individualEvent.status}
                        </div>
                      </div>
                    </div>
                    <hr className="mb-0" />
                  </>
                )
              ) : null}
              <div className="row m-0">
                <div className="col-md-12 col-sm-12 eventCreateInputCol pl-0 row m-0 pb-0 pr-0">
                  <h5 className="col-sm-12 cardTitle border-0">
                    Uploaded Documents
                  </h5>
                  <div>
                    {this.props.individualEvent &&
                    this.props.individualEvent.noOfDocuments === 0 ? (
                      <h3 className=" d-block overflow-hidden pt-4 w-100 pl-4">
                        No Document Uploaded
                      </h3>
                    ) : (
                      <p
                        className="linkBtn2 d-block overflow-hidden pt-2 w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#viewDocumentsModel"
                      >
                        View Uploaded Documents
                      </p>
                    )}
                    <EventDocumentsView
                      eventDocuments={this.props.eventDocument}
                      eid={this.props.match.params.eid}
                      event={this.props.event}
                      eventImages={this.props.eventImages}
                      deleteDocument={this.props.deleteDocument}
                      clearDocState={this.props.clearDocState}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    regions: Object.values(state.region),
    states: Object.values(state.state),
    product: Object.values(state.product),
    cities: Object.values(state.city),
    tokendata: localStorage.getItem("userToken")
      ? jwt_decode(localStorage.getItem("userToken"))
      : null,
    individualEvent: Object.values(state.event).filter(
      (e) => e.id === parseInt(ownProps.match.params.id)
    )[0],
    event: Object.values(state.event).filter(
      (e) => e.id === parseInt(ownProps.match.params.id)
    ),
    eventDocument: Object.values(state.eventDocument),
    eventImages: Object.values(
      _.mapKeys(
        Object.values(state.eventDocument).filter(
          (im) =>
            im.fileType.toLowerCase() === ".png" ||
            im.fileType.toLowerCase() === ".jpg" ||
            im.fileType.toLowerCase() === ".jpeg" ||
            im.fileType.toLowerCase() === ".gif" ||
            im.fileType.toLowerCase() === ".raw"
        ),
        ("dmsDocContent", "description")
      )
    ),
  };
};

export default connect(mapStateToProps, {
  fetchEvent,
  fetchEventDocument,
  fetchProducts,
  fetchRegions,
  fetchAllStates,
  fetchAllCities,
  deleteDocument,
  clearDocState,
})(AuctionIndividualData);
