import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import history from "../../history";
import { fetchEventRankList } from "../../actions/event/EventAuditTrailActions";
import { fetchEvent } from "../../actions/event/EventActions";
import { connect } from "react-redux";

const ViewEventRankHistory = ({
  match,
  fetchEventRankList,
  eventRankList,
  fetchEvent,
  currevent,
}) => {
  useEffect(() => {
    fetchEventRankList(match.params.auctionId);
    fetchEvent(match.params.auctionId);
  }, [fetchEventRankList, fetchEvent]);

  const renderDateTime = (datetime) => {
    return [datetime.split("T")[0], datetime.split("T")[1].split(".")[0]];
  };

  const renderList = (data) => {
    return data.map((bid) => {
      return (
        <tr key={bid.id}>
          <td>{bid.modifiedBy && bid.modifiedBy}</td>
          <td>{bid.userMaster.user_name}</td>
          <td>{bid.rank && bid.rank}</td>
          <td>{bid.bidAmount && bid.bidAmount}</td>
          <td>
            {bid.modifiedDate && renderDateTime(bid.modifiedDate)[0]}{" "}
            {bid.modifiedDate && renderDateTime(bid.modifiedDate)[1]}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="BodyInContainer  bidDetailsAudit">
      <div className="d-block clearfix">
        <div className="Header-Content float-start">
          <h3 className="cardTitle">
            Event ID - {currevent && currevent.eventId}
          </h3>
        </div>
        <div className="float-end mt-15">
          <Link
            className="float-end cardBackBtn"
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </Link>
        </div>
      </div>

      <div className="mainBodyCard pt-0">
        {eventRankList.length > 0 ? (
          <table className="table table-hover">
            <thead>
              <tr>
                <td>UserId</td>
                <td>User Name</td>
                <td>Rank</td>
                <td>Bid Amount</td>
                <td>Bid Date-Time</td>
              </tr>
            </thead>
            <tbody>{renderList(eventRankList)}</tbody>
          </table>
        ) : (
          <div
            className="mainBodyCard pt-30 d-flex flex-row justify-content-center"
            style={{ top: "100px", right: "25px" }}
          >
            <h4 className="notFound">No Bids Placed For this Event</h4>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  eventRankList: state.auditTrailHistory.eventRankList,
  currevent: state.event[ownProps.match.params.auctionId],
});

const mapDispatchToProps = { fetchEventRankList, fetchEvent };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewEventRankHistory);
