import { USER_LAST_LOGIN } from "../../actions/types";

const eventBidderReducers = (state = {}, action) => {
  switch (action.type) {
    case USER_LAST_LOGIN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default eventBidderReducers;
