import AuctionPortal from "../../apis/AuctionPortal";
import { FETCH_LDAPUSERs, CLEAR_LDAPUSER } from "../types";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.minimal.css";
import { isSessionExpired } from "../login/loginActions";
import history from "../../history";
import CryptoJS from "crypto-js";

toast.configure({
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
});

export const fetchLDAPUser = (userId) => async (dispatch) => {
  var key='';
  var iv='';
    for (let index = 0; index < 4; index++) {
      key += "8080";
      iv +="8080"; 
      }
      key = CryptoJS.enc.Utf8.parse(key);
       iv = CryptoJS.enc.Utf8.parse(iv);
  userId = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(userId+"~"+new Date().toISOString()),
    key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();
  while(userId.includes('/'))
  userId = userId.replace('/','zxcvb')
while(userId.includes('+'))
userId = userId.replace('+','plus')
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get(
        `/masters/GetLdapUserbyId/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      var k = '';
      var d = new Date().toLocaleString();
      for (let index = 0; index <4; index++) {
      k += "8080";
      var value = "";
    }
      const decryptedData = {};
      debugger;
      for (const key in response.data) {
        value = response.data[key];
        if (response.data.hasOwnProperty(key)&& value != null && value.length>5)
        {
          while(value.includes('zxcvb'))
          value = value.replace('zxcvb','/')
          while(value.includes('plus'))
          value = value.replace('plus','+')
          if(!(value.includes('T') && value.includes(':'))){
          value = await CryptoJS.AES.decrypt(
            value,
            CryptoJS.enc.Utf8.parse(k),
            {
              keySize: 128 / 8,
              iv:CryptoJS.enc.Utf8.parse(k),
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          ).toString(CryptoJS.enc.Utf8);
          value = value.substring(0,value.indexOf('~'));
          response.data[key]=value.toString();
        }
        }
      }
      dispatch({ type: FETCH_LDAPUSERs, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      toast.error(error.response.data?.Description[0]);
    }
  }
};

export const clearLDAPUser = () => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    dispatch({ type: CLEAR_LDAPUSER, payload: "" });
  }
};
