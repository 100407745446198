import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";

class EventDocumentsUpload extends Component {
  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, id, meta, disabled, type, maxLength }) => {
    return (
      <div className="col-md-4 col-sm-12 eventCreateInputCol pl-0 pr-0 pt-2">
        <label className="d-block">{label}</label>
        <div className="input-field">
          <input
            {...input}
            id={id}
            type={type}
            maxLength={maxLength}
            autoComplete="off"
            placeholder={label}
            className="form-control"
            disabled={disabled}
          />
        </div>
        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
  };

  renderFileInput = ({ input, label, id, meta, disabled, type, maxLength }) => {
    return (
      <div className="col-md-5 col-sm-12 eventCreateInputCol pl-0 pr-0">
        <label className="d-block">{label}</label>
        <div className="input-field">
          <input
            name={input.name}
            id={id}
            type={type}
            className="form-control"
            onChange={(event) => this.handleChange(event, input)}
          />
        </div>
        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
  };

  handleChange = (event, input) => {
    event.preventDefault();
    let imageFile = event.target.files[0];
    input.onChange(imageFile);
  };

  render() {
    return (
      <div className="pt-4">
        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
          <div className="row m-0">
            <Field
              name="Description"
              id="eventDocDescription"
              component={this.renderInput}
              label="Description"
              type="text"
            />
            <Field
              name="Document"
              id="eventDocFile"
              component={this.renderFileInput}
              label="Document"
              type="file"
            />
            <button
              className="btnMain col-md-2 col-sm-12  pl-0 pr-0"
              style={{ marginTop: "15px", height: "50px" }}
            >
              Upload
            </button>
          </div>
          <div className="mainBodyCardFtBtn mt-5">
            {this.props.event[0] && this.props.event[0].noOfDocuments === 0 ? (
              <h3 className=" d-block overflow-hidden pt-4 w-100">
                No Document Uploaded
              </h3>
            ) : (
              <p
                className="linkBtn2 d-block overflow-hidden pt-4 w-100"
                data-bs-toggle="modal"
                data-bs-target="#viewDocumentsModel"
              >
                View Uploaded Documents
              </p>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.Document) {
    errors.Document = "This Field is Required";
  }
  if (!formValues.Description) {
    errors.Description = "This Field is Required";
  }
  return errors;
};

export default reduxForm({
  form: "EventDocumentUpload",
  validate,
  enableReinitialize: true,
})(EventDocumentsUpload);
