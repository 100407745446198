import {
  ADD_BUYER_DOCs,
  FETCH_BUYER_DOCs,
  CLEAR_BUYER_DOCs,
} from "../../actions/types";
import _ from "lodash";

const buyerReducers = (state = {}, action) => {
  switch (action.type) {
    case ADD_BUYER_DOCs:
      return { ...state, [action.payload.id]: action.payload };
    case FETCH_BUYER_DOCs:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case CLEAR_BUYER_DOCs:
      return {};
    default:
      return state;
  }
};

export default buyerReducers;
