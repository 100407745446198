import {
  FETCH_EVENT_BID_HISTORY,
  FETCH_EVENT_RANK_LIST,
} from "../../actions/types";
import _ from "lodash";

const initialState = {
  eventBidHistory: {},
  eventRankList: {},
  eventHistory: {},
  buyerEmpanemlmentHistory: {},
};

const eventAuditTrailReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENT_BID_HISTORY:
      return { ...state, eventBidHistory: action.payload };
    case FETCH_EVENT_RANK_LIST:
      return { ...state, eventRankList: action.payload };
    default:
      return state;
  }
};

export default eventAuditTrailReducers;
