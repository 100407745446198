import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { adminPaymentRefund } from "../../../actions/BuyerEmpanelment/PaymentRefund/PaymentRefundActions";

class AdminPaymentRefundForm extends Component {
  state = {
    payRef: "",
    payDate: "",
    remarks: "",
    amt: 0,
  };
  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }
  renderInput = ({ input, label, meta, maxLength, value }) => {
    return (
      <div className="form-group">
        <div className="eventCreateInputCol">
          <label className="d-block">{label}</label>
          <div className="input-field">
            <input
              {...input}
              autoComplete="off"
              placeholder={label}
              className="form-control"
              value={value}
              maxLength={maxLength}
            />
          </div>
        </div>
        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
  };
  renderTextArea = ({ input, label, meta, disabled, type, maxLength }) => {
    return (
      <div className="col-md col-sm-12 eventCreateInputCol pl-0 pr-0">
        <label className="d-block">{label}</label>
        <div className="input-field">
          <textarea
            {...input}
            type={type}
            maxLength={maxLength}
            autoComplete="off"
            placeholder={label}
            className="form-control"
            disabled={disabled}
            value={this.state.remarks}
          />
        </div>
        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
  };
  renderDate = ({ input, label, meta, maxDate }) => {
    return (
      <div className="eventCreateInputCol">
        <div className="row m-0">
          <label className="d-block w-100">{label}</label>
          <div className="input-field">
            <input
              {...input}
              type="date"
              autoComplete="off"
              placeholder={label}
              className="form-control"
              max={maxDate}
            />
          </div>
          <div className="error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  render() {
    return this.props.Popup > 0 && this.props.id === this.props.Popup ? (
      <div
        className="modal fade"
        id="paymentModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="paymentModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Payment Details</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  this.setState({
                    payRef: "",
                    remarks: "",
                    amt: "",
                    payDate: "",
                  })
                }
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body bidModalBody">
              <form onSubmit={(e) => e.preventDefault()}>
                <Field
                  name="Amount"
                  component={this.renderInput}
                  label="Amount"
                  type="text"
                  maxLength={20}
                  props={{
                    value: this.state.amt,
                  }}
                  onChange={(event) =>
                    this.setState({
                      amt: event.target.value.replace(/\D/g, ""),
                    })
                  }
                />
                <Field
                  name="RefNo"
                  component={this.renderInput}
                  label="Payment Reference Number"
                  type="text"
                  maxLength={20}
                  props={{
                    value: this.state.payRef,
                  }}
                  onChange={(event) =>
                    this.setState({
                      payRef: event.target.value.replace(/\D/g, ""),
                    })
                  }
                />
                <Field
                  name="PaymentDate"
                  component={this.renderDate}
                  label="Payment Date"
                  maxDate={new Date().toISOString().split("T")[0]}
                  onChange={(event) =>
                    this.setState({
                      payDate: event.target.value,
                    })
                  }
                />
                <Field
                  name="Remarks"
                  component={this.renderTextArea}
                  label="Remarks"
                  type="text"
                  onChange={(event) =>
                    this.setState({
                      remarks: event.target.value,
                    })
                  }
                />
                <div className="mainBodyCardFtBtn mt-50">
                  <button
                    className="btnMain"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      this.props.adminPaymentRefund(
                        this.props.id,
                        this.state.payRef,
                        this.state.remarks,
                        this.state.amt,
                        this.state.payDate,
                        "Y"
                      );
                      this.setState({
                        payRef: "",
                        remarks: "",
                        amt: "",
                        payDate: "",
                      });
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.RefNo) {
    errors.RefNo = "This Field is Required";
  }
  if (!formValues.Remarks) {
    errors.Remarks = "This Field is Required";
  }
  if (!formValues.PaymentDate) {
    errors.PaymentDate = "This Field is Required";
  }
  if (!formValues.Amount) {
    errors.Amount = "This Field is Required";
  }
  return errors;
};

const adminPaymentRefundForm = reduxForm({
  form: "adminPaymentRefundForm",
  validate,
  enableReinitialize: true,
})(AdminPaymentRefundForm);

export default connect(null, { adminPaymentRefund })(adminPaymentRefundForm);
