import AuctionPortal from "../../apis/AuctionPortal";
import history from "../../history";

import {
  FETCH_STATEs,
  FETCH_STATE,
  EDIT_STATE,
  CREATE_STATE,
  DELETE_STATE,
  FETCH_ALL_STATES,
  USER_LOGOUT,
} from "../types";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isSessionExpired, userLogout } from "../login/loginActions";

toast.configure({
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
});

export const fetchStates = (id) => async (dispatch) => {
  try {
    const response = await AuctionPortal.get(`/masters/region/${id}/state`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    dispatch({ type: FETCH_STATEs, payload: response.data });
  } catch (error) {
    switch (error.response.status) {
      case 408:
        history.push("/sessionExpired");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        history.push("/BadRequest");
        break;
      default:
        toast.error("Error Occurred");
    }
  }
};

export const fetchAllStates = () => async (dispatch) => {
  try {
    const response = await AuctionPortal.get("/masters/state", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    dispatch({ type: FETCH_ALL_STATES, payload: response.data });
  } catch (error) {
    switch (error.response.status) {
      case 408:
        history.push("/sessionExpired");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        history.push("/BadRequest");
        break;
      default:
        toast.error("Error Occurred");
    }
  }
};

export const fetchAllStatesForEmpanelment = () => async (dispatch) => {
  try {
    const response = await AuctionPortal.get("/masters/stateForEmpanelment", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    dispatch({ type: FETCH_ALL_STATES, payload: response.data });
  } catch (error) {
    switch (error.response.status) {
      case 408:
        history.push("/sessionExpired");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        history.push("/BadRequest");
        break;
      default:
        toast.error("Error Occurred");
    }
  }
};

export const fetchState = (sid) => async (dispatch) => {
  try {
    const response = await AuctionPortal.get(`/masters/state/${sid}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    dispatch({ type: FETCH_STATE, payload: response.data });
  } catch (error) {
    switch (error.response.status) {
      case 408:
        history.push("/sessionExpired");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        history.push("/BadRequest");
        break;
      default:
        toast.error("Error Occurred");
    }
  }
};

export const editState = (id, sid, formValues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.put(
        `/masters/region/${id}/state/${sid}`,
        {
          ...formValues,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      dispatch({ type: EDIT_STATE, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.push(`/masters/location/`);
      toast("Edited Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const deleteState = (id, sid, formValues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.put(
        `/masters/region/${id}/state/${sid}`,
        {
          ...formValues,
          ActiveFLag: "N",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      dispatch({ type: DELETE_STATE, payload: response.data.id });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.push(`/masters/region/${id}/state/`);
      toast.error("Deleted Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const createState = (id, formValues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.post(
        `/masters/region/${id}/state/`,
        formValues,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      dispatch({ type: CREATE_STATE, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.push(`/masters/location/`);
      toast("Added Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};
