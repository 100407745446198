import React, { Component } from "react";
import { connect } from "react-redux";
import { approveEvent } from "../../actions/event/EventActions";
import { fetchProducts } from "../../actions/masters/productActions";
import { removeMyAuction } from "../../actions/event/EventActions";
import { fetchCurrentTime } from "../../actions/masters/DefaultTimeAction";
import AuctionData from "./AuctionData";
import jwt_decode from "jwt-decode";
import BuyerEmpanelmentPaymentCreate from "../BuyerEmpanelment/BuyerEmpanelmentPaymentCreate";
import BuyerEmpanelmentDocPage from "../BuyerEmpanelment/BuyerEmpDocuments/BuyerEmpanelmentDocPage";
import { fetchAllStates } from "../../actions/masters/stateActions";
import { Link } from "react-router-dom";
import SimpleSearch from "../search/simpleSearch";
import AuctionPortal from "../../apis/AuctionPortal";
import {
  fetchEventsByStatus,
  
} from "../../actions/event/EventActions";
// import { objectValues } from "react-toastify/dist/utils";
class SearchedList extends Component {
  //   componentDidMount() {
  //     this.props.fetchProducts();
  //     this.props.fetchAllStates();
  //     this.props.fetchCurrentTime();
  //   }
  constructor(props) {
    super(props);
    this.state = {
      claimNo: "MOT",
      eventsArchive: []
    }
    this.searchEvents = this.searchEvents.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  // componentDidMount = async () => {
  //   this.props.fetchEventsByClaimNo(this.state.claimNo);
  // };
  getdata = async () => {
    try {
      
      var response = await AuctionPortal.get(`/event/status/SEARCHED/${this.state.claimNo}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      
    } catch (error) {
      alert(error);
    }
    this.setState((prevState) => ({
      ...prevState.claimNo,
      eventsArchive: response.data,
    }));
  }

  handleChange(event){
    this.setState((prevState) => ({
      // ...prevState.eventsArchive,
      eventsArchive:[],
      claimNo: event.target.value,
    }));

  }
  searchEvents = () => {
    
   
    this.getdata();
    
    
  };
  render() {
    return (
      <div className="tabbable-responsive">
        <div className="homeTabContainer tabbable">
          <div className="container row">
            <div className="col-sm">
              <h4 className="pageMainTitle">Search Events</h4>
            </div>
          </div>
          <div className="search">
          <div className="input-field">
            <input type="text"
              value={this.state.claimNo}
              onChange={this.handleChange}
              id="claimsearch"
            />
            </div>
            <button
              className="btnMain"
              id="searchbutton"
              onClick={this.searchEvents}
            >Search</button>
            <div
              id="searched"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="tab-content" id="searchedTabContent">
                <div
                  className={
                    "tab-pane fade show active"
                  }
                  id="searched"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "tab-pane fade show active"
            }
            id="searched"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <AuctionData
              currentTime={Date.parse(this.props.currentTime)}
              eventStatus="SEARCHED"
              pageNo={1}
              product={this.props.product}
              state={this.props.state}
              data={this.state.eventsArchive}
              tokendata={this.props.tokendata}
            />
          </div>
        </div>
      </div>
    );
  }
}
//Object.values(this.props.fetchEventsByStatus("ARCHIVE"))
const mapStateToProps = (state, ownProps) => {
  var tokendata = localStorage.getItem("userToken")
    ? jwt_decode(localStorage.getItem("userToken"))
    : null;
  return {
    product: Object.values(state.product),
    state: Object.values(state.state),
    tokendata,
    currentTime: state.currentTime.currentTime,
  };
};
export default connect(mapStateToProps, {
  fetchEventsByStatus,
  
})(SearchedList);