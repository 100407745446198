import React, { Component } from "react";
import { connect } from "react-redux";
import BuyerEmpanelmentPaymentForm from "./BuyerEmpanelmentPaymentForm";
import {
  fetchBuyerEmpanelment,
  buyerPayment,
} from "../../actions/BuyerEmpanelment/BuyerEmpanelmentActions";
import jwt_decode from "jwt-decode";

class BuyerEmpanelmentPaymentCreate extends Component {
  componentDidMount() {
    const { sub } = jwt_decode(localStorage.getItem("userToken"));
    this.props.fetchBuyerEmpanelment(sub);
  }

  onSubmit = (formValues) => {
    const userId = this.props.buyerEmpanelment[0].id;
    this.props.buyerPayment(userId, formValues);
  };

  render() {
    return (
      <>
        {this.props.buyerEmpanelment[0] ? (
          this.props.buyerEmpanelment[0].isDepositPaid !== "Y" ? (
            <BuyerEmpanelmentPaymentForm onSubmit={this.onSubmit} />
          ) : (
            <div>
              <div className="card tabCard mb-1">
                <div className="card-body">
                  <h6 className="card-title clearfix">
                    <span className="float-start">
                      Payment Details Added
                      <span className="card-subtitle mb-2 text-orange">
                        {/* {this.props.buyerEmpanelment[0].eventId} */}
                      </span>
                    </span>
                  </h6>
                  <div className="card-text">
                    <h6 className="d-block cardLabelText">
                      Details :
                      <span className="card-subtitle mb-2 text-muted">
                        {/* {this.props.buyerEmpanelment[0].auctionDetails} */}
                      </span>
                    </h6>
                    <div className="d-block m-0 d-flex flex-row overflow-hidden cardFloatItems">
                      <span className="float-start">
                        <span className="cardLabelText">Request ID</span>
                        <span className="cardDetailsText card-subtitle mb-2 text-muted">
                          {this.props.buyerEmpanelment[0].buyerRequestId}
                        </span>
                      </span>
                      <span className="float-left">
                        <span className="cardLabelText">Name</span>
                        <span className="cardDetailsText card-subtitle mb-2 text-muted">
                          {this.props.buyerEmpanelment[0].name}
                        </span>
                      </span>
                      <span className="float-left">
                        <span className="cardLabelText">Email ID</span>
                        <span className="cardDetailsText card-subtitle mb-2 text-muted">
                          {this.props.buyerEmpanelment[0].email}
                        </span>
                      </span>
                      <span className="float-left">
                        <span className="cardLabelText">
                          Payment Reference Number
                        </span>
                        <span className="cardDetailsText  card-subtitle mb-2 text-muted">
                          {this.props.buyerEmpanelment[0].paymentRefNo}
                        </span>
                      </span>

                      <span className="float-left">
                        <span className="cardLabelText">Bank Name</span>
                        <span className="cardDetailsText card-subtitle mb-2 text-muted">
                          {this.props.buyerEmpanelment[0].bankName}
                        </span>
                      </span>
                      <span className="float-left">
                        <span className="cardLabelText">
                          Account Holder Name
                        </span>
                        <span className="cardDetailsText card-subtitle mb-2 text-muted">
                          {this.props.buyerEmpanelment[0].accountHolderName}
                        </span>
                      </span>
                      <span className="float-left">
                        <span className="cardLabelText">Amount</span>
                        <span className="cardDetailsText card-subtitle mb-2 text-muted">
                          {this.props.buyerEmpanelment[0].amount}
                        </span>
                      </span>
                      <span className="float-left">
                        <span className="cardLabelText">Date Of Payment</span>
                        <span className="cardDetailsText card-subtitle mb-2 text-muted">
                          {
                            this.props.buyerEmpanelment[0].dateOfPayment.split(
                              "T"
                            )[0]
                          }
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.login,
    buyerEmpanelment: Object.values(state.buyerEmpanelment),
  };
};

export default connect(mapStateToProps, {
  fetchBuyerEmpanelment,
  buyerPayment,
})(BuyerEmpanelmentPaymentCreate);
