import { FETCH_EVENTMASTER, EDIT_EVENTMASTER } from "../../actions/types";

const EventMasterReducers = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EVENTMASTER:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_EVENTMASTER:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};

export default EventMasterReducers;
