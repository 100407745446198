import AuctionPortal from "../../apis/AuctionPortal";
import history from "../../history";
import {
  FETCH_EVENT_BID_HISTORY,
  FETCH_EVENT_RANK_LIST,
  USER_LOGOUT,
} from "../types";
import { isSessionExpired } from "../login/loginActions";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
 import { encryptId,decryptIndex } from "../../components/Encryption";
 
export const fetchEventBidHistory = (eventID) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get(
        `/audittrail/bidhistory/${eventID}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: FETCH_EVENT_BID_HISTORY, payload: response.data });
      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const fetchEventRankList = (auctionId) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
     
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get(
        `/event/getRankList/${encryptId(auctionId)}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      var k = '';
      for (let index = 0; index < 4; index++) {
        k += "8080";
      }

      // Debugging breakpoint if needed after initialization
     

      const isProbablyEncrypted = (value) => {
        return typeof value === 'string' && (!value.includes(':'));
      };

      const processValue = async (value) => {
        if (typeof value === 'string') {
          if (isProbablyEncrypted(value)) {
            while (value.includes('zxcvb')) {
              value = value.replace('zxcvb', '/');
            }
            while (value.includes('plus')) {
              value = value.replace('plus', '+');
            }

          try {
            const decrypted = await CryptoJS.AES.decrypt(
              value,
              CryptoJS.enc.Utf8.parse(k),
              {
                keySize: 128 / 8,
                iv: CryptoJS.enc.Utf8.parse(k),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
              }
            ).toString(CryptoJS.enc.Utf8);

              return decrypted.substring(0, decrypted.indexOf('~'));
            } catch (error) {
              console.error('Decryption error:', error);
              return value; // Return original value if decryption fails
            }
          } else {
            return value; // Return original value if not encrypted
          }
        } else if (typeof value === 'object' && value !== null) {
          // Recursively process nested objects
          for (const nestedKey in value) {
            if (value.hasOwnProperty(nestedKey)) {
              value[nestedKey] = await processValue(value[nestedKey]);
            }
          }
        }
        return value;
      };

      for (const key in response.data) {
        if (response.data.hasOwnProperty(key)) {
          const value = response.data[key];
          response.data[key] = await processValue(value);
        }
      }
      dispatch({ type: FETCH_EVENT_RANK_LIST, payload: response.data });
      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};
