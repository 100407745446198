import axios from "axios";

// // --------DEV------
//  const domain = "http://localhost:52240";
//  const domainUI = "http://localhost:3000";
//  const clientId = "038ADB4E";

// // // --------UAT-------
// const domain = "https://ievaluatedev.insurancearticlez.com";
//  const domainUI = "https://ievaluatedev.insurancearticlez.com";
//  const clientId = "09136F32";
// const domain = "https://cldilstaffweb04.insurancearticlez.com/AuctionPortalAPI";
// const domainUI = "https://cldilstaffweb04.insurancearticlez.com";
// const clientId = "09136F32";


// // --------PROD-------
const domain = "https://ievaluate.icicilombard.com";
const domainUI = "https://ievaluate.icicilombard.com";
//const clientId = "5C143D0D";
const clientId = "09136F32";  // Use only if UI not working

export default axios.create({
  baseURL: `${domain}/api`,
});

export const DigilockerUrl = `https://api.digitallocker.gov.in/public/oauth2/1/authorize?client_id=${clientId}&redirect_uri=${domainUI}/home&response_type=code&state=buyerDocuments`;
