import AuctionPortal from "../../apis/AuctionPortal";
import history from "../../history";
import { FETCH_EVENTMASTER, EDIT_EVENTMASTER, USER_LOGOUT } from "../types";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isSessionExpired, userLogout } from "../login/loginActions";

toast.configure({
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
});

export const fetchEventMaster = () => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get("/masters/event/1", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      dispatch({ type: FETCH_EVENTMASTER, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const editEventMaster = (formValues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.put(
        "/masters/event/1",
        {
          ...formValues,
          activeFlag: "Y",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: EDIT_EVENTMASTER, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.push("/masters/EventMaster");
      toast("Edited Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};
