import AuctionPortal from "../../apis/AuctionPortal";
import history from "../../history";

import {
  FETCH_REGION,
  FETCH_REGIONs,
  DELETE_REGION,
  CREATE_REGION,
  EDIT_REGION,
  USER_LOGOUT,
} from "../types";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isSessionExpired, userLogout } from "../login/loginActions";

toast.configure({
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
});

export const fetchRegions = () => async (dispatch) => {
  try {
    const response = await AuctionPortal.get("/masters/region", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    dispatch({ type: FETCH_REGIONs, payload: response.data });
  } catch (error) {
    switch (error.response.status) {
      case 408:
        history.push("/sessionExpired");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        history.push("/BadRequest");
        break;
      default:
        toast.error("Error Occurred");
    }
  }
};

export const fetchRegionsForEmpanelment = () => async (dispatch) => {
  try {
    const response = await AuctionPortal.get("/masters/region/ForEmpanelment", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    dispatch({ type: FETCH_REGIONs, payload: response.data });
  } catch (error) {
    switch (error.response.status) {
      case 408:
        history.push("/sessionExpired");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        history.push("/BadRequest");
        break;
      default:
        toast.error("Error Occurred");
    }
  }
};

export const fetchRegion = (id) => async (dispatch) => {
  try {
    const response = await AuctionPortal.get(`/masters/region/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    dispatch({ type: FETCH_REGION, payload: response.data });
  } catch (error) {
    switch (error.response.status) {
      case 408:
        history.push("/sessionExpired");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        history.push("/BadRequest");
        break;
      default:
        toast.error("Error Occurred");
    }
  }
};

export const editRegion = (id, formValues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.put(
        `/masters/region/${id}`,
        {
          ...formValues,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: EDIT_REGION, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.push("/masters/location");
      toast("Edited Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const deleteRegion = (id, formValues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.put(
        `/masters/region/${id}`,
        {
          ...formValues,
          ActiveFLag: "N",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      dispatch({ type: DELETE_REGION, payload: response.data.id });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.push("/masters/location");
      toast.error("Deleted Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const createRegion = (formValues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.post("/masters/region", formValues, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      dispatch({ type: CREATE_REGION, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.push("/masters/location");
      toast("Added Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};
