import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchProducts } from "../../../actions/masters/productActions";
import { fetchAllSubProducts } from "../../../actions/masters/subProductActions";
import ProdList from "./ProdList";
import SubProdList from "./SubProdList";
import jwt_decode from "jwt-decode";

class ProdSubProdList extends Component {
  state = { productId: "", subProductId: "" };
  componentDidMount() {
    this.props.fetchAllSubProducts();
    this.props.fetchProducts();
  }
  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Product Master</h3>
          </div>
        </div>
        <div className="mainBodyCard">
          <div className="row m-0">
            <div className="col-md-6 col-sm-12 p-0">
              <ProdList
                products={this.props.products}
                user={this.props.userData}
              />
            </div>

            <div className="col-md-6 col-sm-12 p-0">
              <SubProdList
                subProducts={this.props.subProducts}
                products={this.props.products}
                user={this.props.userData}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    subProducts: Object.values(state.subProduct),
    products: Object.values(state.product),
    userData: localStorage.getItem("userToken")
      ? jwt_decode(localStorage.getItem("userToken"))
      : null,
  };
};

export default connect(mapStateToProps, { fetchAllSubProducts, fetchProducts })(
  ProdSubProdList
);
