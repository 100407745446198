import {
  CREATE_EVENT,
  EDIT_EVENT,
  FETCH_EVENT,
  FETCH_BUYER_EVENTS,
  FETCH_EVENTs,
  APPROVE_EVENT,
} from "../../actions/types";
import _ from "lodash";

const eventReducers = (state = {}, action) => {
  switch (action.type) {
    case EDIT_EVENT:
    case APPROVE_EVENT:
    case FETCH_EVENT:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };

    case CREATE_EVENT:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };

    case FETCH_EVENTs:
    case FETCH_BUYER_EVENTS:
      return { ...state, ..._.mapKeys(action.payload, "id"), loading: false };

    default:
      return state;
  }
};

export default eventReducers;
