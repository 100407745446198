export const FETCH_NOLs = "FETCH_NOLs";
export const FETCH_NOL = "FETCH_NOL";
export const EDIT_NOL = "EDIT_NOL";
export const CREATE_NOL = "CREATE_NOL";
export const DELETE_NOL = "DELETE_NOL";

export const FETCH_FRAUDs = "FETCH_FRAUDs";
export const FETCH_FRAUD = "FETCH_FRAUD";
export const EDIT_FRAUD = "EDIT_FRAUD";
export const CREATE_FRAUD = "CREATE_FRAUD";
export const DELETE_FRAUD = "DELETE_FRAUD";

export const FETCH_GROUPMAPPINGs = "FETCH_GROUPMAPPINGs";
export const FETCH_GROUPMAPPING = "FETCH_GROUPMAPPING";
export const EDIT_GROUPMAPPING = "EDIT_GROUPMAPPING";
export const CREATE_GROUPMAPPING = "CREATE_GROUPMAPPING";
export const DELETE_GROUPMAPPING = "DELETE_GROUPMAPPING";

export const FETCH_PRODUCTs = "FETCH_PRODUCTs";
export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";

export const FETCH_EVENTMASTER = "FETCH_EVENTMASTER";
export const EDIT_EVENTMASTER = "FETCH_EVENTMASTER";

export const FETCH_DEFAULTTIMEs = "FETCH_DEFAULTTIMEs";
export const FETCH_DEFAULTTIME = "FETCH_DEFAULTTIME";
export const EDIT_DEFAULTTIME = "EDIT_DEFAULTTIME";

export const FETCH_SUBPRODUCTs = "FETCH_SUBPRODUCTs";
export const FETCH_ALL_SUBPRODUCTS = "FETCH_ALL_SUBPRODUCTS";
export const FETCH_SUBPRODUCT = "FETCH_SUBPRODUCT";
export const EDIT_SUBPRODUCT = "EDIT_SUBPRODUCT";
export const CREATE_SUBPRODUCT = "CREATE_SUBPRODUCT";
export const DELETE_SUBPRODUCT = "DELETE_SUBPRODUCT";

export const FETCH_REGION = "FETCH_REGION";
export const FETCH_REGIONs = "FETCH_REGIONs";
export const DELETE_REGION = "DELETE_REGION";
export const CREATE_REGION = "CREATE_REGION";
export const EDIT_REGION = "EDIT_REGION";

export const FETCH_STATE = "FETCH_STATE";
export const FETCH_STATEs = "FETCH_STATEs";
export const FETCH_ALL_STATES = "FETCH_ALL_STATES";
export const DELETE_STATE = "DELETE_STATE";
export const CREATE_STATE = "CREATE_STATE";
export const EDIT_STATE = "EDIT_STATE";

export const FETCH_CITY = "FETCH_CITY";
export const FETCH_CITYs = "FETCH_CITYs";
export const FETCH_ALL_CITIES = "FETCH_ALL_CITIES";
export const DELETE_CITY = "DELETE_CITY";
export const CREATE_CITY = "CREATE_CITY";
export const EDIT_CITY = "EDIT_CITY";

export const FETCH_ROLEs = "FETCH_ROLEs";

export const CREATE_EVENT = "CREATE_EVENT";
export const FETCH_EVENT = "FETCH_EVENT";
export const FETCH_BUYER_EVENTS = "FETCH_BUYER_EVENTS";
export const FETCH_BUYER_EVENTMAPPING = "FETCH_BUYER_EVENTMAPPING";
export const BID_EVENT = "BID_EVENT";
export const EDIT_EVENT = "EDIT_EVENT";
export const FETCH_EVENTs = "FETCH_EVENTs";
export const APPROVE_EVENT = "APPROVE_EVENT";
export const FETCH_WORDS_FROM_NUMBERS = "FETCH_WORDS_FROM_NUMBERS";
export const FETCH_PAYMENT_REFUNDs = "FETCH_PAYMENT_REFUNDs";
export const FETCH_PAYMENT_REFUND = "FETCH_PAYMENT_REFUND";
export const ADMIN_PAYMENT_REFUND = "ADMIN_PAYMENT_REFUND";
export const BUYER_PAYMENT_REFUND = "BUYER_PAYMENT_REFUND";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOAD = "USER_LOAD";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_TOKEN_REFRESH = "USER_TOKEN_REFRESH";

export const FETCH_USERs = "FETCH_USERs";
export const FETCH_BUYERs = "FETCH_BUYERs";
export const FETCH_USER = "FETCH_USER";
export const CREATE_USER = "CREATE_USER";
export const EDIT_USER = "EDIT_USER";

export const FETCH_LDAPUSERs = "FETCH_LDAPUSERs";
export const CLEAR_LDAPUSER = "CLEAR_LDAPUSER";

export const FETCH_BUYER = "FETCH_BUYER";
export const EDIT_BUYER = "EDIT_BUYER";
export const ADD_BUYER_DOCs = "ADD_BUYER_DOCs";
export const FETCH_BUYER_DOCs = "FETCH_BUYER_DOCs";

export const FETCH_BUYER_DOCsStart = "FETCH_BUYER_DOCsStart";
export const FETCH_BUYER_DOCsEnd = "FETCH_BUYER_DOCsEnd";

export const ADD_EMPANELMENT_BUYER_DOCs = "ADD_EMPANELMENT_BUYER_DOCs";
export const ADD_EMPANELMENT_BUYER_DOC = "ADD_EMPANELMENT_BUYER_DOC";
export const FETCH_EMPANELMENT_BUYER_DOCs = "FETCH_EMPANELMENT_BUYER_DOCs";
export const CLEAR_BUYER_DOCs = "CLEAR_BUYER_DOCs";

export const SEARCH_AUCTION = "SEARCH_AUCTION";

export const FETCH_EVENT_PARAMETER = "FETCH_EVENT_PARAMETER";
export const CREATE_EVENT_PARAMETER = "CREATE_EVENT_PARAMETER";
export const EDIT_EVENT_PARAMETER = "EDIT_EVENT_PARAMETER";

export const CLEAR_EVENT_DOCs = "CLEAR_EVENT_DOCs";
export const DELETE_EVENT_DOCUMENT = "DELETE_EVENT_DOCUMENT";
export const ADD_EVENT_DOC = "ADD_EVENT_DOC";
export const FETCH_EVENT_DOC = "FETCH_EVENT_DOC";
export const ADD_EVENT_DOCsStart = "ADD_EVENT_DOCsStart";
export const ADD_EVENT_DOCsEnd = "ADD_EVENT_DOCsEnd";

export const FETCH_EVENT_BIDDERs = "FETCH_EVENT_BIDDERs";
export const ADD_EVENT_BIDDERs = "ADD_EVENT_BIDDERs";
export const REMOVE_EVENT_BIDDERs = "REMOVE_EVENT_BIDDERs";

export const CREATE_BUYER_EMPANELMENT = "CREATE_BUYER_EMPANELMENT";
export const FETCH_BUYER_EMPANELMENTs = "FETCH_BUYER_EMPANELMENTs";
export const FETCH_BUYER_EMPANELMENT = "FETCH_BUYER_EMPANELMENT";
export const BUYER_ADMIN_APPROVE = "BUYER_ADMIN_APPROVE";
export const BUYER_ADMIN_RELEASE = "BUYER_ADMIN_RELEASE";
export const BUYER_SALVAGE_APPROVE = "BUYER_SALVAGE_APPROVE";
export const BUYER_PAYMENT = "BUYER_PAYMENT";

export const EDIT_BUYERRMC = "EDIT_BUYERRMC";
export const FETCH_BUYERRMC_LISTS = "FETCH_BUYERRMC_LISTS";
export const FETCH_BUYER_RMCs = "FETCH_BUYER_RMCs";
export const FETCH_BUYER_RMC = "FETCH_BUYER_RMC";
export const BUYERRMC_ADMIN_APPROVE = "BUYERRMC_ADMIN_APPROVE";

export const FETCH_DIGILOCKER_LIST = "FETCH_DIGILOCKER_LIST";

export const GENERATE_OTP_SUCCESS = "GENERATE_OTP_SUCCESS";
export const GENERATE_OTP_FAIL = "GENERATE_OTP_FAIL";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_FAIL = "VALIDATE_OTP_FAIL";
export const CLEAR_OTP_STATE = "CLEAR_OTP_STATE";

export const OFFICERNAMES = "OFFICERNAMES";
export const CATEGORIES = "CATEGORIES";
export const POLICYPRODUCTS = "POLICYPRODUCTS";

export const USER_LAST_LOGIN = "USER_LAST_LOGIN";
export const FETCH_EVENT_BID_HISTORY = "FETCH_EVENT_BID_HISTORY";
export const FETCH_EVENT_RANK_LIST = "FETCH_EVENT_RANK_LIST";

export const ACCEPT_TERMS_AND_CONDITIONS = "ACCEPT_TERMS_AND_CONDITIONS";
export const FETCH_CURRENT_TIME = "FETCH_CURRENT_TIME";
export const REMOVE_MY_AUCTION="REMOVE_MY_AUCTION";


