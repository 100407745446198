import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import Modal from "../../Modal";
import Select from "react-select";
import { fetchRegions } from "../../../actions/masters/regionActions";
import { fetchAllStates } from "../../../actions/masters/stateActions";
import { fetchAllCities } from "../../../actions/masters/cityActions";
import { fetchFrauds } from "../../../actions/masters/fraudActions";

class BuyerForm extends Component {
  componentDidMount() {
    this.props.fetchRegions();
    this.props.fetchAllStates();
    this.props.fetchAllCities();
    this.props.fetchFrauds();
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, meta, disabled, type, maxLength }) => {
    return (
      <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
        <label className="d-block">{label}</label>
        <div className="input-field">
          <input
            {...input}
            type={type}
            maxLength={maxLength}
            autoComplete="off"
            placeholder={label}
            className="form-control"
            disabled={disabled}
          />
        </div>
        <div className="offset-md-4 error">{this.renderError(meta)}</div>
      </div>
    );
  };

  renderRadioInput = ({ input, label, meta, type }) => {
    return (
      <div className="radio">
        <input
          {...input}
          autoComplete="off"
          type={type}
          value={input.value}
          id={label}
        />

        <label className="radio-label" htmlFor={label}>
          {input.value === "Y"
            ? "Active"
            : input.value === "N"
            ? "In Active"
            : input.value === "A"
            ? "Unlocked"
            : input.value === "I"
            ? "Locked"
            : input.value}
        </label>

        <div className="offset-md-4 error">{this.renderError(meta)}</div>
      </div>
    );
  };

  renderSelectInput = ({ input, label, meta, options, placeholder }) => {
    return (
      <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
        <label className="d-block">{label}</label>
        <div className="">
          <Select
            {...input}
            value={
              input.value === ""
                ? null
                : options.find((obj) => obj.id === input.value)
            }
            onChange={input.onChange}
            onBlur={(value) => input.onBlur(value)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onBlurResetsInput={false}
            onCloseResetsInput={false}
            options={options}
            onFocus={input.onFocus}
            isSearchable={true}
            noOptionsMessage={() => null}
            placeholder={placeholder}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,

                primary25: "#ffa552",
                primary50: "#fabc82",
                primary: "#fcb26d",
              },
            })}
          />
          <div className="offset-md-4 error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  renderDate = ({ input, label, meta, minDate }) => {
    return (
      <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
        <div className="row m-0">
          <label className="d-block w-100">{label}</label>
          <div className="input-field">
            <input
              {...input}
              type="date"
              autoComplete="off"
              placeholder={label}
              className="form-control"
              min={minDate}
            />
          </div>
          <div className="text-danger" style={{ marginLeft: "30%" }}>
            {this.renderError(meta)}
          </div>
        </div>
      </div>
    );
  };

  onSubmit = (formValues) => {
    formValues.role_id = "5"; //To be changed according to role API
    formValues.userMasterId = this.props.initialValues.userMasterId;
    formValues.buyerMasterId = this.props.initialValues.buyerMasterId;
    formValues.temporaryBlacklistDate = `${
      formValues.temporaryBlacklistDateform
    }T${"00:00:00"}`;
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <div className="mainBodyCard pt-30">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          {this.props.LDAPUser[0] || this.props.EditUser ? (
            <div className="row m-0">
              <Field
                name="user_id"
                component={this.renderInput}
                label="Buyer ID"
                type="text"
                disabled={true}
              />
              <Field
                name="user_name"
                component={this.renderInput}
                label="Buyer Name"
                type="text"
                // disabled={true}
              />
              <Field
                name="email_id"
                component={this.renderInput}
                label="Email ID"
                type="text"
                // disabled={true}
              />
              <Field
                name="mobile_no"
                component={this.renderInput}
                label="Mobile No"
                type="text"
                maxLength="10"
              />
              <Field
                name="contact_no"
                component={this.renderInput}
                label="Contact No"
                maxLength="10"
                type="text"
              />
              {/* Buyer Master Feleds */}
              <Field
                name="fatherName"
                component={this.renderInput}
                label="Father's Name"
                type="text"
              />
              <hr />
              <div className="row m-0">
                <h5 className="col-sm-12 cardTitle border-0">
                  Present Address
                </h5>

                <Field
                  name="presentAddress"
                  component={this.renderInput}
                  label="Address"
                  type="text"
                />
                <Field
                  name="presentNearestLandmark"
                  component={this.renderInput}
                  label="Nearest Landmark"
                  type="text"
                />
                <Field
                  name="presentRegionId"
                  component={this.renderSelectInput}
                  placeholder="Select Present Region"
                  label="Region"
                  props={{
                    options: this.props.regions,
                  }}
                  normalize={(value) => value.id}
                />
                <Field
                  name="presentStateId"
                  component={this.renderSelectInput}
                  placeholder="Select Present State"
                  label="State"
                  props={{
                    options: this.props.states.filter((c) => {
                      return (
                        c.regionID === parseInt(this.props.presentRegionIdValue)
                      );
                    }),
                  }}
                  normalize={(value) => value.id}
                />
                <Field
                  name="presentCityId"
                  component={this.renderSelectInput}
                  placeholder="Select Present City"
                  label="City"
                  props={{
                    options: this.props.cities.filter((c) => {
                      return (
                        c.stateId === parseInt(this.props.PresentStateIdValue)
                      );
                    }),
                  }}
                  normalize={(value) => value.id}
                />
                <Field
                  name="presentPincode"
                  component={this.renderInput}
                  label="Pincode"
                  type="text"
                />
                <Field
                  name="presentTelephoneNumber"
                  component={this.renderInput}
                  label="Telephone Number"
                  type="text"
                />
                <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0 row m-0">
                  <label className="col-sm-12 p-0">Owned/Rental</label>
                  <div className="col-sm-12 p-0">
                    <Field
                      name="presentOwnedOrRental"
                      component={this.renderRadioInput}
                      type="radio"
                      label="presentOwnedOrRental1"
                      value="Owned"
                    />
                    <Field
                      name="presentOwnedOrRental"
                      component={this.renderRadioInput}
                      type="radio"
                      label="presentOwnedOrRental2"
                      value="Rental"
                    />
                  </div>
                </div>
                <Field
                  name="presentStayingSince"
                  component={this.renderDate}
                  label="Staying Since"
                />
              </div>
              <hr />
              <div className="col-md-12 col-sm-12 eventCreateInputCol pl-0 row m-0 pb-0 pr-0">
                <h5 className="col-sm-12 cardTitle border-0">
                  Permanent Address
                </h5>

                <Field
                  name="permanentAddress"
                  component={this.renderInput}
                  label="Address"
                  type="text"
                />
                <Field
                  name="permanentNearestLandmark"
                  component={this.renderInput}
                  label="Nearest Landmark"
                  type="text"
                />
                <Field
                  name="permanentRegionId"
                  component={this.renderSelectInput}
                  placeholder="Select Permanent Region"
                  label="Region"
                  props={{
                    options: this.props.regions,
                  }}
                  normalize={(value) => value.id}
                />
                <Field
                  name="permanentStateId"
                  component={this.renderSelectInput}
                  placeholder="Select Permanent State"
                  label="State"
                  props={{
                    options: this.props.states.filter((c) => {
                      return (
                        c.regionID ===
                        parseInt(this.props.permanentRegionIdValue)
                      );
                    }),
                  }}
                  normalize={(value) => value.id}
                />
                <Field
                  name="permanentCityId"
                  component={this.renderSelectInput}
                  placeholder="Select Permanent City"
                  label="City"
                  props={{
                    options: this.props.cities.filter((c) => {
                      return (
                        c.stateId === parseInt(this.props.PermanentStateIdValue)
                      );
                    }),
                  }}
                  normalize={(value) => value.id}
                />
                <Field
                  name="permanentPincode"
                  component={this.renderInput}
                  label="Pincode"
                  type="text"
                />
                <Field
                  name="permanentTelephoneNumber"
                  component={this.renderInput}
                  label="Telephone Number"
                  type="text"
                />
                <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
                  <label>Owned/Rental</label>
                  <div>
                    <Field
                      name="permanentOwnedOrRental"
                      component={this.renderRadioInput}
                      type="radio"
                      label="permanentOwnedOrRental1"
                      value="Owned"
                    />
                  </div>
                  <div>
                    <Field
                      name="permanentOwnedOrRental"
                      component={this.renderRadioInput}
                      type="radio"
                      label="permanentOwnedOrRental2"
                      value="Rental"
                    />
                  </div>
                </div>
                <Field
                  name="permanentStayingSince"
                  component={this.renderDate}
                  label="Staying Since"
                />
              </div>

              <hr />
              <Field
                name="residenceTelephone"
                component={this.renderInput}
                label="Residence Telephone"
                type="text"
              />
              <Field
                name="officeTelephone"
                component={this.renderInput}
                label="Office Telephone"
                type="text"
              />
              <Field
                name="mobileNumber"
                component={this.renderInput}
                label="Mobile Number"
                type="text"
              />
              <Field
                name="faxNumber"
                component={this.renderInput}
                label="Fax Number"
                type="text"
              />
              <Field
                name="drivingLicenseNumber"
                component={this.renderInput}
                label="Driving License Number"
                type="text"
              />
              <Field
                name="panNumber"
                component={this.renderInput}
                label="PAN Number"
                type="text"
              />
              <Field
                name="passportNumber"
                component={this.renderInput}
                label="Passport Number"
                type="text"
              />
              <Field
                name="savingBankAccountNumber"
                component={this.renderInput}
                label="Saving Bank Account Number"
                type="text"
              />
              <Field
                name="currentAccountNumber"
                component={this.renderInput}
                label="Current Account Number"
                type="text"
              />
              <Field
                name="carBikeNumber"
                component={this.renderInput}
                label="Car Bike Number"
                type="text"
              />
              <Field
                name="fraudTypeId"
                component={this.renderSelectInput}
                placeholder="Select Fraud Type"
                label="Fraud Type"
                props={{
                  options: this.props.fraudType,
                }}
                normalize={(value) => value.id}
              />
              {this.props.fraudTypeValue === 2 && (
                <Field
                  name="temporaryBlacklistDateform"
                  component={this.renderDate}
                  label="BlackList Till Date"
                  minDate={new Date().toISOString().split("T")[0]}
                />
              )}
              {/* 
                  FraudTypeId*/}

              {this.props.EditUser ? (
                <div className="row m-0 col-sm-12 p-0">
                  <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
                    <label>Active/Inactive</label>
                    <div>
                      <Field
                        name="active_flag"
                        component={this.renderRadioInput}
                        type="radio"
                        label="active_flag1"
                        value="Y"
                      />
                      <Field
                        name="active_flag"
                        component={this.renderRadioInput}
                        type="radio"
                        label="active_flag2"
                        value="N"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 eventCreateInputCol pl-0 pr-0">
                    <label>Locked/Unlocked</label>
                    <div>
                      <Field
                        name="active_status"
                        component={this.renderRadioInput}
                        type="radio"
                        label="active_status1"
                        value="A"
                      />
                      <Field
                        name="active_status"
                        component={this.renderRadioInput}
                        type="radio"
                        label="active_status2"
                        value="I"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="mainBodyCardFtBtn mt-50">
                <button type="submit" className="btnMain">
                  Save
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </form>
        <Modal onDeleteClick={this.props.onDelete} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const selector = formValueSelector("UserForm");
  const PresentStateIdValue = selector(state, "presentStateId");
  const PermanentStateIdValue = selector(state, "permanentStateId");
  const presentRegionIdValue = selector(state, "presentRegionId");
  const permanentRegionIdValue = selector(state, "permanentRegionId");
  const fraudTypeValue = selector(state, "fraudTypeId");

  return {
    PresentStateIdValue,
    PermanentStateIdValue,
    presentRegionIdValue,
    permanentRegionIdValue,
    fraudTypeValue,
    LDAPUser: Object.values(state.LDAPUser),
    regions: Object.values(state.region),
    states: Object.values(state.state),
    cities: Object.values(state.city),
    fraudType: Object.values(state.fraudType),
  };
};

const validate = (formValues) => {
  const errors = {};
  if (!formValues.user_id) {
    errors.user_id = "This Field is Required";
  }
  if (!formValues.user_name) {
    errors.user_name = "This Field is Required";
  }
  if (!formValues.email_id) {
    errors.email_id = "This Field is Required";
  }
  if (formValues.mobile_no && formValues.mobile_no.length !== 10) {
    errors.mobile_no = "Mobile no must be less than 10 digits";
  }
  if (formValues.contact_no && formValues.contact_no.length !== 10) {
    errors.contact_no = "This Field is Required";
  }
  if (!formValues.role_id) {
    errors.role_id = "This Field is Required";
  }

  if (!formValues.presentAddress) {
    errors.presentAddress = "This Field is Required";
  }
  if (!formValues.presentNearestLandmark) {
    errors.presentNearestLandmark = "This Field is Required";
  }
  if (
    formValues.presentStateId === 0 ||
    formValues.presentStateId === undefined
  ) {
    errors.presentStateId = "This Field is Required";
  }
  if (
    formValues.presentCityId === 0 ||
    formValues.presentCityId === undefined
  ) {
    errors.presentCityId = "This Field is Required";
  }
  if (!formValues.presentPincode) {
    errors.presentPincode = "This Field is Required";
  }
  if (!formValues.PresentOwnedOrRental) {
    errors.PresentOwnedOrRental = "This Field is Required";
  }
  if (formValues.fraudTypeId === 2 && !formValues.temporaryBlacklistDateform) {
    errors.temporaryBlacklistDateform = "This Field is Required";
  }
  return errors;
};

const buyerForm = reduxForm({
  form: "UserForm",
  validate,
  enableReinitialize: true,
})(BuyerForm);

export default connect(mapStateToProps, {
  fetchRegions,
  fetchAllCities,
  fetchAllStates,
  fetchFrauds,
})(buyerForm);
