import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCities } from "../../../actions/masters/cityActions";
import history from "../../../history";

class CityList extends Component {
  componentDidMount() {
    this.props.fetchCities(this.props.match.params.sid);
  }

  renderList() {
    const { id, sid } = this.props.match.params;
    return this.props.cities.map((city) => {
      if (city.stateId === parseInt(sid)) {
        return (
          <tr key={city.id}>
            <td>{city.name}</td>
            {this.props.user.role === "Admin" ? (
              <td data-th="Edit">
                <Link
                  to={`/masters/region/${id}/state/${sid}/city/${city.id}/edit`}
                >
                  <span>Edit</span>
                </Link>
              </td>
            ) : (
              ""
            )}
          </tr>
        );
      }
      return null;
    });
  }

  render() {
    const { id, sid } = this.props.match.params;
    return (
      <div
        className="container"
        style={{ padding: "80px 30px", maxWidth: "800px" }}
      >
        <div className="float-end mt-15">
          <Link
            className="float-end cardBackBtn"
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </Link>
        </div>
        <div className="container">
          <div className="row" style={{ paddingBottom: "5px" }}>
            <div
              className="Header-Content col-8"
              style={{ paddingTop: "5px", paddingBottom: "5px" }}
            >
              <h3 style={{ marginBottom: "0px" }}>City Master</h3>
            </div>
            {this.props.user.role === "Admin" ? (
              <div className="col-4" style={{ padding: "5px 15px" }}>
                <Link
                  className="btnMain"
                  style={{
                    padding: "7px",
                    textAlign: "center",
                    minWidth: "70px",
                  }}
                  to={`/masters/region/${id}/state/${sid}/city/create`}
                >
                  Add
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            <h5
              style={{
                borderTop: "1px solid #dee2e6",
                borderBottom: "2px solid #dee2e6",
                marginBottom: "0px",
                padding: "5px 0px 5px 15px",
              }}
            >
              Name
            </h5>
          </div>
          <table className="table table-hover">
            <tbody>{this.renderList()}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cities: Object.values(state.city),
  };
};

export default connect(mapStateToProps, { fetchCities })(CityList);
