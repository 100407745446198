import _ from "lodash";

import {
  FETCH_SUBPRODUCTs,
  FETCH_SUBPRODUCT,
  EDIT_SUBPRODUCT,
  CREATE_SUBPRODUCT,
  DELETE_SUBPRODUCT,
  FETCH_ALL_SUBPRODUCTS,
} from "../../actions/types";

const subProductReducers = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SUBPRODUCTs:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case FETCH_ALL_SUBPRODUCTS:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case FETCH_SUBPRODUCT:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_SUBPRODUCT:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_SUBPRODUCT:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_SUBPRODUCT:
      return _.omit(state, action.payload);

    default:
      return state;
  }
};

export default subProductReducers;
