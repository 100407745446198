import React, { Component } from "react";

class BuyerEmpanelmentDocView extends Component {
  renderList(documents) {
    return documents.map((document) => {
      return (
        <tr key={document.id}>
          <td>{document.fileName}</td>
          <td data-th="Download">
            {document.fileType === ".pdf" ? (
              <a
                href={`data:application/pdf;base64,${document.dmsDocContent}`}
                download={document.fileName}
              >
                Download
              </a>
            ) : (
              <a
                href={`data:application/octet-stream;base64,${document.dmsDocContent}`}
                download={document.fileName}
              >
                Download
              </a>
            )}
          </td>
        </tr>
      );
    });
  }
  render() {
    return (
      <div
        className="modal fade bd-example-modal-lg bedv_viewDocumentsModel"
        id="viewDocumentsModel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="viewDocumentsModelTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="viewDocumentsModelTitle">
                View Documents
              </h5>
              <button
                type="button"
                className="close docButton"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-hover tableStyle1">
                <thead>
                  <tr>
                    <td>FileName</td>
                    <td>Download</td>
                  </tr>
                </thead>
                <tbody>{this.renderList(this.props.buyerEmpDocuments)}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BuyerEmpanelmentDocView;
