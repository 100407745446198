import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
  fetchState,
  editState,
  deleteState,
} from "../../../actions/masters/stateActions";
import StateForm from "./StateForm";
import { Link } from "react-router-dom";
import { fetchRegions } from "../../../actions/masters/regionActions";
import history from "../../../history";

class StateEdit extends Component {
  componentDidMount() {
    this.props.fetchState(this.props.match.params.sid);
    this.props.fetchRegions();
  }

  onSubmit = (formValues) => {
    this.props.editState(
      this.props.match.params.id,
      this.props.match.params.sid,
      formValues
    );
  };

  onClickDelete = () => {
    this.props.deleteState(
      this.props.match.params.id,
      this.props.match.params.sid,
      this.props.states
    );
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Edit State</h3>
          </div>
          <div className="float-end mt-15">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        <StateForm
          initialValues={_.pick(
            this.props.states,
            "name",
            "shortName",
            "activeFlag",
            "regionID"
          )}
          onSubmit={this.onSubmit}
          onDelete={this.onClickDelete}
          regions={this.props.regions}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    states: state.state[ownProps.match.params.sid],
    regions: Object.values(state.region),
  };
};

export default connect(mapStateToProps, {
  editState,
  fetchState,
  deleteState,
  fetchRegions,
})(StateEdit);
