import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import {
  fetchEventMaster,
  editEventMaster,
} from "../../../actions/masters/EventMasterActions";
import { connect } from "react-redux";
import Spinner from "../../Spinner";
import history from "../../../history";

class EventMasterEdit extends Component {
  componentDidMount() {
    this.props.fetchEventMaster();
  }

  renderError = ({ error, touched }) => {
    if (touched && error) {
      return <div>{error}</div>;
    }
  };

  renderTextInput = ({ input, label, meta }) => {
    return (
      <div className="w-100 d-block overflow-hidden">
        <div className="form-group">
          <div className="input-field">
            <input
              {...input}
              autoComplete="off"
              placeholder={label}
              className="form-control"
            />
          </div>
          <div className="error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  renderRadioInput = ({ input, meta, type, label }) => {
    return (
      <div className="radio">
        <input
          className=""
          {...input}
          autoComplete="off"
          type={type}
          value={input.value}
          id={label}
        />
        <label className="radio-label" htmlFor={label}>
          {input.value === true
            ? "True"
            : input.value === false
            ? "False"
            : input.value}
        </label>
        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.editEventMaster(formValues);
  };

  render() {
    return (
      <div>
        {Object.values(this.props.initialValues).length === 0 ? (
          <Spinner />
        ) : (
          <div className="BodyInContainer">
            <div className="d-block clearfix">
              <div className="Header-Content float-start">
                <h3 className="pageMainTitle">Edit Event Master</h3>
              </div>
              <div className="float-end mt-15">
                <Link
                  className="float-end cardBackBtn"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </Link>
              </div>
            </div>
            <div className="mainBodyCard">
              <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Auction Base Currency
                    </label>
                    <Field
                      name="auctionBaseCurrency"
                      component={this.renderTextInput}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Auction Variant
                    </label>
                    <Field
                      name="auctionVariant"
                      component={this.renderTextInput}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Auction Type
                    </label>
                    <Field
                      name="auctionType"
                      label="auctionTypeStandard"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Standard"
                    />
                    <Field
                      name="auctionType"
                      label="auctionTypeRank"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Rank"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Bidding Price Increment In
                    </label>
                    <Field
                      name="biddingPriceIncrementIn"
                      label="biddingPriceIncrementInFigure"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Figure"
                    />
                    <Field
                      name="biddingPriceIncrementIn"
                      label="biddingPriceIncrementInRadio"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Percentage"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Increment Decrement In Multiples
                    </label>
                    <div>
                      <Field
                        name="incrementDecrementInMultiples"
                        label="incrementDecrementInMultiplesTrue"
                        component={this.renderRadioInput}
                        type="radio"
                        value={true}
                        normalize={(value) => value === "true"}
                      />
                      <Field
                        name="incrementDecrementInMultiples"
                        label="incrementDecrementInMultiplesFalse"
                        component={this.renderRadioInput}
                        type="radio"
                        value={false}
                        normalize={(value) => value === "true"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Reserve Price Check Required
                    </label>
                    <Field
                      name="reservePriceCheckRequired"
                      label="reservePriceCheckRequiredTrue"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="reservePriceCheckRequired"
                      label="reservePriceCheckRequiredFalse"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      normalize={(value) => value === "true"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Item Selection Required
                    </label>
                    <Field
                      name="itemSelectionRequired"
                      label="itemSelectionRequiredTrue"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="itemSelectionRequired"
                      label="itemSelectionRequiredFalse"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      normalize={(value) => value === "true"}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Line Item Wise Bid Submission Time Allowed
                    </label>
                    <Field
                      name="lineItemWiseBidSubmissionTimeAllowed"
                      label="lineItemWiseBidSubmissionTimeAllowedTrue"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="lineItemWiseBidSubmissionTimeAllowed"
                      label="lineItemWiseBidSubmissionTimeAllowedFalse"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      normalize={(value) => value === "true"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Bid Submission Mode
                    </label>
                    <Field
                      name="bidSubmissionMode"
                      label="bidSubmissionModeSerial"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Serial"
                    />
                    <Field
                      name="bidSubmissionMode"
                      label="bidSubmissionModeParallel"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Parallel"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Auto Bid Allowed
                    </label>
                    <Field
                      name="autoBidAllowed"
                      label="autoBidAllowedTrue"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="autoBidAllowed"
                      label="autoBidAllowedFalse"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      normalize={(value) => value === "true"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Allow Mobile Bidding
                    </label>
                    <Field
                      name="allowMobileBidding"
                      label="allowMobileBiddingTrue"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="allowMobileBidding"
                      label="allowMobileBiddingFalse"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      normalize={(value) => value === "true"}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Bidding Form
                    </label>
                    <Field
                      name="biddingForm"
                      label="biddingFormStandardForm"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Use standard form"
                    />
                    <Field
                      name="biddingForm"
                      label="biddingFormNewForm"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Create new form"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Allowed Auto Extension
                    </label>
                    <Field
                      name="allowedAutoExtension"
                      label="allowedAutoExtensionTrue"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="allowedAutoExtension"
                      label="allowedAutoExtensionFalse"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      normalize={(value) => value === "true"}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Auto Extension Mode
                    </label>
                    <Field
                      name="autoExtensionMode"
                      label="autoExtensionModeLimited"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Limited extensions"
                    />
                    <Field
                      name="autoExtensionMode"
                      label="autoExtensionModeUnlimited"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Unlimited extensions"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      First Bid Acceptance Condition
                    </label>
                    <Field
                      name="firstBidAcceptanceCondition"
                      label="firstBidAcceptanceConditionStart"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Accept start price"
                    />
                    <Field
                      name="firstBidAcceptanceCondition"
                      label="firstBidAcceptanceConditionStartPlusIncrement"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Accept start price + increment value"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Display L1 H1 Amount
                    </label>
                    <Field
                      name="displayL1_H1Amount"
                      label="displayL1_H1AmountTrue"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="displayL1_H1Amount"
                      label="displayL1_H1AmountFalse"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      normalize={(value) => value === "true"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Demo Auction
                    </label>
                    <Field
                      name="demoAuction"
                      label="demoAuctiontrue"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="demoAuction"
                      label="demoAuctionFalse"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      normalize={(value) => value === "true"}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Display IP Address
                    </label>
                    <Field
                      name="displayIPAddress"
                      label="displayIPAddressTrue"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="displayIPAddress"
                      label="displayIPAddressFalse"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      normalize={(value) => value === "true"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Display Price Bid Break Up Link
                    </label>
                    <Field
                      name="displayPriceBidBreakUpLink"
                      label="displayPriceBidBreakUpLinkTrue"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="displayPriceBidBreakUpLink"
                      label="displayPriceBidBreakUpLinkFalse"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      normalize={(value) => value === "true"}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Hide Live Bidding To Department User
                    </label>
                    <Field
                      name="hideLiveBiddingToDepartmentUser"
                      label="hideLiveBiddingToDepartmentUserTrue"
                      component={this.renderRadioInput}
                      type="radio"
                      value={true}
                      normalize={(value) => value === "true"}
                    />
                    <Field
                      name="hideLiveBiddingToDepartmentUser"
                      label="hideLiveBiddingToDepartmentUserFalse"
                      component={this.renderRadioInput}
                      type="radio"
                      value={false}
                      normalize={(value) => value === "true"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="form_label d-block w-100">
                      Bid Submission For
                    </label>
                    <Field
                      name="bidSubmissionFor"
                      label="bidSubmissionForGrandTotal"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Grand total"
                    />
                    <Field
                      name="bidSubmissionFor"
                      label="bidSubmissionForEachLineItem"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Each line item"
                    />
                    <Field
                      name="bidSubmissionFor"
                      label="bidSubmissionForEachLineItemAndGrandTotal"
                      component={this.renderRadioInput}
                      type="radio"
                      value="Each line item and grand total"
                    />
                  </div>
                </div>
                <div className="mainBodyCardFtBtn mt-50">
                  <button className="btnMain">Save</button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.auctionBaseCurrency) {
    errors.auctionBaseCurrency = "Enter Auction Based Currency";
  }
  if (!formValues.auctionVariant) {
    errors.auctionVariant = "Enter Auction Variant";
  }
  return errors;
};

const eventMaster = reduxForm({
  form: "eventmaster",
  validate,
  enableReinitialize: true,
})(EventMasterEdit);

const mapStateToProps = (state) => {
  const initialValues = {};
  if (state.eventMaster[1] !== undefined) {
    initialValues.auctionBaseCurrency =
      state.eventMaster[1].auctionBaseCurrency;
    initialValues.auctionVariant = state.eventMaster[1].auctionVariant;
    initialValues.auctionType = state.eventMaster[1].auctionType;
    initialValues.biddingPriceIncrementIn =
      state.eventMaster[1].biddingPriceIncrementIn;
    initialValues.incrementDecrementInMultiples =
      state.eventMaster[1].incrementDecrementInMultiples;
    initialValues.bidSubmissionFor = state.eventMaster[1].bidSubmissionFor;
    initialValues.reservePriceCheckRequired =
      state.eventMaster[1].reservePriceCheckRequired;
    initialValues.itemSelectionRequired =
      state.eventMaster[1].itemSelectionRequired;
    initialValues.lineItemWiseBidSubmissionTimeAllowed =
      state.eventMaster[1].lineItemWiseBidSubmissionTimeAllowed;
    initialValues.bidSubmissionMode = state.eventMaster[1].bidSubmissionMode;
    initialValues.autoBidAllowed = state.eventMaster[1].autoBidAllowed;
    initialValues.allowMobileBidding = state.eventMaster[1].allowMobileBidding;
    initialValues.biddingForm = state.eventMaster[1].biddingForm;
    initialValues.allowedAutoExtension =
      state.eventMaster[1].allowedAutoExtension;
    initialValues.autoExtensionMode = state.eventMaster[1].autoExtensionMode;
    initialValues.firstBidAcceptanceCondition =
      state.eventMaster[1].firstBidAcceptanceCondition;
    initialValues.displayL1_H1Amount = state.eventMaster[1].displayL1_H1Amount;
    initialValues.demoAuction = state.eventMaster[1].demoAuction;
    initialValues.displayIPAddress = state.eventMaster[1].displayIPAddress;
    initialValues.displayPriceBidBreakUpLink =
      state.eventMaster[1].displayPriceBidBreakUpLink;
    initialValues.hideLiveBiddingToDepartmentUser =
      state.eventMaster[1].hideLiveBiddingToDepartmentUser;
  }
  return {
    eventMaster: Object.values(state.eventMaster),
    initialValues,
  };
};

export default connect(mapStateToProps, { fetchEventMaster, editEventMaster })(
  eventMaster
);
