import _ from "lodash";

import {
  FETCH_GROUPMAPPINGs,
  FETCH_GROUPMAPPING,
  EDIT_GROUPMAPPING,
  CREATE_GROUPMAPPING,
  DELETE_GROUPMAPPING,
} from "../../actions/types";

const groupmappingReducers = (state = {}, action) => {
  switch (action.type) {
    case FETCH_GROUPMAPPINGs:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case FETCH_GROUPMAPPING:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_GROUPMAPPING:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_GROUPMAPPING:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_GROUPMAPPING:
      return _.omit(state, action.payload);

    default:
      return state;
  }
};

export default groupmappingReducers;
