import AuctionPortal from "../../apis/AuctionPortal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { encryptId,decryptIndex } from "../../components/Encryption";
import {
  CREATE_BUYER_EMPANELMENT,
  FETCH_BUYER_EMPANELMENTs,
  FETCH_BUYER_EMPANELMENT,
  BUYER_ADMIN_APPROVE,
  BUYER_ADMIN_RELEASE,
  BUYER_SALVAGE_APPROVE,
  BUYER_PAYMENT,
  USER_LOGOUT,
} from "../types";
import { isSessionExpired, userLogout } from "../login/loginActions";
import history from "../../history";
import CryptoJS from "crypto-js"; //Added By Mayur
import { forEach } from "lodash";


toast.configure({
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
});

export const createBuyerEmpanelment = (formValues) => async (dispatch) => {
 
  try {
    document.getElementById("spinner").classList.add("spinnerDisplay");
    
    var key='';
    var iv='';
      for (let index = 0; index < 4; index++) {
        key += "8080";
        iv +="8080"; 
        }
        key = CryptoJS.enc.Utf8.parse(key);
         iv = CryptoJS.enc.Utf8.parse(iv);
        
    formValues.firstName = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.firstName+"~"+new Date().toISOString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    formValues.lastName = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.lastName+"~"+new Date().toISOString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    formValues.email = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.email+"~"+new Date().toISOString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    formValues.presentAddress = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.presentAddress+"~"+new Date().toISOString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    formValues.presentNearestLandmark = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.presentNearestLandmark+"~"+new Date().toISOString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    formValues.presentPincode = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.presentPincode+"~"+new Date().toISOString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    formValues.presentTelephoneNumber = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.presentTelephoneNumber+"~"+new Date().toISOString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    formValues.panNumber = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.panNumber+"~"+new Date().toISOString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    formValues.officeAddress = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.officeAddress+"~"+new Date().toISOString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    formValues.mobileNumber = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.mobileNumber+"~"+new Date().toISOString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    
    formValues.permanentAddress = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.permanentAddress+"~"+new Date().toISOString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    formValues.permanentNearestLandmark = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.permanentNearestLandmark+"~"+new Date().toISOString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    formValues.permanentPincode = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.permanentPincode+"~"+new Date().toISOString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    formValues.permanentTelephoneNumber = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.permanentTelephoneNumber+"~"+new Date().toISOString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
      formValues.drivingLicenseNumber = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(formValues.drivingLicenseNumber+"~"+new Date().toISOString()),
        key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
        ).toString();
      
  	formValues.firmPAN = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(formValues.firmPAN+"~"+new Date().toISOString()),
        key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
    
      formValues.mobileNumber2 = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(formValues.mobileNumber2+"~"+new Date().toISOString()),
        key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
    

      formValues.gstNo = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(formValues.gstNo+"~"+new Date().toISOString()),
        key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
    


    const response = await AuctionPortal.post(
      "/BuyerEmpanelment",
      {
        ...formValues,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    var k = '';
    var d = new Date().toLocaleString();
    for (let index = 0; index <4; index++) {
    k += "8080";
    var value = "";
  }
    const decryptedData = {};
    debugger;
    for (const key in response.data) {
      value = response.data[key];
      if (response.data.hasOwnProperty(key)&& value != null && value.length>5)
      {
        while(value.includes('zxcvb'))
        value = value.replace('zxcvb','/')
        while(value.includes('plus'))
        value = value.replace('plus','+')
        if(!(value.includes('T') && value.includes(':'))){
        value = await CryptoJS.AES.decrypt(
          value,
          CryptoJS.enc.Utf8.parse(k),
          {
            keySize: 128 / 8,
            iv:CryptoJS.enc.Utf8.parse(k),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
          }
        ).toString(CryptoJS.enc.Utf8);
        value = value.substring(0,value.indexOf('~'));
        response.data[key]=value.toString();
      }
      }
    }
    dispatch({ type: CREATE_BUYER_EMPANELMENT, payload: response.data });

    document.getElementById("spinner").classList.remove("spinnerDisplay");
    toast("Empanelment Process initiated");
  } 
  catch (error) {
    document.getElementById("spinner").classList.remove("spinnerDisplay");
    switch (error.response.status) {
      case 408:
        history.push("/MultipleLogin");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        toast.error(error.response.data.Description[0]);
        break;
      default:
        toast.error("Error Occurred");
    }
  }
};

export const fetchBuyerEmpanelmentsList = () => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get("/BuyerEmpanelment", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      let k = '';
      for (let index = 0; index < 4; index++) {
        k += "8080";
      }
      
     
      
      for(const major in response.data){
        for(const key in response.data[major]){
          var value = response.data[major][key];
          try{
          if (response.data[major].hasOwnProperty(key) && value !==null && value.length>5)
          {
            while(value.includes('zxcvb'))
            value = value.replace('zxcvb','/')
            while(value.includes('plus'))
            value = value.replace('plus','+')
            if(!(value.includes('T') && value.includes(':')))
            {
            value = await CryptoJS.AES.decrypt(
              value,
              CryptoJS.enc.Utf8.parse(k),
              {
                keySize: 128 / 8,
                iv:CryptoJS.enc.Utf8.parse(k),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
              }
            ).toString(CryptoJS.enc.Utf8);
            value = value.substring(0,value.indexOf('~'));
            response.data[major][key]=value.toString();
          }
          }}
          catch(error){
           
            console.log(error);
           
           
          }
        }
       
        
      }
      dispatch({ type: FETCH_BUYER_EMPANELMENTs, payload: response.data });
      
      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const fetchBuyerEmpanelment = (id) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get(`/BuyerEmpanelment/${encryptId(id)}/azid`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
     
      var k = '';
      var d = new Date().toLocaleString();
      for (let index = 0; index <4; index++) {
      k += "8080";
      var value = "";
    }
      const decryptedData = {};
      debugger;
      for (const key in response.data) {
        value = response.data[key];
        if (response.data.hasOwnProperty(key) && value != null && value.length>5)
        {
          while(value.includes('zxcvb'))
          value = value.replace('zxcvb','/')
          while(value.includes('plus'))
          value = value.replace('plus','+')
          if(!(value.includes('T') && value.includes(':'))) {
          value = await CryptoJS.AES.decrypt(
            value,
            CryptoJS.enc.Utf8.parse(k),
            {
              keySize: 128 / 8,
              iv:CryptoJS.enc.Utf8.parse(k),
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          ).toString(CryptoJS.enc.Utf8);
          value = value.substring(0,value.indexOf('~'));
          response.data[key]=value.toString();
        }
      }
      }
      //ended by siddharth
      dispatch({ type: FETCH_BUYER_EMPANELMENT, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const adminBuyerApprove = (id, value) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      let ApprovalRemarks = "";
      if (value == "N") {
        ApprovalRemarks = prompt("Rejection Remarks ");
      } else {
        ApprovalRemarks = "Done";
      }
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.put(
        `BuyerEmpanelment/${encryptId(id)}/MakerApproval`,
        {
          isApproved: value,
          remarks: ApprovalRemarks,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      document
        .getElementById(`empanelmentCard_${id}`)
        .classList.remove("cardFadeIn");
      document
        .getElementById(`empanelmentCard_${id}`)
        .classList.add("cardFadeOut");

      setTimeout(function () {
        dispatch({ type: BUYER_ADMIN_APPROVE, payload: response.data });
      }, 500);

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      if (value === "Y") {
        toast("Buyer Approved By Admin");
      } else {
        toast("Buyer Rejected By Admin");
      }
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const salvageBuyerApprove = (id, value) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      let ApprovalRemarks = "";
      if (value == "N") {
        ApprovalRemarks = prompt("Rejection Remarks ");
      } else {
        ApprovalRemarks = "Done";
      }
      if (ApprovalRemarks !== "") {
        document.getElementById("spinner").classList.add("spinnerDisplay");
        const response = await AuctionPortal.put(
          `BuyerEmpanelment/${encryptId(id)}/CheckerApproval`,
          {
            isApproved: value,
            remarks: ApprovalRemarks,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          }
        );
        document
          .getElementById(`empanelmentCard_${id}`)
          .classList.remove("cardFadeIn");
        document
          .getElementById(`empanelmentCard_${id}`)
          .classList.add("cardFadeOut");

        setTimeout(function () {
          dispatch({ type: BUYER_SALVAGE_APPROVE, payload: response.data });
        }, 500);

        document.getElementById("spinner").classList.remove("spinnerDisplay");
        if (value === "Y") {
          toast("Buyer Approved By Salvage");
        } else {
          toast("Buyer Rejected By Salvage");
        }
      }
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const adminBuyerRelease = (id, value) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.put(
        `BuyerEmpanelment/${encryptId(id)}/AdminRelease`,
        {
          isApproved: value,
          // remarks: ApprovalRemarks,
          remarks: "Done",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      document
        .getElementById(`empanelmentCard_${id}`)
        .classList.remove("cardFadeIn");
      document
        .getElementById(`empanelmentCard_${id}`)
        .classList.add("cardFadeOut");

      setTimeout(function () {
        dispatch({ type: BUYER_ADMIN_RELEASE, payload: response.data });
      }, 500);

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      if (value === "Y") {
        toast("Buyer Released By Admin");
        // }
      }
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const buyerPayment = (id, formValues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.put(
        `BuyerEmpanelment/${id}/payments`,
        {
          ...formValues,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: BUYER_PAYMENT, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          toast.error(error.response.data.Description[0]);
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};
