import React from "react";

const Spinner = () => {
  return (
    <div className="preloaderContainerMain " id="spinner">
      <div className="preloaderContainer">
        <div className="spinner-grow text-dark"></div>
        <div className="spinner-grow text-light"></div>
        <div className="spinner-grow text-dark"></div>
        <div className="spinner-grow text-light"></div>
        <div className="spinner-grow text-dark"></div>
        <div className="spinner-grow text-light"></div>
        <div className="spinner-grow text-dark"></div>
        <div className="spinner-grow text-light"></div>
      </div>
    </div>
  );
};

export default Spinner;
