import React, { Component } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import Spinner from "../../Spinner";

class SubProdList extends Component {
  state = { selectedSubProduct: "", selectedProduct: "" };

  handleSelect = (val) => {
    const product = this.props.products.filter(
      (prod) => prod.id === val.productId
    )[0];
    this.setState({
      selectedSubProduct: val,
      selectedProduct: product,
    });
  };

  customFilter = (option, searchText) => {
    if (option.data.name.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <div>
        {this.props.subProducts.length > 0 ? (
          <div className="row m-0">
            <div className="col-md-12 col-sm-12 pb-5 pl-0 mb_p_0">
              <div className="Header-Content overflow-hidden">
                <h5 className="form_label">Sub Products</h5>
              </div>
              <div className="">
                <Select
                  onChange={this.handleSelect}
                  getOptionLabel={(option) => `${option.name} `}
                  getOptionValue={(option) => `${option.id}`}
                  isOptionSelected={(option) => {
                    return this.state.selectedSubProduct.id === option.id
                      ? true
                      : false;
                  }}
                  options={this.props.subProducts}
                  isSearchable={true}
                  filterOption={this.customFilter}
                  onInputChange={this.handleInputChange}
                  noOptionsMessage={() => null}
                  placeholder={"Search SubProduct"}
                  autoFocus={false}
                  menuIsOpen={this.state.menuOpen}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,

                      primary25: "#ffa552",
                      primary50: "#fabc82",
                      primary: "#fcb26d",
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 pl-0 locationTableDiv mb_p_0">
              {this.state.selectedSubProduct !== "" ? (
                <div className="">
                  <table className="table table-hover tableStyle2">
                    <thead>
                      <tr>
                        <td>SubProduct</td>
                        <td>Product</td>
                        <td>Is Active</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td data-th="SubProduct">
                          {this.state.selectedSubProduct.name}
                        </td>
                        <td data-th="Product">
                          {this.state.selectedProduct.name}
                        </td>
                        <td data-th="Is Active">
                          {this.state.selectedSubProduct.activeFlag === "Y"
                            ? "True"
                            : "False"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {this.props.user.role === "Admin" ? (
                    <div className="row m-0">
                      <div className="col p-0">
                        <Link
                          to={`/masters/product/${this.state.selectedProduct.id}/subproduct/${this.state.selectedSubProduct.id}/edit`}
                          className="linkBtn1"
                        >
                          Modify
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

export default SubProdList;
