import React from "react";
import { connect } from "react-redux";
import { tokenRefresh, userLogout } from "../../actions/login/loginActions";

const TokenRefresh = ({ tokenRefresh, userLogout, timeRemaining }) => {
  return (
    <div className="preloaderContainerMain spinnerDisplay" id="token">
      <div className="preloaderContainer">
        <div
          className="modal-content"
          style={{ width: "500px", borderRadius: "10px" }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Session Expire Warning
            </h5>
          </div>
          <div className="modal-body">
            <h6>
              Your Session is about to expire. You will be logged out in{" "}
              <b>{timeRemaining} seconds</b>
            </h6>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={() => userLogout()}>
              Logout
            </button>
            <button className="btn btn-primary" onClick={() => tokenRefresh()}>
              Continue Session
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { tokenRefresh, userLogout })(TokenRefresh);
