import AuctionPortal from "../../../apis/AuctionPortal";
import {
  FETCH_PAYMENT_REFUNDs,
  FETCH_PAYMENT_REFUND,
  ADMIN_PAYMENT_REFUND,
  BUYER_PAYMENT_REFUND,
  USER_LOGOUT,
} from "../../types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.minimal.css";
import { isSessionExpired, userLogout } from "../../login/loginActions";
import history from "../../../history";

toast.configure({
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
});

export const fetchPaymentRefunds = () => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get(`/paymentrefund`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      dispatch({ type: FETCH_PAYMENT_REFUNDs, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/sessionExpired");
          dispatch({ type: USER_LOGOUT });

          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const fetchPaymentRefund = (userId) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.get(
        `/paymentrefund/buyer/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: FETCH_PAYMENT_REFUND, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          history.push("/BadRequest");
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const adminPaymentRefund =
  (id, pay, remarks, amount, date, flag) => async (dispatch) => {
    if (isSessionExpired()) {
      history.push("/SessionExpired");
    } else {
      try {
        document.getElementById("spinner").classList.add("spinnerDisplay");
        const response = await AuctionPortal.put(
          `/paymentrefund/${id}`,
          {
            PaymentRefNo: pay,
            Remarks: remarks,
            Amount: parseInt(amount),
            PaymentDate: new Date(date),
            ActiveFlag: flag,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          }
        );
        dispatch({ type: ADMIN_PAYMENT_REFUND, payload: response.data });

        document.getElementById("spinner").classList.remove("spinnerDisplay");
        toast("Payment Successful");
      } catch (error) {
        document.getElementById("spinner").classList.remove("spinnerDisplay");
        switch (error.response.status) {
          case 408:
            history.push("/sessionExpired");
            dispatch({ type: USER_LOGOUT });
            break;
          case 401:
            history.push("/NotAuthorised");
            break;
          case 403:
            history.push("/acessforbidden");
            break;
          case 404:
            history.push("/PageNotFound");
            break;
          case 400:
            history.push("/BadRequest");
            break;
          default:
            toast.error("Error Occurred");
        }
      }
    }
  };

export const buyerPaymentRefund =
  (buyerid, bName, aName, aNo, ifsc, bank) => async (dispatch) => {
    if (isSessionExpired()) {
      history.push("/SessionExpired");
    } else {
      try {
        document.getElementById("spinner").classList.add("spinnerDisplay");
        const response = await AuctionPortal.post(
          `/paymentrefund/${buyerid}`,
          {
            BuyerName: bName,
            BankAccountName: aName,
            BankAccountNumber: aNo,
            IFSC: ifsc,
            BankName: bank,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          }
        );
        dispatch({ type: BUYER_PAYMENT_REFUND, payload: response.data });

        document.getElementById("spinner").classList.remove("spinnerDisplay");
        toast("Request Initiated");
      } catch (error) {
        document.getElementById("spinner").classList.remove("spinnerDisplay");
        switch (error.response.status) {
          case 408:
            history.push("/sessionExpired");
            dispatch({ type: USER_LOGOUT });
            break;
          case 401:
            history.push("/NotAuthorised");
            break;
          case 403:
            history.push("/acessforbidden");
            break;
          case 404:
            history.push("/PageNotFound");
            break;
          case 400:
            if (error.response.data.errors) {
              alert(JSON.stringify(error.response.data.errors));
            } else {
              toast.error(error.response.data.Description[0]);
            }
            break;
          default:
            toast.error("Error Occurred");
        }
      }
    }
  };
