import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchRegions } from "../../actions/masters/regionActions";
import { fetchAllStates } from "../../actions/masters/stateActions";
import { fetchAllCities } from "../../actions/masters/cityActions";
import { fetchBuyerEmpanelment } from "../../actions/BuyerEmpanelment/BuyerEmpanelmentActions";
import { Link } from "react-router-dom";
import BuyerEmpanelmentDocView from "./BuyerEmpDocuments/BuyerEmpanelmentDocView";
import { fetchBuyer } from "../../actions/masters/buyerActions";
import { fetchBuyerEmpanelmentDocument } from "../../actions/BuyerEmpanelment/BuyerEmpanelmentDocActions";
import history from "../../history";
import BuyerEmpanelmentData from "./BuyerEmpanelmentData";
import { decryptId } from "../Decryption";
class BuyerEmpanelmentViewIndividual extends Component {
  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.props.fetchRegions();
    this.props.fetchAllStates();
    this.props.fetchAllCities();
   //debugger;
   //console.log(this.props.buyerEmpanelment[0].buyerRequestId );
    await this.props.fetchBuyerEmpanelment(decryptId(this.props.match.params.id));
    // await this.props.fetchBuyer(this.props.buyerEmpanelment[0].buyerRequestId);
    await this.props.fetchBuyerEmpanelmentDocument(
      this.props.buyerEmpanelment[0].buyerRequestId
    );
  };

  renderCity = (cityId, cities) => {
    if (cities.length > 0) {
      return cities.filter((pr) => pr.id === cityId)[0].name;
    }
    return "";
  };

  renderState = (stateId, states) => {
    if (states.length > 0) {
      return states.filter((pr) => pr.id === stateId)[0].name;
    }
    return "";
  };

  renderRegion = (regionId, regions) => {
    if (regions.length > 0) {
      return regions.filter((pr) => pr.id === regionId)[0].name;
    }
    return "";
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">
              Buyer Empanelment -
              {this.props.buyerEmpanelment[0]
                ? this.props.buyerEmpanelment[0].buyerRequestId
                : null}
            </h3>
          </div>
          <div className="float-end mt-15">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        <div className="mainBodyCard pt-30">
          {this.props.buyerEmpanelment[0] ? (
            <>
              <div className="row m-0">
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Name</div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].firstName +
                      " " +
                      (this.props.buyerEmpanelment[0].middleName
                        ? this.props.buyerEmpanelment[0].middleName
                        : "") +
                      " " +
                      this.props.buyerEmpanelment[0].lastName}
                  </div>
                </div>
                 <div className="col-md-4 col-sm-12 mb-3">
                      <div className="w-100 d-block form_label">Email</div>
                      <div className="label_details">
                        
                        {this.props.buyerEmpanelment[0].email?.includes('@') 
                          ? this.props.buyerEmpanelment[0].email
                          : 'Not Available'}
                      </div>
                    </div>
                 {/*<div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Email</div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].email}
                  </div>
                      </div> */}
              </div>
              <hr className="mb-0" />
              <div className="row m-0">
                <h5 className="col-sm-12 cardTitle border-0">
                  Present Address
                </h5>

                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Address</div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].presentAddress}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    Nearest Landmark
                  </div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].presentNearestLandmark}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Region</div>
                  <div className="label_details">
                    {this.renderRegion(
                      this.props.buyerEmpanelment[0].presentRegionId,
                      this.props.regions
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">State</div>
                  <div className="label_details">
                    {this.renderState(
                      this.props.buyerEmpanelment[0].presentStateId,
                      this.props.states
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">City</div>
                  <div className="label_details">
                    {this.renderCity(
                      this.props.buyerEmpanelment[0].presentCityId,
                      this.props.cities
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Pincode</div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].presentPincode}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    Telephone Number
                  </div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].presentTelephoneNumber}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Ownerd/ Rental</div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].presentOwnedOrRental}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Staying Since</div>
                  <div className="label_details">
                    {
                      this.props.buyerEmpanelment[0].presentStayingSince.split(
                        "T"
                      )[0]
                    }
                  </div>
                </div>
              </div>
              <hr className="mb-0" />
              <div className="row m-0">
                <div className="col-md-12 col-sm-12 eventCreateInputCol pl-0 row m-0 pb-0 pr-0">
                  <h5 className="col-sm-12 cardTitle border-0">
                    Permanent Address
                  </h5>

                  <div className="col-md-4 col-sm-12 mb-3">
                    <div className="w-100 d-block form_label">Address</div>
                    <div className="label_details">
                      {this.props.buyerEmpanelment[0].permanentAddress}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 mb-3">
                    <div className="w-100 d-block form_label">
                      Nearest Landmark
                    </div>
                    <div className="label_details">
                      {this.props.buyerEmpanelment[0].permanentNearestLandmark}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 mb-3">
                    <div className="w-100 d-block form_label">Region</div>
                    <div className="label_details">
                      {this.renderRegion(
                        this.props.buyerEmpanelment[0].permanentRegionId,
                        this.props.regions
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 mb-3">
                    <div className="w-100 d-block form_label">State</div>
                    <div className="label_details">
                      {this.renderState(
                        this.props.buyerEmpanelment[0].permanentStateId,
                        this.props.states
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 mb-3">
                    <div className="w-100 d-block form_label">City</div>
                    <div className="label_details">
                      {this.renderCity(
                        this.props.buyerEmpanelment[0].permanentCityId,
                        this.props.cities
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 mb-3">
                    <div className="w-100 d-block form_label">Pincode</div>
                    <div className="label_details">
                      {this.props.buyerEmpanelment[0].permanentPincode}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 mb-3">
                    <div className="w-100 d-block form_label">
                      Telephone Number
                    </div>
                    <div className="label_details">
                      {this.props.buyerEmpanelment[0].permanentTelephoneNumber}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 mb-3">
                    <div className="w-100 d-block form_label">
                      Ownerd/ Rental
                    </div>
                    <div className="label_details">
                      {this.props.buyerEmpanelment[0].permanentOwnedOrRental}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 mb-3">
                    <div className="w-100 d-block form_label">
                      Staying Since
                    </div>
                    <div className="label_details">
                      {
                        this.props.buyerEmpanelment[0].permanentStayingSince.split(
                          "T"
                        )[0]
                      }
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mb-0" />
              <div className="row m-0">
                <h5 className="col-sm-12 cardTitle border-0">
                  Personal Details
                </h5>
                <div className="col-md-4 col-sm-12 mb-3">
                      <div className="w-100 d-block form_label">Driving License Number</div>
                      <div className="label_details">
                        {this.props.buyerEmpanelment[0].drivingLicenseNumber?.includes(':')
                          ? 'Not Available'
                          : this.props.buyerEmpanelment[0].drivingLicenseNumber}
                      </div>
                    </div>
               {/*} <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    Driving License Number
                  </div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].drivingLicenseNumber
                      ? this.props.buyerEmpanelment[0].drivingLicenseNumber
                      : "NA"}
                  </div>
                    </div>*/}
                <div className="col-md-4 col-sm-12 mb-3">
                      <div className="w-100 d-block form_label">PAN Number</div>
                      <div className="label_details">
                        {this.props.buyerEmpanelment[0].panNumber?.includes(':')
                          ? 'Not Available'
                          : this.props.buyerEmpanelment[0].panNumber}
                      </div>
                    </div>
                 {/*<div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">PAN Number</div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].panNumber}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">EMD Amount</div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].emdAmount}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    Passport Number
                  </div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].passportNumber}
                  </div>
              </div> */}
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Vehicle Type</div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].vehicleType}
                  </div>
                </div>
                {/* <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    Car/Bike Number
                  </div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].carBikeNumber}
                  </div>
                </div> */}
              </div>
              <hr className="mb-0" />
              <div className="row m-0">
                <h5 className="col-sm-12 cardTitle border-0">Other Details</h5>

                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Office Address</div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].officeAddress}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                      <div className="w-100 d-block form_label">GST No.</div>
                      <div className="label_details">
                        {this.props.buyerEmpanelment[0].gstNo?.includes(':')
                          ? 'Not Available'
                          : this.props.buyerEmpanelment[0].gstNo}
                      </div>
                    </div>
               {/*} <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">GST No.</div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].gstNo}
                  </div>
              </div>*/}
                <div className="col-md-4 col-sm-12 mb-3">
                      <div className="w-100 d-block form_label">Firm PAN No.</div>
                      <div className="label_details">
                        {this.props.buyerEmpanelment[0].firmPAN?.includes(':')
                          ? 'Not Available'
                          : this.props.buyerEmpanelment[0].firmPAN}
                      </div>
                    </div>
               {/*} <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Firm PAN No.</div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].firmPAN}
                  </div>
                </div>
                 <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    Residence Telephone
                  </div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].residenceTelephone}
                  </div>
                </div> */}
                {/* <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    Office Telephone
                  </div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].officeTelephone}
                  </div>
                </div> */}
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    Primary Mobile Number
                  </div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].mobileNumber}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">
                    Secondary Mobile Number
                  </div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].mobileNumber2}
                  </div>
                </div>
                {/* <div className="col-md-4 col-sm-12 mb-3">
                  <div className="w-100 d-block form_label">Fax Number</div>
                  <div className="label_details">
                    {this.props.buyerEmpanelment[0].faxNumber}
                  </div>
                </div> */}
              </div>
             {/* <hr className="mb-0" />
              <div className="row m-0">
                <div className="col-md-12 col-sm-12 eventCreateInputCol pl-0 row m-0 pb-0 pr-0">
                  <h5 className="col-sm-12 cardTitle border-0">Firm Details</h5>
                  {this.props.buyerEmpanelment[0].isDepositPaid === "Y" ? (
                    <div className="row">
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Payment Reference Number
                        </div>
                        <div className="label_details">
                          {this.props.buyerEmpanelment[0].paymentRefNo}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Bank Name
                        </div>
                        <div className="label_details">
                          {this.props.buyerEmpanelment[0].bankName}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Account Holder Name
                        </div>
                        <div className="label_details">
                          {this.props.buyerEmpanelment[0].accountHolderName}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">Amount</div>
                        <div className="label_details">
                          {this.props.buyerEmpanelment[0].amount}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="w-100 d-block form_label">
                          Date of Payment
                        </div>
                        <div className="label_details">
                          {
                            this.props.buyerEmpanelment[0].dateOfPayment.split(
                              "T"
                            )[0]
                          }
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="label_details">Payment Not done</div>
                  )}
                </div>
                  </div>*/}
              <hr className="mb-0" />
              <div className="row m-0">
                <div className="col-md-12 col-sm-12 eventCreateInputCol pl-0 row m-0 pb-0 pr-0">
                  <h5 className="col-sm-12 cardTitle border-0">
                    Uploaded Documents
                  </h5>

                  {this.props.buyerEmpanelment[0].isDocumentUploaded === "Y" ? (
                    <div>
                      <p
                        className="linkBtn2 d-block overflow-hidden pt-2 w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#viewDocumentsModel"
                      >
                        View Uploaded Documents
                      </p>
                      <BuyerEmpanelmentDocView
                        buyerEmpDocuments={this.props.buyerEmpanelmentDocs}
                      />
                    </div>
                  ) : (
                    "No Documents uploaded"
                  )}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownprops) => {
  return {
    regions: Object.values(state.region),
    states: Object.values(state.state),
    cities: Object.values(state.city),
    buyerEmpanelment: Object.values(state.buyerEmpanelment).filter(
      (buy) => buy.id === parseInt(decryptId(ownprops.match.params.id))
    ),
    buyer: Object.values(state.buyer),
    buyerEmpanelmentDocs: Object.values(state.buyerEmpanelmentDocuments),
  };
};

export default connect(mapStateToProps, {
  fetchRegions,
  fetchAllCities,
  fetchAllStates,
  fetchBuyerEmpanelment,
  fetchBuyer,
  fetchBuyerEmpanelmentDocument,
})(BuyerEmpanelmentViewIndividual);
