import { FETCH_DIGILOCKER_LIST } from "../../actions/types";
import _ from "lodash";

const buyerDigiLockerDocumentsReducers = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DIGILOCKER_LIST:
      return { ...state, ..._.mapKeys(action.payload, "name") };
    default:
      return state;
  }
};

export default buyerDigiLockerDocumentsReducers;
