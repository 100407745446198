import React, { Component, createRef } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { bidEvent } from "../../../actions/event/EventActions";
import "./popup.css";

var startPrice, reservePrice, amount, increment;

class BiddingPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      page1: true,
      btn1: false,
      btn2: false,
      checked1: false,
      bidAmount: "",
    };
    this.modalRef = createRef();
  }

  componentDidMount() {
    this.initModal();
  }

  componentDidUpdate(prevProps) {
    if (this.props.buttonPopup !== prevProps.buttonPopup) {
      this.initModal();
    }
  }

  componentWillUnmount() {
    if (this.modalInstance) {
      this.modalInstance.dispose();
    }
  }

  initModal() {
    if (this.props.buttonPopup > 0 && this.modalRef.current) {
      this.modalInstance = new window.bootstrap.Modal(this.modalRef.current, {
        backdrop: "static",
        keyboard: false,
      });
      this.modalInstance.show();
    } else if (this.modalInstance) {
      this.modalInstance.hide();
    }
  }

  renderError({ error, visited }) {
    if (visited && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, meta, maxLength }) => {
    return (
      <div className="form-group">
        <div className="eventCreateInputCol">
          <label className="d-block">{label}</label>
          <div className="input-field">
            <input
              {...input}
              autoComplete="off"
              placeholder={label}
              maxLength={maxLength}
              className="form-control"
              value={this.state.bidAmount}
            />
          </div>
        </div>
        <div className="error">{this.renderError(meta)}</div>
      </div>
    );
  };

  inWords(num) {
    var a = [
      "",
      "One ",
      "Two ",
      "Three ",
      "Four ",
      "Five ",
      "Six ",
      "Seven ",
      "Eight ",
      "Nine ",
      "Ten ",
      "Eleven ",
      "Twelve ",
      "Thirteen ",
      "Fourteen ",
      "Fifteen ",
      "Sixteen ",
      "Seventeen ",
      "Eighteen ",
      "Nineteen ",
    ];
    var b = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    if ((num = num.toString()).length > 10) return "overflow";
    var n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "only "
        : "";
    return str;
  }

  onSubmit = (formValues) => {};

  render() {
    return this.props.buttonPopup > 0 &&
      this.props.events.id === this.props.buttonPopup ? (
      <div
        className="modal fade"
        id="bidModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="bidModalLabel"
        aria-hidden="true"
        ref={this.modalRef}
      >
        {
          ((startPrice = this.props.events.parameter.startPrice),
          (reservePrice = this.props.events.parameter.reservePrice),
          (amount = this.props.amount + this.props.events.parameter.increment),
          (increment = this.props.events.parameter.increment))
        }
        <div id={this.props.events.eventId} className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.props.events.eventId}</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ marginLeft: 'auto' }}
                onClick={() => (
                  this.props.setButtonPopup(0),
                  this.setState({
                    page1: true,
                    checked: false,
                    btn1: false,
                    btn2: false,
                    checked1: false,
                    bidAmount: "",
                  })
                )}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="animation-container">
              <div className="animated">
                {this.props.amount > 0 ? (
                  <>
                    <h6 className="lastbidcss">
                      Last Bid :{" "}
                      <span className="text-orange">
                        {Number(this.props.amount).toFixed(2)}
                      </span>
                    </h6>
                    <h6 className="lastbidcss">
                      Next Possible Bid :{" "}
                      <span className="text-orange">
                        {Number(this.props.amount + increment).toFixed(2)}
                      </span>
                    </h6>
                  </>
                ) : (
                  <>
                    <h6 className="lastbidcss">No Bid Placed yet</h6>
                    <h6 className="lastbidcss">
                      Next Possible Bid :{" "}
                      <span className="text-orange">{startPrice}</span>
                    </h6>
                  </>
                )}
              </div>
            </div>
            <div className="modal-body bidModalBody">
              <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                {this.state.page1 ? (
                  <div>
                    <Field
                      name="bid"
                      component={this.renderInput}
                      label="Enter Bid Amount"
                      maxLength={10}
                      onChange={(event) =>
                        this.setState({
                          bidAmount: event.target.value.replace(
                            /[a-zA-Z!@#$%^&*><":?/,]/,
                            ""
                          ),
                          checked: false,
                          btn1: false,
                        })
                      }
                    />
                    <div className="custom-control custom-checkbox">
                      <input
                        id="checkbox1"
                        className="custom-control-input"
                        type="checkbox"
                        disabled={this.state.bidAmount <= 0}
                        checked={this.state.checked}
                        onChange={() =>
                          this.setState({
                            checked: !this.state.checked,
                            btn1: !this.state.btn1,
                          })
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkbox1"
                      >
                        This can't be undone. Are you sure?
                      </label>
                    </div>
                    <div className="row m-0 mt-4">
                      <div>
                        <button
                          disabled={!this.state.btn1}
                          className={
                            this.state.btn1
                              ? "btnMain mr-2"
                              : "btnMainDisabled mr-2"
                          }
                          onClick={() => this.setState({ page1: false })}
                        >
                          Place Bid
                        </button>
                      </div>

                      <button
                        className="btnMain"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => (
                          this.props.setButtonPopup(0),
                          this.setState({
                            page1: true,
                            checked: false,
                            btn1: false,
                            btn2: false,
                            checked1: false,
                            bidAmount: "",
                          })
                        )}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <label>
                        You have Entered{" : "}
                        <b>
                          Rupees {this.inWords(parseInt(this.state.bidAmount))}
                        </b>
                        .
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        id="checkbox2"
                        type="checkbox"
                        className="custom-control-input"
                        checked={this.state.checked1}
                        onChange={() =>
                          this.setState({
                            checked1: !this.state.checked1,
                            btn2: !this.state.btn2,
                          })
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkbox2"
                      >
                        I Confirm to place a Bid.
                      </label>
                    </div>
                    <div className="mt-4">
                      <div>
                        <button
                          disabled={!this.state.btn2}
                          className={
                            this.state.btn2
                              ? "btnMain mr-2"
                              : "btnMainDisabled mr-2"
                          }
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => (
                            this.props.bidEvent(
                              this.props.events.id,
                              this.props.buyer,
                              parseFloat(this.state.bidAmount)
                            ),
                            this.props.setButtonPopup(0),
                            this.setState({
                              page1: true,
                              checked: false,
                              btn1: false,
                              btn2: false,
                              checked1: false,
                              bidAmount: "",
                            })
                          )}
                        >
                          Bid
                        </button>
                        <button
                          className="btnMain"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => (
                            this.props.setButtonPopup(0),
                            this.setState({
                              page1: true,
                              checked: false,
                              btn1: false,
                              btn2: false,
                              checked1: false,
                              bidAmount: "",
                            })
                          )}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.bid) {
    errors.bid = "This Field is Required";
  }
  if (formValues.bid < startPrice) {
    errors.bid = "Amount Should not be Less than the Start Price";
  }
  if (formValues.bid > reservePrice) {
    errors.bid = "Amount Should not be Greater than the Reserve Price";
  }
  if (parseInt((formValues.bid - startPrice) % increment) !== 0) {
    errors.bid = "Amount Should be in Multiples of Increment Amount";
  }
  if (formValues.bid < amount) {
    errors.bid = "Amount is Less than Next Possible Bid Amount ";
  }
  return errors;
};

const bidForm = reduxForm({
  form: "bidform",
  validate,
  enableReinitialize: true,
})(BiddingPopUp);

export default connect(null, { bidEvent })(bidForm);
