import React, { Component } from "react";
import { connect } from "react-redux";
import { createEventParameter } from "../../../actions/event/EventParameterActions";
import EventParameterForm from "./EventParameterForm";
import { fetchDefaultTimes } from "../../../actions/masters/DefaultTimeAction";
import { Link } from "react-router-dom";
import history from "../../../history";
import { fetchEvent } from "../../../actions/event/EventActions";

class EventParameterCreate extends Component {
  componentDidMount() {
    this.props.fetchDefaultTimes();
    this.props.fetchEvent(this.props.match.params.eid);
  }
  onSubmit = (formValues) => {
    const startTime = this.props.defaultStartTime;
    const endTime = this.props.defaultEndTime;
    formValues.startDateTime = `${formValues.startDate}T${formValues.startTime}`;
    formValues.endDateTime = `${formValues.endDate}T${formValues.endTime}`;
    const { eid } = this.props.match.params;
    this.props.createEventParameter(eid, formValues);
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Add Parameters</h3>
          </div>
          <div className="float-end mt-15 ">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        <div className="">
          {this.props.event[0] && this.props.event[0].status === "PENDING" ? (
            <EventParameterForm
              onSubmit={this.onSubmit}
              defaultStartTime={this.props.defaultStartTime}
              defaultEndTime={this.props.defaultEndTime}
              initialValues={{
                extendTimeWhenValidBidReceivedInLast: 3,
                extendTimeInMinutes: 3,
                increment: 1000,
                incrementDuringExtension: 1000,
              }}
            />
          ) : this.props.event[0] &&
            this.props.event[0].status !== "PENDING" ? (
            <div className="mainBodyCard pt-30 d-flex flex-column justify-content-center align-items-center">
              <h4 className="errorName">{`Event is in ${
                this.props.event[0] ? this.props.event[0].status : ""
              } stage`}</h4>
              <h4 className="errorTextEx">
                You can only add event parametes of events which are in{" "}
                <b>PENDING</b> stage
              </h4>
              <Link
                className="float-end returnHomeBtn mt-5 btnMain"
                onClick={() => {
                  history.goBack();
                }}
              >
                Go Back
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    defaultStartTime:
      state.defaultTime[2] && state.defaultTime[2].defaultTime
        ? state.defaultTime[2].defaultTime
        : null,
    defaultEndTime:
      state.defaultTime[3] && state.defaultTime[3].defaultTime
        ? state.defaultTime[3].defaultTime
        : null,
    event: Object.values(state.event).filter(
      (e) => e.id === parseInt(ownProps.match.params.eid)
    ),
  };
};

export default connect(mapStateToProps, {
  createEventParameter,
  fetchDefaultTimes,
  fetchEvent,
})(EventParameterCreate);
