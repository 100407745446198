import AuctionPortal from "../../apis/AuctionPortal";
import history from "../../history";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOGOUT,
  USER_LOAD,
  USER_TOKEN_REFRESH,
  USER_LAST_LOGIN,
} from "../types";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js"; 
import jwt from "jsonwebtoken";
import { encryptId,decryptIndex } from "../../components/Encryption";
toast.configure({
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
});

export const userLogin = (formValues) => async (dispatch) => {
  //added for audit LAM Siddharth
  try {
    try{
    if(sessionStorage.getItem("userid")==formValues.UserId.toUpperCase())
    {
      if(sessionStorage.getItem("count")!=null || sessionStorage.getItem("count")!=undefined )
      { 
        sessionStorage.setItem("count",+sessionStorage.getItem("count").toString() +1);
      }
      else{
        sessionStorage.setItem("count",1);
      }
    }
    else{
      sessionStorage.setItem("userid",formValues.UserId.toUpperCase()); 
      sessionStorage.setItem("count",1);

    }
  }catch(exception){
    sessionStorage.setItem("userid",formValues.UserId.toUpperCase());
    sessionStorage.setItem("count",1);
  }
  if(+sessionStorage.getItem("count").toString()>=4)
  {
    toast.error("Maximum login attempt excedded, please contact iEvaluate admin to get further details or to get your id unlocked");
    return;
  }
  //ended for audit LAM Siddharth
    var k='';
    var d = "~" + new Date().toLocaleString();
    for (let index = 0; index <4; index++) {
      k += "8080";
    }

    var key = CryptoJS.enc.Utf8.parse(k);
    var iv = CryptoJS.enc.Utf8.parse(k);
    
    formValues.password = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.password+d),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    formValues.UserId = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formValues.UserId+d),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();

    document.getElementById("spinner").classList.add("spinnerDisplay");
    const res = await AuctionPortal.post("/user_token", formValues);

    jwt.verify(res.data.token, "njQNymv^uzTn9k3z#bwh6$Hk0v2b0DmP*hxrhgXI");

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data.token,
    });
    sessionStorage.setItem("count",0);
    dispatch(userLastLogin());
    document.getElementById("spinner").classList.remove("spinnerDisplay");
    history.push("/home");
  } catch (error) {
    document.getElementById("spinner").classList.remove("spinnerDisplay");
    if (error.response) {
      //added for LAM Audit
      //debugger;
      if(+sessionStorage.getItem("count").toString()>=3){
        //debugger;
        //console.log("USER LOCK API");
        const response1 = await AuctionPortal.put(
          `/masters/updateuserbysystem/${sessionStorage.getItem("userid")}`,
          );
          //debugger;
        //sessionStorage.setItem("count",1);
      }
      //debugger;
      //ended for LAM Audit Siddharth
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          dispatch({
            type: LOGIN_FAIL,
          });
          toast.error("Invalid Username or Password!");
          break;
        case 400:
          toast.error(error.response.data);
          break;
        default:
          toast.error("Service unavailable. please try again later.");
      }
    }
  }
};

export const userLogout = () => async (dispatch) => {
  try {
    await AuctionPortal.post(
      "/userLogout",
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
  } catch (error) {
    toast(error);
  } finally {
    dispatch({ type: USER_LOGOUT });
    history.push("/");
    window.location.reload();
  }
};

export const resetPassword = (userid, formValues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      await AuctionPortal.put(
        `/masters/user/resetpassword/${encryptId(userid)}`,
        {
          ...formValues,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.push("/profile/viewprofile");
      toast("The Password is Changed Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      if (error.response.status === 400) {
        toast.error(error.response.data.Description[0]);
      }
    }
  }
};

export const userLoad = () => async (dispatch) => {
  const token = localStorage.getItem("userToken");
  if (token) {
    const { exp } = jwt_decode(token);
    if (exp * 1000 < Date.now()) {
      // userLogout();
      dispatch({ type: USER_LOGOUT });
      history.push("/SessionExpired");
      // window.location.reload();
    }

    if (token) {
      dispatch({ type: USER_LOAD, payload: token });
    } else {
      history.push("/login");
    }
  } else {
    history.push("/login");
  }
};

export const tokenRefresh = () => async (dispatch) => {
  try {
    document.getElementById("spinner").classList.add("spinnerDisplay");
    const response = await AuctionPortal.post(
      "/user_token/refresh",
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );

    dispatch({ type: USER_TOKEN_REFRESH, payload: response.data.token });
  } catch (error) {
    toast.error("Error Occurred");
  } finally {
    document.getElementById("spinner").classList.remove("spinnerDisplay");
  }
};

export function isSessionExpired() {
  if (localStorage.getItem("userToken")) {
    const { exp } = jwt_decode(localStorage.getItem("userToken"));
    return exp * 1000 < Date.now();
  } else {
    return true;
  }
}

export const userLastLogin = () => async (dispatch) => {
  try {
    const response = await AuctionPortal.get("masters/userLastLogin", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    dispatch({ type: USER_LAST_LOGIN, payload: response.data });
  } catch (error) {
    toast.error(error);
  }
};
