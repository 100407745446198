import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
  fetchNol,
  editNol,
  deleteNol,
} from "../../../actions/masters/natureOfLossActions";
import NatureOfLossForm from "./NatureOfLossForm";
import { Link } from "react-router-dom";
import Spinner from "../../Spinner";
import history from "../../../history";

class NatureOfLossEdit extends Component {
  componentDidMount() {
    this.props.fetchNol(this.props.match.params.id);
  }

  onSubmit = (formValues) => {
    this.props.editNol(this.props.match.params.id, formValues);
  };

  onClickDelete = () => {
    this.props.deleteNol(this.props.match.params.id, this.props.natureofloss);
  };

  render() {
    return (
      <div>
        {this.props.natureofloss !== undefined ? (
          <div className="BodyInContainer">
            <div className="d-block clearfix">
              <div className="Header-Content float-start">
                <h3 className="pageMainTitle">Edit Nature Of Loss</h3>
              </div>
              <div className="float-end mt-15">
                <Link
                  className="float-end cardBackBtn"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </Link>
              </div>
            </div>
            <NatureOfLossForm
              initialValues={_.pick(
                this.props.natureofloss,
                "name",
                "activeFlag"
              )}
              onSubmit={this.onSubmit}
              onDelete={this.onClickDelete}
            />
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { natureofloss: state.natureofloss[ownProps.match.params.id] };
};

export default connect(mapStateToProps, { editNol, fetchNol, deleteNol })(
  NatureOfLossEdit
);
