import _ from "lodash";

import {
  FETCH_CITYs,
  FETCH_CITY,
  EDIT_CITY,
  CREATE_CITY,
  DELETE_CITY,
  FETCH_ALL_CITIES,
} from "../../actions/types";

const cityReducers = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CITYs:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case FETCH_CITY:
      return { ...state, [action.payload.id]: action.payload };
    case FETCH_ALL_CITIES:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case EDIT_CITY:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_CITY:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_CITY:
      return _.omit(state, action.payload);

    default:
      return state;
  }
};

export default cityReducers;
