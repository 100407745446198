import {
  ADD_EMPANELMENT_BUYER_DOCs,
  ADD_EMPANELMENT_BUYER_DOC,
  FETCH_EMPANELMENT_BUYER_DOCs,
} from "../../actions/types";
import _ from "lodash";

const buyerEmpanelmentDocReducers = (state = {}, action) => {
  switch (action.type) {
    case ADD_EMPANELMENT_BUYER_DOC:
      return { ...state, [action.payload.id]: action.payload };
    case ADD_EMPANELMENT_BUYER_DOCs:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case FETCH_EMPANELMENT_BUYER_DOCs:
      return { ...state, ..._.mapKeys(action.payload, "id") };

    default:
      return state;
  }
};

export default buyerEmpanelmentDocReducers;
