import React, { Component } from "react";
import { Link } from "react-router-dom";

class BadRequest extends Component {
  render() {
    return (
      <div className="BodyInContainer errorPage">
        <div className="mainBodyCard pt-30 d-flex flex-column justify-content-center align-items-center">
          <h1 className="errorCode">400</h1>
          <h4 className="errorName">Bad Request.</h4>
          <h4 className="errorTextEx">You have Issued an Invalid Request.</h4>
          <h4 className="errorTextEx">
            Please contact admin for further Details.
          </h4>
          <Link className="float-end returnHomeBtn mt-5 btnMain" to="/">
            Return Home
          </Link>
        </div>
      </div>
    );
  }
}

export default BadRequest;
