import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import Modal from "../../Modal";
import Select from "react-select";

class SubProductForm extends Component {
  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, meta }) => {
    return (
      <div className="">
        <div className="form-group">
          <div className="input-field">
            <input
              {...input}
              autoComplete="off"
              placeholder="Enter Sub Product"
              className="form-control"
            />
          </div>
          <div className="error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  renderRadioInput = ({ input, meta, type, label }) => {
    return (
      <div className="radio ml-0 enofRadio">
        <input
          {...input}
          autoComplete="off"
          type={type}
          value={input.value}
          id={label}
        />
        <label className="radio-label" htmlFor={label}>
          {input.value === "Y" ? "Active" : "In Active"}
        </label>
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderSelectInput = ({ input, meta, options }) => {
    return (
      <div>
        <div>
          <Select
            {...input}
            value={
              input.value === ""
                ? null
                : options.find((obj) => obj.id === input.value)
            }
            onChange={input.onChange}
            onBlur={(value) => input.onBlur(value)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onBlurResetsInput={false}
            onCloseResetsInput={false}
            options={options}
            onFocus={input.onFocus}
            isSearchable={true}
            noOptionsMessage={() => null}
            placeholder={"Search Product"}
            autoFocus={true}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,

                primary25: "#ffa552",
                primary50: "#fabc82",
                primary: "#fcb26d",
              },
            })}
          />
          <div>{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <div className="mainBodyCard centerAlignCard">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field name="name" component={this.renderInput} label="Enter Name" />
          <div className="row m-0">
            {this.props.onDelete ? (
              <div>
                <div className="row m-0">
                  <div className="col-md-12 col-sm-12 p-0 editRegionInputCol">
                    <label>Product:</label>
                    <Field
                      name="productId"
                      component={this.renderSelectInput}
                      props={{
                        options: this.props.products,
                      }}
                      normalize={(value) => value.id}
                    />
                  </div>
                </div>
                <div className="row m-0">
                  <div className="d-inline-block mr-5">
                    <Field
                      name="activeFlag"
                      component={this.renderRadioInput}
                      type="radio"
                      label="SubProductMasterActiveTrue"
                      value="Y"
                    />
                    <Field
                      name="activeFlag"
                      component={this.renderRadioInput}
                      type="radio"
                      label="SubProductMasterActiveFalse"
                      value="N"
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="mainBodyCardFtBtn mt-5">
              <button className="btnMain">Submit</button>
            </div>
          </div>
        </form>
        <Modal onDeleteClick={this.props.onDelete} />
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.name) {
    errors.name = "This Field is Required";
  }

  return errors;
};

export default reduxForm({
  form: "SubProductForm",
  validate,
  enableReinitialize: true,
})(SubProductForm);
