import React, { Component } from "react";
import { connect } from "react-redux";
import { createSubProduct } from "../../../actions/masters/subProductActions";
import { Link } from "react-router-dom";
import SubProductForm from "./SubProductForm";
import history from "../../../history";

class SubProductCreate extends Component {
  onSubmit = (formValues) => {
    this.props.createSubProduct(this.props.match.params.id, formValues);
  };

  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Add a Sub Product</h3>
          </div>
          <div className="float-end mt-15">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        <SubProductForm onSubmit={this.onSubmit} />
      </div>
    );
  }
}

export default connect(null, { createSubProduct })(SubProductCreate);
