import React, { Component } from "react";
import { connect } from "react-redux";
import { addEventBidders } from "../../../actions/event/EventBidderActions";
import { Field, formValueSelector, reduxForm } from "redux-form";
import Select from "react-select";

class AddBidders extends Component {
  state = {
    selectedBidders: [],
    searchOn: "",
    regionOn: 0,
    buyRender: [],
    checkbox_selected: false,
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = (e) => {
    e.preventDefault();
    const { selectedBidders } = this.state;
    this.props.addEventBidders(this.props.eid, selectedBidders);
    document.addbidders.reset();
    this.state.selectedBidders = [];
  };

  selectAllBidders = () => {
    const { buyers } = this.props;
    if (document.querySelector("#selectAllChkboxAdd:checked") !== null) {
      this.setState({
        checkbox_selected: true,
        selectedBidders: Object.values(buyers).map((buyer) => buyer.id),
      });
    } else {
      this.setState({
        checkbox_selected: false,
        selectedBidders: [],
      });
    }
  };

  isBidderSelected = (id) => {
    this.setState((prevState) => {
      const selectedBidders = [...prevState.selectedBidders];
      const index = selectedBidders.indexOf(id);
      if (index !== -1) {
        selectedBidders.splice(index, 1);
      } else {
        selectedBidders.push(id);
      }
      return { selectedBidders };
    });
  };

  clearSelectedBuyersState = () => {
    this.setState({ selectedBidders: [] });
  };

  renderInput = ({ input, label, disabled, type, onChange }) => {
    return (
      <div>
        <div className="eventCreateInputCol buyerSearch">
          <label className="d-none">{label}</label>
          <div className="input-field">
            <input
              {...input}
              type={type}
              autoComplete="off"
              placeholder={label}
              className="form-control"
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    );
  };

  renderList = (buyers) => {
    var filtdbidders = Object.values(buyers).filter((b) => {
      if (this.state.searchOn === "Region") {
        return b.regionId === this.state.regionOn;
      } else if (this.props.buyerSearchValue) {
        if (this.state.searchOn === "Name") {
          return b.user_name
            .toLowerCase()
            .includes(this.props.buyerSearchValue.toLowerCase());
        } else if (this.state.searchOn === "Email") {
          return b.email_id
            .toLowerCase()
            .includes(this.props.buyerSearchValue.toLowerCase());
        } else {
          return b;
        }
      } else {
        return b;
      }
    });

    return filtdbidders.map((buyer) => {
      if (!(buyer.id in this.props.currentbidders)) {
        return (
          <tr key={buyer.id}>
            <td data-th="">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  onClick={() => this.isBidderSelected(buyer.id)}
                  className="custom-control-input"
                  id={buyer.id}
                  checked={this.state.selectedBidders.includes(buyer.id)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={buyer.id}
                ></label>
              </div>
            </td>
            <td data-th="User Name">{buyer.user_name}</td>
            <td data-th="Email Id">{buyer.email_id}</td>
            <td data-th="Mobile No.">{buyer.mobile_no}</td>
            <td data-th="Region">
              {this.props.renderFieldName(buyer.regionId, this.props.regions)}
            </td>
          </tr>
        );
      }
    });
  };

  render() {
    return (
      <div className="mappedBiddersContainer">
        <div className="row">
          <div className="col-md-4">
            <h4 className="cardTitle">Add Bidders</h4>
          </div>

          <div className="col-md-4">
            <div className="eventCreateInputCol  buyerSearch">
              <form onSubmit={(e) => e.preventDefault()}>
                <Select
                  name="dropSelector"
                  id="dropDownSelector"
                  onChange={(ev) => {
                    this.setState({
                      searchOn: ev.value,
                    });
                    this.clearSelectedBuyersState();
                  }}
                  options={[
                    { value: "Name", label: "Name" },
                    { value: "Email", label: "Email" },
                    { value: "Region", label: "Region" },
                  ]}
                  isSearchable={true}
                  placeholder={"Search Criteria"}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#ffa552",
                      primary50: "#fabc82",
                      primary: "#fcb26d",
                    },
                  })}
                />
              </form>
            </div>
          </div>

          <div className="col-md-4">
            {this.state.searchOn !== "Region" ? (
              this.state.searchOn === "Email" ||
              this.state.searchOn === "Name" ? (
                <div className="eventCreateInputCol buyerSearch">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Field
                      name="bidderSearch"
                      component={this.renderInput}
                      onChange={() => this.clearSelectedBuyersState()}
                      label="Buyer Search"
                      type="text"
                    />
                  </form>
                </div>
              ) : (
                <></>
              )
            ) : this.state.searchOn === "Region" ? (
              <div className="eventCreateInputCol buyerSearch">
                <form onSubmit={(e) => e.preventDefault()}>
                  <Select
                    name="regionSelect"
                    isSearchable={true}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={this.props.regions}
                    onChange={(ev) => {
                      this.setState({
                        regionOn: ev.id,
                      });
                      this.clearSelectedBuyersState();
                    }}
                    placeholder={"Search Region"}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#ffa552",
                        primary50: "#fabc82",
                        primary: "#fcb26d",
                      },
                    })}
                  />
                </form>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {typeof this.props.buyerSearchValue !== "undefined" ||
        (this.state.searchOn === "Region" && this.state.regionOn !== 0) ? (
          <form onSubmit={this.onSubmit} name="addbidders">
            <div className="addBiddersTableContainer">
              <table className="table table-hover tableStyle1">
                <thead>
                  <tr>
                    <td>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="selectAllChkboxAdd"
                          onClick={() => this.selectAllBidders()}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="selectAllChkboxAdd"
                        >
                          Select All
                        </label>
                      </div>
                    </td>
                    <td>User Name</td>
                    <td>Email Id</td>
                    <td>Mobile No.</td>
                    <td>Region</td>
                  </tr>
                </thead>
                <tbody>{this.renderList(this.props.buyers)}</tbody>
              </table>
            </div>
            <div className="mainBodyCardFtBtn mt-50">
              <button className="btnMain">Add Bidders</button>
            </div>
          </form>
        ) : (
          <p className="searchToDisplayData">Search Buyers</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var selector = formValueSelector("AddBiddersForm");
  const buyerSearchValue = selector(state, "bidderSearch");
  return {
    buyerSearchValue,
    regions: Object.values(state.region),
  };
};

const AddBiddersForm = reduxForm({
  form: "AddBiddersForm",
  enableReinitialize: true,
})(AddBidders);

export default connect(mapStateToProps, { addEventBidders })(AddBiddersForm);
