import _ from "lodash";

import {
  CREATE_USER,
  EDIT_USER,
  FETCH_USER,
  FETCH_USERs,
  FETCH_BUYERs,
} from "../../actions/types";

const userReducers = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USERs:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case FETCH_BUYERs:
      return { ..._.mapKeys(action.payload, "id") };
    case FETCH_USER:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_USER:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_USER:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};

export default userReducers;
