import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import Modal from "../../Modal";
import Select from "react-select";

class CityForm extends Component {
  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, meta }) => {
    return (
      <div className="">
        <div className="form-group">
          <div className="input-field">
            <input
              {...input}
              type="text"
              autoComplete="off"
              placeholder={label}
              className="form-control"
            />
          </div>
          <div className="error">{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  renderRadioInput = ({ input, meta, type, label }) => {
    return (
      <div className="d-inline-block mr-5">
        <div className="radio ml-0 enofRadio">
          <input
            {...input}
            autoComplete="off"
            type={type}
            value={input.value}
            id={label}
          />
          <label className="radio-label" htmlFor={label}>
            {input.value === "Y" ? "Active" : "In Active"}
          </label>
          <div>{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  renderSelectInput = ({ input, meta, options }) => {
    return (
      <div>
        <div>
          <Select
            {...input}
            value={
              input.value === ""
                ? null
                : options.find((obj) => obj.id === input.value)
            }
            onChange={input.onChange}
            onBlur={(value) => input.onBlur(value)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onBlurResetsInput={false}
            onCloseResetsInput={false}
            options={options}
            onFocus={input.onFocus}
            isSearchable={true}
            noOptionsMessage={() => null}
            placeholder={"Search Region"}
            autoFocus={true}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,

                primary25: "#ffa552",
                primary50: "#fabc82",
                primary: "#fcb26d",
              },
            })}
          />
          <div>{this.renderError(meta)}</div>
        </div>
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <div className="mainBodyCard centerAlignCard">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field name="name" component={this.renderInput} label="Enter Name" />
          {this.props.onDelete ? (
            <div>
              <div className="row m-0">
                <div className="col-md-12 col-sm-12 p-0 editRegionInputCol">
                  <label>State: </label>
                  <Field
                    name="stateId"
                    component={this.renderSelectInput}
                    placeholder="Select State"
                    props={{
                      options: this.props.states,
                    }}
                    normalize={(value) => value.id}
                  />
                </div>
              </div>
              <div className="row m-0 mt-3">
                <Field
                  name="activeFlag"
                  component={this.renderRadioInput}
                  type="radio"
                  value="Y"
                  label="CityActiveFLagTrue"
                />
                <Field
                  name="activeFlag"
                  component={this.renderRadioInput}
                  type="radio"
                  value="N"
                  label="CityActiveFLagFalse"
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="mainBodyCardFtBtn mt-5">
            <button className="btnMain">Submit</button>
          </div>
        </form>
        <Modal onDeleteClick={this.props.onDelete} />
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.name) {
    errors.name = "This Field is Required";
  }
  return errors;
};

export default reduxForm({
  form: "CityForm",
  validate,
  enableReinitialize: true,
})(CityForm);
