import React, { Component } from "react";
import NatureOfLossList from "./masters/natureofloss/NatureOfLossList";
import NatureOfLossCreate from "./masters/natureofloss/NatureOfLossCreate";
import NatureOfLossEdit from "./masters/natureofloss/NatureOfLossEdit";
import FraudTypeList from "./masters/fraudtype/FraudTypeList";
import FraudTypeCreate from "./masters/fraudtype/FraudTypeCreate";
import FAQs from "../components/common/FAQ";
import { Router, Route, Switch } from "react-router-dom";
import history from "../history";
import FraudTypeEdit from "./masters/fraudtype/FraudTypeEdit";
import GroupMappingList from "./masters/groupmapping/GroupMappingList";
import EventMasterView from "./masters/eventmaster/EventMasterView";
import EventMasterEdit from "./masters/eventmaster/EventMasterEdit";
import GroupMappingCreate from "./masters/groupmapping/GroupMappingCreate";
import GroupMappingEdit from "./masters/groupmapping/GroupMappingEdit";
import ProdSubProdList from "./masters/product/ProdSubProdList";
import ProductCreate from "./masters/product/ProductCreate";
import ProductEdit from "./masters/product/ProductEdit";
import defaultTimeList from "./masters/defaultTime/defaultTimeList";
import defaultTimeEdit from "./masters/defaultTime/defaultTimeEdit";
import SubProductList from "./masters/subproduct/SubProductList";
import SubProductEdit from "./masters/subproduct/SubProductEdit";
import SubProductCreate from "./masters/subproduct/SubProductCreate";
import RegionList from "./masters/region/RegionList";
import RegionCreate from "./masters/region/RegionCreate";
import RegionEdit from "./masters/region/RegionEdit";
import StateCreate from "./masters/state/StateCreate";
import StateEdit from "./masters/state/StateEdit";
import StateList from "./masters/state/StateList";
import GenerateReports from "./Reports/GenerateReports";
import CityCreate from "./masters/city/CityCreate";
import CityEdit from "./masters/city/CityEdit";
import CityList from "./masters/city/CityList";
import EventCreate from "./event/EventCreate";
import LocationList from "./masters/location/LocationList";
import AuctionIndividualData from "./Login/AuctionIndividualData";

import EventEdit from "./event/EventEdit";
import Homepage from "./Login/Homepage";
import LoginPage from "./Login/LoginPage";

import {
  userLastLogin,
  userLoad,
  userLogout,
} from "../actions/login/loginActions";
import { connect } from "react-redux";
import TopBar from "./layout/TopBar";
import UserList from "./masters/user/UserList";
import UserCreate from "./masters/user/UserCreate";
import UserEdit from "./masters/user/UserEdit";
import EventParameterCreate from "./event/eventParameters/EventParameterCreate";
import EventParameterEdit from "./event/eventParameters/EventParameterEdit";
import ViewProfile from "./profile/ViewProfile";
import AuctionBiddersPage from "./event/eventBiddersMap/AuctionBiddersPage";
import BuyerList from "./masters/buyer/BuyerList";
import BuyerEdit from "./masters/buyer/BuyerEdit";
import BuyerRMCEdit from "./masters/buyer/BuyerRMCEdit";
import EventDocumentsPage from "./event/eventDocuments/EventDocumentsPage";
import BuyerEmpanelmentCreate from "./BuyerEmpanelment/BuyerEmpanelmentCreate";
import BuyerEmpanelmentList from "./BuyerEmpanelment/BuyerEmpanelmentList";
import BuyerPaymentRefundList from "./BuyerEmpanelment/BuyerPaymentRefund/BuyerPaymentRefundList";
import BuyerEmpanelmentViewIndividual from "./BuyerEmpanelment/BuyerEmpanelmentViewIndividual";
import PasswordResetPage from "./Login/PasswordResetPage";
import ForgotPasswordPage from "./Login/ForgotPasswordPage";

import BuyerRmcList from "./masters/buyer/BuyerRmcList";
import NoAuthorizationPage from "./common/NoAuthorizationPage";
import BadRequest from "./common/BadRequest";
import SessionExpiredPage from "./common/SessionExpiredPage";
import MultipleAuthentication from "./common/MultipleAuthentication";
import PageNotFoundPage from "./common/PageNotFoundPage";
import ForbiddenRequest from "./common/ForbiddenRequest";
import Spinner from "./Spinner";
import jwt_decode from "jwt-decode";
import TokenRefresh from "./layout/TokenRefresh";
import BuyerRmcViewIndividual from "./masters/buyer/BuyerRmcViewIndividual";
import ViewEventBidDetails from "./event/ViewEventBidDetails";
import ViewEventRankHistory from "./event/ViewEventRankHistory";
import { fetchCurrentTime } from "../actions/masters/DefaultTimeAction";
import SearchedList from "./Login/SearchedList";

class App extends Component {
  state = { checkSessionLogout: false, timeRemainingInSeconds: 0 };
  checkSessionInterval = null;

  componentDidMount = async () => {
    await this.props.fetchCurrentTime();
    this.props.userLoad();
    
    // window.addEventListener("beforeunload", (ev) => {
    //   ev.preventDefault();
    //   localStorage.removeItem("userToken");
    //   return ev.returnValue = "";
    // });
    
    localStorage.getItem("userToken") && this.props.userLastLogin();

    // console.log(
    //   "exp",
    //   localStorage.getItem("userToken") &&
    //     jwt_decode(localStorage.getItem("userToken")).exp * 1000
    // );
    // console.log("now", Date.now());
    // console.log("fetchTime", Date.parse(this.props.currentTime));

    this.checkSessionInterval = setInterval(() => {
      var timeRemaining =
        localStorage.getItem("userToken") &&
        jwt_decode(localStorage.getItem("userToken")).exp * 1000 - Date.now();

      if (timeRemaining && timeRemaining < 2 * 60 * 1000 && timeRemaining > 0) {
        this.setState({
          checkSessionLogout: true,
          timeRemainingInSeconds: parseInt(timeRemaining / 1000),
        });
      } else if (timeRemaining && timeRemaining < 0) {
        this.setState({ checkSessionLogout: false });
        this.props.userLogout();
      } else {
        this.setState({ checkSessionLogout: false });
      }
    }, 1000);
  };

  componentWillUnmount() {
    clearInterval(this.checkSessionInterval);
  }

  render() {
    return (
      <div className="mainContainer">
        {this.state.checkSessionLogout ? (
          <TokenRefresh timeRemaining={this.state.timeRemainingInSeconds} />
        ) : null}
        <Spinner />
        <Router history={history}>
          <div>
            <TopBar history={history} />
            <section className="BodyMainContainer">
              <Switch>
                <Route path="/login" exact component={LoginPage} />
                <Route
                  path="/NotAuthorised"
                  exact
                  component={NoAuthorizationPage}
                />
                <Route
                  path="/MultipleLogin"
                  exact
                  component={MultipleAuthentication}
                />
                <Route
                  path="/SessionExpired"
                  exact
                  component={SessionExpiredPage}
                />
                <Route path="/BadRequest" exact component={BadRequest} />
                <Route path="/getReports" exact component={GenerateReports} />
                <Route
                  path="/resetpassword"
                  exact
                  component={PasswordResetPage}
                />
                <Route
                  path="/acessforbidden"
                  exact
                  component={ForbiddenRequest}
                />
                <Route
                  path="/forgotpassword"
                  exact
                  component={ForgotPasswordPage}
                />
                <Route
                  path="/masters/NatureOfLoss/"
                  exact
                  component={NatureOfLossList}
                />
                <Route
                  path="/masters/NatureOfLoss/create"
                  exact
                  component={NatureOfLossCreate}
                />
                <Route
                  path="/masters/NatureOfLoss/edit/:id"
                  exact
                  component={NatureOfLossEdit}
                />
                <Route
                  path="/masters/FraudType"
                  exact
                  component={FraudTypeList}
                />
                {/* <Route
                  path="/masters/FraudType/create"
                  exact
                  component={FraudTypeCreate}
                /> */}
                <Route
                  path="/masters/FraudType/edit/:id"
                  exact
                  component={FraudTypeEdit}
                />
                <Route
                  path="/event/viewbiddetails/:eventId"
                  exact
                  component={ViewEventBidDetails}
                />
                <Route
                  path="/event/viewEventRanks/:auctionId"
                  exact
                  component={ViewEventRankHistory}
                />
                <Route
                  path="/masters/EventMaster"
                  exact
                  component={EventMasterView}
                />
                <Route
                  path="/masters/EventMaster/edit"
                  exact
                  component={EventMasterEdit}
                />
                <Route
                  path="/masters/GroupMapping/"
                  exact
                  component={GroupMappingList}
                />
                <Route
                  path="/masters/GroupMapping/create"
                  exact
                  component={GroupMappingCreate}
                />
                <Route
                  path="/masters/GroupMapping/edit/:id"
                  exact
                  component={GroupMappingEdit}
                />
                <Route
                  path="/masters/Product/"
                  exact
                  component={ProdSubProdList}
                />
                <Route
                  path="/masters/Product/create"
                  exact
                  component={ProductCreate}
                />
                <Route
                  path="/masters/Product/edit/:id"
                  exact
                  component={ProductEdit}
                />
                <Route
                  path="/masters/product/:id/subproduct"
                  exact
                  component={SubProductList}
                />
                <Route
                  path="/masters/product/:id/subproduct/:sid/edit"
                  exact
                  component={SubProductEdit}
                />
                <Route
                  path="/masters/product/:id/subproduct/create"
                  exact
                  component={SubProductCreate}
                />
                <Route
                  path="/masters/defaultTime"
                  exact
                  component={defaultTimeList}
                />
                <Route
                  path="/masters/defaultTime/edit/:id"
                  exact
                  component={defaultTimeEdit}
                />
                <Route path="/masters/region" exact component={RegionList} />
                <Route
                  path="/masters/region/create"
                  exact
                  component={RegionCreate}
                />
                <Route
                  path="/masters/region/edit/:id"
                  exact
                  component={RegionEdit}
                />
                <Route
                  path="/masters/region/:id/state"
                  exact
                  component={StateList}
                />
                <Route
                  path="/masters/region/:id/state/create"
                  exact
                  component={StateCreate}
                />
                <Route
                  path="/masters/region/:id/state/:sid/edit"
                  exact
                  component={StateEdit}
                />

                <Route
                  path="/masters/region/:id/state/:sid/city"
                  exact
                  component={CityList}
                />
                <Route
                  path="/masters/state/:sid/city/create"
                  exact
                  component={CityCreate}
                />
                <Route
                  path="/masters/state/:sid/city/:cid/edit"
                  exact
                  component={CityEdit}
                />

                <Route
                  path="/masters/location"
                  exact
                  component={LocationList}
                />

                <Route path="/event/create" exact component={EventCreate} />
                <Route path="/event/edit/:id" exact component={EventEdit} />

                <Route path="/masters/users" exact component={UserList} />
                <Route
                  path="/masters/users/create"
                  exact
                  component={UserCreate}
                />
                <Route
                  path="/masters/users/edit/:id"
                  exact
                  component={UserEdit}
                />

                <Route path="/masters/buyers" exact component={BuyerList} />
                <Route
                  path="/masters/buyers/edit/:id"
                  exact
                  component={BuyerEdit}
                />
                <Route
                  path="/masters/buyers/editRMC/:id"
                  exact
                  component={BuyerRMCEdit}
                />
                <Route path="/" exact component={Homepage} />
                <Route path="/home/" exact component={Homepage} />
                <Route path="/home/:auctionStatus" exact component={Homepage} />
                <Route
                  path="/event/:eid/createparameter"
                  component={EventParameterCreate}
                />
                <Route
                  path="/event/:eid/editparameter"
                  component={EventParameterEdit}
                />
                <Route
                  path="/event/:eid/eventbidders"
                  component={AuctionBiddersPage}
                />
                <Route
                  path="/event/:eid/eventdocuments"
                  component={EventDocumentsPage}
                />
                <Route path="/profile/viewprofile" component={ViewProfile} />

                <Route
                  path="/buyerempanelment/create"
                  exact
                  component={BuyerEmpanelmentCreate}
                />
                <Route path="/faqs" exact component={FAQs} />
                <Route
                  path="/buyerempanelment"
                  exact
                  component={BuyerEmpanelmentList}
                />
                <Route
                  path="/buyerempanelment/:buyerempanelmentstatus"
                  exact
                  component={BuyerEmpanelmentList}
                />
                <Route
                  path="/buyerempanelment/view/:id"
                  exact
                  component={BuyerEmpanelmentViewIndividual}
                />
                <Route
                  path="/searched"
                  exact
                  component={SearchedList}
                />
                <Route path="/buyerrmc/view" exact component={BuyerRmcList} />
                <Route
                  path="/buyerrmc/view/:id"
                  exact
                  component={BuyerRmcViewIndividual}
                />

                <Route
                  path="/buyerpaymentrefund/view"
                  exact
                  component={BuyerPaymentRefundList}
                />
                <Route
                  path="/event/view/:id"
                  exact
                  component={AuctionIndividualData}
                />
                <Route path="*" exact component={PageNotFoundPage} />
              </Switch>
            </section>
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentTime: state.currentTime.currentTime,
  };
};

export default connect(mapStateToProps, {
  userLoad,
  userLogout,
  userLastLogin,
  fetchCurrentTime,
})(App);
