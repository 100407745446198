import {
  FETCH_PAYMENT_REFUNDs,
  FETCH_PAYMENT_REFUND,
  ADMIN_PAYMENT_REFUND,
  BUYER_PAYMENT_REFUND,
} from "../../../actions/types";
import _ from "lodash";

const PaymentRefundReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_REFUNDs:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case FETCH_PAYMENT_REFUND:
      return { ...state, isRequested: action.payload };
    case ADMIN_PAYMENT_REFUND:
      return { ...state, [action.payload.id]: action.payload };
    case BUYER_PAYMENT_REFUND:
      return { ...state, isRequested: action.payload };
    default:
      return state;
  }
};

export default PaymentRefundReducer;
