import React, { Component } from "react";
import { connect } from "react-redux";
import EventDocumentsUpload from "./EventDocumentsUpload";
import EventDocumentsView from "./EventDocumentsView";
import _ from "lodash";
import {
  addEventDocument,
  clearDocState,
  fetchEventDocument,
  deleteDocument,
} from "../../../actions/event/EventDocumentActions";
import { fetchEvent } from "../../../actions/event/EventActions";
import { Link } from "react-router-dom";
import history from "../../../history";
import { reset } from "redux-form";

class EventDocumentsPage extends Component {
  componentDidMount = async () => {
    await this.props.fetchEvent(this.props.match.params.eid);
    await this.props.fetchEventDocument(this.props.match.params.eid);
  };

  componentWillUnmount() {
    this.props.clearDocState();
  }

  onSubmit = (formValues, dispatch) => {
    this.props.addEventDocument(this.props.match.params.eid, formValues);
    dispatch(reset("EventDocumentUpload"));
    document.getElementById("eventDocDescription").value = "";
    document.getElementById("eventDocFile").value = "";
  };
  render() {
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">
              Documents -{" "}
              {this.props.event[0] ? this.props.event[0].eventId : null}
            </h3>
          </div>
          <div className="float-end mt-15 ">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>

        <div className="mainBodyCard pt-0">
          <EventDocumentsUpload
            onSubmit={this.onSubmit}
            event={this.props.event}
          />

          <EventDocumentsView
            eventImages={this.props.eventImages}
            eventDocuments={this.props.eventDocument}
            eid={this.props.match.params.eid}
            event={this.props.event}
            deleteDocument={this.props.deleteDocument}
            clearDocState={this.props.clearDocState}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    event: Object.values(state.event).filter(
      (e) => e.id == ownProps.match.params.eid
    ),
    eventDocument: Object.values(state.eventDocument),
    eventImages: Object.values(
      _.mapKeys(
        Object.values(state.eventDocument).filter(
          (im) =>
            im.fileType.toLowerCase() === ".png" ||
            im.fileType.toLowerCase() === ".jpg" ||
            im.fileType.toLowerCase() === ".jpeg" ||
            im.fileType.toLowerCase() === ".gif" ||
            im.fileType.toLowerCase() === ".raw"
        ),
        ("dmsDocContent", "description")
      )
    ),
  };
};

export default connect(mapStateToProps, {
  addEventDocument,
  fetchEventDocument,
  fetchEvent,
  clearDocState,
  deleteDocument,
})(EventDocumentsPage);
