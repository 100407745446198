import AuctionPortal from "../../apis/AuctionPortal";
import history from "../../history";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isSessionExpired, userLogout } from "../login/loginActions";

import {
  FETCH_CITYs,
  FETCH_CITY,
  EDIT_CITY,
  CREATE_CITY,
  DELETE_CITY,
  FETCH_ALL_CITIES,
  USER_LOGOUT,
} from "../types";

toast.configure({
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
});

export const fetchCities = (sid) => async (dispatch) => {
  try {
    const response = await AuctionPortal.get(`/masters/state/${sid}/city`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    dispatch({ type: FETCH_CITYs, payload: response.data });
  } catch (error) {
    switch (error.response.status) {
      case 408:
        history.push("/sessionExpired");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        history.push("/BadRequest");
        break;
      default:
        toast.error("Error Occurred");
    }
  }
};

export const fetchAllCities = () => async (dispatch) => {
  try {
    const response = await AuctionPortal.get("/masters/city", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    dispatch({ type: FETCH_ALL_CITIES, payload: response.data });
  } catch (error) {
    switch (error.response.status) {
      case 408:
        history.push("/sessionExpired");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        history.push("/BadRequest");
        break;
      default:
        toast.error("Error Occurred");
    }
  }
};

export const fetchAllCitiesForEmpanelment = () => async (dispatch) => {
  try {
    const response = await AuctionPortal.get("/masters/cityForEmpanelment", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    dispatch({ type: FETCH_ALL_CITIES, payload: response.data });
  } catch (error) {
    switch (error.response.status) {
      case 408:
        history.push("/sessionExpired");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        history.push("/BadRequest");
        break;
      default:
        toast.error("Error Occurred");
    }
  }
};

export const fetchCity = (sid, cid) => async (dispatch) => {
  try {
    const response = await AuctionPortal.get(
      `/masters/state/${sid}/city/${cid}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    dispatch({ type: FETCH_CITY, payload: response.data });
  } catch (error) {
    switch (error.response.status) {
      case 408:
        history.push("/sessionExpired");
        dispatch({ type: USER_LOGOUT });
        break;
      case 401:
        history.push("/NotAuthorised");
        break;
      case 404:
        history.push("/PageNotFound");
        break;
      case 400:
        history.push("/BadRequest");
        break;
      default:
        toast.error("Error Occurred");
    }
  }
};

export const editCity = (id, sid, cid, formValues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.put(
        `/masters/state/${sid}/city/${cid}`,
        {
          ...formValues,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: EDIT_CITY, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.push(`/masters/location`);
      toast("Edited Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const deleteCity = (id, sid, cid, formValues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.put(
        `/masters/state/${sid}/city/${cid}`,
        {
          ...formValues,
          ActiveFLag: "N",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: DELETE_CITY, payload: response.data.id });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.push(`/masters/region/${id}/state/${sid}/city`);
      toast.error("Deleted Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};

export const createCity = (id, sid, formValues) => async (dispatch) => {
  if (isSessionExpired()) {
    history.push("/SessionExpired");
  } else {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      const response = await AuctionPortal.post(
        `/masters/state/${sid}/city/`,
        formValues,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      dispatch({ type: CREATE_CITY, payload: response.data });

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.goBack();
      toast("Added Successfully");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      switch (error.response.status) {
        case 408:
          history.push("/MultipleLogin");
          dispatch({ type: USER_LOGOUT });
          break;
        case 401:
          history.push("/NotAuthorised");
          break;
        case 403:
          history.push("/acessforbidden");
          break;
        case 404:
          history.push("/PageNotFound");
          break;
        case 400:
          if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
          } else {
            toast.error(error.response.data.Description[0]);
          }
          break;
        default:
          toast.error("Error Occurred");
      }
    }
  }
};
