import _ from "lodash";

import { FETCH_ROLEs } from "../../actions/types";

const rolesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ROLEs:
      return { ...state, ..._.mapKeys(action.payload, "role_Id") };

    default:
      return state;
  }
};

export default rolesReducer;
