import { combineReducers } from "redux";
import natureOfLossReducers from "./masters/natureOfLossReducers";
import fraudReducers from "./masters/fraudReducers";
import groupmappingReducers from "./masters/groupmappingReducers";
import { reducer as formReducer } from "redux-form";
import eventMasterReducers from "./masters/EventMasterReducers";
import productReducers from "./masters/productReducers";
import defaultTimeReducer from "./masters/defaultTimeReducers";
import subProductReducers from "./masters/subProductReducers";
import regionReducers from "./masters/regionReducers";
import stateReducers from "./masters/stateReducers";
import cityReducers from "./masters/cityReducers";
import eventReducrers from "./event/eventReducers";
import loginReducers from "./login/loginReducers";
import lastLoginReducer from "./login/lastLoginReducer";
import userReducers from "./masters/userReducers";
import LDAPReducers from "./masters/LDAPReducers";
import eventParameterReducers from "./event/eventParameterReducers";
import buyerEventMappingReducers from "./event/buyerEventMappingReducers";
import eventBidderReducers from "./event/eventBidderReducers";
import buyerReducers from "./masters/buyerReducers";
import buyerRmcReducer from "./masters/buyerRmcReducer";
import BuyerEmpanelmentReducers from "./BuyerEmpanelment/BuyerEmpanelmentReducers";
import { USER_LOGOUT } from "../actions/types";
import rolesReducer from "./masters/rolesReducer";
import eventDocumentReducers from "./event/eventDocumentReducers";
import buyerDocReducers from "./masters/buyerDocReducers";
import buyerEmpanelmentDocReducers from "./BuyerEmpanelment/BuyerEmpanelmentDocReducers";
import paymentRefundReducers from "./BuyerEmpanelment/PaymentRefund/PaymentRefundReducers";
import buyerDigiLockerDocumentsReducers from "./BuyerEmpanelment/BuyerDigiLockerDocumentReducers";
import otpReducers from "./login/otpReducers";
import reportsReducers from "./Reports/reportsReducers";
import eventAuditTrailReducers from "./event/eventAuditTrailReducers";
import currentTimeReducer from "./masters/currentTimeReducer";

const appReducers = combineReducers({
  form: formReducer,
  natureofloss: natureOfLossReducers,
  fraudType: fraudReducers,
  groupmapping: groupmappingReducers,
  product: productReducers,
  eventMaster: eventMasterReducers,
  defaultTime: defaultTimeReducer,
  subProduct: subProductReducers,
  region: regionReducers,
  state: stateReducers,
  city: cityReducers,
  event: eventReducrers,
  login: loginReducers,
  auditTrailHistory: eventAuditTrailReducers,
  eventParameter: eventParameterReducers,
  eventBidders: eventBidderReducers,
  userMaster: userReducers,
  LDAPUser: LDAPReducers,
  buyer: buyerReducers,
  buyerRmc: buyerRmcReducer,
  buyerEmpanelment: BuyerEmpanelmentReducers,
  buyerPaymentRefund: paymentRefundReducers,
  buyerEventMapping: buyerEventMappingReducers,
  role: rolesReducer,
  eventDocument: eventDocumentReducers,
  buyerDocument: buyerDocReducers,
  buyerEmpanelmentDocuments: buyerEmpanelmentDocReducers,
  buyerDigiLockerDocuments: buyerDigiLockerDocumentsReducers,
  otp: otpReducers,
  reports: reportsReducers,
  lastLogin: lastLoginReducer,
  currentTime: currentTimeReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }
  return appReducers(state, action);
};

export default rootReducer;
