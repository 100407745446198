import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchUsers } from "../../../actions/masters/userActions";
import Spinner from "../../Spinner";
import { fetchRoles } from "../../../actions/masters/roleActions";
import CryptoJS from "crypto-js";
const encryptId = (id) => {
  id = id.toString();
  let key = '';
  let iv = '';
  for (let index = 0; index < 4; index++) {
    key += '8080';
    iv += '8080';
  }
  key = CryptoJS.enc.Utf8.parse(key);
  iv = CryptoJS.enc.Utf8.parse(iv);

  let idx = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(id + '~' + new Date().toISOString()),
    key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();

  while (idx.includes('/')) {
    idx = idx.replace('/', 'zxcvb');
  }
  while (idx.includes('+')) {
    idx = idx.replace('+', 'plus');
  }

  return idx;
};
class UserList extends Component {
  componentDidMount = async () => {
    await this.props.fetchRoles();
    this.props.fetchUsers();
  };

  renderList() {
    return this.props.userMaster.map((user) => {
      return (
        <tr key={user.id}>
          <td data-th="User Name" className="">
            {user.user_name}
          </td>
          <td data-th="User ID" className="">
            {user.user_id}
          </td>
          <td data-th="Email Address" className="">
            {user.email_id}
          </td>
          <td data-th="Role" className="">
            {
              this.props.roles.filter(
                (pr) => pr.role_Id === parseInt(user.role_id)
              )[0].role_name
            }
          </td>
          <td data-th="Active/Inactive" className="">
            {user.active_flag === "Y" ? "Active" : "InActive"}
          </td>

          {this.props.user.role === "Admin" ? (
            <td className="">
              <Link to={`/masters/users/Edit/${encryptId(user.user_id)}`}>
                <span>
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </span>
              </Link>
            </td>
          ) : (
            ""
          )}
        </tr>
      );
    });
  }

  render() {
    return (
      <div>
        {this.props.userMaster.length > 0 ? (
          <div className="BodyInContainer">
            <div className="">
              <div className="d-block clearfix">
                <div className="Header-Content float-start">
                  <h3 className="pageMainTitle">User Master</h3>
                </div>
                {this.props.user.role === "Admin" ? (
                  <div className="float-end mt-15">
                    <Link className="btnMain" to="/masters/users/create">
                      Add
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="mainBodyCard pt-0">
                <table className="table table-hover tableStyle1">
                  <thead>
                    <tr>
                      <td>User Name</td>
                      <td>User ID</td>
                      <td>Email Address</td>
                      <td>Role</td>
                      <td>Active/Inactive</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>{this.renderList()}</tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userMaster: Object.values(state.userMaster).filter(
      (user) => parseInt(user.role_id) !== 5 && parseInt(user.role_id) !== 7
    ),
    user: state.login,
    roles: Object.values(state.role),
  };
};

export default connect(mapStateToProps, { fetchUsers, fetchRoles })(UserList);
